import jwtDecode from "jwt-decode";
import  moment from "moment";
import { toast } from "react-toastify";
import http from "./httpService";
import avatarPlaceholder from "../assets/userAvatar-placeholder.svg";

const apiEndpointLogin = "/user/login";
const tokenKey = "cmse_uid";

http.setRequestHeaderAuth(getJwt());

export async function login(email, password) {
  const { data } = await http.post(apiEndpointLogin, { email, password });
  localStorage.setItem(tokenKey, data.token);
  return { data }
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export async function refreshToken() {
  try {
    const { data } = await http.get('/user/refresh');
    if ( !data.success ) {
      logout();
      return
    }
    localStorage.setItem(tokenKey, data.token);
    http.setRequestHeaderAuth(getJwt());
  } catch (error) {
    logout();
  }
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const loginToken = jwtDecode(jwt);
    const isExpired = moment(loginToken.expires).isSameOrBefore(new Date());
    const now = moment(new Date());
    const expires = moment(loginToken.expires);
    const timeLeft = expires.diff(now, 'minutes');

    if (isExpired) {
      toast.error("Your session has expired. Please log in again.", {
        toastId: 'sessionExpires'
      });
      logout();
      return null
    }

    timeLeft <= loginToken.session_time_left && refreshToken();

    const user = {
      name: loginToken.user.first_name + ' ' + loginToken.user.last_name,
      firstName: loginToken.user.first_name,
      lastName: loginToken.user.last_name,
      email: loginToken.user.email,
      roleId: loginToken.user.role_id,
      role: loginToken.user.role,
      department_id: loginToken.user.department_id,
      department_name: loginToken.user.department_name,
      avatar: (loginToken.user.avatar) ? process.env.REACT_APP_API_URL + loginToken.user.avatar : avatarPlaceholder
    }
    return user;
  } catch (ex) {
    return null;
  }
}

export function getCurrentUserPermisions() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    const loginToken = jwtDecode(jwt);
    const permissions = loginToken.user.permissions;
    return permissions;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}

export async function resetPassword(email) {
  const { data } = await http.post('/user/reset_password', { email });
  return { data }
}

export default {
  login,
  logout,
  getCurrentUser,
  getCurrentUserPermisions,
  getJwt,
  resetPassword
};
