import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

export function staffDataModel() {
  return {
    //Personal Info
    photo: "",
    first_name: "", //String
    last_name: "", //String
    middle_name: "", //String
    home_phone: "", //String
    address_1: "", //String
    city: "", //String
    state: "", //String
    zip_code: "", //String
    paycom_id: "", //String
    work_phone: "", //String

    // Aditional Information
    email: "", //String
    gender: "", //String
    marital_status: "", //String
    us_veteran: false, //Boolean
    national_guard: false, //Boolean
    contact_name: "", //String
    contact_phone_1: "", //String
    has_car: false, //Boolean
    has_blocked_phone: false, //Boolean
    email_opt_in: false, //Boolean
    sms_opt_in: false, //Boolean
    american_indian: false, //Boolean
    alaskan_native: false, //Boolean
    asian_pacific_islander: false, //Boolean
    black_not_hispanic: false, //Boolean
    hispanic: false, //Boolean
    white_not_hispanic: false, //Boolean

    // CMS Info
    department_id: '', //Integer
    birth_date: null, //Date
    working_status: "" , //String
    trained: false, //Boolean
    hire_date: null, //Date
    termination_date: null, //Date
    public_facing: false,  //Boolean
    blazer_size_id: '', //Integer
    coat_size_id: '', //Integer
    shirt_size_id: '', //Integer
    tie: false, //Boolean
    security_license_number: '',
    alcohol_license_number: '',
    flagger_license_number: '',
    trained_crowd_license_number: '',
    department_name: null
  }
}

export function staffSchema(isEditing) {
  return {
    id: Joi.number()
      .integer().allow(null, ''),
    photo: Joi.string().allow([ null, '' ]),
    first_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("First Name"),
    middle_name: Joi.string()
      .allow(null, '')
      .label("Middle Name"),
    last_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Last Name"),
    home_phone: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Home Phone"),
    address_1: Joi.string()
      .required()
      .label("Address"),
    city: Joi.string()
      .required()
      .label("City"),
    state: Joi.string()
      .required()
      .label("State"),
    zip_code: Joi.string()
      .required()
      .regex(/^\d{5}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.zipcodeInvalid }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Zipcode"),
    paycom_id: Joi.string()
      .allow(null, ''),
    last_updated: Joi.string()
      .allow(null, ''),
    work_phone: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Work Phone"),
    // Aditional Information
    email: Joi.string()
      .email()
      .allow(null, '')
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.email":
              errorMessage = {message: messages.error.mailInvalid }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Email"),
    gender: Joi.string()
      .allow(null, '')
      .label("Gender"),
    marital_status: Joi.string()
      .allow(null, '')
      .label("Marital Status"),
    us_veteran: Joi.boolean()
      .allow(null, '')
      .label("US Veteran"),
    national_guard: Joi.boolean()
      .allow(null, '')
      .label("National Guard"),
    contact_name: Joi.string()
      .allow(null, '')
      .label("Contact Name"),
    email_opt_in: Joi.boolean()
      .allow(null, '')
      .label("Email Opt In/Out"),
    sms_opt_in: Joi.boolean()
      .allow(null, '')
      .label("SMS Opt In/Out"),
    contact_phone_1: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Contact Phone 1"),
    has_car: Joi.boolean()
      .allow(null, '')
      .label("Has Car"),
    has_blocked_phone: Joi.boolean()
      .allow(null, '')
      .label("Has Blocked Phone"),
    american_indian: Joi.boolean()
      .allow(null, '')
      .label("American Indian"),
    alaskan_native: Joi.boolean()
      .allow(null, '')
      .label("Alaskan Native"),
    asian_pacific_islander: Joi.boolean()
      .allow(null, '')
      .label("Asian of Pacific Islander"),
    black_not_hispanic: Joi.boolean()
      .allow(null, '')
      .label("Black, not of Hispanic Origin"),
    hispanic: Joi.boolean()
      .allow(null, '')
      .label("Hispanic"),
    white_not_hispanic: Joi.boolean()
      .allow(null, '')
      .label("White, not of Hispanic Origin"),
    // CMS Info
    department_name: Joi.string()
      .allow(null, ''),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Department"),
    birth_date: Joi.date()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Birth Date"),
    working_status: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Working Status"),
    trained: Joi.boolean()
      .allow(null, '')
      .label("Trained"),
    hire_date: Joi.date()
      .allow(null, '')
      .label("Hire Date"),
    termination_date: Joi.date()
      .allow(null, '')
      .label("Termination Date"),
    public_facing: Joi.boolean()
      .allow(null, '')
      .label("Public Facing"),
    blazer_size_id: Joi.number()
      .integer()
      .allow(null, '')
      .label("Blazer Size"),
    coat_size_id: Joi.number()
      .integer()
      .allow(null, '')
      .label("Coat Size"),
    shirt_size_id: Joi.number()
      .integer()
      .allow(null, '')
      .label("Shirt Size"),
    tie: Joi.boolean()
      .allow(null, '')
      .label("Tie"),
    alcohol_license_date: Joi.date()
      .allow(null, ''),
    alcohol_license_number: Joi.string()
      .allow(null, ''),
    flagger_license_date: Joi.date()
      .allow(null, ''),
    flagger_license_number: Joi.string()
      .allow(null, ''),
    security_license_date: Joi.date()
      .allow(null, ''),
    security_license_number: Joi.string()
      .allow(null, ''),
    trained_crowd_license_date: Joi.date()
      .allow(null, ''),
    trained_crowd_license_number: Joi.string()
      .allow(null, ''),
  }
}

export function staffEditMapToViewModel(staffItem, avatar) {
  return {
    address_1: ( staffItem.address_1 ) ? staffItem.address_1 : "",
    alaskan_native: ( staffItem.alaskan_native ) ? staffItem.alaskan_native : false,
    alcohol_license_date: staffItem.alcohol_license_date,
    alcohol_license_number: ( staffItem.alcohol_license_number ) ? staffItem.alcohol_license_number : "",
    american_indian: ( staffItem.american_indian ) ? staffItem.american_indian : false,
    asian_pacific_islander: ( staffItem.asian_pacific_islander ) ? staffItem.asian_pacific_islander : false,
    birth_date: staffItem.birth_date,
    black_not_hispanic: ( staffItem.black_not_hispanic ) ? staffItem.black_not_hispanic : false,
    blazer_size_id: ( staffItem.blazer_size_id ) ? staffItem.blazer_size_id : "",
    city: ( staffItem.city ) ? staffItem.city : "",
    coat_size_id: ( staffItem.coat_size_id ) ? staffItem.coat_size_id : "",
    contact_name: ( staffItem.contact_name ) ? staffItem.contact_name : "",
    contact_phone_1: ( staffItem.contact_phone_1 ) ? staffItem.contact_phone_1 : "",
    department_id: ( staffItem.department_id ) ? staffItem.department_id : "",
    department_name: ( staffItem.department_name ) ? staffItem.department_name : "",
    email: ( staffItem.email ) ? staffItem.email : "",
    first_name: ( staffItem.first_name ) ? staffItem.first_name : "",
    flagger_license_date: staffItem.flagger_license_date,
    flagger_license_number: ( staffItem.flagger_license_number ) ? staffItem.flagger_license_number : "",
    trained_crowd_license_date: staffItem.trained_crowd_license_date,
    trained_crowd_license_number: ( staffItem.trained_crowd_license_number ) ? staffItem.trained_crowd_license_number : "",
    gender: ( staffItem.gender ) ? staffItem.gender : "",
    has_blocked_phone: ( staffItem.has_blocked_phone ) ? staffItem.has_blocked_phone : false,
    has_car: ( staffItem.has_car ) ? staffItem.has_car : false,
    hire_date: staffItem.hire_date,
    hispanic: ( staffItem.hispanic ) ? staffItem.hispanic : false,
    home_phone: ( staffItem.home_phone ) ? staffItem.home_phone : "",
    id: staffItem.id,
    last_name: ( staffItem.last_name ) ? staffItem.last_name : "",
    last_updated: ( staffItem.last_updated ) ? staffItem.last_updated : "N/A",
    marital_status: ( staffItem.marital_status ) ? staffItem.marital_status : "",
    middle_name: ( staffItem.middle_name ) ? staffItem.middle_name : "",
    national_guard: ( staffItem.national_guard ) ? staffItem.national_guard : false,
    paycom_id: ( staffItem.paycom_id ) ? staffItem.paycom_id : "N/A",
    photo: ( staffItem.photo ) ? avatar : "",
    public_facing: ( staffItem.public_facing ) ? staffItem.public_facing : false,
    security_license_date: staffItem.security_license_date,
    security_license_number: ( staffItem.security_license_number ) ? staffItem.security_license_number : "",
    shirt_size_id: ( staffItem.shirt_size_id ) ? staffItem.shirt_size_id : "",
    state: ( staffItem.state ) ? staffItem.state : "",
    termination_date: staffItem.termination_date,
    tie: ( staffItem.tie ) ? staffItem.tie : false,
    trained: ( staffItem.trained ) ? staffItem.trained : false,
    us_veteran: ( staffItem.us_veteran ) ? staffItem.us_veteran : false,
    white_not_hispanic: ( staffItem.white_not_hispanic ) ? staffItem.white_not_hispanic : false,
    work_phone: ( staffItem.work_phone ) ? staffItem.work_phone : "",
    working_status: ( staffItem.working_status ) ? staffItem.working_status.toLowerCase() : "",
    zip_code: ( staffItem.zip_code ) ? staffItem.zip_code : "",
    email_opt_in: ( staffItem.email_opt_in ) ? staffItem.email_opt_in : false,
    sms_opt_in: ( staffItem.sms_opt_in ) ? staffItem.sms_opt_in : false
  }
}

export function staffPositionsMapToViewModel( staffPositions ) {
  let mapViewModel = [];
  for (let i = 0; i < staffPositions.length; i++) {
    const element = staffPositions[i];
    mapViewModel.push({
      class: (element.class) ? element.class : '',
      id: element.id,
      logs: element.logs,
      maximum_rate: (element.maximum_rate) ? element.maximum_rate : '',
      minimum_rate: (element.minimum_rate) ? element.minimum_rate : '',
      position_id: element.position_id,
      position_name: (element.position_name) ? element.position_name : '',
      regular_pay_rate: (element.regular_pay_rate) ? element.regular_pay_rate : '',
      active: element.active ? true : false,
      staff_id: element.staff_id,
      travel_pay_rate: (element.travel_pay_rate) ? element.travel_pay_rate : '',
      travel_rate: element.travel_rate,
    })
  }

  return  mapViewModel;

}

export default {
  staffSchema,
  staffEditMapToViewModel,
  staffPositionsMapToViewModel
}