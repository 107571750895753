import React, { useEffect, useState } from 'react';

function EventStatusCountdown({ event, dispatchEvent }) {
  const [showCountdown, setShowCountdown] = useState(true);

  let counter = 0
  const calculateCountdown = () => {
    const remainingTime = event.remaining_start_time;

    if (!remainingTime) return false;

    counter ++
    let seconds = parseInt(remainingTime, 10);
    seconds -= counter
    let days = Math.floor(seconds / (3600*24));
    seconds -= days*3600*24;
    let hours = Math.floor(seconds / 3600);
    seconds -= hours*3600;
    let minutes = Math.floor(seconds / 60);
    seconds -= minutes*60;

    days = days < 10 ? "0" + days.toString() : days.toString();
    hours = hours < 10 ? "0" + hours.toString() : hours.toString();
    minutes =  minutes < 10 ? "0" + minutes.toString() : minutes.toString();
    seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString() ;

    if (days === '00' && hours === '00' && minutes === '00' && seconds === '00' ){
      setShowCountdown(false);
      return false
    }
    const timeLeft = { days, hours, minutes, seconds }

    return timeLeft

  };
  const [timeLeft, setTimeLeft] = useState(calculateCountdown());

  useEffect(() => {
    let countdown = setInterval(() => {
      setTimeLeft(calculateCountdown());
    }, 1000);

    !showCountdown && dispatchEvent({ TYPE: 'EVENT_STATUS', VALUE: 'Initiated'});
    return () => {
      clearInterval(countdown)
    }
  },[showCountdown]);

  return (
    <>
    {showCountdown &&
      <div className="EventStatusCountdown">
        { timeLeft.days !== '00' &&
        <>
          <span className="EventStatusCountdown-number days">
            {timeLeft.days}
          </span>
          <span className="EventStatusCountdown-label">days</span>
        </>
        }
        <span className="EventStatusCountdown-number hours">
          {timeLeft.hours}
        </span>
        <span className="EventStatusCountdown-label">:</span>
        <span className="EventStatusCountdown-number minutes">
          {timeLeft.minutes}
        </span>
        <span className="EventStatusCountdown-label">:</span>
        <span className="EventStatusCountdown-number seconds">
          {timeLeft.seconds}
        </span>
      </div>
    }
    </>
  )
}

export default EventStatusCountdown
