import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getPayrollReportHistory } from '../../../services/logsService';
import Pagination from '../../Common/pagination/pagination';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, LogsContext } from '../Logs';
import './PayrollHistory.scss';

function PayrollHistory() {
  const logsContext = useContext(LogsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [payrollReportHistory, setPayrollReportHistory ] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "desc" });
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      path: 'created_date',
      label: 'Date Created'
    },
    {
      path: 'department',
      label: 'Department',
      disableSort: true
    },
    {
      path: 'filters',
      label: 'Filters'
    },
    {
      path: 'user',
      label: 'User',
      disableSort: true,
    },
    {
      path: 'reports_files',
      label: 'Files',
      disableSort: true,
      content: item =>  (
        item.reports_files.map( (file, idx) => 
          <a key={idx} href={process.env.REACT_APP_API_URL + '/' + file.file_path} target="_blank" rel="noopener noreferrer">
            <i aria-hidden="true" className="fa fa-download"></i>&nbsp;{file.file_name}
          </a>
        )
      )
    }
  ];

  useEffect(() => {
    const fetchPayrollHistory = async () => {
      const { data } = await getPayrollReportHistory({
        LIMIT: pageSize,
        PAGE: currentPage,
        ORDER_BY: sortColumn.path,
        SORT_ORDER: sortColumn.order
      });
      if (!data.success) {
        toast.error('Error on get Payroll History data.');
        return
      }
      setPayrollReportHistory(data.logs);
      setTotalCount(data.count);
      setLoading(false);
    };
    fetchPayrollHistory();
  }, [currentPage, sortColumn]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={logsContext.props.location} />
      : <section className="PayrollHistory content-wrapper container-fluid">
          <Table
            name="PayrollHistoryTable"
            columns={columns}
            data={payrollReportHistory}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={loading}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={ page => setCurrentPage(page) }
          />
        </section>
  )
}

export default PayrollHistory
