import React from 'react';
import Input from '../../../Common/form/input';
import TextArea from '../../../Common/form/textarea';
import { Modal , Button } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

  const renderInput = (name, label, handleChange, data) => {
    return (
      <Input
        type={'text'}
        label={label}
        name={name}
        value={data[name]}
        onChange={handleChange}
      />
    )
  }

  const renderTextArea = (name, label, rows, handleChange, data) => {
    return (
      <TextArea
        name={name}
        label={label}
        onChange={handleChange}
        value={data[name]}
        rows={rows}
      />
    );
  }

  const HtmlTooltipPreview = withStyles(() => ({
    tooltip: {
      backgroundColor: '#ffc19f',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 620
    },
  }))(Tooltip);

const TemplatesEditModal = ({ isOpen, onRequestClose, closeModal, data, handleChange, handleSave }) => {
  return (
    (data) ?
    <Modal show={isOpen} onHide={onRequestClose} className="modal-edit-template" size="xl">
      <Modal.Header>
        <Modal.Title>Edit {data.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-3 justify-content-center">
          <div className="col-sm-12">
            {renderInput('subject', 'Communication Subject', handleChange, data )}
          </div>
          <div className="col-sm-6">
            {renderTextArea("content_text", "Mail Text Content", 15, handleChange, data)}
          </div>
          <div className="col-sm-6">
            <div className="preview-container">
              <HtmlTooltipPreview
                title={
                  <React.Fragment>
                    <div className="content-center" dangerouslySetInnerHTML={{ __html: data.content_html }}>
                    </div>
                  </React.Fragment>
                }
              >
                <span className="badge-preview">
                  <i className="fas fa-eye"></i>
                </span>
              </HtmlTooltipPreview>
            </div>
            {renderTextArea("content_html", "Mail HTML Content", 15, handleChange, data)}
          </div>
          <div className="col-sm-12">
            {renderInput('content_sms', 'SMS Content', handleChange, data )}
          </div>  
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" onClick={handleSave}>Save</Button>
      </Modal.Footer>
    </Modal>
    : ''
  );
}
export default TemplatesEditModal;
