import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";
import { currencyUSD } from '../../utils/value-formats';

class VolunteersList extends Component {
  columns = [
    { path: "name", label: "Name" },
    { path: "department", label: "Department", disableSort: true },
    { path: "rate", label: "Rate", content: item => (currencyUSD(item.rate)) },
    { path: "available_all_positions", label: "Available for all Position Types",
    content: item => (
      (item.available_all_positions)
        ? <i className="fas fa-check blueIcon"></i>
        : <i className="fas fa-close redIcon"></i>
    ) }
  ];

  deleteColumn = {
    key: "delete",
    path: "delete",
    disableSort: true,
    content: item => (
      <button
        onClick={() => this.props.onDelete(item)}
        className="btn btn-danger btn-sm btn-delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: item => (
      <Link to={`/volunteers/${item.id}`} className="btn btn-sm btn-edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    const authPermissions = auth.getCurrentUserPermisions();
    if (user) this.columns.push(this.editColumn);
    if (user && authPermissions["module_volunteers_delete"]) this.columns.push(this.deleteColumn);
  }

  render() {
    const { data, onSort, sortColumn, isLoading } = this.props;

    return (
      <Table
        name={'organizations'}
        columns={this.columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
      />
    );
  }
}

export default VolunteersList;
