import React, { useEffect, useState } from 'react';
import { Modal , Button } from 'react-bootstrap';
import { toast } from "react-toastify";
import { syncStaffPaycomMiddleware } from "../../../services/staffService";
import { messages } from "../../../utils/language-en.json";
import EmptyData from '../../Common/emptydata/emptyData.jsx';

const StaffPaycomSyncModal = ({ isOpen, closeModal, item }) => {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [success]);

  const handlePaycomSync = async () => {
    setLoading(true);
    setSuccess(false);

    const response = await syncStaffPaycomMiddleware();
    if (!response.success) {
      setLoading(false);
      setSuccess(false);
      toast.error(response.message);
      return
    }

    setTimeout(() => {
      setLoading(false);
      setSuccess(true);
      toast.success(response.message);

      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }, 120000);
  }

  return (
    <Modal show={isOpen} onHide={closeModal} className="modal-staff-paycom-sync">
      <Modal.Header closeButton>
        <Modal.Title>
          Paycom Sync
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="scroll-wrapper">          
          <h5>Last Run</h5>
          {item ? 
          <table className="table">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{item.start_date ? item.start_date : '-'}</td>
                <td>{item.end_date ? item.end_date : '-'}</td>
                <td>{item.user ? item.user : '-'}</td>
              </tr>
            </tbody>
          </table>
          : <EmptyData 
            type={"logs"}
            title={messages.emptydata.paycomLastRunTitle}
          />
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        { loading
            ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
            : success
                ? <i className="fas fa-check-square"></i>  
                : ''
        }
        <Button variant="success" onClick={() => handlePaycomSync()}>Run Paycom Sync</Button>
        <p className="disclaimer">Take into account this process may take between 12 and 15 minutes.</p>
      </Modal.Footer>
    </Modal>
  )
}
export default StaffPaycomSyncModal;
