import React, { useContext } from 'react';
import Input from '../../../Common/form/input';
import Select from '../../../Common/form/select';
import { DepartmentSettingsContext } from '../DepartmentsSettings';
import { weekDaysSelectOptions } from './DepartmentsSettingsUtils';

const FIELD_TYPE = {
  INPUT: 'input',
  NUMBER: 'number',
  SELECT: 'select'
}

function DepartmentTableCellView({item, fieldName, fieldType}) {

  const departmentSettingsContext = useContext(DepartmentSettingsContext);
  const editDepartment = departmentSettingsContext.state.editDepartment;

  return (
    <>
    {item.id 
      ? editDepartment && item.id === editDepartment.id 
        ? <RenderField name={fieldName} item={item} isEditing={true} fieldType={fieldType}/>
        : item[fieldName]
      :<RenderField name={fieldName} item={item} fieldType={fieldType} />
    }
    </>
  )
}

function RenderField({name, isEditing, fieldType }) {
  const departmentSettingsContext = useContext(DepartmentSettingsContext);
  const editDepartment = departmentSettingsContext.state.editDepartment;
  const setEditDepartment = departmentSettingsContext.action.setEditDepartment;
  const newDepartment = departmentSettingsContext.state.newDepartment;
  const setNewDepartment = departmentSettingsContext.action.setNewDepartment;

  const handleChange = ( isEditing ) => ({ currentTarget: input }) => {
    if ( isEditing ) {
      const data = { ...editDepartment };
      data[input.name] = input.value;
      setEditDepartment(data);
    } else {
      const dataNew = { ...newDepartment };
      dataNew[input.name] = input.value;
      setNewDepartment(dataNew);
    }
  };
  
  return (
    <>
    { fieldType === FIELD_TYPE.INPUT &&
      <Input
        type="text"
        name={name}
        value={isEditing ? editDepartment[name] : newDepartment[name] }
        placeholder={name === 'name' ? "Add Department*" : '' }
        onChange={handleChange(isEditing)}
        inputsize={'sm'}
      />
    }
    { fieldType === FIELD_TYPE.NUMBER &&
      <div className="input-group">
        <div className="input-group-prepend"><span className="input-group-text">$</span></div>
        <Input
          type="number"
          step="0.1"
          name={name}
          value={isEditing ? editDepartment[name] : newDepartment[name] }
          onChange={handleChange(isEditing)}
          inputsize={'sm'}
        />
      </div>
    }
    { fieldType === FIELD_TYPE.SELECT &&
      <Select
        name={name}
        value={isEditing ? editDepartment[name] : newDepartment[name] }
        options={weekDaysSelectOptions}
        onChange={handleChange(isEditing)}
        inputsize={'sm'}
      />
    }
    </>
  )
}

export default DepartmentTableCellView
