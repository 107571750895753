import React, { useState } from 'react';
import { Modal , Button } from 'react-bootstrap';
import Input from '../../Common/form/input';
import { toast } from "react-toastify";
import { messages } from '../../../utils/language-en.json';
import { changePasswordUser } from '../../../services/usersService';

const UserChangePasswordModal = ({ isOpen, closeModal, props }) => {
  const [newPassword, setNewPassword] = useState('');
  const renderInput = (name, label, handleChange, data, type, handleBlur) => {
    return (
      <Input
        type={type}
        label={label}
        name={name}
        value={data[name]}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )
  }

  const handleChange = ({ currentTarget: input }) => {
    setNewPassword(input.value);
  }

  const handleOnChangePassword = () => {
    try {
      changePasswordUser(props.data.id, newPassword);
      toast.success(messages.success.passwordChanged, { autoClose: 1000 });
      closeModal();
    } catch (ex) {
      const errorMessage = ex.message;
      toast.error(errorMessage);
    }
    return;
  }
  
  return (
    <Modal show={isOpen} onHide={closeModal} className="modal-user-change-password">
      <Modal.Header closeButton>
        <Modal.Title>
        <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp; Change Password
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="scroll-wrapper">
          <table className="table">
            <tbody>
                <tr>
                  <td>
                    {renderInput('password', 'New Password', handleChange, '', 'password' )}
                  </td>
                </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" disabled={newPassword.length<6} onClick={handleOnChangePassword}>Change Password</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UserChangePasswordModal;
