import React from 'react';
import EmptyDataIcon from "../../../../assets/cms-icon-empty-files.svg";
import { messages } from '../../../../utils/language-en.json';
import EmptyData from '../../../Common/emptydata/emptyData';
import PositionsPayRateSettingsCard from './PositionsPayRateSettingsCard';

function PositionsPayRateSettingsList({ positions }) {
  return (
    <div className="PositionsPayRateSettingsList">
      <div className="row">
        { positions.length === 0
            ? <EmptyData 
              type={"datatable"} 
              icon={EmptyDataIcon}
              title={messages.emptydata.tableTitle}
              messsage={messages.emptydata.tableCopy}
            />
            : positions.map( (item, idx) => (
                <div className="col-sm-3" key={idx}>
                  <PositionsPayRateSettingsCard item={item} />
                </div>
              ) )
        }
      </div>
    </div>
  )
}

export default PositionsPayRateSettingsList
