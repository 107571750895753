import React, { useContext } from 'react';
import Select from 'react-select';
import { EventStaffAssignedListContext } from '../EventStaffAssignedList';

function EventStaffAssignedListItemGroup(props) {
  const item = props.item;
  const eventStaffAssignedListContext = useContext(EventStaffAssignedListContext);
  const groupOptions = [
    { value: 0 , label: '', staff_event_id: item.staff_event_id },
    { value: 1 , label: 1, staff_event_id: item.staff_event_id },
    { value: 2 , label: 2, staff_event_id: item.staff_event_id },
    { value: 3 , label: 3, staff_event_id: item.staff_event_id },
    { value: 4 , label: 4, staff_event_id: item.staff_event_id },
    { value: 5 , label: 5, staff_event_id: item.staff_event_id },
    { value: 6 , label: 6, staff_event_id: item.staff_event_id },
    { value: 7 , label: 7, staff_event_id: item.staff_event_id },
    { value: 8 , label: 8, staff_event_id: item.staff_event_id },
    { value: 9 , label: 9, staff_event_id: item.staff_event_id },
    { value: 10, label: 10, staff_event_id: item.staff_event_id },
    { value: 11, label: 11, staff_event_id: item.staff_event_id },
    { value: 12, label: 12, staff_event_id: item.staff_event_id },
    { value: 13, label: 13, staff_event_id: item.staff_event_id },
    { value: 14, label: 14, staff_event_id: item.staff_event_id },
    { value: 15, label: 15, staff_event_id: item.staff_event_id },
    { value: 16, label: 16, staff_event_id: item.staff_event_id },
    { value: 17, label: 17, staff_event_id: item.staff_event_id },
    { value: 18, label: 18, staff_event_id: item.staff_event_id },
    { value: 19, label: 19, staff_event_id: item.staff_event_id },
    { value: 20, label: 20, staff_event_id: item.staff_event_id }
  ]
  return (
    <React.Fragment>
      { !item.exported
          ? !item.reconciled
              ?  <Select
                  className="select-style-default"
                  options={groupOptions}
                  value={groupOptions[item.group]}
                  onChange={groupSelected => eventStaffAssignedListContext.handleAssignedStaffPositionUpdate('group', groupSelected)}
                  isDisabled={item.staff_id === 0 }
                  menuPlacement="auto"
                />
              : item.group
          : item.group
      }
    </React.Fragment>
  );
}

export default EventStaffAssignedListItemGroup
