import React, { useContext } from 'react';
import defaultVolunteerAvatar from "../../../../../assets/volunteerAvatar-placeholder.svg";
import { EventStaffAvailableContext } from './EventStaffAvailable';

function EventStaffAvailableListNotification({ event }) {
  const eventStaffAvailableContext = useContext(EventStaffAvailableContext);
  const maximumNotifications = 3;
  const reason1 = '<li>Already added to the event</li>';
  const reason2 = '<li>Already replied to an invitation</li>';
  const reason3 = '<li>Has all notifications disabled</li>';
  const reason4 = '<li>Has reached the limit of notifications allowed</li>';

  const validateAvailability = (member) => {
    if ((member.disable_email_notification && member.disable_sms_notification) || 
      member.staff_selected || member.number_notification >= maximumNotifications ||
      member.notification_status === 'Accepted' || member.notification_status === 'Rejected') {
      return false
    }
    return true;
  }

  const reasonsNotifications = (member) => {
    var reasons = '<ul>';
    if (member.staff_selected) {
      reasons = reasons + reason1;
    }
    if (member.notification_status === 'Accepted' || member.notification_status === 'Rejected') {
      reasons = reasons + reason2;
    }
    if (member.disable_email_notification && member.disable_sms_notification) {
      reasons = reasons + reason3;
    }
    if (member.number_notification >= maximumNotifications) {
      reasons = reasons + reason4;
    }
    return {__html: reasons + '</ul>'};
  }

  // ** HANDLE
  // *********************************************************
  
  const handleSelectAvailableStaffModel = (member) => {
    let selected;
    const staffAvailableSelected = eventStaffAvailableContext.staffAvailable.selected;

    const selectMemberNotificationModel =  {
      staff_id: member.staff_id,
      staff_name: member.staff_name,
      type: member.type
    }

    const isSelected = staffAvailableSelected && staffAvailableSelected.find(item => item.staff_id === selectMemberNotificationModel.staff_id );
    if (isSelected) {
      const deselect = staffAvailableSelected.filter(m => m.staff_id !== selectMemberNotificationModel.staff_id);
      selected = deselect
    } else {
      selected = !staffAvailableSelected 
                    ? [selectMemberNotificationModel] 
                    : [...staffAvailableSelected, selectMemberNotificationModel] 
    }

    eventStaffAvailableContext.dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: selected });
    return
  }

  return (
    <ul className="list-unstyled">
      { eventStaffAvailableContext.staffAvailable.data && eventStaffAvailableContext.staffAvailable.data.map((member, idx) => {
          const isStaff = (validateAvailability(member)) ? '' : ' isStaff ';
          const isSelected =  eventStaffAvailableContext.staffAvailable.selected && eventStaffAvailableContext.staffAvailable.selected.find(item => item.staff_id === member.staff_id);
          const isSelectedStyle = isSelected ? ' isSelected ' : member.staff_selected ? '': ' notSelected ';

          return (
            <li className={"EventStaffAvailable-item d-flex align-items-center " + member.type + " justify-content-between" + isStaff + isSelectedStyle } 
              key={idx}
              onClick={() => validateAvailability(member) && handleSelectAvailableStaffModel(member) }
            >
              <div className="EventStaffAvailable-item-info d-flex align-items-center">
                <div className="avatar">
                  { member.type === 'Volunteer' && 
                    <img className="img-fluid" src={defaultVolunteerAvatar} alt="{member.staff_name}"/>
                  }
                </div>
                <h5 className="name">{member.staff_name}</h5>
                <div className="staff-member-info-overlay">
                  <h6 className="staff-member-info-overlay-name">{member.staff_name}</h6>
                  { member.type === 'Staff' &&
                    <div className="notification-details">
                      <p className="positions">
                        <strong>Last Notification:</strong> {member.last_notification}<br />
                        <strong>Email:</strong> {member.email}<br / >
                        <strong>Home Phone:</strong> {member.home_phone}<br />
                        <strong>Email Notification:</strong>&nbsp;
                        { !member.disable_email_notification && 
                          <span className="badge-notification-check">
                            <i className="fas fa-check-circle"></i>
                          </span>
                        }
                        { member.disable_email_notification && 
                          <span className="badge-notification-ban">
                            <i className="fas fa-ban"></i>
                          </span>
                        }
                        <br / >
                        <strong>SMS Notification:</strong>&nbsp;
                        { !member.disable_sms_notification &&
                          <span className="badge-notification-check">
                            <i className="fas fa-check-circle"></i>
                          </span>
                        }
                        { member.disable_sms_notification && 
                          <span className="badge-notification-ban">
                            <i className="fas fa-ban"></i>
                          </span>
                        }
                      </p>
                        
                      { isStaff === ' isStaff ' &&
                        <div className="notice">
                          <span className="badge-notification-ban">
                            <i className="fas fa-ban"></i>
                          </span>&nbsp;
                          Can't be notified because this staff member:
                          <div dangerouslySetInnerHTML={reasonsNotifications(member)} />
                        </div>
                      }
                    </div>
                  }
                  { member.type === 'Volunteer' &&
                    <div className="notification-details">
                      <p className="notice">
                        <span className="badge-notification-ban">
                          <i className="fas fa-ban"></i>
                        </span>&nbsp;
                        Can't be notified because this staff member:
                        <ul>
                          <li>Is a volunteer</li>
                        </ul>
                      </p>
                    </div>
                  }
                </div>
              </div>
              
              { member.type === 'Staff' &&
                <div className="staff-badges">
                  <div className="circle-wrapper">
                    <div className={"circle " + member.notification_status.replace(/\s/g, '')}></div>
                  </div>
                  <div className="notification-status">
                    {member.notification_status}&nbsp;
                    { (member.number_notification > 0 ) ? "(" + member.number_notification + ")" : "" }
                  </div>
                  <div className="notification-type">
                    {member.notification_type}
                  </div>
                </div>
              }
            </li>
          )
        })
      }
    </ul>
  )
}

export default EventStaffAvailableListNotification
