import React, { useContext, useEffect } from 'react';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import StatesSettingsList from './StatesSettingsUtils/StatesSettingsList';
import './StatesSettings.scss';

function StatesSettings() {
    const settingsContext = useContext(SettingsContext);
    const swipeableViewsContext = useContext(SwipeableViewsContext);
    
    useEffect(() => {
      swipeableViewsContext.updateSwipeableHeight();
    });

    return (
      !getCurrentUser()
        ? <RedirectToLogin location={settingsContext.props.location} />
        : <section className="StatesSettings content-wrapper container-fluid">
            <StatesSettingsList />
          </section>
    )
}

export default StatesSettings