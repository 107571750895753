import React from 'react';
import { currencyUSD } from '../../../../utils/value-formats';

function PositionsPayRateSettingsReadOnly({ item }) {

  return (
    <section className="PositionsPayRateSettingsEdit">
      <div className="row">
        <div className="col-6">
          <div className="form-group read-only">
            <label>Min Rate</label>
            <p>{currencyUSD(item.minimum_rate)}</p>
          </div>
        </div>  
        <div className="col-6">
          <div className="form-group read-only">
            <label>Max Rate</label>
            <p>{currencyUSD(item.maximum_rate)}</p>
          </div>
        </div>  
      </div>
    </section>
  )
}

export default PositionsPayRateSettingsReadOnly
