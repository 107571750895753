import React, { useContext, useEffect, useState } from 'react';
import { EventReconciliationListContext } from '../EventReconciliationList';

function EventReconciliationListItemCheckbox(props) {
  const item = props.item;
  const field = props.field;
  const disabled = props.disabled;
  const eventReconciliationListContext = useContext(EventReconciliationListContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [item, field]);

  return (
    <button className="btn checkbox" 
      disabled={disabled 
        || (item.volunteer && field === 'salary') 
        || (item.reconciled && field !== 'reconciled')}
      onClick={() => {
        eventReconciliationListContext.handleUpdateReconciliation(field, item)
        setLoading(true)
      }}
    >
      { loading
          ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          : item[field] 
              ? <i className="fas fa-check-square"></i>  
              : <i className="far fa-square"></i>
      }
    </button>
  )
}

export default EventReconciliationListItemCheckbox
