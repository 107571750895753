import React, { useState, useEffect } from 'react';
import "./updateCheckIn.scss"
import { Modal , Button } from 'react-bootstrap';
import Input from '../../../../Common/form/input';
import moment from 'moment';

const renderInput = (name, label, type, handleChange) => {
  const inputsize = 'sm';
  return (
    <Input
      type={type}
      label={label}
      name={name}
      maxLength='10'
      inputsize={inputsize}
      onChange={handleChange}
    />
  )
}

const UpdateCheckIn = ({ isOpen, onRequestClose, closeModal, item, onCheckIn, handleChange }) => {
  const [calculatedTime, setCalculatedTime] = useState(null);
  const calculateTime = () => {
      let currentDate = moment().format();
      let rounded = Math.round(moment(currentDate).minute() / 15) * 15;  
      if (rounded < 10) {
        rounded = '0' + rounded;
      }
      currentDate = moment().minutes(rounded);
      setCalculatedTime(currentDate);
    };
  
    useEffect(() => {
      let countdown = setInterval(() => {
        setCalculatedTime(calculateTime);
      }, 1000);
  
      return () => {
        clearInterval(countdown)
      }
    });

  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-checkin" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{item.staff_name}</h1>
        <h2>{moment(calculatedTime).format('dddd, MMMM Do')}</h2>
        <h2>{moment(calculatedTime).format('HH:mm')}</h2>
        <div className="equipment">
          <div className="col-12">
            <h3 className="form-group-label">Equipment</h3>
          </div>
          {item.checkAction === 'Check In' && 
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-3">
              {renderInput('shirt_number', 'Shirt', 'text', handleChange )}
            </div>
            <div className="col-3">
              {renderInput('jacket_number', 'Jacket', 'text', handleChange )}
            </div>
            <div className="col-3">
              {renderInput('radio_number', 'Radio', 'text', handleChange )}
            </div>
            <div className="col-3">
              {renderInput('flagger_number', 'Flagger', 'text', handleChange )}
            </div>
          </div>
          }
          {item.checkAction === 'Check Out' && 
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-3">
              <label>Shirt</label>
              <p><strong>{item.shirt_number}&nbsp;</strong></p>
            </div>
            <div className="col-3">
              <label>Jacket</label>
              <p><strong>{item.jacket_number}&nbsp;</strong></p>
            </div>
            <div className="col-3">
              <label>Radio</label>
              <p><strong>{item.radio_number}&nbsp;</strong></p>
            </div>
            <div className="col-3">
              <label>Flagger</label>
              <p><strong>{item.flagger_number}&nbsp;</strong></p>
            </div>
          </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={item.buttonType} onClick={onCheckIn}>{item.checkAction}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UpdateCheckIn;            