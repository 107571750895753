import http from "./httpService";

const apiEndpoint = "/widgets";

export function getEventsDepartment(year) {
  return http.get(apiEndpoint + '/list_events_department', { 
    params: {
      year: year 
    }
  });
}

export function getAverageRateDepartment(year) {
  return http.get(apiEndpoint + '/list_average_rate_department', { 
    params: {
      year: year 
    }
  });
}

export function getStaffEventsDepartment(year) {
  return http.get(apiEndpoint + '/list_staff_events_department', { 
    params: {
      year: year 
    }
  });
}

export function getEventsStatus(year, showAll) {
  return http.get(apiEndpoint + '/list_events_status', { 
    params: {
      year: year,
      show_all: showAll
    }
  });
}

export function getEventsMonth(year) {
  return http.get(apiEndpoint + '/list_events_month', { 
    params: {
      year: year 
    }
  });
}

export function getApplicantsDepartment(year) {
  return http.get(apiEndpoint + '/list_applicants_department', { 
    params: {
      year: year 
    }
  });
}

export function getStaffInvalidPaycom() {
  return http.get(apiEndpoint + '/list_no_paycom');
}

export function getActivityLatest() {
  return http.get(apiEndpoint + '/list_activity_log_latest');
}

export function getStaffLicenseExpiration() {
  return http.get(apiEndpoint + '/list_license_expiration');
}

export function getPaycomSyncLatest() {
  return http.get(apiEndpoint + '/get_paycom_latest');
}

export function getUnreconciledEvents() {
  return http.get(apiEndpoint + '/list_unreconciled_events');
}

export function getUpcomingEvents() {
  return http.get(apiEndpoint + '/list_upcoming_events');
}