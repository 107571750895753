import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import auth from "../services/authService";

function Logout() {
  
  useEffect(() => {
    auth.logout();
  }, []);

  return (
    <Redirect to="/login" />
  )
}

export default Logout
