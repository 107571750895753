import React from 'react';
import Select from "react-select";

export default function FilterByApplicantStatus(props) {
  const { handleStatusChange } = props;
  const statusOptions =  [
    { value: '', label: 'All' },
    { value: 'Not Contacted', label: 'Not Contacted' },
    { value: 'Contacted' , label: 'Contacted' },
    { value: 'Hired' , label: 'Hired' },
    { value: 'Not Hired', label: 'Not Hired' }
  ];

  return (
    <div className="filter-by-section">
      <Select
        name="selectStatus"
        placeholder="Filter by Status"
        options={statusOptions}
        defaultValue={ statusOptions[0] }
        className="basic-select"
        classNamePrefix="select"
        onChange={handleStatusChange}
      />
    </div>
  )
}
