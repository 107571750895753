import React, { useEffect, useState, useReducer, useLayoutEffect} from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { PageWrapperHeight } from '../../../utils/LayoutSizes';
import { getCurrentUser } from '../../../services/authService';
import { fetchEventMiddleware } from '../../../services/eventsService';
import { UserPermissions } from '../../../utils/UserPermissions';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import Loading from '../../Common/loading/loading';
import FilesManager from '../../Common/files-manager/filesManager';
import EventStatus from './EventTools/EventStatus/EventStatus';
import SwipeableViews from 'react-swipeable-views';
import EventInfo from './EventInfo/EventInfo';
import EventStaff from './EventStaff/EventStaff';
import EventCheckIn from './EventCheckIn/EventCheckIn';
import EventReconciliation from './EventReconciliation/EventReconciliation';
import EventInfoSummary from './EventTools/EventInfoSummary/EventInfoSummary';
import EventReport from './EventReport/EventReport';
import EventSummary from './EventSummary/EventSummary';
import EventInvoicing from './EventInvoicing/EventInvoicing';
import './Event.scss';

export const EventFetchContext = React.createContext();
export const EventContext = React.createContext();
export const SwipeableViewsContext = React.createContext();

const initialEvent = {
  loading: true,
  data: null,
  error: null
};

const initialFetchData = {
  EVENT: false,
  EVENT_STAFF_POSITIONS: false,
  EVENT_STAFF_STATS: false,
  EVENT_STAFF_ASSIGNED: false,
  EVENT_STAFF_AVAILABLE: false,
  EVENT_CHECKIN: false,
  EVENT_RECONCILIATION: false,
  EVENT_SUMMARY: false,
  EVENT_EXPENSES: false
}

const ACTION = {
  EVENT_LOADING: 'EVENT_LOADING',
  EVENT_DATA: 'EVENT_DATA',
  EVENT_ERROR: 'EVENT_ERROR',
  EVENT_STATUS: 'EVENT_STATUS',
  FETCH_DATA: {
    EVENT: 'EVENT',
    EVENT_STAFF_POSITIONS: 'EVENT_STAFF_POSITIONS',
    EVENT_STAFF_STATS: 'EVENT_STAFF_STATS',
    EVENT_STAFF_AVAILABLE: 'EVENT_STAFF_AVAILABLE',
    EVENT_STAFF_ASSIGNED: 'EVENT_STAFF_ASSIGNED',
    EVENT_CHECKIN: 'EVENT_CHECKIN',
    EVENT_RECONCILIATION: 'EVENT_RECONCILIATION',
    EVENT_SUMMARY: 'EVENT_SUMMARY',
    EVENT_EXPENSES: 'EVENT_EXPENSES'
  }
}

const reducerEvent = (state, action) => {
  switch (action.TYPE) {
    case ACTION.EVENT_LOADING:
      return { ...state, loading: action.VALUE }
    case ACTION.EVENT_DATA:
      return { ...state, data: action.VALUE }
    case ACTION.EVENT_ERROR:
      return { ...state, error: action.VALUE }
    case ACTION.EVENT_STATUS:
      return { ...state, data: { ...state.data, status: action.VALUE } }
    case ACTION.FETCH_DATA[action.TYPE]:
      return { ...state, [action.TYPE]: action.VALUE }
    default:
      return state;
  }
}

function Event(props) {
  const eventId = props.match.params.id;
  const [event, dispatchEvent] = useReducer(reducerEvent, initialEvent);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabsContent, setTabsContent] = useState(null);
  const [swipeableActions, setSwipeableActions] = useState(null);
  const [fetchData, dispatchFetchData] = useReducer(reducerEvent, initialFetchData);
  const [pageWrapperHeight, setPageWrapperHeight] = useState(0);

  const fetchDataEvent = async () => {
    const event = await fetchEventMiddleware(eventId);
    if (!event) return props.history.replace("/not-found");
    dispatchEvent({ TYPE: 'EVENT_DATA', VALUE: event.data });
  }

  useEffect(() => {
    setPageWrapperHeight(PageWrapperHeight());
    fetchDataEvent();
  },[]);

  useEffect(() => {
    event.data && eventContentBuilder();
  }, [event.data]);

  useEffect(() => {
    fetchData.EVENT && fetchDataEvent();
    fetchData.EVENT && dispatchFetchData({ TYPE: 'EVENT', VALUE: false });
  }, [fetchData.EVENT]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageWrapperHeight(PageWrapperHeight());
    };
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });

  const EventInfoContent = {
    label: 'Info',
    content: idx => (
      <EventInfo key={idx} event={event.data} dispatchFetchData={dispatchFetchData} />
    )
  };

  const EventStaffContent = {
    label: "Staff",
    content: idx => (
      <EventStaff key={idx} event={event.data} />
    )
  }

  const EventCheckInContent = {
    label: 'Check In/Out',
    content: idx => (
      <EventCheckIn key={idx} event={event.data} />
    )
  }

  const EventReconciliationContent = {
    label: 'Reconciliation',
    content: idx => (
      <EventReconciliation key={idx} event={event.data} />
    )
  }

  const EventReportContent = {
    label: 'Report',
    content: idx => (
      <EventReport key={idx} event={event.data} />
    ) 
  }

  const FilesManagerContent = {
    label: "Files",
    content: idx => (
      <FilesManager key={idx} component="Events" itemId={event.data.id} />
    )
  }

  const EventSummaryContent = {
    label: 'Summary',
    content: idx => (
      <EventSummary key={idx} event={event.data} />
    )
  }

  const EventInvoicingContent = {
    label: 'Costs',
    content: idx => (
      <EventInvoicing key={idx} event={event.data} />
    )
  }

  const eventContentBuilder = () => {
    let eventContent = [];
    UserPermissions('module_events_tab_info') && eventContent.push(EventInfoContent);
    UserPermissions("module_events_tab_staff") && eventContent.push(EventStaffContent);
    UserPermissions("module_events_tab_check_in") && event.data.status !== "Closed" && eventContent.push(EventCheckInContent);
    UserPermissions("module_events_tab_reconciliation") && event.data.status !== "Pre-Event" && eventContent.push(EventReconciliationContent);
    UserPermissions("module_events_tab_report") && eventContent.push(EventReportContent);
    UserPermissions("module_events_tab_files") && eventContent.push(FilesManagerContent);
    UserPermissions("module_events_tab_summary") && eventContent.push(EventSummaryContent);
    UserPermissions("module_events_tab_invoicing") && eventContent.push(EventInvoicingContent);
    setTabsContent(eventContent);
    dispatchEvent({ TYPE: 'EVENT_LOADING', VALUE: false });
  }

  const updateSwipeableHeight = () => {
    swipeableActions && swipeableActions.updateHeight();
  }

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={props.location} />
      : event.loading 
        ? <Loading type={'page'} />
        : <SwipeableViewsContext.Provider value={{ updateSwipeableHeight }}>
            <EventFetchContext.Provider value={{ fetchData, dispatchFetchData }}>
              <EventContext.Provider value={{ event, dispatchEvent }} >
                <main className="Event page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
                  <section className="page-header">
                    <div className="row justify-content-between">
                      <div className="col-4">
                        <EventInfoSummary event={event.data} />
                      </div>
                      <div className="col-3 d-flex align-items-end justify-content-end">
                        <EventStatus event={event.data} dispatchEvent={dispatchEvent} />
                      </div>
                    </div>
                  </section>
                  <section className="page-body">
                    <Tabs 
                      value={tabIndex}  
                      onChange={(e, value) => setTabIndex(value)}
                      classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
                    >
                      {tabsContent.map((form, idx) => (
                        <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
                      ))}
                    </Tabs>
                    <SwipeableViews 
                      index={tabIndex}
                      onChangeIndex={tabIndex => setTabIndex(tabIndex)}
                      animateHeight={true}
                      disableLazyLoading={true}
                      animateTransitions={true}
                      action={actions => {
                        setSwipeableActions(actions);
                      }} >
                      {tabsContent.map((form, idx) => (
                        form.content(idx)
                      ))}
                    </SwipeableViews>
                  </section>
                </main>
              </EventContext.Provider>
            </EventFetchContext.Provider>
          </SwipeableViewsContext.Provider>
  )
}

export default Event
