import React, { useContext, useEffect, useState } from 'react';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getTemplatesList, updateTemplate } from '../../../services/communicationTemplatesService';
import { toast } from 'react-toastify';
import { messages } from '../../../utils/language-en.json';
import Loading from '../../Common/loading/loading';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import TemplatesTableCellView from './TemplatesSettingsUtils/TemplatesTableCellView';
import TemplatesEditModal from './TemplatesSettingsUtils/TemplatesEditModal';
import './TemplatesSettings.scss';

export const TemplatesSettingsContext = React.createContext();

function TemplatesSettings() {
  const settingsContext = useContext(SettingsContext);
    const swipeableViewsContext = useContext(SwipeableViewsContext);
    const [loading, setLoading] = useState(true);
    const [fetchData, setFetchData] = useState(true);
    const [templatesList, setTemplatesList] = useState(null);
    const [sortColumn, setSortColumn] = useState({ path: "title", order: "asc" });
    const [editTemplate, setEditTemplate] = useState(null);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    
    const TABLE_COLUMNS = [
      { 
        path: "title", 
        label: "Title",
        disableSort: true, 
        content: item => <TemplatesTableCellView item={item} fieldName="title" fieldType="input" />
      },
      { 
        path: "subject", 
        label: "Subject",
        disableSort: true, 
        content: item => <TemplatesTableCellView item={item} fieldName="subject" fieldType="input" />
      },
      { 
        path: "content", 
        label: "Content",
        disableSort: true, 
        content: item => <TemplatesTableCellView item={item} fieldName="content" fieldType="input" />
      },
      {
        key: "edit",
        path: "edit",
        disableSort: true, 
        content: item => (
          <>
            <button
              onClick={() => handleTemplateModalOpen(item)}
              className="btn btn-sm btn-edit"
              title="Edit"
            >
              <span className="sr-only">Edit</span>
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
          </>
        )
      }
    ]

    useEffect(() => {
      swipeableViewsContext.updateSwipeableHeight();
    });
  
    useEffect(() => {
      const fetchTemplates = async () => {
        const { data } = await getTemplatesList();
        const templatesList = data.communication_templates;
        setTemplatesList(templatesList);
        setLoading(false);
        setFetchData(false);
      }
      fetchData && fetchTemplates();
    }, [fetchData]);

    const handleTemplateModalOpen = (item) => {
      setSelectedTemplate(item);
      setEditModalIsOpen(true);
    };
  
    const handleEditTemplateClose = () => {
      setEditModalIsOpen(false);
    };

    const handleChangeEditForm = ({ currentTarget: input }) => {
      const data = { ...selectedTemplate };
      let value = input.value;
      data[input.name] = value;
      setSelectedTemplate(data);
    }

    const handleUpdateTemplate = async () => {
      try {
        const { data } = await updateTemplate(
          selectedTemplate.id, 
          selectedTemplate.title, 
          selectedTemplate.subject, 
          selectedTemplate.content_text,
          selectedTemplate.content_html,
          selectedTemplate.content_sms
        );
        const success = data.success;
        if (!success) {
          const errorMessage = data.message;
          toast.error(errorMessage);
          return
        }
        toast.success(messages.success.settingsTemplateUpdated, { autoClose: 1000 });
        setFetchData(true);
      } catch (ex) {
      }
      setEditModalIsOpen(false);
      return;
    }

    return (
      !getCurrentUser()
        ? <RedirectToLogin location={settingsContext.props.location} />
        : loading
          ? <Loading type="tabsContentLoading" />
          : <TemplatesSettingsContext.Provider value={{ 
            action: { setEditTemplate, setFetchData },
            state: { editTemplate, selectedTemplate }
          }} >
              <section className="TemplatesSettings content-wrapper container-fluid">
                <Table
                  name={'templates'}
                  columns={TABLE_COLUMNS}
                  data={templatesList}
                  sortColumn={sortColumn}
                  onSort={sortColumn => setSortColumn(sortColumn)}
                  isLoading={loading}
                  itemIdSelected={editTemplate && editTemplate.id}
                />
              </section>
              <TemplatesEditModal
                isOpen={editModalIsOpen}
                onRequestClose={handleEditTemplateClose}
                closeModal={handleEditTemplateClose}
                data={selectedTemplate}
                handleChange={handleChangeEditForm}
                handleSave={handleUpdateTemplate}
              />
            </TemplatesSettingsContext.Provider>
    )
};

export default TemplatesSettings
