import React, { useContext, useState, useEffect } from 'react';
import Textarea from '../../../../Common/form/textarea';
import { EventReconciliationListContext } from '../EventReconciliationList';
import { EventReconciliationContext } from '../EventReconciliation';

function EventReconciliationListItemNote(props) {
  const item = props.item;
  const eventReconciliationContext = useContext(EventReconciliationContext);
  const eventReconciliationListContext = useContext(EventReconciliationListContext);
  const [noteValue, setNoteValue] = useState(item);

  useEffect(() => {
    setNoteValue(props.item);
  }, [
    props.item,
    eventReconciliationContext.eventdate
  ])

  return (
    <div className="note-wrapper">
      { item.reconciled
        ? <p>{item.note}</p>
        : <Textarea
            name="note"
            value={noteValue.note}
            onFocus={() => eventReconciliationListContext.dispatchEditReconciledNote({ TYPE: 'EDIT_RECONCILED_NOTE_SELECTED', VALUE: item })}
            onChange={({ currentTarget: input }) => setNoteValue({ ...item, note: input.value })}
            onBlur={({ currentTarget: input }) => eventReconciliationListContext.handleUpdateReconciliation('note', {...eventReconciliationListContext.editReconciledNote.selected, note: input.value})}
            rows="1"
          />
      }
    </div>
  )
}

export default EventReconciliationListItemNote
