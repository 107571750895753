import React, { Component } from "react";
import Table from "../../Common/table/table";
import { toast } from "react-toastify";
import Pagination from '../../Common/pagination/pagination';
import { getStaffNotifications } from '../../../services/staffService';
import { messages } from "../../../utils/language-en.json";
import { getStaffNotificationsReport } from '../../../services/staffService';
import EmptyData from '../../Common/emptydata/emptyData.jsx';
import EmptyDataIcon from "../../../assets/cms-icon-empty-files.svg";

class StaffFormNotifications extends Component {
  state = { 
    staffNotifications: [],
    staffNotificationsLoading: true,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    sortColumn: { path: "name", order: "asc" },
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) 
      return this.populateStaffNotifications();
  }

  async populateStaffNotifications() {
    const { data: resStaffNotifications } = await getStaffNotifications({
      STAFF_ID: this.props.staffId, 
      LIMIT: this.state.pageSize, 
      PAGE: this.state.currentPage,
      ORDER_BY: this.state.sortColumn.path, 
      SORT_ORDER: this.state.sortColumn.order
    });
    this.setState({ 
      staffNotifications: resStaffNotifications.notifications,
      totalCount: resStaffNotifications.count,
      staffNotificationsLoading: false 
    });
  }

  columns = [
    { 
      path: "name", 
      label: "Event Name",
      disableSort: true,
      content: item => (
        <>
          {item.name}&nbsp;&nbsp;
          <a href={'/events/' + item.id} target="_blank" rel="noopener noreferrer"> 
            <i className="fas fa-external-link-alt external-link-alt-custom-xsmall"></i>
          </a>
        </>
      )
    },
    { path: "status", label: "Event Status", disableSort: true },
    { path: "start_date", label: "Event Date", disableSort: true },
    { path: "notification_date", label: "Notification Date", disableSort: true },
    { path: "notification_type", label: "Notification Type", disableSort: true },
    { 
      path: "notification_status", 
      label: "Notification Status",
      disableSort: true ,
      content: item => (
        <>
          <div className="circle-wrapper">
            <div className={"circle " + item.notification_status.replace(/\s/g, '')}></div>
          </div>&nbsp;&nbsp;
          {item.notification_status}
        </>
      )
    }
  ];

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateStaffNotifications();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateStaffNotifications();
  };

  // ** HANDLES
  // *********************************************************
  handlePrintOption = async () => {
    const {data: resNotificationsSheet } = await getStaffNotificationsReport(this.props.staffId);
    if (!resNotificationsSheet.success) return toast.error("Print file couldn't be generated");
    window.open(process.env.REACT_APP_API_URL + '/' + resNotificationsSheet.file_name);
  }

  render() {
    const { 
      staffNotifications,
      staffNotificationsLoading,
      pageSize,
      currentPage,
      totalCount,
      sortColumn
    } = this.state;

    return (
    <section className="form-wrapper staff-notifications container-fluid">
      { !staffNotificationsLoading && staffNotifications.length > 0 &&
      <>
        <div className="export-container">
          <button className="btn btn-print-options" type="button" onClick={() => this.handlePrintOption()} >
            <i className="far fa-file-pdf"></i> EXPORT TO PDF
          </button>
        </div>
        <div className="col-left">
          
        </div>
        <Table
          name={ 'notifications' }
          columns={ this.columns }
          data={ staffNotifications }
          sortColumn={ sortColumn }
          onSort={ this.handleSort }
        />
      </>
      }
      { !staffNotificationsLoading && (
        <Pagination
          itemsCount={ totalCount }
          pageSize={ pageSize }
          currentPage={ currentPage }
          onPageChange={ this.handlePageChange }
        />
      )}

      { staffNotifications.length === 0  &&
        <EmptyData 
          type={ 'notifications' } 
          icon={ EmptyDataIcon }
          title={ messages.emptydata.tableTitle }
        />
      }
    </section>
    );
  }
}

export default StaffFormNotifications;
