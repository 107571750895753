import React from 'react';
import ClothesSizeDelete from './ClothesSizeDelete';
import { CLOTHES_TYPES } from './ClothesSettingsUtils';
import ClothesSizeAdd from './ClothesSizeAdd';

function ClothesSizeCard({ CLOTHES_TYPE, CLOTHES_TYPE_DATA }) {
  return (
    <div className="ClothesSizeCard card">
      <div className="card-header">
        <ClothesSizeCardTitle icon={CLOTHES_TYPE.ICON} label={CLOTHES_TYPE.LABEL} />
        <ClothesSizeAdd CLOTHES={CLOTHES_TYPE} />
      </div>
      <div className="card-body">
        <ClothesSizeList CLOTHES={CLOTHES_TYPE} CLOTHES_DATA={CLOTHES_TYPE_DATA} />
      </div>
    </div>
  );
};

function ClothesSizeCardTitle({ icon, label}) {
  return (
    <div className="ClothesSizeCardTitle card-title d-flex align-items-center">
      <i className="icon">
        <img className="img-fluid" src={icon} alt=""/>
      </i>
      <span> {label} </span>
    </div>
  )
};

function ClothesSizeList({ CLOTHES, CLOTHES_DATA }) {
  return (
    CLOTHES.TYPE === CLOTHES_TYPES.BLAZER.TYPE
      ? <div className="row">
          <div className="col-sm-6">
            <ul className="list-group">
              { CLOTHES_DATA.map((item) => 
                ( item.gender === 'F' &&
                  <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                    {item.gender + ' – ' + item.name}
                    <ClothesSizeDelete CLOTHES={CLOTHES} id={item.id}  />
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="list-group">
                { CLOTHES_DATA.map((item) => 
                  ( item.gender === 'M' &&
                    <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                      {item.gender + ' – ' + item.name}
                      <ClothesSizeDelete CLOTHES={CLOTHES} id={item.id}  />
                    </li>
                  )
                )}
              </ul>
          </div>
        </div>
      : <ul className="list-group">
          { CLOTHES_DATA.map((item, idx) => (
            <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
              { CLOTHES.TYPE === CLOTHES_TYPES.SHIRT.TYPE && item.name }
              { CLOTHES.TYPE === CLOTHES_TYPES.COAT.TYPE && item.size }
              <ClothesSizeDelete CLOTHES={CLOTHES} id={item.id}  />
            </li>
          ))}
        </ul>
  );
};

export default ClothesSizeCard
