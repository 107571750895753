import React, { useContext, useEffect, useState } from 'react';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getStaffPayRates } from '../../../services/staffService';
import StaffPayRateSettingsFilters from './StaffPayRateSettingsUtils/StaffPayRateSettingsFilters';
import StaffPayRateRatesView from './StaffPayRateSettingsUtils/StaffPayRateRatesView';
import Table from '../../Common/table/table';
import Loading from '../../Common/loading/loading';
import _ from "lodash";
import './StaffPayRateSettings.scss';

export const StaffPayRateSettingsContext = React.createContext();

function StaffPayRateSettings() {
  const settingsContext = useContext(SettingsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [departmentIdSelected, setDepartmentIdSelected] = useState(7);
  const [staff, setStaff] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "last_name", order: "asc" });
  const [loading, setLoading] = useState(true);

  const TABLE_COLUMNS = [
    { 
      path: "last_name", 
      label: "Staff Name",
      content: item => (
        <>
          {item.last_name}, {item.first_name}&nbsp;&nbsp;
          <a href={'/staff/' + item.id} target="_blank" rel="noopener noreferrer"> 
            <i className="fas fa-external-link-alt external-link-alt-custom-xsmall"></i>
          </a>
        </>
      )
    },
    { 
      path: "sort_date", 
      label: "Hire Date",
      content: item => (
        item.hire_date
      )
    },
    { 
      path: "working_status", 
      label: "Pay Rates",
      disableSort: true,
      content: item => (
        <StaffPayRateRatesView item={item}/>
      )
    }
  ]

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    const getDepartmentPayRates = async () => {
      const { data } = await getStaffPayRates(departmentIdSelected);
      const sortedData = _.orderBy(data.staff, [sortColumn.path], [sortColumn.order]);
      setStaff(sortedData);
      setLoading(false);
    }
    departmentIdSelected && getDepartmentPayRates();
  }, [departmentIdSelected]);

  useEffect(() => {
    const sortedData = _.orderBy(staff, [sortColumn.path], [sortColumn.order]);
    setStaff(sortedData);
  }, [sortColumn]);

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : loading
        ? <Loading type="tabsContentLoading" />
        : <StaffPayRateSettingsContext.Provider>
          <section className="StaffPayRateSettings content-wrapper container-fluid">
            <StaffPayRateSettingsFilters setDepartmentIdSelected={setDepartmentIdSelected} numberStaffMembers={staff.length} />
            <Table
              name={'staff'}
              columns={TABLE_COLUMNS}
              data={staff}
              isLoading={loading}
              sortColumn={sortColumn}
              onSort={sortColumn => setSortColumn(sortColumn)}
            />
          </section>
        </StaffPayRateSettingsContext.Provider>
  )
}

export default StaffPayRateSettings
