import React from 'react';

const StaffFormLicenses = props => {

  return ( 
    <section className="form-wrapper staff-licenses-form container-fluid">
    
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          <h3 className="form-group-label text-center">Security</h3>
        </div>
        <div className="col-3">
          {props.renderDatePicker("security_license_date", "Expiration date" )}
        </div>
        <div className="col-3">
          {props.renderInput("security_license_number", "License Number", "text" )}
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          <h3 className="form-group-label text-center">Alcohol</h3>
        </div>
        <div className="col-3">
          {props.renderDatePicker("alcohol_license_date", "Expiration date" )}
        </div>
        <div className="col-3">
          {props.renderInput("alcohol_license_number", "License Number", "text" )}
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          <h3 className="form-group-label text-center">Flagger</h3>
        </div>
        <div className="col-3">
          {props.renderDatePicker("flagger_license_date", "Expiration date", false, "top-start" )}
        </div>
        <div className="col-3">
          {props.renderInput("flagger_license_number", "License Number", "text" )}
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          <h3 className="form-group-label text-center">Trained Crowd Manager</h3>
        </div>
        <div className="col-3">
          {props.renderDatePicker("trained_crowd_license_date", "Expiration date", false, "top-start" )}
        </div>
        <div className="col-3">
          {props.renderInput("trained_crowd_license_number", "License Number", "text" )}
        </div>
      </div>
      
    </section>
  );
}

export default StaffFormLicenses;