import React from 'react';

const StaffFormCMSInfo = props => {

  const isEditing = props.isEditing();
  const optionsWorkingStatus = [
    { id: '' },
    { id: 'Active', name: 'Active' },
    { id: 'Inactive', name: 'Inactive' },
  ];
  const optionsBlazerSizes = props.optionsBlazerSizes;
  const optionsCMSdepartments = props.optionsCMSdepartments;
  const optionsCoatSizes = props.optionsCoatSizes;
  const optionsShirtSizes = props.optionsShirtSizes;

  return ( 
    <section className="form-wrapper staff-cms-info container-fluid">

      <div className="row justify-content-between align-items-center paycom-info">
          <div className="col-3">
            <div>
              <label><strong>Paycom ID</strong></label>
              <p>{props.state.data.paycom_id}</p>
            </div>
          </div>
          <div className="col-3">
          { isEditing
            ?  <div>
                  <label><strong>Department</strong></label>
                  <p>{props.state.data.department_name}</p>
                </div>
            : props.renderSelect( "department_id", "Department", optionsCMSdepartments, true )
          }
        </div>
        <div className="col-3">
          { isEditing
            ?  <div>
                  <label><strong>Working Status</strong></label>
                  <p>{props.state.data.working_status.charAt(0).toUpperCase() + props.state.data.working_status.slice(1)}</p>
                </div>
            : props.renderSelect( "working_status", "Working Status", optionsWorkingStatus, true )
          }
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          { isEditing
            ?  <div>
                  <label><strong>Birth Date</strong></label>
                  <p>{props.state.data.birth_date}</p>
                </div>
            : props.renderDatePicker( "birth_date", "Birth Date", true )
          }
        </div>
        <div className="col-3">
          { isEditing
            ?  <div>
                  <label><strong>Hire Date</strong></label>
                  <p>{props.state.data.hire_date}</p>
                </div>
            : props.renderDatePicker( "hire_date", "Hire Date" )
          }
        </div>
        <div className="col-3">
          { isEditing
            ?  <div>
                  <label><strong>Termination Date</strong></label>
                  <p>{props.state.data.termination_date}</p>
                </div>
            : props.renderDatePicker( "termination_date", "Termination Date" )
          }
        </div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-3 align-self-baseline">
          {props.renderSwitch("trained", "Trained?", false, false, true )}
        </div>
        <div className="col-3">
          {props.renderSwitch("public_facing", "Public Facing?", false, false, true )}
        </div>
        <div className="col-3"></div>
      </div>

      <div className="row justify-content-between align-items-center">
        <div className="col-12">
          <h3 className="form-group-label">
            Clothing Info
          </h3>
        </div>
        <div className="col-12">
          <div className="row justify-content-between align-items-center">
            <div className="col-3">
              {props.renderSelect("blazer_size_id", "Blazer Size", optionsBlazerSizes )}
            </div>
            <div className="col-3">
              {props.renderSelect("coat_size_id", "Coat Size", optionsCoatSizes )}
            </div>
            <div className="col-3">
              {props.renderSelect("shirt_size_id", "Shirt Size", optionsShirtSizes )}
            </div>
            <div className="col-3 align-self-baseline">
              {props.renderSwitch("tie", "Tie?" )}
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}

export default StaffFormCMSInfo;