import React, { Component } from "react";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";

class ApplicantList extends Component {
  columns = [
    { path: "application_date", label: "Date of Application" },
    { path: "last_name", label: "Last Name" },
    { path: "first_name", label: "First Name" },
    { path: "department", label: "Department", disableSort: true },
    { path: "contact_phone", label: "Contact Phone" },
    { path: "email", label: "Email" },
    { path: "status", label: "Status" },
    { path: "edit",
    disableSort: true,
    content: applicantItem => (
      <Link to={`/applicants/${applicantItem.id}`} className="btn btn-sm btn-edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )}
  ];

  render() {
    const { applicants, onSort, sortColumn, isLoading, overLoading } = this.props;

    return (
      <Table
        name={'applicants'}
        columns={this.columns}
        data={applicants}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
        overLoading={overLoading}
      />
    );
  }
}

export default ApplicantList;
