import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";

class EventsList extends Component {
  columns = [
    { path: "name", label: "Events Name" },
    { path: "department", label: "Department", disableSort: true }, 
    { path: "customer", label: "Customer", disableSort: true },
    { path: "venue", label: "Venue", disableSort: true },
    { path: "event_number", label: "Event Number", content: item => item.event_number.substring(0, 15) },
    { path: "start_date", label: "Start Date" },
    { path: "number_days", label: "Days", disableSort: true },
    { path: "status", label: "Event Status" }
  ];

  cloneColumn = {
    key: "clone",
    path: "clone",
    disableSort: true,
    content: customersItem => (
      <button
        onClick={() => this.props.onClone(customersItem)}
        className="btn btn-sm btn-edit"
        title="Clone"
      >
        <span className="sr-only">Clone</span>
        <i className="fas fa-clone" aria-hidden="true"></i>
      </button>
    )
  };

  deleteColumn = {
    key: "delete",
    path: "delete",
    disableSort: true,
    content: customersItem => (
      <button
        onClick={() => this.props.onDelete(customersItem)}
        className="btn btn-danger btn-sm btn-delete"
        title="Delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: customersItem => (
      <Link to={`/events/${customersItem.id}`} className="btn btn-sm btn-edit" title="Edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    const authPermissions = auth.getCurrentUserPermisions();
    if (user) this.columns.push(this.editColumn);
    if (user && authPermissions["module_events_clone"]) this.columns.push(this.cloneColumn);
    if (user && authPermissions["module_events_delete"]) this.columns.push(this.deleteColumn);
  }

  render() {

    const { data, onSort, sortColumn, isLoading } = this.props;

    return (
      <Table
        name={'events'}
        columns={this.columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
      />
    );
  }
}

export default EventsList;
