import React from "react";
import MaskedInput from "react-maskedinput";
import "./form.scss";

const MaskInput = ({ name, label, mask, error, required, ...rest }) => {
  return (
    <div className={ error ? 'form-group has-error' : 'form-group'}>
      <div className="d-flex flex-column-reverse">
        <MaskedInput 
          {...rest}
          className={'form-control'}
          mask={mask}
          placeholder=""
          name={name}
          />
        { label && (
          <label className={ (required) ? 'required' : '' } htmlFor={name}>{label}</label>
        )}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default MaskInput;
