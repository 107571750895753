import React from 'react';
import "./cloneConfirmation.scss"
import Input from '../form/input';
import { Modal , Button } from 'react-bootstrap';
import CloneEventDates from './cloneEventDates';

const CloneConfirmation = ({ isOpen, onAfterOpen, onRequestClose, closeModal, itemName, eventDates, dispatchEventDates, dispatchEventName, nameValid, datesComplete, onClone }) => {
  const handleChange = ({ currentTarget: input }) => {
    dispatchEventName(input.value);
  }

  const renderInput = (name, label, type = "text", required = false, maxlength='') => {
    return (
      <Input
        type={type}
        name={name}
        label={label}
        onChange={handleChange}
        required={required}
        maxLength={maxlength}
      />
    );
  }

  const validate = () => {
    let disabled = true;
    if (nameValid && datesComplete) {
      disabled = false;
    }
    return disabled;
  };

  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-clone-confirmation">
      <Modal.Header>
        <Modal.Title>Clone an Event</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row mb-3 justify-content-center">
          <div className="col-sm-9">
            {renderInput("name", "Event Name", "text", true )}
          </div>
          <div className="col-sm-9">
            <p>Select the dates you want to map into the cloned event. Keep in mind that at least one date 
              needs to be mapped and all blank dates will not be cloned.</p>
          </div>
          <div className="date-list">
            <CloneEventDates eventDates={eventDates} dispatchEventDates={dispatchEventDates} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-11">
            <p className="disclaimer text-center">
              Are you sure you want to clone <b>{ itemName }</b>?
            </p>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" disabled={validate()} onClick={onClone}>Clone</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default CloneConfirmation;
