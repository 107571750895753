import React, { useEffect, useState } from 'react';
import { getActivityLatest } from "../../../services/widgetsService";
 
function LatestActivitiesWidget() {
  const [logs, setLogs] = useState([]);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const getActivityLatestData = async () => {
    const { data } = await getActivityLatest();
    setLogs(data.logs);
    setTitle(data.title);
    setText(data.text);
  }

  useEffect(() => {
    getActivityLatestData();
  }, []);

  return (
    <div>
      { logs.length === 0
        ? ''
        : <div className="col-auto"> 
          <div className='DashboardList-item btn d-flex flex-column'>
            <h4><i className="fas fa-people-carry"></i>&nbsp;{title}</h4>
            <p>{text}</p>
            <ul>
            { logs.map( (item, idx) => (
                <li key={idx}>{item.created_date} {item.user} - {item.activity}</li>
              ) )
            }
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default LatestActivitiesWidget;