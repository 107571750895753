import React from 'react';
import _ from "lodash";
import SwipeableViews from 'react-swipeable-views';
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core';
import { customersSchema, customerDataModel, customerEditMapToViewModel } from "../../models/customersSchema";
import { messages, pagetitle } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import { getCustomersItem, saveCustomer } from "../../services/customersService";
import { getDepartmentList } from '../../services/departmentService';
import { getActiveStates } from "../../services/statesService";
import auth from "../../services/authService";
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import FilesManager from '../Common/files-manager/filesManager';
import CustomersFormInfo from './customers-forms/customersFormInfo';
import { filterDepartmentsUser } from '../../utils/value-formats';

class CustomersForm extends Form {
  mounted = false;

  state = { 
    dataLoading: false,
    tabIndex: 0,
    data: customerDataModel(),
    errors: {}
  }

  schema = {};

  customersForms = [];

  CustomersFormInfo = {
    label: "Customer Info",
    submitButton: true,
    content: idx => (
      <CustomersFormInfo
        key={idx}
        state={this.state}
        data={this.state.data}
        renderInput={this.renderInput}
        renderSelect={this.renderSelect}
        renderMaskInput={this.renderMaskInput}
        renderSwitch={this.renderSwitch}
        renderTextarea={this.renderTextarea}
        optionsCMSdepartments={this.state.optionsCMSdepartments}
        optionsStates={this.state.optionsStates}
        isEditing={this.isEditing}
        handleChange={this.handleChange}
      />
    )
  }
  FilesManager = {
    label: "Contracts",
    content: idx => (
      <FilesManager 
        key={idx} 
        component="Customers"
        loading={this.state.dataLoading} 
        itemId={this.state.data.id}
      />
    )
  }

  constructor(props) {
    super(props);
    if (this.authPermissions("module_customers_tab_info")) this.customersForms.push(this.CustomersFormInfo);
    if (this.isEditing() && this.authPermissions("module_customers_tab_contracts")) this.customersForms.push(this.FilesManager);
  }
  
  async componentDidMount() {
    this.mounted = true;
    this.setState({ dataLoading: true })
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = filterDepartmentsUser(sortDepartments, '');
    const { data: resStates } = await getActiveStates();
    const states = [{ id: '', name: '' }, ...resStates.states];
    if (this.mounted) this.setState({ optionsStates: states, dataLoading: false })
    this.schema = customersSchema(this.isEditing());
    if (this.mounted) this.setState({ optionsCMSdepartments: departments, dataLoading: false })
    const customerName = await this.populateCustomers();
    const titlePage =  ( this.isEditing() ) ? pagetitle.customersEdit + ' — ' + customerName : pagetitle.customersCreate ;
    this.setState({ pagetitle: titlePage, submitButton: this.customersForms[0].submitButton })
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateCustomers() {
    try {
      const id = this.props.match.params.id;
      if ( id === 'create') return;
      this.setState({ dataLoading: true })
      
      const { data: resCustomer } = await getCustomersItem(id);
      const customer = resCustomer.customer;
      
      this.setState({ data: customerEditMapToViewModel(customer) });
      this.validate();
      this.setState({ dataLoading: false })

      return customer.name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  authPermissions = (module) => {
    const authPermissions = auth.getCurrentUserPermisions();
    return authPermissions[module];
  }

  isEditing = () => {
    const urlParams = this.props.match.params.id;
    const isEditing = ( urlParams === 'create') ? false : true ;
    return isEditing
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value,
      submitButton: this.customersForms[value].submitButton
    });
  };

  handleChangeTabsIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  doHandleChange = input => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
    }
    return value;
  }

  doSubmit = async () => {
    try {
      const { data } = await saveCustomer(this.state.data);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage);
        return
      }
      if (this.isEditing()) {
        toast.success(messages.success.customerUpdated, { autoClose: 1000 })
      } else {
        toast.success(messages.success.customerCreated, { autoClose: 1000 });
        this.props.history.push("/customers/" + data.id);
        setTimeout(() => {
          window.location.reload()
        }, 500);
      }
    } catch (ex) {
    }
    return;
  };

  render() { 
    const { 
      dataLoading,
      pagetitle,
      tabIndex,
      submitButton,
      pageWrapperHeight
    } = this.state;

    if (!auth.getCurrentUser())
      return <RedirectToLogin location={this.props.location} />

    if (!this.isEditing() && !this.authPermissions("module_customers_add"))
      return (
        <Redirect
          to={{
            pathname: "/customers",
            state: { from: this.props.location }
          }}
        />
      )

    return (
      <main className="customers-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <Loading active={dataLoading} />
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fa fa-bullhorn"></i>&nbsp;{pagetitle}</h1>
            </div>
          </div>
        </section>
        {!dataLoading && 
          <section className="page-body">
            <Tabs 
              value={tabIndex}  
              onChange={this.handleChangeTabs} 
              classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
            >
              {this.customersForms.map((form, idx) => (
                <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
              ))}
            </Tabs>
            <form onSubmit={this.handleSubmit}>
              <SwipeableViews 
                index={tabIndex} 
                onChangeIndex={this.handleChangeTabsIndex} 
                animateHeight={true}
                animateTransitions={true} >
                {this.customersForms.map((form, idx) => (
                  form.content(idx)
                ))}
              </SwipeableViews>
              {submitButton &&
                <div className="form-footer">
                  {this.renderButton("Save Customer Info")}
                </div>
              }
            </form>
          </section>
        }
      </main>
    );
  }
}

export default CustomersForm;