import React from 'react';
import Select from "react-select";

export default function FilterByShiftStatus(props) {
  const { handleStatusChange } = props;
  const sectionOptions =  [
    { value: '1', label: 'All' },
    { value: '2', label: 'Checked In' },
    { value: '3', label: 'Checked Out' }
  ];

  return (
    <div className="filter-by-shift">
      <Select
        name="selectStatus"
        placeholder="Filter by Checked Status"
        options={sectionOptions}
        className="basic-select"
        classNamePrefix="select"
        onChange={handleStatusChange}
      />
    </div>
  )
}
