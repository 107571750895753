import moment from "moment";
import auth from "../services/authService";

export function currencyUSD(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  value = formatter.format(value);
  return value;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDateTime(date) {
  return moment(date).format('MM/DD/YYYY hh:mm A');
}

export function filterDepartmentsUser(departments, emptyOptionLabel) {
  const userData = auth.getCurrentUser();
  const userDepartmentId = userData.department_id;
  let departmentsAvailable = [];
  let departmentsNew = [];
  
  if (userDepartmentId) {
    departments.forEach((elementDepartment) => {
      if (elementDepartment.id === userDepartmentId) {
        departmentsAvailable.push(elementDepartment);
      }
    });
    departmentsNew = [{ id: '', name: emptyOptionLabel }, ...departmentsAvailable];
  } else {
    departmentsNew = [{ id: '', name: emptyOptionLabel }, ...departments];
  }

  return departmentsNew;
}

export default {
  currencyUSD,
  capitalize,
  formatDateTime
}
