import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getDepartmentRateLog } from '../../../services/logsService';
import Pagination from '../../Common/pagination/pagination';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, LogsContext } from '../Logs';
import { getDepartmentList } from "../../../services/departmentService";
import FilterByDepartment from "../../Common/filters/FilterByDepartment";
import './DepartmentRate.scss';

function DepartmentRate() {
  const logsContext = useContext(LogsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [departmentRateLog, setDepartmentRateLog] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "desc" });
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDepartment, setFilterDepartment] = useState([]);

  const columns = [
    {
      path: 'created_date',
      label: 'Date Created'
    },
    {
      path: 'user',
      label: 'User',
      disableSort: true,
    },
    {
      path: 'department',
      label: 'Department',
      disableSort: true,
    },
    {
      path: 'hourly_rate',
      label: 'Hourly Rate',
    }
  ];

  const handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    setFilterDepartment(departmentSelected);
    fetchDepartmentRateLog();
  };

  const fetchDepartmentRateLog = async () => {
    const { data } = await getDepartmentRateLog({
      DEPARTMENT_ID: filterDepartment,
      LIMIT: pageSize,
      PAGE: currentPage,
      ORDER_BY: sortColumn.path,
      SORT_ORDER: sortColumn.order
    });
    if (!data.success) {
      toast.error('Error on gettting Department Rate Logs data.');
      return
    }
    setDepartmentRateLog(data.logs);
    setTotalCount(data.count);
    setLoading(false);
  };

  const fetchDepartments = async () => {
    const { data: resDepartment } = await getDepartmentList({
      LIMIT: 1000, 
      PAGE: 1
    });
    setDepartments(resDepartment.department);
  };

  useEffect(() => {
    fetchDepartmentRateLog();
    fetchDepartments();
  }, [currentPage, sortColumn, filterDepartment]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={logsContext.props.location} />
      : <section className="DepartmentRate content-wrapper container-fluid">
          <div className="table-console-left d-flex align-items-end">
            <FilterByDepartment
              departments={departments}
              handleDepartmentChange={handleDepartmentChange}
            />
          </div>
          <Table
            name="DepartmentRateTable"
            columns={columns}
            data={departmentRateLog}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={loading}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={ page => setCurrentPage(page) }
          />
        </section>
  )
}

export default DepartmentRate
