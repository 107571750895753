import React from 'react';
import "./form.scss";

const Switch = ({ name, label, error, required, centerElement, strongTitle, justifyContent, flexDirection, ...rest }) => {
  return ( 
    <div className={`switch d-flex flex-${flexDirection} ${(centerElement) ? 'align-items-center' : ''} justify-content-${justifyContent} `}>
      <span className={`label ${ (strongTitle) ? 'strongTitle' : '' }`}>{label}</span>
      <input {...rest} name={name} id={name} type="checkbox" />
      <label htmlFor={name}>{label}</label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default Switch;