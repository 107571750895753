import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { PageWrapperHeight } from "../../utils/LayoutSizes";
import logo from "../../assets/cms-logo-login.svg";
import loginBgImage from "../../assets/login-bg.jpg";
import auth from "../../services/authService";
import Form from "../Common/form/form";
import RecoverPasswordModal from './login-modals/recoverPassword';
import "./loginForm.scss";

const company = process.env.REACT_APP_COMPANY;
const appName = process.env.REACT_APP_NAME;
const appVersion= 'v' + process.env.REACT_APP_VERSION;
const year = new Date().getFullYear();

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {}
  };

  schema = {
    email: Joi.string()
      .required()
      .label("email"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  componentDidMount() {
    this.setState({ pageWrapperHeight: PageWrapperHeight() })
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }
  
  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  doHandleChange = input => {
    return input.value;
  }

  doHandleBlur = input => {
    return;
  }
  
  doSubmit = async () => {
    try {
      const { data } = this.state;
      const { state } = this.props.location;
      const { data: resp } = await auth.login(data.email, data.password);

      if (!resp.success) {
        return toast.error('User credentials are invalid');
      }

      window.location = state ? state.from.pathname : "/";

    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const pageBackground = { backgroundImage:`url(${loginBgImage})` };

    return (
      <main className="container-fluid login-page" style={ pageBackground }> 
        <div className="row align-items-center justify-content-center full-height" style={{minHeight: this.state.pageWrapperHeight + "px"}}>
          <div className="w-100"></div>
          <div className="login-wrapper col-sm-auto">
            <div className="row justify-content-center">
              <div className="col-7 col-sm-6 col-xl-5 p-0">
                <h1 className="brand">
                  <span className="rs-only"></span>
                  <img className="img-fluid" src={logo} alt={appName}/>
                </h1>
              </div>
            </div>
            <h4>Log in to {appName}</h4>
            <p>This is a secure site. Please enter your login information</p>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput("email", "Email")}
              {this.renderInput("password", "Password", "password")}
              <br/>
              <p className="text-right">
                {this.renderButton("Login")}
              </p>
              <RecoverPasswordModal/>
            </form>
          </div>
          <div className="col-sm-1 d-sm-none d-lg-block"></div>
          <div className="login-hero col-sm-10 col-lg-4 col-xl-4">
            <h2>Welcome</h2>
            <h3>
              Make friends<br/>make memories<br/>protect the fun
            </h3>
            <p><a href="http://www.cmsprotectsfun.com/" target="_blank" rel="noopener noreferrer" >www.cmsprotectsfun.com</a></p>
          </div>
          <div className="w-100 align-self-end footer">
            <div className="container-fluid">
              <div className="row justify-content-between">
                <div className="col-sm-4">
                  <p className="copyright"><small><b>{company}</b> © {year} - All rights reserved</small></p>
                </div>
                <div className="col-sm-4">
                <p className="version"><small><b>{appName}</b> {appVersion}</small>  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default LoginForm;
