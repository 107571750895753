import React, { useContext, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { ReportContext } from '../../Report';
import { ReportResultContext } from '../ReportResults';

function ReportResultsHeader() {
  const reportContext = useContext(ReportContext);
  const reportResultContext = useContext(ReportResultContext);
  const [dropdownDownload, setDropdownDownload] = useState(false);

  const handleDouwnloadFile = (file) => {
    window.open(process.env.REACT_APP_API_URL + '/' + file );
  }

  
  return (
    <div className="ReportResults-header"
      onMouseLeave={() => setDropdownDownload(false)}
    >
      <div className="exportfiles-wrapper">
        <button className="btn" 
          onMouseEnter={() => setDropdownDownload(true)}
        >
          <i className="fas fa-cloud-download-alt"></i>
          Download
        </button>
        { dropdownDownload && 
        <ul className="dropdown" 
          onMouseLeave={() => setDropdownDownload(false)}
        >
          {reportContext.reportResults.files.map((file, idx) => (
            <li key={idx}
              onClick={() => handleDouwnloadFile(file.path)}
            > 
              {file.title}
            </li>
          ))}
        </ul>
        }
      </div>
      <ReactToPrint
        trigger={() => (
            <button className="btn"
              onMouseEnter={() => setDropdownDownload(false)}
            >
              <i className="fas fa-print"></i>
              Print Report
            </button>
          )
        }
        content={() => reportResultContext.printComponentRef}
      />
    </div>
  )
}

export default ReportResultsHeader
