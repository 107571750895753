import React from 'react';
import Input from '../../../Common/form/input';

const VolunteersAdd = ({
  newFirstName,
  handleChangeNewFirstName,
  newLastName,
  handleChangeNewLastName,
  handleAddVolunteer
  }) => {
  return (
    <div className="add-volunteer">
      <div className="card-title">
        Create New Volunteer
      </div>
      <div className="row">
        <div className="col-9">
          <Input 
            type="text"
            placeholder={"First Name*"}
            name={'newFirstName'}
            value={newFirstName}
            onChange={handleChangeNewFirstName}
          />
        </div>
        <div className="col-9">
          <Input 
            type="text"
            placeholder={"Last Name*"}
            name={'newLastName'}
            value={newLastName}
            onChange={handleChangeNewLastName}
          />
        </div>
        <div className="col-3 pl-0">
          <button 
            disabled={!newFirstName || !newLastName}
            className="btn btn-secondary btn-block" 
            onClick={() => handleAddVolunteer()}
          > 
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
export default VolunteersAdd;