import React, { useContext, useState, useEffect} from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import { messages } from "../../../../../../utils/language-en.json";
import { EventFetchContext } from '../../../Event';
import { EventStaffAssignedListContext } from '../EventStaffAssignedList';

function EventStaffAssignedListItemDate(props) {
  const item = props.item;
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffAssignedListContext = useContext(EventStaffAssignedListContext);
  const [datepickerNameSelected, setDatepickerNameSelected] = useState(null);
  const isEditable = eventStaffAssignedListContext.eventStaffAssignedDate.selected && eventStaffAssignedListContext.eventStaffAssignedDate.selected.staff_event_id === item.staff_event_id;

  useEffect(() => {
    if (!eventStaffAssignedListContext.eventStaffAssignedDate.original) return
    if (eventStaffAssignedListContext.eventStaffAssignedDate.original.save_start_date && eventStaffAssignedListContext.eventStaffAssignedDate.original.save_end_date) {
      handleEditEventStaffAssignedDate()
    }
  },[eventStaffAssignedListContext.eventStaffAssignedDate.original]);

  // ** HANDLE
  // *********************************************************
  
  const handleEditEventStaffAssignedDate = (item) => {
    if (eventStaffAssignedListContext.eventStaffAssignedDate.selected && !item) {
      eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_SELECTED', VALUE: null});
      eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_ORIGINAL', VALUE: null});
      return
    }

    const ORIGINAL = {
      start_date: item.start_date,
      save_start_date: false,
      end_date: item.end_date,
      save_end_date: false
    }
    eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_SELECTED', VALUE: item})
    eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_ORIGINAL', VALUE: ORIGINAL})
  }

  const handleChangeEventStaffAssignedDate = (date) => {
    const dateFormat = 'MM/DD/YYYY – HH:mm';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    
    if (moment(toDateFormat, dateFormat, true).isValid()) {
      date =  new Date( date ).toISOString();
      const updatedDate = {
        ...eventStaffAssignedListContext.eventStaffAssignedDate.selected,
        [datepickerNameSelected]: date
      }
      eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_SELECTED', VALUE: updatedDate});
    } else {
      toast.error(messages.error.dateTimeInvalid);
    }
  }

  const validateEventDateRange = () => {
    const { start_date, end_date } = eventStaffAssignedListContext.eventStaffAssignedDate.selected;
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    if (endDate <= startDate){
      toast.error(messages.error.dateOutOfRange);
      return false
    }

    var startDateShift = moment(start_date);
    var endDateShift = moment(end_date);
    var durationShift = endDateShift.diff(startDateShift, 'minutes');
    if (durationShift > 1440) {
      toast.error(messages.error.dateShiftLonger);
      return false
    }

    handleSaveEventStaffAssignedDate();
  }

  const handleSaveEventStaffAssignedDate = async () => {
    await eventStaffAssignedListContext.handleAssignedStaffPositionUpdate('date', eventStaffAssignedListContext.eventStaffAssignedDate.selected);
    const dateNameSaved = {
      ...eventStaffAssignedListContext.eventStaffAssignedDate.original,
      start_date: eventStaffAssignedListContext.eventStaffAssignedDate.selected.start_date,
      end_date: eventStaffAssignedListContext.eventStaffAssignedDate.selected.end_date,
      ['save_' + datepickerNameSelected]: true
    }
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
    eventStaffAssignedListContext.dispatchEventStaffAssignedDate({ TYPE: 'EVENT_STAFF_ASSIGNED_DATE_ORIGINAL', VALUE: dateNameSaved});
  }

  // ** RENDERS
  // *********************************************************

  const renderEventDateTimePicker = (name) => {
    const dateFormat = moment(eventStaffAssignedListContext.eventStaffAssignedDate.selected[name]).format('YYYY-MM-DD HH:mm:ss');
    const value = new Date(dateFormat);

    const CustomInput = React.forwardRef((props,ref) => {
      return (
        <button type="button" className="btn btn-datepicker"
          onClick={props.onClick} 
          ref={ref}>
          {props.value || props.placeholder} &nbsp;<span className="fas fa-calendar-day"></span>
        </button>
      )
    });

    const CustomTimeInput = ({ date, value, onChange }) => (
      <input
        value={value}
        onChange={(e) => onChange(e.target ? e.target.value : null)}
      />
    );

    return (
      <div className="form-group">
        <ReactDatePicker
          calendarClassName="event-datepicker"
          dateFormat="MM/dd/yyyy – HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="Time"
          fixedHeight
          selected={value}
          name={name}
          onChange={(date) => handleChangeEventStaffAssignedDate(date)}
          onCalendarOpen={() => setDatepickerNameSelected(name)}
          onCalendarClose={() => validateEventDateRange()}
          customInput={<CustomInput />}
          popperPlacement="auto"
          customTimeInput={<CustomTimeInput/>}
          timeInputLabel="Time:"
          showTimeSelect
        />
      </div>
    )
  }

  return (
    <div className="date-wrapper">
      { !isEditable &&
        <React.Fragment>
          <span>{item.start_date}</span>
          &nbsp;<span>|</span>&nbsp;
          <span>{item.end_date}</span>
          { item.staff_id !== 0 
            ? !item.exported
              ? !item.reconciled
                ? <i className="fas fa-pencil-alt" onClick={() => handleEditEventStaffAssignedDate(item)}></i>
                : ''
              : ''
            : ''
          }
        </React.Fragment>
      }
      { isEditable &&
        <React.Fragment>
          {renderEventDateTimePicker('start_date')}
          {renderEventDateTimePicker('end_date')}
          <i className="fas fa-times" onClick={() => handleEditEventStaffAssignedDate()}></i>
        </React.Fragment>
      }
    </div>
  );
}

export default EventStaffAssignedListItemDate
