import http from "./httpService";

const apiEndpoint = "/availability";

export function getStaffAvailability(staffId) {
  return http.get(apiEndpoint + '/get_staff_id', { 
    params: {
      id: staffId 
    }
  });
}
export function updateStaffAvailabilty(hourId, checked) {
  return http.post(apiEndpoint + '/update_id', {  id: hourId, checked: checked  });
}
