import React, { useReducer, useContext } from 'react';
import { toast } from 'react-toastify';
import { updateEventReconciliation } from '../../../../services/eventsService';
import Table from '../../../Common/table/table';
import { EventFetchContext, EventContext } from '../Event';
import { EventReconciliationContext } from './EventReconciliation';
import EventReconciliationListItemStaffName from './EventReconciliationListItems/EventReconciliationListItemStaffName';
import EventReconciliationListItemDate from './EventReconciliationListItems/EventReconciliationListItemDate';
import EventReconciliationListItemCheckbox from './EventReconciliationListItems/EventReconciliationListItemCheckbox';
import EventReconciliationListItemNote from './EventReconciliationListItems/EventReconciliationListItemNote';
import EventStaffAssignedListItemPayRate from '../EventStaff/EventStaffAssigned/EventStaffAssignedListItems/EventStaffAssignedListItemPayRate';

export const EventReconciliationListContext = React.createContext();

const initialEditReconciledDate = {
  selected: null,
  original: null
}
const initialEditReconciledNote = {
  selected: null
}

const reducerEventReconciliationList = (state, action) => {
  switch (action.TYPE) {
    case 'EDIT_RECONCILED_DATE_SELECTED':
      return {...state, selected: action.VALUE}
    case 'EDIT_RECONCILED_DATE_ORIGINAL':
      return {...state, original: action.VALUE}
    case 'EDIT_RECONCILED_NOTE_SELECTED':
      return {...state, selected: action.VALUE}
    default:
      return state
  }
}

// ** RENDERS COLUMN ITEMS
// *********************************************************
const columns = [
  {
    path: 'staff_name',
    label: 'Staff Name',
    content: item => (
      <EventReconciliationListItemStaffName item={item} />
    )
  },
  {
    path: 'position_name',
    label: 'Position'
  },
  {
    path: "volunteer", 
    label: "Volunteer",
    content: item => (
      (item.volunteer)
        ? <i className="fas fa-check blueIcon"></i>
        : <i></i>
    )
  },
  {
    path: 'start_date',
    label: 'Start | End Date',
    content: item => (
      <EventReconciliationListItemDate item={item} />
    )
  },
  { 
    path:'hours_worked', 
    label: 'Hours'
  },
  { 
    path:'pay_rate', 
    label: 'Pay Rate',
    content: item => item.pay_rate && item.pay_rate[0] && <EventStaffAssignedListItemPayRate item={item} type="2" />
  },
  {
    path: 'tardy',
    label: 'Tardy',
    content: item => (
      <EventReconciliationListItemCheckbox item={item} field='tardy' disabled={false} />
    )
  },
  {
    path: 'no_show',
    label: 'No Show',
    content: item => (
      <EventReconciliationListItemCheckbox item={item} field='no_show' disabled={false} />
    )
  },
  {
    path: 'short_notice',
    label: 'Short Notice',
    content: item => (
      <EventReconciliationListItemCheckbox item={item} field='short_notice' disabled={false} />
    )
  },
  {
    path: 'cancel',
    label: 'Cancel',
    content: item => (
      <EventReconciliationListItemCheckbox item={item} field='cancel' disabled={false} />
    )
  },
  {
    path: 'salary',
    label: 'Salary',
    content: item => (
      <EventReconciliationListItemCheckbox item={item} field='salary' disabled={false} />
    )
  },
  {
    path: 'note',
    label: 'Note',
    content: item => (
      <EventReconciliationListItemNote item={item} />
    )
  },
  {
    path: 'reconciled',
    label: 'Reconciled',
    content: item => (
      !item.exported ? <EventReconciliationListItemCheckbox item={item} field='reconciled' disabled={false} /> : <i className="fas fa-check-square"></i>
    )
  }
]

function EventReconciliationList() {
  const event = useContext(EventReconciliationContext).event;
  const eventFetchContext = useContext(EventFetchContext);
  const eventContext = useContext(EventContext);
  const eventReconciliationContext = useContext(EventReconciliationContext);
  const reconciliationList = useContext(EventReconciliationContext).eventReconciliationList;
  const [editReconciledDate, dispatchEditReconciledDate] = useReducer(reducerEventReconciliationList, initialEditReconciledDate);
  const [editReconciledNote, dispatchEditReconciledNote] = useReducer(reducerEventReconciliationList, initialEditReconciledNote);

  // ** HANDLE
  // *********************************************************

  const handleUpdateReconciliation = async (field, item) => {
    let updateBothDates = false;
    let value;
    let dataToUpdate = [];

    switch (field) {
      case 'rating':
        value = item[field];
        break;
      case 'note':
        value = item[field];
        break;
      case 'start_date':
      case 'end_date':
        updateBothDates = true;
        break;
      case 'payrate':
        value = item.current_pay_rate;
        break;
      default:
        value = item[field] ? false : true;
        break;
    };

    if (updateBothDates) {
      dataToUpdate = {
        staff_event_id: item.staff_event_id,
        field: field,
        staff_id: item.staff_id,
        start_date_value: item['start_date'],
        end_date_value: item['end_date'],
        event_id: event.id,
        salary: item.salary,
        staff_type: item.type,
      };
    } else {
      dataToUpdate = {
        staff_event_id: item.staff_event_id,
        staff_id: item.staff_id,
        field: field,
        value: value,
        event_id: event.id,
        salary: item.salary,
        staff_type: item.type,
      };
    }

    try {
      const { data } = await updateEventReconciliation(dataToUpdate);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage);
        return
      }

      data.status && eventContext.dispatchEvent({ TYPE: 'EVENT_STATUS', VALUE: data.status });
      toast.success('Reconciliation Updated!', { autoClose: 1000 });
      eventReconciliationContext.fetchReconciliationList(event.id, eventReconciliationContext.eventdate.id);
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED', VALUE: true })
      setTimeout(() => {
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      }, 500);
    } catch (ex) { }

  }

  return (
    <EventReconciliationListContext.Provider 
      value={{
        editReconciledDate, 
        dispatchEditReconciledDate,
        handleUpdateReconciliation,
        editReconciledNote,
        dispatchEditReconciledNote
      }}
    >
      {!reconciliationList.loading &&
        <Table
          name="reconciliation"
          columns={columns}
          data={reconciliationList.data}
          sortColumn={eventReconciliationContext.sortColumn}
          onSort={sortColumn => eventReconciliationContext.dispatchSortColumn({ TYPE: 'SORT_COLUMN', VALUE: sortColumn })}
          isLoading={reconciliationList.loading}
        />
      }
    </EventReconciliationListContext.Provider>
  )
}

export default EventReconciliationList
