import React, { useState } from 'react';
import staffAvatarPlaceholder from "../../../assets/staffAvatar-placeholder.svg";
import UserChangePasswordModal from '../users-modals/userChangePassword';

const UsersFormInfo = props => {
  const optionsRoles = props.optionsRoles;
  const isEditing = props.isEditing();
  const validateUserInfo = props.validateUserInfo();
  const optionsDepartments = props.optionsDepartments;

  const avatar = props.state.data.avatar;
  const avatarImg = (avatar) ? process.env.REACT_APP_API_URL + '/avatars/' + avatar : staffAvatarPlaceholder;

  const [changePasswordModalisOpen, setChangePasswordModalisOpen] = useState(false);
  const handleCloseChangePasswordModal = () => {
    setChangePasswordModalisOpen(false);
  }

  return (
    <section className="form-wrapper user-info-form container-fluid">
      <form onSubmit={props.handleSubmit}>
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            <div className="avatar-upload d-flex flex-column align-items-center">
              <div className="avatar-wrapper">
                <img className="avatar img-fluid" src={avatarImg} alt="Staff Avatar"/>
              </div>
            </div>
          </div>
          <div className="col-3">
            {props.renderInput("first_name", "First Name", "text", true )}
          </div>
          <div className="col-3">
            {props.renderInput("last_name", "Last Name", "text", true )}
          </div>
        </div>

        <div className="row justify-content-between align-items-center">
          <div className="col-3">
          </div>
          <div className="col-3">
            {props.renderSelect("role_id", "Role", optionsRoles, true )}
          </div>
          <div className="col-3">
            {props.renderSelect("department_id", "Department", optionsDepartments, false )}
          </div>
        </div>

        <div className="row justify-content-between align-items-center">
          <div className="col-3">
          { isEditing
            ?  <div>
                  <label>Email</label>
                  <p>{props.state.data.email}</p>
                </div>
            : props.renderInput("email", "Email", "text", true, 50 )
          }
          </div>
          <div className="col-3">
          { isEditing
              ?  <div>
                    <label>Password</label>
                    <p>
                      <span>*********</span>
                      <button className="btn btn-change-password" type="button"
                        onClick={() => setChangePasswordModalisOpen(true)}>
                        <i className="fa fa-pencil" aria-hidden="true"></i>&nbsp;Change Password
                      </button>
                    </p>
                  </div>
              : props.renderInput("password", "Password", "password", true )
            }
          </div>

          <div className="col-3">
            {props.renderCheckbox("active", "Active" )}
          </div>
        </div>
        
        <div className="form-footer">
          <div className="row justify-content-end align-items-center">
            <div className="col-auto">
              <button disabled={ validateUserInfo } className="btn btn-primary" type="submit">
                Save User
              </button>
            </div>
          </div>
        </div>
      </form>

      <UserChangePasswordModal
        isOpen={changePasswordModalisOpen}
        closeModal={handleCloseChangePasswordModal}
        props={props}
      />
    </section>
  );
}

export default UsersFormInfo;
