import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { capitalize }from '../../../../utils/value-formats';
import { UserPermissions } from '../../../../utils/UserPermissions';
import RedirectToLogin from '../../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../../services/authService';
import { deleteShirtSize } from '../../../../services/shirtSizesService';
import { deleteBlazerSize } from '../../../../services/blazerSizesService';
import { deleteCoatSize } from '../../../../services/coatSizesService';
import DeleteConfirmation from '../../../Common/delete-confirmation/deleteConfirmation';
import { SettingsContext } from '../../Settings';
import { ClothesSettingsContext } from '../ClothesSettings';
import { CLOTHES_TYPES } from './ClothesSettingsUtils';

function ClothesSizeDelete({ CLOTHES, id }) {
  const settingsContext = useContext(SettingsContext);
  const clothesSettingsContext = useContext(ClothesSettingsContext);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  
  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmation(false);
  };

  const handleDeleteClothesSize = () => {
    const deleteClotheSizeData = {};
    deleteClotheSizeData.CLOTHES_TYPE = CLOTHES.TYPE;
    deleteClotheSizeData.CLOTHES_LABEL = CLOTHES.LABEL.slice(0, -1);
    deleteClotheSizeData.CLOTHES_ID = id;
    setDeleteConfirmation(true);
    setDeleteData(deleteClotheSizeData);
  };

  const handleRemoveClothesSize = async () => {
    const { CLOTHES_TYPE, CLOTHES_ID } = deleteData;
    setDeleteConfirmation(false);
    let dataRes;
    if (CLOTHES_TYPE === CLOTHES_TYPES.SHIRT.TYPE) dataRes = await deleteShirtSize(CLOTHES_ID);
    if (CLOTHES_TYPE === CLOTHES_TYPES.BLAZER.TYPE) dataRes = await deleteBlazerSize(CLOTHES_ID);
    if (CLOTHES_TYPE === CLOTHES_TYPES.COAT.TYPE) dataRes = await deleteCoatSize(CLOTHES_ID);
    if (!dataRes.data.success) {
      let errorMessage = dataRes.data.message;
      if (dataRes.data.message === 'EmptyResponse') {
        toast.error(messages.error['settings' + capitalize(CLOTHES_TYPE) + 'SizeReadyDeleted']);
        return;
      }
      toast.error(errorMessage);
      return;
    }
    toast.success(messages.success['settings' + capitalize(CLOTHES_TYPE) + 'SizeDeleted'], { autoClose: 1000 });
    clothesSettingsContext.setFetchData(true);
  }

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : UserPermissions('module_settings_tab_clothes_delete') && 
        <>
          <i className="fa fa-trash" 
            onClick={() => handleDeleteClothesSize() }
          ></i> 

          <DeleteConfirmation
            isOpen={deleteConfirmation}
            onRequestClose={handleCloseDeleteConfirmation}
            closeModal={handleCloseDeleteConfirmation}
            itemName={deleteData && 'a ' + deleteData.CLOTHES_LABEL}
            onDelete={handleRemoveClothesSize}
          />
        </>
  )
};

export default ClothesSizeDelete
