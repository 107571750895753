import React from "react";
import "./emptyData.scss"

const EmptyData = ({ type, icon, title, message }) => {
  return (
    <div className={"empty-data type-" + type + " d-flex flex-column justify-content-center"}>
      { icon &&
        <div className="image-wrapper">
          <img className="img-fluid"  src={ icon } alt={ title }/>
        </div>
      }
      <h5 className="empty-data-title">{ title }</h5>
      {message && <p className="empty-data-copy">{ message }</p>}
    </div>
  );
}

export default EmptyData;
