import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { messages } from '../../../../utils/language-en.json';
import { saveMessage } from '../../../../services/messagesService';
import { MessageDataModel } from './MessagesSettingsUtils';
import { MessagesSettingsContext } from '../MessagesSettings';

function AddMessage() {
  const messageSettingsContext = useContext(MessagesSettingsContext);
  const setFetchData = messageSettingsContext.action.setFetchData;
  const newMessage = messageSettingsContext.state.newMessage;
  const setNewMessage = messageSettingsContext.action.setNewMessage;

  const handleAddMessage = async () => {
    const { data } = await saveMessage(newMessage);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.settingsMesssageCreated, { autoClose: 1000 });
    setFetchData(true);
    setNewMessage(MessageDataModel)
  }

  const disableButton = () => {
    var validStartDate = moment(newMessage.start_date, "MM/DD/YYYY", true).isValid();
    var validEndDate = moment(newMessage.end_date, "MM/DD/YYYY", true).isValid();

    if (!newMessage.title || 
      !newMessage.content || 
      !newMessage.start_date || 
      !newMessage.end_date ||
      !validStartDate || 
      !validEndDate) {
      return true;
    }
    return false;
  }

  return (
    <button title="Add Message" className="btn btn-secondary btn-sm btn-add" 
      disabled={ disableButton() }
      onClick={() => handleAddMessage()}
    ><i className="fa fa-plus" aria-hidden="true"></i>
    </button>
  )
}

export default AddMessage
