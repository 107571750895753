import React from 'react';

function EventReconciliationListItemStaffName(props) {
  const item = props.item;
  return (
    <div className="staff-info d-flex align-items-center">
      <p className="staff-name">
        {item.staff_name}&nbsp;&nbsp;
        { item.type === 'Staff' &&
          <a href={'/staff/' + item.staff_id} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-external-link-alt external-link-alt-custom-xsmall"></i>
          </a>
        }
      </p>
    </div>
  )
}

export default EventReconciliationListItemStaffName
