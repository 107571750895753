import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { getStates, updateState } from '../../../../services/statesService';
import  { messages } from "../../../../utils/language-en.json";

function StatesSettingsList() {
  const [states, setStates] = useState([]);

  async function handleUpdateState(id, active) {
    active = (active) ? false : true;
    const { data } = await updateState(id, active);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.stateUpdated, { autoClose: 1000 });
    getAllStates();
  }

  const getAllStates = async () => {
    const { data } = await getStates();
    setStates(data.states);
  }

  useEffect(() => {
    getAllStates();
  }, []);

  return (
    <div className="StatesSettingsList">
      <div className="row">
        { states.length === 0
          ? <div>No data found</div>
          : states.map( (item, idx) => (
            <div className="col-sm-2" key={idx}>
              <div className={ item.active ? 'StatesSettingsCard card active' : 'StatesSettingsCard card' }>
                <div className="card-body">
                  <div className="card-title">
                    <h6>{item.name} - {item.code}</h6>
                  </div>
                  <div className="card-access">
                    <span className="access"
                      key={item.id} 
                      onClick={() => handleUpdateState(item.id, item.active)}
                    >
                    { item.active 
                      ? <i className="fas fa-check blueIcon" title="Click to disable"></i>
                      : <i className="fas fa-close redIcon" title="Click to enable"></i>
                    }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) )
        }
      </div>
    </div>
  )
}

export default StatesSettingsList
