import React from 'react';
import { UserPermissions } from '../../../../utils/UserPermissions';
import PositionsPayRateSettingsEdit from './PositionsPayRateSettingsEdit';
import PositionsPayRateSettingsReadOnly from './PositionsPayRateSettingsReadOnly';

function PositionsPayRateSettingsCard({ item }) {
  return (
    <div className="PositionsPayRateSettingsCard card">
      <div className="card-body">
        <h5 className="card-title">
          <i className={item.class}></i>{item.name}
        </h5>
        {UserPermissions('module_settings_tab_pay_rates_edit')
          ? <PositionsPayRateSettingsEdit item={item} />
          : <PositionsPayRateSettingsReadOnly item={item} />
        }
      </div>
    </div>
  )
}

export default PositionsPayRateSettingsCard
