import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import auth from "../../services/authService";
import { getDepartmentList } from "../../services/departmentService";
import { getEvents, deleteEvent, cloneEvent } from '../../services/eventsService';
import { messages } from "../../utils/language-en.json";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import DeleteConfirmation from '../Common/delete-confirmation/deleteConfirmation';
import CloneConfirmation from '../Common/clone-confirmation/cloneConfirmation';
import SearchBox from "../Common/searchBox";
import Pagination from '../Common/pagination/pagination';
import EventsList from './eventsList';
import "./events.scss";
import moment from 'moment';
import FilterByEventStatus from "../Common/filters/FilterByEventStatus";
import FilterByDepartment from "../Common/filters/FilterByDepartment";

class Events extends Component {
  mounted = false;

  state = {
    dataLoading: true,
    departments: [],
    events: [],
    filterDepartment: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc"},
    searchQuery: "",
    totalCount: 0,
    deleteItemSelected: [],
    cloneItemSelected: [],
    datesComplete: false,
    nameValid: false,
    status: []
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = sortDepartments;

    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) {
      this.setState({ filterDepartment: [userDepartmentId], currentPage: 1 });
    } else {
      this.setState({ filterDepartment: [], currentPage: 1 });
    }
    
    this.mounted && this.setState({ departments });
    this.mounted && this.populateEvents();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateEvents = async () => {
    try {
      const { data } = await getEvents({
        DEPARTMENT_ID: this.state.filterDepartment,
        LIMIT: this.state.pageSize,
        PAGE: this.state.currentPage,
        ORDER_BY: this.state.sortColumn.path,
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery,
        STATUS: this.state.status
      });
      this.setState({
        events: data.events,
        totalCount: data.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateEvents();
  };

  handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    await this.setState({ filterDepartment: departmentSelected, currentPage: 1 });
    this.populateEvents();
  };

  handleEventStatusChange = async (itemSelected) => {
    const statusSelected = itemSelected ? itemSelected.map((status) => status.value) : [];
    await this.setState({ status: statusSelected, currentPage: 1 });
    this.populateEvents();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateEvents();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateEvents();
  };

  openModalDeleteConfirmation = item => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  openModalCloneConfirmation = item => {
    this.setState({ cloneConfirmationModalIsOpen: true, cloneItemSelected: item, eventDates: item.eventDates });
  }

  closeModalCloneConfirmation = () => {
    this.setState({ newEventName: '', nameValid: false, datesComplete: false, cloneConfirmationModalIsOpen: false, cloneItemSelected: [], eventDates: [] });
  }

  dispatchEventDates = (eventDates) => {
    if (Object.keys(eventDates).length > 0) {
      this.setState({ datesComplete: true });
    }
    this.setState({ newEventDates: eventDates });
  };

  dispatchEventName = (newEventName) => {
    if (newEventName.length > 0) {
      this.setState({ nameValid: true });
    }
    this.setState({ newEventName: newEventName });
  };

  handleDelete = async () => {
    const item = this.state.deleteItemSelected;
    const originalEvents = this.state.events;
    const events = originalEvents.filter(m => m.id !== item.id);
    this.setState({ events, deleteConfirmationModalIsOpen: false, deleteItemSelected:[] });

    try {
      const { data } = await deleteEvent(item.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        this.setState({ events: originalEvents });
        return
      }
      toast.success(messages.success.eventDeleted, { autoClose: 1000 });
      window.location.reload();
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.eventReadyDeleted);

      this.setState({ events: originalEvents });
    }
  };

  handleClone = async () => {
    const item = this.state.cloneItemSelected;
    const originalEvents = this.state.events;
    const events = originalEvents.filter(m => m.id !== item.id);
    this.setState({ events, cloneConfirmationModalIsOpen: false, cloneItemSelected:[] });

    try {
      const { data } = await cloneEvent(item.id, this.state.newEventName, this.state.newEventDates);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        this.setState({ events: originalEvents });
        return
      }
      toast.success(messages.success.eventCloned, { autoClose: 1000 });
      window.location.reload();
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.eventReadyDeleted);

      this.setState({ events: originalEvents });
    }
  };

  render() {
    const {
      dataLoading,
      departments,
      events,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      totalCount,
      deleteConfirmationModalIsOpen,
      cloneConfirmationModalIsOpen,
      deleteItemSelected,
      cloneItemSelected,
      datesComplete,
      nameValid,
      pageWrapperHeight
    } = this.state;
    const { user } = this.props;
    const authPermissions = auth.getCurrentUserPermisions();

    return (
      <main className="events-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="far fa-calendar-alt"></i>&nbsp;Events</h1>
            </div>
            <div className="col text-right">
                <Link to={`/events/calendar/${moment().month() + 1}/${moment().year()}`} 
                  className="btn btn-primary">
                  Calendar of Events
                </Link>
                &nbsp;
              {authPermissions["module_events_add"] &&
                <Link
                  to="/events/create/"
                  className="btn btn-primary"
                >
                  Add Event
                </Link>
              }
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-left d-flex align-items-end justify-content-center">
              <FilterByDepartment
                departments={departments}
                handleDepartmentChange={this.handleDepartmentChange}
              />
              <FilterByEventStatus 
                handleEventStatusChange={this.handleEventStatusChange} 
              />
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </div>
            </div>
          </section>
          <EventsList
            data={ events }
            user={ user }
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onDelete={this.openModalDeleteConfirmation}
            onClone={this.openModalCloneConfirmation}
            isLoading={ dataLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          )}
          <CloneConfirmation
            isOpen={cloneConfirmationModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModalCloneConfirmation}
            closeModal={this.closeModalCloneConfirmation}
            itemName={cloneItemSelected.name}
            eventDates={cloneItemSelected.eventDates}
            dispatchEventName={this.dispatchEventName}
            dispatchEventDates={this.dispatchEventDates}
            datesComplete={datesComplete}
            nameValid={nameValid}
            onClone={this.handleClone}
          />
          <DeleteConfirmation
            isOpen={deleteConfirmationModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModalDeleteConfirmation}
            closeModal={this.closeModalDeleteConfirmation}
            itemName={deleteItemSelected.name}
            onDelete={this.handleDelete}
          />
        </section>
      </main>
    );
  }
}

export default Events;
