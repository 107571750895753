import React from 'react';
import { Modal , Button } from 'react-bootstrap';

const EventStaffAvailableToolNotification = ({ isOpen, onAfterOpen, onRequestClose, closeModal, numberItems, onNotify, testModeNotifications }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-notification">
      <Modal.Header>
        <Modal.Title>Notification {(testModeNotifications) ? '(TEST MODE)' : ''}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p><b>You are about to notify {(numberItems + '' === '0') ? 'ALL' : numberItems} staff {(numberItems + '' === '1') ? 'member' : 'members'}</b></p>
        <p>Selected staff will receive the event details to their preferred notification method.</p>
        <p>Do you want to proceed?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" onClick={onNotify} >Yes</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default EventStaffAvailableToolNotification;
