import React, { useReducer, useContext, useEffect } from 'react';
import { SwipeableViewsContext, EventFetchContext } from '../../Event/Event';
import EventStaffPositions from './EventStaffPositions/EventStaffPositions';
import EventStaffAvailable from './EventStaffAvailable/EventStaffAvailable';
import EventsStaffStats from './EventsStaffStats/EventsStaffStats';
import EventStaffAssigned from './EventStaffAssigned/EventStaffAssigned';
import EventDatesNav from '../EventTools/EventDatesNav/EventDatesNav';
import EventStaffAssignedToolClonePosition from './EventStaffAssigned/EventStaffAssignedTools/EventStaffAssignedToolClonePosition';
import EventStaffAssignedToolConfirmations from './EventStaffAssigned/EventStaffAssignedTools/EventStaffAssignedToolConfirmations';
import { UserPermissions } from '../../../../utils/UserPermissions';
import './EventStaff.scss';

export const EventStaffContext = React.createContext()

const initialPosition = {
  selected: null
};
const initialEventDate = null;

const initialAvailableAssignedPositions = {
  list: null,
  total: null,
  positionsTotal: null
}

const reducerEventStaff = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_DATE':
      return action.VALUE
    case 'SELECT_POSITION':
      return { ...state, selected: action.VALUE }
    case 'POSITIONS_AVAILABLE':
      return action.VALUE
    case 'POSITIONS_ASSIGNED_TOTAL':
      return action.VALUE
    default:
      return state;
  }
}

function EventStaff({ event }) {
  const eventFetchContext = useContext(EventFetchContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [position, dispatchPosition] = useReducer(reducerEventStaff, initialPosition);
  const [eventdate, dispatchEventDate] = useReducer(reducerEventStaff, initialEventDate);
  const [availableAssignedPositions, dispatchAvailableAssignedPositions] = useReducer(reducerEventStaff, initialAvailableAssignedPositions)
  const eventIsClosed = event.status === 'Closed';
  const eventIsReconciled = event.status === 'Reconciled';

  useEffect(() => {
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: event.events_dates[0] });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
  }, []);

  useEffect(() => { 
    swipeableViewsContext.updateSwipeableHeight();
  })

  // ** HANDLE
  // *********************************************************

  const handleChangeEventDate = (dateIndex) => {
    const eventDateSelected = event.events_dates[dateIndex.value];
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: eventDateSelected });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
  }

  return (
    <EventStaffContext.Provider 
      value={{
        event,
        eventdate,
        position, 
        dispatchPosition,
        availableAssignedPositions,
        dispatchAvailableAssignedPositions,
      }}
    >
      { !eventIsClosed && !eventIsReconciled
          ? <section className="EventStaff form-wrapper container-fluid">
              <div className="row">
                <div className="col-sm-3">
                  <EventStaffPositions />
                  <EventStaffAvailable />
                </div>
                <div className="col-sm-9">
                  <EventsStaffStats 
                    event={event} 
                    eventDateSelected={eventdate}
                  />
                  <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                      <EventDatesNav
                        event={event}
                        eventDateSelected={eventdate}
                        handleChangeEventDate={handleChangeEventDate}
                      />
                    </div>
                    <div className="col-sm-4 d-flex align-items-center justify-content-end">
                      { UserPermissions('module_events_pending_confirmations') &&
                        <EventStaffAssignedToolConfirmations />
                      }
                      { UserPermissions('module_events_clone_positions') &&
                        <EventStaffAssignedToolClonePosition />
                      }
                    </div>
                  </div>
                  <EventStaffAssigned />
                </div>
              </div>
            </section>
          : <section className="EventStaff form-wrapper container-fluid">
              <div className="row">
                <div className="col-sm-3">
                  <EventStaffPositions />
                </div>
                <div className="col-sm-9">
                  <EventsStaffStats 
                      event={event} 
                      eventDateSelected={eventdate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-center">
                  <EventDatesNav
                    event={event}
                    eventDateSelected={eventdate}
                    handleChangeEventDate={handleChangeEventDate}
                  />
                </div>
                <div className="col-sm-12">
                  <EventStaffAssigned />
                </div>
              </div>
            </section>
      }
    </EventStaffContext.Provider>
  )
}

export default EventStaff
