import React from "react";
import "./form.scss";

const Select = ({ name, label, options, error, required, labelBold = false, ...rest }) => {
  return (
    <div className={ error ? 'form-group has-error' : 'form-group'}>
      { label && (
        <label className={ (required) ? 'required' : '' || (labelBold) ? 'label-bold' : '' } htmlFor={name}>{label}</label>
      )}
      <select name={name} id={name} {...rest} required={required} className={(rest.inputsize) ? "form-control form-control-"+rest.inputsize : "form-control " }>
        {options && options.map(option => {
          let optionName = option.name;
          if ( name === 'blazer_size_id' ) 
            optionName =  ( ( (option.gender) ? option.gender + ' - ' : '' ) + ( (option.name) ? option.name : '' ) );
          if ( name === 'coat_size_id' ) 
            optionName = option.size;
          return (
            <option key={option.id} value={option.id}>
              { optionName }
            </option>
          );
        })}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
