import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import { UserPermissions } from '../../../utils/UserPermissions';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getDepartmentList } from '../../../services/departmentService';
import Loading from '../../Common/loading/loading';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import { DepartmentDataModel } from './DepartmentsSettingsUtils/DepartmentsSettingsUtils';
import DepartmentTableCellView from './DepartmentsSettingsUtils/DepartmentTableCellView';
import UpdateDepartment from './DepartmentsSettingsUtils/UpdateDepartment';
import EditDepartment from './DepartmentsSettingsUtils/EditDepartment';
import AddDepartment from './DepartmentsSettingsUtils/AddDepartment';
import './DepartmentsSettings.scss';

export const DepartmentSettingsContext = React.createContext();

function DepartmentsSettings() {
  const settingsContext = useContext(SettingsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [fetchData, setFetchData] = useState(true);
  const [departments, setDepartments] = useState(null);
  const [departmentsList, setDepartmentsList] = useState(null);
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [editDepartment, setEditDepartment] = useState(null);
  const [newDepartment, setNewDepartment] = useState(DepartmentDataModel);

  const TABLE_COLUMNS = [
    { path: "name", 
      label: "Department",
      content: item => <DepartmentTableCellView item={item} fieldName="name" fieldType="input" />
    },
    { path: "office_phone", 
      label: "Office Phone",
      content: item => <DepartmentTableCellView item={item} fieldName="office_phone" fieldType="input" />
    },
    { path: "target_hourly_rate", 
      label: "Target Hourly Rate",
      content: item => <DepartmentTableCellView item={item} fieldName="target_hourly_rate" fieldType="number" />
    },
    { path: "company_code", 
      label: "Company Code",
      content: item => <DepartmentTableCellView item={item} fieldName="company_code" fieldType="input" />
    },
    { path: "state", 
      label: "State",
      content: item => <DepartmentTableCellView item={item} fieldName="state" fieldType="input" />
    },
    { path: "division", 
      label: "Division",
      content: item => <DepartmentTableCellView item={item} fieldName="division" fieldType="input" />
    },
    { path: "city_code", 
      label: "City Code",
      content: item => <DepartmentTableCellView item={item} fieldName="city_code" fieldType="input" />
    }
  ]

  const TABLE_COLUMN_ACTIONS = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: item => (
      item.id 
        ? editDepartment && item.id === editDepartment.id 
          ? <UpdateDepartment />
          : <EditDepartment item={item} />
        : <AddDepartment />
    )
  }

  UserPermissions('module_settings_tab_departments_add') && TABLE_COLUMNS.push(TABLE_COLUMN_ACTIONS);

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    const fetchDepartments = async () => {
      const { data } = await getDepartmentList();
      const departmentList = UserPermissions('module_settings_tab_departments_add') ? [newDepartment, ...data.department] : data.department ;
      setDepartments(departmentList);
      setDepartmentsList(data.department);
      setLoading(false);
      setFetchData(false);
    }
    fetchData && fetchDepartments();
  }, [fetchData]);

  useEffect(() => {
    const sortedData = _.orderBy(departmentsList, [sortColumn.path], [sortColumn.order]);
    const dataList = UserPermissions('module_settings_tab_departments_add') ? [newDepartment, ...sortedData] : sortedData ;
    setDepartments(dataList);
  }, [sortColumn]);


  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : loading
        ? <Loading type="tabsContentLoading" />
        : <DepartmentSettingsContext.Provider value={{ 
            action: { setEditDepartment, setNewDepartment, setFetchData },
            state: { editDepartment, newDepartment }
          }} >
            <section className="DepartmentsSettings content-wrapper container-fluid">
              <Table
                name={'departments'}
                columns={TABLE_COLUMNS}
                data={departments}
                sortColumn={sortColumn}
                onSort={sortColumn => setSortColumn(sortColumn)}
                isLoading={loading}
                itemIdSelected={editDepartment && editDepartment.id}
              />
            </section>
          </DepartmentSettingsContext.Provider>
  )
}

export default DepartmentsSettings
