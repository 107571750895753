import http from "./httpService";

const apiEndpoint = "/notes";

export function saveNote(dataBody) {
  if (dataBody.id)
    return http.post(apiEndpoint + '/update', dataBody);

  return http.post(apiEndpoint + '/add', dataBody);
}

export function getNoteById(noteId) {
  return http.get(apiEndpoint + '/get_id', {
    params: {
      id: noteId
    }
  });
}

export function getNotes(component, componentId) {
  return http.get(apiEndpoint + '/list', {
    params: {
      component: component, 
      component_id: componentId
    }
  });
}

export function deleteNote(noteId) {
  return http.post(apiEndpoint + '/delete', {id: noteId});
}
