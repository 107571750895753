import React, { useState } from 'react';
import { toast } from "react-toastify";
import { Modal , Button } from 'react-bootstrap';
import auth from "../../../services/authService";
import { messages } from '../../../utils/language-en.json';

function RecoverPasswordModal() {
  const [showModal, setShowModal] = useState(false);
  const [emailAdded, setEmailAdded] = useState(null);

  const handleChange = ({ currentTarget: input }) => {
    setEmailAdded(input.value);
  }
  
  const handleRecoverPassword = async () => {
    if (emailAdded) {
      const { data: resp } = await auth.resetPassword(emailAdded);
      if (!resp.success) {
        return toast.error(resp.message);
      }
      
      toast.success(messages.success.forgotPasswordSent, { autoClose: 1000 });
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  }

  return (
    <>
      <p className="text-right">
        <button type="button" className="recoverLink" onClick={() => setShowModal(true)}>
          Forgot Password?
        </button>
      </p>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="modal-recover-password">
        <Modal.Header closeButton>
          <Modal.Title>
            Forgot Password?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll-wrapper">
            <p>Enter your email address and we'll send a new password</p>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                  <div className="form-group">
                      <div className="d-flex flex-column-reverse">
                        <input 
                          required="required" 
                          name="recover_email" 
                          id="recover_email" 
                          className="form-control"
                          onChange={handleChange} />
                        <label className="required" htmlFor="recover_email">Email</label>
                      </div>
                    </div>  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => handleRecoverPassword()}>Send</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default RecoverPasswordModal;
