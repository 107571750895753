import http from "./httpService";

const apiEndpoint = "/volunteers";

export function getOrganizations({DEPARTMENT_ID = [], LIMIT = 100, PAGE = 1, ORDER_BY = 'name', SORT_ORDER = 'ASC', SEARCH} ) {
  return http.get(apiEndpoint + '/list', {
    params: {
      department_id: DEPARTMENT_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  });
}

export function getAllOrganizations() {
  return http.get(apiEndpoint + '/list_all');
}

export function deleteOrganization(id) {
  return http.post( apiEndpoint + '/delete', { id: id } );
}

export function saveOrganization(data) {
  if (data.id) {
    const body = { ...data };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', data);
}

export function getOrganizationItem(id) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: id 
    }
  });
}

export function getVolunteersOrganization(organizationId) {
  return http.get(apiEndpoint + '/list_volunteer_organization', {
    params: {
      organization_id: organizationId
    }
  });
}

export function deleteVolunteer(id) {
  return http.post( apiEndpoint + '/delete_volunteer', { id: id } );
}

export function addVolunteer(firstName, lastName, organizationId) {
  return http.post(apiEndpoint + '/add_volunteer', { 
    first_name: firstName, 
    last_name: lastName,
    organization_id: organizationId
  });
}

export function updateVolunteer(id, firstName, lastName) {
  return http.post(apiEndpoint + '/update_volunteer', { 
    id: id,
    first_name: firstName, 
    last_name: lastName
  });
}

export function getVolunteerItem(id) {
  return http.get(apiEndpoint + '/get_id_volunteer', { 
    params: {
      id: id 
    }
  });
}