import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

export function organizationsDataModel() {
  return {
    id: null,
    name: "",
    department_id: "",
    rate: ""
  }
}

export function organizationsSchema(isEditing) {
  return {
    id: Joi.number()
      .integer().allow(null, ''),
    name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Organization Name"),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Department"),
    rate: Joi.number()
      .required()
      .label("Rate"),
    available_all_positions: Joi.boolean()
      .allow(null, '')
      .label("Available for all Position Types?"),
  }
}

export function organizationsEditMapToViewModel(item,) {
  return {
    id: item.id,
    name: (item.name) ? item.name : "",
    department_id: item.department_id,
    rate: (item.rate) ? item.rate : "",
    available_all_positions: (item.available_all_positions) ? item.available_all_positions : false
  }
}

export function organizationStaffModel() {
  return {
    first_name: "",
    last_name: "",
    organization_id: ""
  }
}

export default {
  organizationsDataModel,
  organizationsSchema,
  organizationsEditMapToViewModel,
  organizationStaffModel
}