import React, { useState, useContext } from 'react';
import { EventStaffContext } from '../../EventStaff';
import { EventReconciliationContext } from '../../../EventReconciliation/EventReconciliation';
import { Modal } from 'react-bootstrap';
import { getOvertimeStaff, getOverlapStaff } from '../../../../../../services/eventsService';

function EventStaffAssignedListItemBadges(props) {
  const item = props.item;
  const type = props.type;
  let event = null;
  let travelStyle = '';
  let overtimeStyle = '';

  if (type === '1') {
    event = useContext(EventStaffContext).event;
    travelStyle = item.department_id && item.department_id !== event.department_id ? 'travel-staff' : '';
    overtimeStyle = item.type === 'Staff' && (item.overtime || item.overlap) ? ' overtime-staff' : '';
  } else if (type === '2') {
    event = useContext(EventReconciliationContext).event;
  }
  
  const [showOvertimeModal, setShowOvertimeModal] = useState(false);
  const [showOverlapModal, setShowOverlapModal] = useState(false);
  const [overtimeData, setOvertimeData] = useState([]);
  const [overlapData, setOverlapData] = useState([]);

  const getOvertimeData = async (item) => {
    const { data } = await getOvertimeStaff(item.staff_id, item.start_date, item.end_date);
    setOvertimeData(data.events);
    setShowOvertimeModal(true);
  }

  const getOverlapData = async (item) => {    
    const { data } = await getOverlapStaff(item.staff_id, item.staff_event_id, item.start_date, item.end_date);
    setOverlapData(data.events);
    setShowOverlapModal(true);
  }

  return (
    <div className={"staff-members-badges d-flex align-items-center " + travelStyle + overtimeStyle }>
      { item.staff_id > 0 && type === '1' && item.type === 'Staff' 
        ? <span className="badge badge-outline badge-rating">
          <i className="fas fa-star" title="Average Rating"></i>&nbsp;{ (item.rating > 0 ) ? item.rating : '–' }
        </span>
        : <span className="badge badge-outline-blank badge-placeholder"></span>
      }
      { type === '1' && item.type === 'Staff' && item.public_facing &&
        <span className="badge ">PF</span> 
      }
      { type === '1' && item.department_id && item.department_id !== event.department_id &&
        <span className="badge badge-travel">
          <i className="fas fa-plane" title="Shared Resource"></i>
        </span>
      }
      { item.type === 'Volunteer' &&
        <span className="badge badge-volunteer">
          <i className="fas fa-hands-helping" title="Volunteer"></i>
        </span>
      }
      { type === '1' && item.type === 'Staff' && item.overtime &&
        <>
          <button className="btn btn-overtime" type="button"
            onClick={() => getOvertimeData(item)}>
            <span className="badge badge-overtime">
              <i className="fas fa-clock"></i>
            </span>
          </button>
          
          <Modal show={showOvertimeModal} onHide={() => setShowOvertimeModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="fas fa-clock"></i>&nbsp;Overtime - {item.staff_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{parseFloat(item.week_hours).toFixed(2)} scheduled hours between {item.week_start} and {item.week_end} on these events:</p>
              <table className="modal-table">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Date</th>
                    <th>Hours</th>
                  </tr>
                </thead>
                <tbody>
                {overtimeData.map( event => (
                  <tr key={event.id + '-' + event.hours}>
                    <td><a href={"/events/" + event.id} target="_blank" rel="noopener noreferrer">{event.name}</a></td>
                    <td>{event.start_date}</td>
                    <td>{parseFloat(event.hours).toFixed(2)}</td>
                  </tr>
                 ) )}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
        </>
      }
      { item.overlap &&
        <>
          <button className="btn btn-overlap" type="button"
            onClick={() => getOverlapData(item)}>
            <span className="badge badge-overlap">
              <i className="fas fa-calendar-alt"></i>
            </span>
          </button>

          <Modal show={showOverlapModal} onHide={() => setShowOverlapModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="fas fa-calendar-alt"></i>&nbsp;Overlap - {item.staff_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>This staff member is scheduled for another shift that conflicts with this one in these events:</p>
              <table className="modal-table">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Position</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                {overlapData.map( event => (
                  <tr key={event.id}>
                    <td><a href={"/events/" + event.id} target="_blank" rel="noopener noreferrer">{event.name}</a></td>
                    <td>{event.assignment_name}</td>
                    <td>{event.start_date}</td>
                    <td>{event.start_time} - {event.end_time}</td>
                  </tr>
                ) )}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
        </>
      }
    </div>
  )
}

export default EventStaffAssignedListItemBadges
