import React, { useState, useEffect, useContext } from 'react';
import Select, { components } from 'react-select';
import { toast } from "react-toastify";
import _ from "lodash";
import { messages } from '../../../../../utils/language-en.json';
import { getEventPositions } from '../../../../../services/eventsService';
import { addConfigurationPosition } from '../../../../../services/eventsService';
import { EventFetchContext } from '../../Event';
import { EventStaffContext } from '../EventStaff';
import { getPositions }  from '../../../../../services/positionsService';
import VenueConfigurationPositionForm from '../../../../Venues/venues-forms/venue-form-configuration/partials/venue-configuration-position-form.jsx';
import './EventStaffPositions.scss';

const EventStaffPositions = () => {
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const eventDate = eventStaffContext.eventdate;
  const positionSelected = eventStaffContext.position.selected;

  const event = useContext(EventStaffContext).event;
  const eventIsClosed = event.status === 'Closed';
  const eventIsReconciled = event.status === 'Reconciled';

  const [positionsOptions, setPositionsOptions] = useState([]);
  const [showConfigurationPositionModal, setShowConfigurationPositionModal] = useState(false);
  const [positionList, setPositionList] = useState([]);
  const [configurationPositionItem, setConfigurationPositionItem] = useState([]);

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_STAFF_POSITIONS && fetchPositions();
    populatePositions();
  }, [eventFetchContext.fetchData.EVENT_STAFF_POSITIONS]);

  const fetchPositions = async () => {
    const { data } = await getEventPositions(eventDate.event_id, eventDate.id);
    const positions = data.positions.map(position => {
      position = { ...position, value: position.position_event_id}
      return position;
    });
    setPositionsOptions(positions);
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: false });
    
    if (!positionSelected) 
      return eventStaffContext.dispatchPosition({TYPE:'SELECT_POSITION', VALUE: positions[0]});

    const positionsUpdated = positions.find(item => item.position_event_id === positionSelected.position_event_id);
    if (positionsUpdated)
      eventStaffContext.dispatchPosition({TYPE:'SELECT_POSITION', VALUE: positionsUpdated })
      if (eventDate) {
        const positionIndex = positions.findIndex(item => item.position_name === positionSelected.position_name);
        eventStaffContext.dispatchPosition({TYPE:'SELECT_POSITION', VALUE: positions[positionIndex]});
      }
    else
      eventStaffContext.dispatchPosition({TYPE:'SELECT_POSITION', VALUE: positions[0]});
  }

  const populatePositions = async () => {
    const { data: resPositionList } = await getPositions();
    const sortPositionsList = _.orderBy(resPositionList.position, ['name'], ['asc']);
    const positionList = [{ id: '', name: '' }, ...sortPositionsList];
    setPositionList(positionList);
  }

  // ** RENDER VIEWS
  // *********************************************************

  const optionPositionContainer = ({data, ...props}) => {
    const isFullyStaffed = data.position_staff_count === data.position_staff_total;
    return (
      <components.Option className={isFullyStaffed ? "position-option isFull" : "position-option" } {...props}>
        {renderPositionOption(data)}
      </components.Option>
    )
  }
  
  const valuePositionContainer = ({ children, selectProps, ...props }) => {
    return (
      <components.ValueContainer {...props}>
        {children}
        {selectProps.value && renderPositionOption(selectProps.value)}
      </components.ValueContainer>
    )
  };
  
  const renderPositionOption = (data) => (
    <div className="position-option-info d-flex justify-content-between align-items-center">
      <span className="name">{data.position_name}</span>
      <span className="counter">
        <i className="fas fa-users"></i>&nbsp;
        <span className="count">{data.position_staff_count}</span>
        <span className="total">{data.position_staff_total}</span>
      </span>
    </div>
  );

  const handleAddPosition = () => {
    setShowConfigurationPositionModal(true);
  };

  const handleAddPositionClose = () => {
    setShowConfigurationPositionModal(false);
    setConfigurationPositionItem([]);
  };

  const afterOpenModal = {}

  const handleChangeConfigurationPositionForm = ({ currentTarget: input }) => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
      if ( input.name === 'radio') {
        const data = { ...configurationPositionItem };
        if (!configurationPositionItem.radio_qty) {
          data['radio_qty'] = data['qty'];
        }
        data[input.name] = value;
        setConfigurationPositionItem(data);
      }
    }
    const data = { ...configurationPositionItem };
    data[input.name] = value;

    setConfigurationPositionItem(data);
  }

  const handleBlur = ({ currentTarget: input }) => {
    if ( input.name === 'qty') {
      const data = { ...configurationPositionItem };
      if (!configurationPositionItem.radio_qty) {
        data['radio_qty'] = input.value;
      }
      setConfigurationPositionItem(data);
    }
  }

  const handleSaveConfigurationPosition = async () => {
    try {
      const { data } = await addConfigurationPosition(configurationPositionItem, eventDate.event_id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.eventConfigurationPositionCreated, { autoClose: 1000 });
      fetchPositions();
    } catch (ex) {
    }
    setConfigurationPositionItem([]);
    setShowConfigurationPositionModal(false);
    return; 
  }

  return (
    <section className="EventStaffPositions">
      <h4 className="label position-title"><i className="fas fa-shield-alt"></i>&nbsp;Positions</h4>
      <div className="add-position-button">
        { !eventIsClosed && !eventIsReconciled
          ? <button type="button" className="btn btn-sm btn-add-position" onClick={() => handleAddPosition()}>
            <b>+</b>
          </button>
        : ''
        }
      </div>
      <div className="positions-select">
        <Select
          isSearchable={false}
          className="select-position"
          options={positionsOptions}
          value={positionSelected}
          components={ {Option: optionPositionContainer, ValueContainer: valuePositionContainer } }
          onChange={optionSelected => eventStaffContext.dispatchPosition({TYPE:'SELECT_POSITION', VALUE: optionSelected }) }
        />
      </div>
      <VenueConfigurationPositionForm
        isOpen={showConfigurationPositionModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleAddPositionClose}
        closeModal={handleAddPositionClose}
        data={configurationPositionItem}
        handleChange={handleChangeConfigurationPositionForm}
        handleBlur={handleBlur}
        positionsTypeOptions={positionList}
        handleSave={handleSaveConfigurationPosition}
      />
    </section>
  );
}

export default EventStaffPositions
