import React from "react";
import PropTypes from "prop-types";
import "./pagination.scss";
import Input from "../form/input";

const Pagination = ({ itemsCount, totalItemsCount, pageSize, currentPage, onPageChange, overLoading }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount <= 1) return null;
  const nextPage = currentPage + 1;
  const prevPage = currentPage - 1;
  const lastPage = pagesCount;
  const firstPage = 1;

  const handleChange = ({ currentTarget: input }) => {
    const value = (input.value) ? Number(input.value) : false;
    const gotoPage = ( value > pagesCount ) ? pagesCount : ( value === 0 ) ? 1 : value;

    if ( value )
      onPageChange(gotoPage);
  }

  const handleSelect = ({ currentTarget: input }) => {
    input.select();
  }

  return (
    <nav className="pagination-console d-flex align-items-center justify-content-end">
      <span className="pagination-info"> <b>{ currentPage }</b> of <b>{ pagesCount }</b> pages / Total items: <b>{ itemsCount }</b>&nbsp;&nbsp;</span>
      { overLoading && (
        <React.Fragment>
          <span className="pagination-info">of <b>{totalItemsCount}</b></span>
          <div className="over-loading">
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        </React.Fragment>
      ) }
      <ul className="pagination">
        <li className="page-item">
          <button type="button" className="page-link" disabled={ currentPage === 1 } onClick={ () => onPageChange(firstPage)}>
            <i className="fa fa-angle-double-left"></i>
          </button>
        </li>
        <li className="page-item">
          <button type="button" className="page-link prev-page" disabled={ currentPage === 1 } onClick={ () => onPageChange(prevPage)}>
            <i className="fa fa-angle-left"></i>
          </button>
        </li>
        <li className="page-item goto-page">
          <Input
            type={'number'}
            name={'gotoPage'}
            value={currentPage}
            onChange={handleChange}
            max={pagesCount}
            min={1}
            onClick={handleSelect}
          />
        </li>
        <li className="page-item">
          <button type="button" className="page-link next-page" disabled={ currentPage === pagesCount } onClick={ () => onPageChange(nextPage)}>
            <i className="fa fa-angle-right"></i>
          </button>
        </li>
        <li className="page-item">
          <button type="button" className="page-link" disabled={ currentPage === pagesCount } onClick={ () => onPageChange(lastPage)}>
            <i className="fa fa-angle-double-right"></i>
          </button>
        </li>
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
