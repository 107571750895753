import React, { useContext, useReducer, useEffect } from 'react';
import _ from "lodash";
import { UserPermissions } from '../../../../utils/UserPermissions';
import { getEventReconciliationList } from '../../../../services/eventsService';
import usePreviousValue from '../../../../utils/usePreviousValue';
import { paginate } from "../../../../utils/paginate";
import Pagination from '../../../Common/pagination/pagination';
import EventDatesNav from '../../Event/EventTools/EventDatesNav/EventDatesNav';
import { EventFetchContext, SwipeableViewsContext } from '../Event';
import EventReconciliationToolBulkEndTime from './EventReconciliationTools/EventReconciliationToolBulkEndTime';
import EventReconciliationToolBulk from './EventReconciliationTools/EventReconciliationToolBulk';
import EventReconciliationList from './EventReconciliationList';
import EventsReconciliationSummary from './EventReconciliationSummary';
import './EventReconciliation.scss';

export const EventReconciliationContext = React.createContext();

const initialEventDate = null;
const initialSearchQuery= '';
const initialEventReconciliationList = {
  loading: true,
  data: null,
  allData: null,
  error: null,
  selected: null
}
const initialPagination = {
  currentPage: 1,
  pageSize: 100,
  totalCount: 0
}

const initialSortColumn = {
  path: 'name', 
  order: 'asc'
}

const reducerEventReconciliation = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_DATE':
      return action.VALUE
    case 'RECONCILIATION_LIST':
      return {...state, data: action.VALUE}
    case 'RECONCILIATION_LIST_ALL_DATA':
      return {...state, allData: action.VALUE}
    case 'RECONCILIATION_LIST_LOADING':
      return {...state, loading: action.VALUE}
    case 'SEARCH_RECONCILIATION_LIST':
      return action.VALUE
    case 'PAGINATION_TOTAL_COUNT':
      return {...state, totalCount: action.VALUE}
    case 'PAGINATION_CURRENT_PAGE':
      return {...state, currentPage: action.VALUE}
    case 'SORT_COLUMN':
      return action.VALUE
    case 'RECONCILIATION_SUMMARY':
      return {...state, summaryData: action.VALUE}
    default:
      return state;
  }
}

function EventReconciliation({ event }) {
  const eventFetchContext = useContext(EventFetchContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [eventdate, dispatchEventDate] = useReducer(reducerEventReconciliation, initialEventDate);
  const [eventReconciliationList, dispatchEventReconciliationList] = useReducer(reducerEventReconciliation, initialEventReconciliationList);
  const [searchQuery, dispatchSearchQuery] = useReducer(reducerEventReconciliation, initialSearchQuery);
  const [pagination, dispatchPagination] = useReducer(reducerEventReconciliation, initialPagination);
  const [sortColumn, dispatchSortColumn] = useReducer(reducerEventReconciliation, initialSortColumn);
  const eventIsClosed = event.status === 'Closed';
  const prevEventdateValue = usePreviousValue(eventdate)

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: event.events_dates[0] });
  },[]);

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_RECONCILIATION && fetchReconciliationList( event.id, eventdate.id);
    eventFetchContext.fetchData.EVENT_RECONCILIATION && eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: false });
  }, [eventFetchContext.fetchData.EVENT_RECONCILIATION]);

  useEffect(() => {
    event && eventdate && fetchReconciliationList( event.id, eventdate.id);
    prevEventdateValue && prevEventdateValue.id !== eventdate.id && dispatchPagination({ TYPE: 'PAGINATION_CURRENT_PAGE', VALUE: 1 });
  }, [
    event, 
    eventdate
  ]);

  useEffect(() => {
    eventReconciliationList.allData && buildListData();
  }, [
    pagination.currentPage,
    sortColumn
  ]);

  useEffect(() => {
    searchQuery && dispatchPagination({ TYPE: 'PAGINATION_CURRENT_PAGE', VALUE: 1 });
    eventReconciliationList.allData && buildListData();
  }, [
    eventReconciliationList.allData,
    searchQuery
  ]);

  const buildListData = () => {
    let filtered = eventReconciliationList.allData;
    
    if (searchQuery) {
      filtered = eventReconciliationList.allData.filter(m =>
        m.staff_name.toLowerCase().includes(searchQuery.toLowerCase()) |
        m.position_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const reconciliationListData = paginate(sorted, pagination.currentPage, pagination.pageSize);

    dispatchEventReconciliationList({ TYPE: 'RECONCILIATION_LIST', VALUE: reconciliationListData });
    dispatchPagination({ TYPE: 'PAGINATION_TOTAL_COUNT', VALUE: filtered.length });

  }

  const fetchReconciliationList = async (EVENT_ID, EVENT_DATE_ID) => {
    const fetchProperties = {
      params: {
        event_id: EVENT_ID,
        event_date_id: EVENT_DATE_ID
      }
    }
    try {
      const { data: resReconciliation } = await getEventReconciliationList(fetchProperties);
      const reconciliationList = resReconciliation.reconciliation;
      dispatchEventReconciliationList({ TYPE: 'RECONCILIATION_LIST_ALL_DATA', VALUE: reconciliationList });
      dispatchEventReconciliationList({ TYPE: 'RECONCILIATION_LIST_LOADING', VALUE: false });
      dispatchEventReconciliationList({ TYPE: 'RECONCILIATION_SUMMARY', VALUE: resReconciliation.summary });
    } catch (ex) { }
  }


  // ** HANDLE
  // *********************************************************
  const handleChangeEventDate = (dateIndex) => {
    const eventDateSelected = event.events_dates[dateIndex.value];
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: eventDateSelected });
  }

  return (
    <EventReconciliationContext.Provider
      value={{
        event,
        eventReconciliationList,
        dispatchEventReconciliationList,
        eventdate,
        fetchReconciliationList,
        sortColumn,
        dispatchSortColumn
      }}
    >
      <section className="EventReconciliation form-wrapper container-fluid">
        <section className="EventReconciliation-header">
          <div className="OptionsContainer">
            { !eventIsClosed && UserPermissions('module_events_reconcile_all') && <EventReconciliationToolBulk /> }&nbsp;
            { !eventIsClosed && <EventReconciliationToolBulkEndTime /> }
          </div>
          <div className="row justify-content-between align-items-center">
            <div>
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <div className="SearchBox">
                  <input
                    type="text"
                    name="query"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={e => dispatchSearchQuery({ TYPE: 'SEARCH_RECONCILIATION_LIST', VALUE: e.currentTarget.value})}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <EventDatesNav
                  event={event}
                  eventDateSelected={eventdate}
                  handleChangeEventDate={handleChangeEventDate}
                />
            </div>
            <div className="col-sm-4 d-flex justify-content-end">
              <EventsReconciliationSummary/>
            </div>
          </div>
        </section>
        <EventReconciliationList />
        <Pagination
          itemsCount={pagination.totalCount}
          pageSize={pagination.pageSize}
          currentPage={pagination.currentPage}
          onPageChange={page => dispatchPagination({ TYPE: 'PAGINATION_CURRENT_PAGE', VALUE: page })} 
        />
      </section>
    </EventReconciliationContext.Provider>
  )
}

export default EventReconciliation
