import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import auth from "../../services/authService";
import { getDepartmentList } from "../../services/departmentService";
import { getCustomers, deleteCustomer } from '../../services/customersService';
import { messages } from "../../utils/language-en.json";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import DeleteConfirmation from '../Common/delete-confirmation/deleteConfirmation';
import SearchBox from "../Common/searchBox";
import Pagination from '../Common/pagination/pagination';
import CustomersList from './customersList';
import FilterByDepartment from "../Common/filters/FilterByDepartment";
import "./customers.scss";

class Customers extends Component {
  mounted = false;

  state = {
    dataLoading: true,
    departments: [],
    customers: [],
    filterDepartment: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc"},
    searchQuery: "",
    totalCount: 0,
    deleteItemSelected : []
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = sortDepartments;

    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) {
      this.setState({ filterDepartment: [userDepartmentId], currentPage: 1 });
    } else {
      this.setState({ filterDepartment: [], currentPage: 1 });
    }

    this.mounted && this.setState({ departments });
    this.mounted && this.populateCustomers();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateCustomers = async () => {
    try {
      const { data } = await getCustomers({
        DEPARTMENT_ID: this.state.filterDepartment,
        LIMIT: this.state.pageSize,
        PAGE: this.state.currentPage,
        ORDER_BY: this.state.sortColumn.path,
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery
      });
      this.setState({
        customers: data.customer, 
        totalCount: data.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateCustomers();
  };

  handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    await this.setState({ filterDepartment: departmentSelected, currentPage: 1 });
    this.populateCustomers();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateCustomers();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateCustomers();
  };

  openModalDeleteConfirmation = item => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  handleDelete = async () => {
    const customersItem = this.state.deleteItemSelected;
    const originalCustomersList = this.state.customers;
    const customers = originalCustomersList.filter(m => m.id !== customersItem.id);
    this.setState({ customers, deleteConfirmationModalIsOpen: false, deleteItemSelected:[] });

    try {
      const { data } = await deleteCustomer(customersItem.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        this.setState({ customers: originalCustomersList });
        return
      }
      toast.success(messages.success.customerDeleted, { autoClose: 1000 });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.customersReadyDeleted);

      this.setState({ customers: originalCustomersList });
    }
  };

  render() {
    const {
      dataLoading, 
      departments, 
      customers,
      pageSize, 
      currentPage, 
      sortColumn, 
      searchQuery, 
      totalCount,
      deleteConfirmationModalIsOpen, 
      deleteItemSelected, 
      pageWrapperHeight
    } = this.state;
    const { user } = this.props;
    const authPermissions = auth.getCurrentUserPermisions();

    return (
      <main className="customers-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fa fa-bullhorn"></i>&nbsp;Customers</h1>
            </div>
            <div className="col text-right">
              {authPermissions["module_customers_add"] &&
                <Link
                  to="/customers/create/"
                  className="btn btn-primary"
                >
                  Add Customer
                </Link>
              }
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-right">
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <FilterByDepartment
                  departments={departments}
                  handleDepartmentChange={this.handleDepartmentChange}
                />
              </div>
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </div>
            </div>
          </section>
          <CustomersList
            customers={ customers }
            user={ user }
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onDelete={this.openModalDeleteConfirmation}
            isLoading={ dataLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          )}
          <DeleteConfirmation
            isOpen={deleteConfirmationModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModalDeleteConfirmation}
            closeModal={this.closeModalDeleteConfirmation}
            itemName={deleteItemSelected.name}
            onDelete={this.handleDelete}
          />
        </section>
      </main>
    );
  }
}

export default Customers;