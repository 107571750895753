import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { capitalize }from '../../../../utils/value-formats';
import { UserPermissions } from '../../../../utils/UserPermissions';
import RedirectToLogin from '../../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../../services/authService';
import { addShirtSize } from '../../../../services/shirtSizesService';
import { addBlazerSize } from '../../../../services/blazerSizesService';
import { addCoatSize } from '../../../../services/coatSizesService';
import Select from '../../../Common/form/select';
import Input from '../../../Common/form/input';
import { SettingsContext } from '../../Settings';
import { CLOTHES_TYPES } from './ClothesSettingsUtils';
import { ClothesSettingsContext } from '../ClothesSettings';

function ClothesSizeAdd({CLOTHES}) {
  const settingsContext = useContext(SettingsContext);
  const clothesSettingsContext = useContext(ClothesSettingsContext);
  const [newSize, setNewSize] = useState(null);

  const disableAddClothesSize = () => {
    let disable = false;
    newSize && Object.keys(newSize).map(key => { if (!newSize[key]) return  disable = true; return  false });
    return disable;
  };

  const initNewSizeValue = () => {
    const sizeValues = {};
    CLOTHES.FORM_SIZE_FIELDS.map( item =>  sizeValues[item.NAME] = "" );
    setNewSize(sizeValues);
  };

  useEffect(() => {
    initNewSizeValue();
  },[]);

  const handleAddClotheSize = async () => {
    let dataRes;
    if (CLOTHES.TYPE === CLOTHES_TYPES.SHIRT.TYPE) dataRes = await addShirtSize(newSize);
    if (CLOTHES.TYPE === CLOTHES_TYPES.BLAZER.TYPE) dataRes = await addBlazerSize(newSize);
    if (CLOTHES.TYPE === CLOTHES_TYPES.COAT.TYPE) dataRes = await addCoatSize(newSize);
    if (!dataRes.data.success) {
      toast.error(messages.error['settings' + capitalize(CLOTHES.TYPE) + 'SizeExist']);
      return
    }
    toast.success(messages.success['settings' + capitalize(CLOTHES.TYPE) + 'SizeCreated'], { autoClose: 1000 });
    clothesSettingsContext.setFetchData(true);
    setNewSize(null);
    initNewSizeValue();
  }

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : UserPermissions('module_settings_tab_clothes_add') &&
        <>
        <div className="ClothesSizeAdd-form">
          <div className="row">
            {CLOTHES.FORM_SIZE_FIELDS.map( (item, idx) => {
              return(
                <React.Fragment key={idx}>
                  { item.ELEMENT === 'select' &&
                    <div className="col-5 pr-0">
                      <Select
                        name={item.NAME}
                        value={newSize ? newSize[item.NAME] : ""}
                        options={item.OPTIONS}
                        onChange={({ currentTarget: input }) => setNewSize({...newSize, [input.name]: input.value})}
                      />
                    </div>
                  }

                  { item.ELEMENT === 'input' &&
                    <div className={ CLOTHES.FORM_SIZE_FIELDS.length > 1 ? 'col-4' : 'col-9' }>
                      <Input
                        type="text"
                        name={item.NAME}
                        placeholder={CLOTHES.LABEL} 
                        value={newSize ? newSize[item.NAME] : ""}
                        onChange={({ currentTarget: input }) => setNewSize({...newSize, [input.name]: input.value})}
                      />
                    </div>
                  }
                </React.Fragment>
              )
            })}
            <div className="col-3 pl-0">
              <button 
                disabled={disableAddClothesSize()}
                className="btn btn-secondary btn-block" 
                onClick={() => handleAddClotheSize()}
              > 
                Add
              </button>
            </div>
          </div>
        </div>
        </>
  )
}

export default ClothesSizeAdd
