import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";

class UsersList extends Component {
  columns = [
    { path: "first_name", label: "First Name" },
    { path: "last_name", label: "Last Name" },
    { path: "email", label: "Email" },
    { path: "department_name", label: "Department", disableSort: true },
    { path: "role", label: "Role", disableSort: true },
    { path: "active", label: "Active", 
      content: userItem => (
        (userItem.active)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
  ];

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: userItem => (
      <Link to={`/users/${userItem.id}`} className="btn btn-sm btn-edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    if (user) this.columns.push(this.editColumn);
  }

  render() {
    const { users, onSort, sortColumn, isLoading, overLoading } = this.props;

    return (
      <Table
        name={ 'users' }
        columns={ this.columns }
        data={ users }
        sortColumn={ sortColumn }
        onSort={ onSort }
        isLoading={ isLoading }
        overLoading={ overLoading }
      />
    );
  }
}

export default UsersList;
