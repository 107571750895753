import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  createKey = (item, column) => {
    return item.id + (column.path || column.key);
  };

  render() {
    const { data, columns, name, itemIdSelected, onClickRow } = this.props;
    const trStyleClassName = (name, item) => {
      let styleName;
      switch (name) {
        case 'staff':
          styleName = item.working_status.toLowerCase();
          break;
        case 'customers':
          styleName = (item.past_due_check || item.red_flag) ? 'alert' : '';
          break;
        case 'departments':
          styleName = (item.id) ? (item.id === itemIdSelected) ? 'editing' : '' : 'add';
          break;
        case 'eventStaffAssigned':
        case 'eventStaffAssignedList':
          styleName = (item.staff_id === 0) ? (item.rowSelected) ? 'empty row-selected ' : ' empty ' :  (item.rowSelected) ? ' row-selected ' : '' ;
          break;
        case 'reconciliation':
          styleName = item.reconciled ? item.exported ? 'reconciled exported ' : 'reconciled' : '' ; 
          break;
        case 'report':
          styleName = item.department_name ? item.department_name === 'TOTAL' ? 'total' : 'region' : '' ; 
          break;
        default:
          break;
      }
      return styleName;
    }

    return (
      <tbody>
        {data.map( (item, idx) => (
          <tr 
            key={idx} 
            className={ trStyleClassName(name, item) }
            onClick={() => onClickRow && onClickRow(item, idx)}
          >
            {columns.map(column => (
              <td key={this.createKey(item, column)} className={column.path}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
