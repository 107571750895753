import React from 'react';
import Joi from "joi-browser";
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { EventInfoSchemaEditing } from '../../../../models/eventsSchema';
import { validateEventNumber, updateEventMiddleware } from '../../../../services/eventsService';
import Form from "../../../Common/form/form";
import EventInfoDates from './EventInfoItems/EventInfoDates';
import { UserPermissions } from '../../../../utils/UserPermissions';
import './EventInfo.scss';

class EventInfo extends Form {

  constructor(props) {
    super(props)
    this.state = {
      data: props.event,
      eventDates: props.event.events_dates,
      eventDatesLength: props.event.events_dates.length,
      errors: {},
      eventDatesChange: false
    }
    this.schema = EventInfoSchemaEditing();
  };

  componentDidUpdate() {
    if (this.state.eventDatesChange) {
      if (this.state.eventDatesLength !== this.props.event.events_dates.length) {
        this.setState({
          eventDates: this.props.event.events_dates, 
          eventDatesLength: this.props.event.events_dates.length 
        });
      }
    }
  }

  renderReadOnlyField = (label, value) => {
    return (
      <div className="form-not-editable-view">
        <h6 className="label">{label}</h6>
        <p className="info">{value}</p>
      </div>
    )
  };

  renderReadOnlyLink = (label, value, id, type) => {
    return (
      <div className="form-not-editable-view">
        <h6 className="label">{label}</h6>
        <p className="info">
          {value}&nbsp;
          <a href={'/' + type + '/' + id} target="_blank" rel="noopener noreferrer" title="Open in a new tab">
            <i className="fas fa-external-link-alt external-link-alt-custom-small"></i>
          </a>
        </p>
      </div>
    )
  };

  doHandleChange = input => {
    return input.value;
  };

  doHandleBlur = input => {
    if (input.name ==='event_number') 
      this.validateEventNumber(input);
  };

  doSubmit = async () => {
    const response = await updateEventMiddleware(this.state.data, this.state.eventDates, this.state.eventDatesChange);
    if (!response.success) {
      toast.error(response.message);
      return
    }

    toast.success(response.message, { autoClose: 1000 });
    this.props.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
    this.props.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
    this.props.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
  };

  dispatchEventDates = (eventDates) => {
    this.setState({ eventDates, eventDatesChange: true });
  };

  // ** VALIDATIONS
  // *************************************************************
  validateEventNumber = async input => {
    const errors = { ...this.state.errors };
    const { data: resValidate} = await validateEventNumber(input.value);
    const isValid = resValidate.valid;
    if ( !isValid ) errors[input.name] = messages.error.eventNumberExist;
    this.setState({ errors });
  }

  validateEventInfo = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    const hasErrors = (Object.keys(this.state.errors).length > 0) ? true : false ;
    if (!error && !hasErrors && this.state.eventDates.length > 0)
      return false
    return true;
  }

  render() {
    const event = this.props.event;
    return (
      <section className="EventInfoForm form-wrapper container-fluid">
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <div className="form-body">
            <div className="row justify-content-around align-items-start">
              <div className="col-5">
              {event.status === 'Closed'
                ? this.renderReadOnlyField('Event Name', event.name )
                : this.renderInput("name", "Event Name", "text", true )
              }
              {this.renderReadOnlyField('Department', event && event['department'] )}
              {this.renderReadOnlyLink('Customer', event && event['customer'], event['customers_id'], 'customers' )}
              {this.renderReadOnlyLink('Venue', event && event['venue'], event['venues_id'], 'venues' )}
              {this.renderReadOnlyField('Configuration', event && event['venuesconf'] )}
              </div>
              <div className="col-7">
                <div className="row">
                  <div className="col-6">
                    { event.status === 'Closed'
                      ? this.renderReadOnlyField('Event Number', event && event['event_number'] )
                      : this.renderInput("event_number", "Event Number", "text", true )
                    }
                  </div>
                  <div className="col-6">
                  { event.status !== 'Closed' && UserPermissions('module_events_edit_hourly_rate') 
                    ? this.renderInput("target_hourly_rate", "Target Hourly Rate", "number", true )
                    : this.renderReadOnlyField('Target Hourly Rate', event && '$' + event['target_hourly_rate'] )
                  }
                  </div>
                </div>
                <EventInfoDates eventDates={this.state.eventDates} dispatchEventDates={this.dispatchEventDates} eventStatus={event.status} />
              </div>
            </div>
          </div>
          {event.status !== 'Closed' &&
            <div className="form-footer">
              <div className="row justify-content-around align-items-center">
                <div className="col-5"></div>
                <div className="col-6 text-right">
                  <button disabled={this.validateEventInfo() } className="btn btn-primary" type="submit">
                    Save Event
                  </button>
                </div>
              </div>
            </div>
          }
        </form>
      </section>
    )
  }
}

export default EventInfo
