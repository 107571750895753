import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { messages } from '../../../../utils/language-en.json';
import { saveMessage } from '../../../../services/messagesService';
import { MessagesSettingsContext } from '../MessagesSettings';

function UpdateMessage() {
  const messageSettingsContext = useContext(MessagesSettingsContext);
  const setFetchData = messageSettingsContext.action.setFetchData;
  const editMessage = messageSettingsContext.state.editMessage;
  const setEditMessage = messageSettingsContext.action.setEditMessage;

  const handleUpdateMessage = async () => {
    const { data } = await saveMessage(editMessage);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.settingsMessageUpdated, { autoClose: 1000 });
    setFetchData(true);
    setEditMessage(null);
  }

  const disableButton = () => {
    var validStartDate = moment(editMessage.start_date, "MM/DD/YYYY", true).isValid();
    var validEndDate = moment(editMessage.end_date, "MM/DD/YYYY", true).isValid();

    if (!editMessage.title || 
      !editMessage.content || 
      !editMessage.start_date || 
      !editMessage.end_date ||
      !validStartDate || 
      !validEndDate) {
      return true;
    }
    return false;
  }

  return (
    <div className="d-flex align-items-center">
      <button title="Save Message" className="btn btn-sm  btn-save"
        disabled={ disableButton() }
        onClick={() => handleUpdateMessage()}>
        <i className="fa fa-save" aria-hidden="true"></i>
      </button> 
      <button title="Cancel" className="btn btn-sm btn-cancel"
        onClick={() => setEditMessage(null)}>
        <i className="fa fa-close" aria-hidden="true"></i>
      </button> 
    </div>
  )
}

export default UpdateMessage
