import React, { Component } from 'react';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import _ from "lodash";
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { messages } from '../../../../utils/language-en.json';
import { getVenuesListConfig, getPositionConflist, addConfiguration, deleteConfiguration, saveConfigurationPosition, deleteConfigurationPosition } from '../../../../services/venuesService';
import { getPositions }  from '../../../../services/positionsService';
import { paginate } from "../../../../utils/paginate";
import DeleteConfirmation from '../../../Common/delete-confirmation/deleteConfirmation.jsx';
import VenueConfigurations from './partials/venue-configuration.jsx';
import VenueConfigurationPositionForm from './partials/venue-configuration-position-form.jsx';
import FilesManager from '../../../Common/files-manager/filesManager';
import VenueConfigurationPositionsList from './partials/venue-configuration-positions-list.jsx';
import { venueConfigurationPositionModel } from '../../../../models/venuesSchema';

class VenuesConfigurations extends Component {
  mounted = false;
  state = { 
    dataLoading: false,
    configrationList: [],
    configurationSelected: null,
    newConfigurationName: '',
    configurationPositions: [],
    currentPage: 1,
    pageSize: 8,
    sortColumn: { path: "assignment_name", order: "asc"},
    deleteItemSelected : [],
    configurationPositionModalIsOpen: false,
    configurationPositionItem: {}, 
    positionList: [],
    tabIndex: 0
  }

  tabContent = [];

  ConfigurationPositions = {
    label: "Configuration Positions",
    content: idx => (
      <VenueConfigurationPositionsList 
        key={idx}
        handleAddConfigurationPosition={this.handleAddConfigurationPosition}
        data={this.getConfigurationPositionsData().data}
        sortColumn={this.state.sortColumn}
        onSort={this.handleSort}
        dataLoading={this.state.dataLoading}
        totalCount={this.getConfigurationPositionsData().totalCount}
        pageSize={this.state.pageSize}
        currentPage={this.state.currentPage}
        onPageChange={this.handlePageChange}
        openModalDeleteConfirmation={this.openModalDeleteConfirmation}
        handleEditConfigurationPosition={this.handleEditConfigurationPosition}
      />
    )
  }

  FilesManager = {
    label: "Configuration Images",
    content: idx => (
      <FilesManager 
        key={idx} 
        component="VenuesConf"
        loading={this.state.dataLoading} 
        itemId={this.state.configurationSelected}
      />
    )
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resListConfig } = await getVenuesListConfig(this.props.venueId, true);
    const dataSort = _.orderBy(resListConfig.venues_conf, ['name'], ['asc']);
    const configrationList = dataSort;
    const configurationInitId = (configrationList.length === 0) ? null : configrationList[0].id;
    this.populateConfigurationsPositions(configurationInitId);
    this.populatePositions();
    if (this.mounted) this.setState({ configrationList, dataLoading: false, configurationSelected: configurationInitId, configurationPositionItem: venueConfigurationPositionModel() })
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }
  
  constructor(props) {
    super(props);
    this.tabContent.push(this.ConfigurationPositions);
    this.tabContent.push(this.FilesManager);
  }

  getConfigurationPositionsData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      configurationPositions: allData
    } = this.state;

    let filtered = allData;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const configurationPositions = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: configurationPositions };

  }

  populateConfigurations = async () => {
    const { data } = await getVenuesListConfig(this.props.venueId, true);
    const dataSort = _.orderBy(data.venues_conf, ['name'], ['asc'])
    const configrationList = dataSort;
    this.setState({ configrationList });
  } 

  populateConfigurationsPositions = async id => {
    const { data } = await getPositionConflist(id);
    const configurationPositions = data.position_conf;
    this.setState({ configurationPositions });
  }

  populatePositions = async () => {
    const { data: resPositionList } = await getPositions();
    const sortPositionsList = _.orderBy(resPositionList.position, ['name'], ['asc']);
    const positionList = [{ id: '', name: '' }, ...sortPositionsList];
    this.setState({ positionList });
  }

  handleSort = sortColumn => {
    this.setState({ sortColumn });
  };

  handlePageChange = page => {
    this.setState({ currentPage: page });
  };

  handleSelectConfiguration = id => {
    this.setState({ configurationSelected: id , tabIndex: 0});
    this.populateConfigurationsPositions(id);
    setTimeout(() => {
      this.context.swipeableViews.slideUpdateHeight();
    }, 280);
  };

  handleChangeNewName = ({ currentTarget: input}) => {
    this.setState({ newConfigurationName: input.value });
  };

  handleAddConfiguration = async () => {
    try {
      const { data } = await addConfiguration(this.state.newConfigurationName, this.props.venueId)
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.venueConfigurationCreated, { autoClose: 1000 });
      this.populateConfigurations();
      this.populateConfigurationsPositions(data.id);
      this.setState({ newConfigurationName: '', configurationSelected: data.id });
    } catch (ex) {
    }
    return; 
  };

  openModalDeleteConfirmation = (item, type) => {
    item = {type: type, ...item}
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  handleDelete = async () => {
    this.setState({ deleteConfirmationModalIsOpen: false });
    const item = this.state.deleteItemSelected;
    let originalData;
    if (item.type === 'configuration') {
      originalData = this.state.configrationList
      const configrationList = originalData.filter(m => m.id !== item.id);
      this.setState({ configrationList })
    }
    if (item.type === 'configurationPosition') {
      originalData = this.state.configurationPositions
      const configurationPositions = originalData.filter(m => m.id !== item.id);
      this.setState({ configurationPositions })
    }

    try {
      let dataRes;
      if (item.type === 'configuration') {
        dataRes = await deleteConfiguration(item.id)
      }
      if (item.type === 'configurationPosition') {
        dataRes = await deleteConfigurationPosition(item.id)
      }
      const success = dataRes.data.success;
      if (!success) {
        let errorMessage = dataRes.data.message;
        if (item.type === 'configuration') {
          errorMessage = messages.error.venueConfigurationReadyDeleted;
          this.setState({ configrationList: originalData });
        }
        if (item.type === 'configurationPosition') {
          errorMessage = messages.error.venueConfigurationPositionReadyDeleted;
          this.populateConfigurationsPositions(this.state.configurationSelected);
        }
        toast.error(errorMessage);
        return
      }
      if (item.type === 'configuration') {
        toast.success(messages.success.venueConfigurationDeleted, { autoClose: 1000 });
        if( this.state.configurationSelected === item.id) {
          this.setState({ configurationSelected: this.state.configrationList[0].id})
        }
      };
      if (item.type === 'configurationPosition') toast.success(messages.success.venueConfigurationPositionDeleted, { autoClose: 1000 });
      this.populateConfigurations();
      this.populateConfigurationsPositions(this.state.configurationSelected);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        if (item.type === 'configuration') {
          toast.error(messages.error.venueConfigurationReadyDeleted);
          this.setState({ configrationList: originalData });
        }
        if (item.type === 'configurationPosition') {
          toast.error(messages.error.venueConfigurationPositionReadyDeleted);
          this.populateConfigurationsPositions(this.state.configurationSelected);
        }

    }
  };

  openModalConfigurationPosition = (item, type) => {
    this.setState({ configurationPositionModalIsOpen: true });
  }

  closeModalConfigurationPosition = () => {
    this.setState({ configurationPositionModalIsOpen: false, configurationPositionItem: venueConfigurationPositionModel() });
  }

  handleAddConfigurationPosition = () => {
    this.openModalConfigurationPosition();
  }

  handleEditConfigurationPosition = item => {
    this.setState({ configurationPositionItem: item,  });
    this.openModalConfigurationPosition();
  }

  handleChangeConfigurationPositionForm = ({ currentTarget: input }) => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
      if ( input.name === 'radio') {
        const data = { ...this.state.configurationPositionItem };
        if (!this.state.configurationPositionItem.radio_qty) {
          data['radio_qty'] = data['qty'];
        }
        data[input.name] = value;
        return this.setState({ configurationPositionItem: data });    
      }
    }
    const data = { ...this.state.configurationPositionItem };
    data[input.name] = value;

    this.setState({ configurationPositionItem: data });
  }

  handleBlur = ({ currentTarget: input }) => {
    if ( input.name === 'qty') {
      const data = { ...this.state.configurationPositionItem };
      if (!this.state.configurationPositionItem.radio_qty) {
        data['radio_qty'] = input.value;
      }
      return this.setState({ configurationPositionItem: data });    
    }
  }

  handleSaveConfigurationPosition = async () => {
    try {
      const { data } = await saveConfigurationPosition(this.state.configurationPositionItem, this.state.configurationSelected);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      (this.state.configurationPositionItem.id)
        ? toast.success(messages.success.venueConfigurationPositionUpdated, { autoClose: 1000 })
        : toast.success(messages.success.venueConfigurationPositionCreated, { autoClose: 1000 })
      this.populateConfigurationsPositions(this.state.configurationSelected);
      this.populateConfigurations();
    } catch (ex) {
    }
    this.closeModalConfigurationPosition();
    return; 
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value
    });
    setTimeout(() => {
      this.context.swipeableViews.slideUpdateHeight();
    }, 280);
  };

  handleChangeTabsIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  render() { 
    const {
      configrationList,
      configurationSelected,
      newConfigurationName,
      deleteConfirmationModalIsOpen,
      deleteItemSelected,
      configurationPositionModalIsOpen,
      configurationPositionItem,
      positionList,
      tabIndex
    } = this.state;

    return (
      <section className="form-wrapper venue-configuration-form container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <VenueConfigurations
              newConfigurationName={newConfigurationName}
              handleChangeNewName={this.handleChangeNewName}
              handleAddConfiguration={this.handleAddConfiguration}
              configrationList={configrationList}
              openModalDeleteConfirmation={this.openModalDeleteConfirmation}
              configurationSelected={configurationSelected}
              handleSelectConfiguration={this.handleSelectConfiguration}
            />
          </div>
          {!(configrationList.length === 0) && 
            <div className="col-sm-9">
              <Tabs 
                value={tabIndex}
                onChange={this.handleChangeTabs} 
                classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
              >
                {this.tabContent.map((form, idx) => (
                  <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
                ))}
              </Tabs>
              <SwipeableViews 
                index={tabIndex}
                // action={actions => {this.swipeableActions = actions;}}
                onChangeIndex={this.handleChangeTabsIndex} 
                animateHeight={true}
                animateTransitions={true} >
                {this.tabContent.map((form, idx) => (
                  form.content(idx)
                ))}
              </SwipeableViews>
              <DeleteConfirmation
                isOpen={deleteConfirmationModalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModalDeleteConfirmation}
                closeModal={this.closeModalDeleteConfirmation}
                itemName={deleteItemSelected.name || deleteItemSelected.assignment_name }
                onDelete={this.handleDelete}
              />
              <VenueConfigurationPositionForm
                isOpen={configurationPositionModalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModalConfigurationPosition}
                closeModal={this.closeModalConfigurationPosition}
                data={configurationPositionItem}
                handleChange={this.handleChangeConfigurationPositionForm}
                handleBlur={this.handleBlur}
                positionsTypeOptions={positionList}
                handleSave={this.handleSaveConfigurationPosition}
              />
            </div>
          }

        </div>
      </section>
    );
  }
}

VenuesConfigurations.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};


export default VenuesConfigurations;
