import React, { useContext } from 'react';
import Input from '../../../Common/form/input';
import Textarea from '../../../Common/form/textarea';
import { TemplatesSettingsContext } from '../TemplatesSettings';

const FIELD_TYPE = {
  INPUT: 'input',
  TEXTAREA: 'textarea'
}

function TemplatesTableCellView({item, fieldName, fieldType}) {
  const templatesSettingsContext = useContext(TemplatesSettingsContext);
  const editTemplate = templatesSettingsContext.state.editTemplate;

  return (
    <>
    {item.id 
      ? editTemplate && item.id === editTemplate.id 
        ? <RenderField name={fieldName} item={item} isEditing={true} fieldType={fieldType} />
        : <div dangerouslySetInnerHTML={{ __html: item[fieldName] }}/>
      :<RenderField name={fieldName} item={item} fieldType={fieldType} />
    }
    </>
  )
}

function RenderField({ name, isEditing, fieldType }) {
  const templatesSettingsContext = useContext(TemplatesSettingsContext);
  const editTemplate = templatesSettingsContext.state.editTemplate;
  const setEditTemplate = templatesSettingsContext.action.setEditTemplate;

  const handleChange = ( isEditing ) => ({ currentTarget: input }) => {
    if ( isEditing ) {
      const data = { ...editTemplate };
      data[input.name] = input.value;
      setEditTemplate(data);
    } 
  };
  
  return (
    <>
    { fieldType === FIELD_TYPE.INPUT &&
      <Input
        type="text"
        name={name}
        value={editTemplate[name]}
        placeholder={name === 'name' ? "Add Template*" : ''}
        onChange={handleChange(isEditing)}
        inputsize={'sm'}
      />
    }
    { fieldType === FIELD_TYPE.TEXTAREA &&
      <Textarea
        name={name}
        value={editTemplate[name]}
        onChange={handleChange(isEditing)}
        rows="4"
      />
    }
    </>
  )
}

export default TemplatesTableCellView
