import http from "./httpService";

const apiEndpoint = "/position";

export function getPositions() {
  return http.get(apiEndpoint + '/list');
}

export function getPositionByDepartment(departmentId) {
  return http.get(apiEndpoint + '/positions_departments_get', { 
    params: {
      id: departmentId 
    }
  });
}

export function savePositionPayRate(updateData) {
  return http.post(apiEndpoint + '/positions_departments_update', updateData );
}
