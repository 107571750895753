import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import Checkbox from "./checkbox";
import Switch from "./switch";
import "react-datepicker/dist/react-datepicker.css";
import "./form.scss";
import MaskInput from "./maskInput";
import Datepicker from "./datepicker";
import Textarea from "./textarea";

class Form extends Component {
  state = {
    data: {},
    errors: {}
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {

    const data = { ...this.state.data };
    let obj = { [name]: value };
    let schema = { [name]: this.schema[name] };

    if ( name === "ssn_confirmation") {
      const ssnValue = data['ssn'];
      obj = { 'ssn': ssnValue, [name]: value }
      schema = { [name]: this.schema[name], 'ssn': this.schema['ssn'] };
    }
    
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChangeDatePicker = date => {
    const input = {
      name: this.state.datePickerNameSelected,
      value: date
    }
    
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });

    this.setState({ dateSelected: date})
  }

  handleFocusDatePicker = ({ currentTarget: input }) => {
    this.setState({ datePickerNameSelected: input.name});
    this.setState({ dateSelected: input.value});
  }

  handleFileChange = ({ currentTarget: input, file }) => {
    this.doHandleFileChange(input, file)
  }

  handleBlur = ({ currentTarget: input }) => {
    this.doHandleBlur(input);
  }

  handleChange = ({ currentTarget: input }) => {
    const value = this.doHandleChange(input);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = value;

    this.setState({ data, errors });
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary" type="submit">
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, required = false, disabled) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={(data[name]) ? data[name] : undefined}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        disabled={disabled}
      />
    );
  }

  renderReadOnlyField = (label, value) => {
    return (
      <div className="form-not-editable-view">
        <h6 className="label">{label}</h6>
        <p className="info">{value}</p>
      </div>
    )
  };

  renderInput(name, label, type = "text", required = false, maxlength='') {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        required={required}
        maxLength={maxlength}
      />
    );
  }

  renderMaskInput(name, label, mask, type = "text", required = false) {
    const { data, errors } = this.state;

    return (
      <MaskInput
        mask={mask}
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        error={errors[name]}
        required={required}
      />
    );
  }
  
  renderCheckbox(name, label, required = false, justifyContent = "start") {
    const { data, errors } = this.state;

    return (
      <Checkbox
        name={name}
        checked={(data[name])}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        justifyContent={justifyContent}
      />
    );
  }

  renderDatePicker(name, label, required = false, popperPlacement = 'bottom-start') {
    const { data, errors } = this.state;
    const date = data[name] ? new Date(data[name]) : null;

    return (
      <Datepicker
        selected={date}
        name={name}
        label={label}
        required={required}
        error={errors[name]}
        fixedHeight
        onChange={this.handleChangeDatePicker}
        onFocus={this.handleFocusDatePicker}
        onBlur={ this.handleChange }
        popperPlacement={popperPlacement}
      />
    )
  }

  renderSwitch( name, label, required = false, centerElement = true, strongTitle = false, justifyContent = "start", flexDirection = "column") { 
    const { data, errors } = this.state;
    
    return (
      <Switch
        name={name}
        checked={(data[name])}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required} 
        strongTitle={strongTitle}
        centerElement={centerElement}
        justifyContent={justifyContent}
        flexDirection={flexDirection} />
    )
  }

  renderTextarea(name, label, required = false, rows = 3) {
    const { data, errors } = this.state;

    return (
      <Textarea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        required={required}
        rows={rows}
      />
    )
  }

}

export default Form;
