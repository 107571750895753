import React from 'react';
import Select from "react-select";

export default function FilterByWorkingStatus(props) {
  const { handleWorkingStatusChange } = props;
  const workingStatusOptions =  [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' }
  ];

  return (
    <div className="filter-by-section">
      <Select
        name="selectWorkingStatus"
        placeholder="Filter by Working Status"
        options={workingStatusOptions}
        defaultValue={ workingStatusOptions[0] }
        className="basic-select"
        classNamePrefix="select"
        onChange={handleWorkingStatusChange}
      />
    </div>
  )
}
