import React, { Component } from 'react';
import _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import auth from "../../services/authService";
import { messages } from "../../utils/language-en.json";
import { getDepartmentList } from "../../services/departmentService";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import { getOrganizations, deleteOrganization } from '../../services/volunteersService';
import DeleteConfirmation from '../Common/delete-confirmation/deleteConfirmation';
import Pagination from '../Common/pagination/pagination';
import SearchBox from "../Common/searchBox";
import VolunteersList from './volunteersList';
import FilterByDepartment from "../Common/filters/FilterByDepartment";
import "./volunteers.scss";

class Volunteers extends Component {
  mounted = false;

  state = {
    dataLoading: true,
    departments: [],
    organizations: [],
    filterDepartment: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "name", order: "asc"},
    searchQuery: "",
    totalCount: 0,
    deleteItemSelected : []
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = sortDepartments;

    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) {
      this.setState({ filterDepartment: [userDepartmentId], currentPage: 1 });
    } else {
      this.setState({ filterDepartment: [], currentPage: 1 });
    }

    this.mounted && this.setState({ departments });
    this.mounted && this.populateOrganizations();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateOrganizations = async () => {
    try {
      const { data } = await getOrganizations({
        DEPARTMENT_ID: this.state.filterDepartment,
        LIMIT: this.state.pageSize,
        PAGE: this.state.currentPage,
        ORDER_BY: this.state.sortColumn.path,
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery
      });
      this.setState({
        organizations: data.organization, 
        totalCount: data.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateOrganizations();
  };
  
  handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    await this.setState({ filterDepartment: departmentSelected, currentPage: 1 });
    this.populateOrganizations();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateOrganizations();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateOrganizations();
  };

  openModalDeleteConfirmation = item => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  handleDelete = async () => {
    const item = this.state.deleteItemSelected;
    const originalOrganizations = this.state.organizations;
    const organizations = originalOrganizations.filter(m => m.id !== item.id);
    this.setState({ organizations, deleteConfirmationModalIsOpen: false, deleteItemSelected:[] });

    try {
      const { data } = await deleteOrganization(item.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        this.setState({ organizations: originalOrganizations });
        return
      }
      toast.success(messages.success.organizationDeleted, { autoClose: 1000 });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.organizationsReadyDeleted);

      this.setState({ organizations: originalOrganizations });
    }
  };

  render() {
    const {
      dataLoading, 
      departments, 
      organizations,
      pageSize, 
      currentPage, 
      sortColumn, 
      searchQuery,
      totalCount,
      deleteConfirmationModalIsOpen, 
      deleteItemSelected, 
      pageWrapperHeight
    } = this.state;
    const { user } = this.props;
    const authPermissions = auth.getCurrentUserPermisions();

    return (
      <main className="volunteers-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-hands-helping"></i>&nbsp;Volunteer Organizations</h1>
            </div>
            <div className="col text-right">
              {authPermissions["module_volunteers_add"] &&
                <Link
                  to="/volunteers/create/"
                  className="btn btn-primary"
                >
                  Add Organization
                </Link>
              }
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-left">
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <FilterByDepartment
                  departments={departments}
                  handleDepartmentChange={this.handleDepartmentChange}
                />
              </div>
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </div>
            </div>
          </section>
          <VolunteersList
            data={ organizations }
            user={ user }
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onDelete={this.openModalDeleteConfirmation}
            isLoading={ dataLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          )}
          <DeleteConfirmation
            isOpen={deleteConfirmationModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModalDeleteConfirmation}
            closeModal={this.closeModalDeleteConfirmation}
            itemName={deleteItemSelected.name}
            onDelete={this.handleDelete}
          />
        </section>
      </main>
    );
  }
}

export default Volunteers;