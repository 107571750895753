import React, { useContext } from 'react';
import Input from '../../../Common/form/input';
import Textarea from '../../../Common/form/textarea';
import { MessagesSettingsContext } from '../MessagesSettings';

const FIELD_TYPE = {
  INPUT: 'input',
  TEXTAREA: 'textarea'
}

function MessageTableCellView({item, fieldName, fieldType}) {
  const messageSettingsContext = useContext(MessagesSettingsContext);
  const editMessage = messageSettingsContext.state.editMessage;

  return (
    <>
    {item.id 
      ? editMessage && item.id === editMessage.id 
        ? <RenderField name={fieldName} item={item} isEditing={true} fieldType={fieldType} />
        : <div dangerouslySetInnerHTML={{ __html: item[fieldName] }}/>
      :<RenderField name={fieldName} item={item} fieldType={fieldType} />
    }
    </>
  )
}

function RenderField({ name, isEditing, fieldType }) {
  const messageSettingsContext = useContext(MessagesSettingsContext);
  const editMessage = messageSettingsContext.state.editMessage;
  const setEditMessage = messageSettingsContext.action.setEditMessage;
  const newMessage = messageSettingsContext.state.newMessage;
  const setNewMessage = messageSettingsContext.action.setNewMessage;

  const handleChange = ( isEditing ) => ({ currentTarget: input }) => {
    if ( isEditing ) {
      const data = { ...editMessage };
      data[input.name] = input.value;
      setEditMessage(data);
    } else {
      const dataNew = { ...newMessage };
      dataNew[input.name] = input.value;
      setNewMessage(dataNew);
    }
  };
  
  return (
    <>
    { fieldType === FIELD_TYPE.INPUT &&
      <Input
        type="text"
        name={name}
        value={isEditing ? editMessage[name] : newMessage[name]}
        placeholder={name === 'name' ? "Add Message*" : ''}
        onChange={handleChange(isEditing)}
        inputsize={'sm'}
      />
    }
    { fieldType === FIELD_TYPE.TEXTAREA &&
      <Textarea
        name={name}
        value={isEditing ? editMessage[name] : newMessage[name]}
        onChange={handleChange(isEditing)}
        rows="4"
      />
    }
    </>
  )
}

export default MessageTableCellView
