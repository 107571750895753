export function PageWrapperHeight() {
  const navbar = document.getElementsByClassName("navbar");
  const footer = document.getElementsByTagName("footer");
  const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const navbarHeight = navbar.length > 0 ? navbar[0].clientHeight : 0;
  const footerHeight = footer.length > 0 ? footer[0].clientHeight : 0;
  const pageWrapperHeight = viewportHeight - (navbarHeight + footerHeight);

  return pageWrapperHeight;
};

export default {
  PageWrapperHeight
}