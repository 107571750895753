import React from 'react';
import auth from "../../../services/authService";

const CustomersFormInfo = props => {
  const optionsCMSdepartments = props.optionsCMSdepartments;
  const optionsStates = props.optionsStates;
  const authPermissions = auth.getCurrentUserPermisions();
  
  return (
    <section className="form-wrapper personal-info-form container-fluid">
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          {props.renderInput("customer_id", "Customer Id", "text" )}
        </div>
        <div className="col-3">
          {props.renderInput("name", "Customer Name", "text", true )}
        </div>
        <div className="col-3">
          {props.renderSelect("department_id", "Department", optionsCMSdepartments, true )}
        </div>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          {props.renderInput("contact", "Contact", "text", true )}
        </div>
        <div className="col-3">
          {props.renderInput("email", "Email", "email", true )}
        </div>
        <div className="col-3">
          {props.renderInput("address_1", "Address 1", "text", true )}
        </div>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
        {props.renderInput("address_2", "Address 2", "text" )}
        </div>
        <div className="col-3">
          {props.renderInput("city", "City", "text", true )}
        </div>
        <div className="col-3">
          {props.renderSelect("state", "State", optionsStates, true )}
        </div>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          {props.renderInput("zip_code", "Zipcode", "text", false, 5 )}
        </div>
        <div className="col-3">
          {props.renderMaskInput("phone_1", "Phone 1", '(111) 111-1111', "text" )}
        </div>
        <div className="col-3">
          {props.renderMaskInput("phone_2", "Phone 2", '(111) 111-1111', "text" )}
        </div>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-3">
          {props.renderMaskInput("fax", "Fax", '(111) 111-1111', "text" )}
        </div>
        <div className="col-3">
        </div>
        <div className="col-3">
        </div>
      </div>
      <div className="row justify-content-between align-items-start">
        <div className="col-3">
          {!props.isEditing() && props.renderSwitch("past_due_check", "Past Due Payments Past Due?" )}
          {props.isEditing() && authPermissions["module_customers_field_past_due_payments"] && props.renderSwitch("past_due_check", "Past Due Payments Past Due?" )}
          {props.isEditing() && !authPermissions["module_customers_field_past_due_payments"] && 
            <div className="form-not-editable-view form-not-editable-view  text-center">
              <label className="text-center" htmlFor="">Past Due Payments Past Due?</label>
              <p className="info icon">{(props.data.past_due_check) ? <i className="fas fa-check blueIcon"></i> : <i className="fas fa-close redIcon"></i>  }</p>
            </div>
          }
        </div>
        <div className="col-4">
          {!props.isEditing() && props.renderInput("past_due_amount", "Past due amount", "number" )}
          {props.isEditing() && authPermissions["module_customers_field_past_due_amount"] && props.renderInput("past_due_amount", "Past due amount", "number" )}
          {props.isEditing() && !authPermissions["module_customers_field_past_due_amount"] && 
            <div className="form-not-editable-view  text-center">
              <label className="text-center" htmlFor="">Past due amount</label>
              <p className="info">{props.data.past_due_amount}</p>
            </div>
          }
        </div>
        <div className="col-3">
          {!props.isEditing() && props.renderSwitch("red_flag", "Red flag?" )}
          {props.isEditing() && authPermissions["module_customers_field_red_flag"] && props.renderSwitch("red_flag", "Red flag?" )}
          {props.isEditing() && !authPermissions["module_customers_field_red_flag"] && 
            <div className="form-not-editable-view  text-center">
              <label className="text-center" htmlFor="">Red Flag?</label>
              <p className="info icon">{(props.data.red_flag) ? <i className="fas fa-flag"></i> : <i className="far fa-flag"></i>  }</p>
            </div>
          }
        </div>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-12">
          <br />
          {props.renderTextarea("note", "Leave Notes", false, 4)}
        </div>
      </div>
    </section>
  );
}

export default CustomersFormInfo;