import http from "./httpService";

const apiEndpoint = "/messages";

export function getActiveMessages() {
    return http.get(apiEndpoint + '/list_active');
}

export function getMessagesList() {
  return http.get(apiEndpoint + '/list');
}

export function saveMessage(messageItem) {
    if (messageItem.id) {
      const body = { ...messageItem };
      return http.post(apiEndpoint + '/update', body);
    }
  
    return http.post(apiEndpoint + '/add', messageItem);
}
