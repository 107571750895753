import React from "react";
import "./form.scss";

const Input = ({ name, label, error, required, ...rest }) => {
  return (
    <div className={ error ? 'form-group has-error' : 'form-group'}>
      <div className="d-flex flex-column-reverse">
        <input {...rest} required={required} name={name} id={name} className={(rest.inputsize) ? "form-control form-control-"+rest.inputsize : "form-control " } />
        { label && (
          <label className={ (required) ? 'required' : '' } htmlFor={name}>{label}</label>
        )}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Input;
