import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core';
import auth from "../../services/authService";
import { saveUser, getUserItem, validateEmail } from '../../services/usersService';
import { getRolesList } from '../../services/rolesService';
import { getDepartmentList } from '../../services/departmentService';
import { usersDataModel, usersSchema, userEditMapToViewModel } from '../../models/usersSchema';
import { messages, pagetitle } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import UsersFormInfo from './users-forms/usersForm-Info';

class UsersForm extends Form {
  mounted = false;

  state = { 
    dataLoading: false,
    tabIndex: 0,
    data: usersDataModel(),
    errors: {}
  }

  schema = {};
  usersForms = [];

  UsersFormInfo = {
    label: "User Info",
    submitButton: true,
    content: idx => (
      <UsersFormInfo
        key={idx}
        state={this.state}
        data={this.state.data}
        renderInput={this.renderInput}
        renderSelect={this.renderSelect}
        renderButton={this.renderButton}
        renderCheckbox={this.renderCheckbox}
        optionsRoles={this.state.optionsRoles}
        optionsDepartments={this.state.optionsDepartments}
        isEditing={this.isEditing}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleSubmit={this.handleSubmit}
        validate={this.validate}
        validateEmail={this.validateEmail}
        validateUserInfo={this.validateUserInfo}
      />
    )
  }

  constructor(props) {
    super(props);
    if (this.authPermissions("module_users")) this.usersForms.push(this.UsersFormInfo);
  }
  
  async componentDidMount() {
    this.mounted = true;
    this.setState({ dataLoading: true })
    const { data: resRoles } = await getRolesList();
    const roles = [{ id: '', name: '' }, ...resRoles.roles];
    const { data: resDepartments } = await getDepartmentList();
    const departments = [{ id: '', name: '' }, ...resDepartments.department];
    this.schema = usersSchema(this.isEditing());
    if (this.mounted) this.setState({ optionsRoles: roles, dataLoading: false })
    if (this.mounted) this.setState({ optionsDepartments: departments, dataLoading: false })
    
    const userName = await this.populateUser();
    const titlePage =  ( this.isEditing() ) ? pagetitle.userEdit + ' — ' + userName : pagetitle.userCreate ;
    this.setState({ pagetitle: titlePage, submitButton: this.usersForms[0].submitButton })

    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateUser() {
    try {
      const id = this.props.match.params.id;
      if ( id === 'create') return;
      this.setState({ dataLoading: true })
      
      const { data: resUser } = await getUserItem(id);
      const user = resUser.user;
      
      this.setState({ data: userEditMapToViewModel(user) });
      this.setState({ dataLoading: false })

      return user.first_name + ' ' + user.last_name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  authPermissions = (module) => {
    const authPermissions = auth.getCurrentUserPermisions();
    return authPermissions[module];
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value,
      submitButton: this.usersForms[value].submitButton
    });
  };

  validateEmail = async input => {
    const errors = { ...this.state.errors };
    const { data: resValidate} = await validateEmail(input.value);
    const isValid = resValidate.valid;
    if ( !isValid ) errors[input.name] = messages.error.emailExist;
    this.setState({ errors });
  }

  isEditing = () => {
    const urlParams = this.props.match.params.id;
    const isEditing = ( urlParams === 'create') ? false : true ;
    return isEditing;
  }

  doHandleBlur = input => {
    if (input.name === 'email') 
      this.validateEmail(input);
  }

  doHandleChange = input => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
    }
    if (input.className.includes("datepicker") ) {
      value = this.state.dateSelected;
    }
    return value;
  }

  doSubmit = async () => {
    try {
      const { data } = await saveUser(this.state.data);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      if (this.isEditing()) {
        toast.success(messages.success.userUpdated, { autoClose: 1000 });
      } else {
        toast.success(messages.success.userCreated, { autoClose: 1000 });
      }
      this.props.history.push("/users");
      setTimeout(() => {
        window.location.reload()
      }, 500);
    } catch (ex) {
    }
    return;
  };

  validateUserInfo = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    const hasErrors = (Object.keys(this.state.errors).length > 0) ? true : false ;
    if (!error && !hasErrors)
      return false
    return true;
  }

  render() { 
    const { 
      dataLoading,
      pagetitle,
      tabIndex,
      pageWrapperHeight
    } = this.state;

    if (!auth.getCurrentUser())
      return <RedirectToLogin location={this.props.location} />

    if (!this.isEditing() && !this.authPermissions("module_users_add"))
      return (
        <Redirect
          to={{
            pathname: "/users",
            state: { from: this.props.location }
          }}
        />
      )

    return (
      <main className="users-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <Loading active={dataLoading} />
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-users-cog"></i>&nbsp;{pagetitle}</h1>
            </div>
          </div>
        </section>
        {!dataLoading && 
          <section className="page-body">
            <Tabs 
              value={tabIndex}  
              onChange={this.handleChangeTabs} 
              classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
            >
              {this.usersForms.map((form, idx) => (
                <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
              ))}
            </Tabs>
            <SwipeableViews 
              index={tabIndex}
              onChangeIndex={this.handleChangeTabsIndex} 
              animateHeight={true}
              animateTransitions={true} >
              {this.usersForms.map((form, idx) => (
                form.content(idx)
              ))}
            </SwipeableViews>
          </section>
        }
      </main>
    );
  }
}

export default UsersForm;
