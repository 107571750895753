import React from 'react';
import { toast } from "react-toastify";
import moment from 'moment';
import auth from "../../services/authService";
import {getApplicantItem, updateStatus, addNotes, generateApplicantPdf} from '../../services/applicantsService';
import { messages } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import Textarea from "../Common/form/textarea";
import Select from '../Common/form/select';

const renderTextarea = (name, label, required = false, rows = 5, handleChange) => {
  return (
    <Textarea
        name={name}
        label={label}
        onChange={handleChange}
        required={required}
        rows={rows}
      />
  )
}

const renderSelect = (name, label, value, options, handleChange) => {
  return (
    <Select
      name={name}
      label={label}
      value={value}
      options={options}
      labelBold={true}
      onChange={handleChange}
    />
  )
}

class ApplicantsForm extends Form {
  state = {
    dataLoading: true,
    data: [],
    applicantName: null,
    notes: null,
    optionsStatus: [
      { id: 'Not Contacted', name: 'Not Contacted' },
      { id: 'Contacted', name: 'Contacted' },
      { id: 'Hired', name: 'Hired' },
      { id: 'Not Hired', name: 'Not Hired' }
    ],
    errors: {}
  };

  async componentDidMount() {
    this.setState({ dataLoading: true })
    const applicantName = await this.populateApplicant();
    const titlePage = 'Applicant — '  + applicantName;
    this.setState({ applicantName: applicantName });
    this.setState({ dataLoading: false, pagetitle: titlePage, });
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateApplicant() {
    this.setState({ dataLoading: true })
    try {
      const applicantId = this.props.match.params.id;
      const userData = auth.getCurrentUser();
      const { data: resApplicant } = await getApplicantItem(applicantId, userData.department_id);
      const applicant = resApplicant.applicant;
      this.setState({ data: applicant });
      this.setState({ dataLoading: false });
      this.setState({ status: applicant.status });

      return applicant.first_name + ' ' + applicant.last_name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  handleAddNotes = async () => {
    try {
      const applicantId = this.props.match.params.id;
      const { data } = await addNotes(applicantId, this.state.applicantName, this.state.notes);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.applicantNotesAdded, { autoClose: 1000 })
      await this.populateApplicant();
    } catch (ex) {
    }
    return;
  };

  handlePrintOption = async () => {
    const applicantId = this.props.match.params.id;
    const {data: resApplicantPdf } = await generateApplicantPdf(applicantId);
    if (!resApplicantPdf.success) return toast.error("PDF file couldn't be generated");
    window.open(process.env.REACT_APP_API_URL + '/' + resApplicantPdf.file_name);
  }

  handleChange = async ({ currentTarget: input }) => {
    if (input.name === 'status') {
      this.setState({ status: input.value });

      try {
        const applicantId = this.props.match.params.id;
        const { data } = await updateStatus(applicantId, input.value);
        const success = data.success;
        if (!success) {
          const errorMessage = data.message;
          toast.error(errorMessage);
          return
        }
        toast.success(messages.success.applicantStatusUpdated, { autoClose: 1000 })
      } catch (ex) {
      }
    }
    if (input.name === 'notes') {
      this.setState({ notes: input.value });
    }
  }

  render() {
    const {
      dataLoading,
      data,
      optionsStatus,
      pagetitle,
      pageWrapperHeight
    } = this.state;

    const authPermissions = auth.getCurrentUserPermisions();

    return (
      !auth.getCurrentUser()
        ? <RedirectToLogin location={this.props.location} />
        : <main className="applicant-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
            <Loading active={dataLoading} />
            <section className="page-header">
              <div className="row">
                <div className="col">
                  <h1 className="page-title"><i aria-hidden="true" className="fas fa-solid fa-clipboard"></i>&nbsp;{pagetitle}</h1>
                  <label>
                    <strong>Date of Application:</strong> {moment(data.application_date).format('MM/DD/YYYY HH:mm')}
                  </label>
                </div>
                <div className="col">
                  <button className="btn btn-print-pdf" type="button" onClick={() => this.handlePrintOption()}>
                    <i className="far fa-file-pdf"></i>EXPORT TO PDF
                  </button>
                </div>
              </div>
            </section>
            {!dataLoading &&
            <section className="page-body">
              <section className="form-wrapper personal-info-form container-fluid">

                <div className="row applicant-navigation">
                  <div className="col-4">
                    {data.navigation[0].previous.id &&
                    <div className="applicant-navigation-item float-left">
                      <a href={`/applicants/${data.navigation[0].previous.id}`}>
                        <button type="button" className="page-link prev-page link">
                          <i className="fa fa-angle-left"></i>
                        </button>
                      </a>

                      <div className="text-left">
                        <em>Previous Applicant</em><br/>
                        <strong>{data.navigation[0].previous.full_name}</strong>
                      </div>
                    </div>
                    }
                  </div>
                  <div className="col-4">
                    <div className="float-center">
                      {renderSelect( "status", "Applicant Status", this.state.status, optionsStatus, this.handleChange )}
                    </div>
                  </div>
                  <div className="col-4">
                    {data.navigation[0].next.id &&
                    <div className="applicant-navigation-item float-right">
                      <div className="text-right">
                        <em>Next Applicant</em><br />
                        <strong>{data.navigation[0].next.full_name}</strong>
                      </div>

                      <a href={`/applicants/${data.navigation[0].next.id}`}>
                        <button type="button" className="page-link next-page link">
                          <i className="fa fa-angle-right"></i>
                        </button>
                      </a>
                    </div>
                    }
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>Region</strong>
                    </label>
                    <p>{data.department.name}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Availability</strong>
                    </label>
                    <p>{data.availability}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>First Name</strong>
                    </label>
                    <p>{data.first_name}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Last Name</strong>
                    </label>
                    <p>{data.last_name}</p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>Current Address</strong>
                    </label>
                    <p>{data.address_1} {data.address_2}<br />{data.city}, {data.state} {data.zip_code}<br />{data.country}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Contact Phone</strong>
                    </label>
                    <p>{data.contact_phone}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Email</strong>
                    </label>
                    <p><a href={`mailto:${data.email}`}>{data.email}</a></p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Are you currently employed?</strong>
                    </label>
                    <p>{
                      (data.currently_employed !== null)
                        ? (data.currently_employed === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>If yes, where?</strong>
                    </label>
                    <p>{data.currently_employed_place}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Hours</strong>
                    </label>
                    <p>{data.currently_employed_hours}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Are you a past employee of Starplex or Crowd Management Services?</strong>
                    </label>
                    <p>{
                      (data.starplex_past_employee !== null)
                        ? (data.starplex_past_employee === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>If yes, where?</strong>
                    </label>
                    <p>{data.starplex_past_employee_location}</p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>Name used during employment if different from above</strong>
                    </label>
                    <p>{data.starplex_past_employee_name}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Do you have family members working for CMS?</strong>
                    </label>
                    <p>{
                      (data.family_working_cms !== null)
                        ? (data.family_working_cms === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>If yes, which region?</strong>
                    </label>
                    <p>{data.starplex_past_employee_location}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Do you currently work for another Guest Services or Event Staffing organization?</strong>
                    </label>
                    <p>{
                      (data.working_another_organization !== null)
                        ? (data.working_another_organization === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>Are you eligible to work in the United States?</strong>
                    </label>
                    <p>{
                      (data.eligible_work_us !== null)
                        ? (data.eligible_work_us === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Are you 18 years of age or older?</strong>
                    </label>
                    <p>{
                      (data.over_18_years_old !== null)
                        ? (data.over_18_years_old === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>If no, what age?</strong>
                    </label>
                    <p>{data.current_age}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>By applying with Starplex/CMS do you agree to participate in a criminal history verification?</strong>
                    </label>
                    <p>{
                      (data.participate_criminal_verification !== null)
                        ? (data.participate_criminal_verification === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>Do you speak a second language/sign? Please list below</strong>
                    </label>
                    <p>{data.speak_second_language}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Have you reviewed the essential functions of the position and/or have the essential functions been explained to you?</strong>
                    </label>
                    <p>{
                      (data.reviewed_essential_functions !== null)
                        ? (data.reviewed_essential_functions === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Can you provide written references?</strong>
                    </label>
                    <p>{
                      (data.provide_written_references !== null)
                        ? (data.provide_written_references === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Do you have a valid driver`s license, or valid form of ID?</strong>
                    </label>
                    <p>{
                      (data.valid_drivers_license !== null)
                        ? (data.valid_drivers_license === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-6">
                    <label>
                      <strong>High School, College or Trade School</strong>
                    </label>
                    <div dangerouslySetInnerHTML={{__html: data.high_school_data}}></div>
                  </div>
                  <div className="col-6">
                    <label>
                      <strong>Current licenses you hold for Security, Flagger, Beverage Enforcement, or Safety (CPR/First Aid)</strong>
                    </label>
                    <div dangerouslySetInnerHTML={{__html: data.current_licenses_data}}></div>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-6">
                    <label>
                      <strong>List last two employers starting with the most recent</strong>
                    </label>
                    <div dangerouslySetInnerHTML={{__html: data.last_employers_data}}></div>
                  </div>
                  <div className="col-6">
                    <label>
                      <strong>Include two people familiar with your work ability. (Direct Supervisor or Manager)</strong>
                    </label>
                    <div dangerouslySetInnerHTML={{__html: data.people_work_ability_data}}></div>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-3">
                    <label>
                      <strong>How did you hear about us: Web-site, Agency (be specific), Name of referral</strong>
                    </label>
                    <p>{data.hear_about_us}</p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Voluntary information</strong>
                    </label>
                    <ul>
                      {(data.american_indian) ? <li>American Indian/Alaskan</li> : ''}
                      {(data.black_african_american) ? <li>Black or African American</li> : ''}
                      {(data.asian) ? <li>Asian</li> : ''}
                      {(data.hispanic_latino) ? <li>Hispanic or Latino</li> : ''}
                      {(data.white_not_hispanic) ? <li>White, not of Hispanic Origin</li> : ''}
                      {(data.two_more_races) ? <li>Two or More Races</li> : ''}
                    </ul>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Are you a United States Veteran?</strong>
                    </label>
                    <p>{
                      (data.us_veteran !== null)
                        ? (data.us_veteran === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                  <div className="col-3">
                    <label>
                      <strong>Are you currently a member of the National Guard?</strong>
                    </label>
                    <p>{
                      (data.member_national_guard !== null)
                        ? (data.member_national_guard === true)
                          ? 'Yes'
                          : 'No'
                        : '-'}
                    </p>
                  </div>
                </div>

                <div className="row row-details">
                  <div className="col-12">
                    <label>
                      <strong>Notes</strong>
                    </label>
                    <ul>
                      {data.applicants_notes.map((item, idx) => (
                        <li key={idx}>
                          <strong>{item.first_name} {item.last_name} wrote
                          on {moment(item.create_date).format('MM/DD/YYYY HH:mm')}:</strong>&nbsp;&nbsp;
                          {item.notes}
                        </li>
                      ))}
                    </ul>
                    <div>
                      {renderTextarea( "notes", "Add Note", false, 5, this.handleChange )}
                    </div>
                  </div>
                </div>


                {authPermissions["module_applicants_edit"] &&
                  <div className="row justify-content-end">
                    <div className="col-sm-6 text-right">
                      <button className="btn btn-primary mt-3"
                        type="button"
                        disabled={!this.state.notes}
                        onClick={() => this.handleAddNotes()}
                      >
                        Add Notes
                      </button>
                    </div>
                  </div>
                }

              </section>
            </section>
            }
          </main>
    );
  }
}

export default ApplicantsForm;
