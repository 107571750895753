import React from 'react';

const VolunteersFormInfo = props => {
  const optionsCMSdepartments = props.optionsCMSdepartments;
  
  return (
    <section className="form-wrapper venue-info-form container-fluid">
      <form onSubmit={props.handleSubmit}>
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            {props.renderInput("name", "Organization Name", "text", true )}
          </div>
          <div className="col-3">
            {props.renderSelect("department_id", "Department", optionsCMSdepartments, true )}
          </div>
          <div className="col-3">
            {props.renderInput("rate", "Rate", "text", false, 5 )}
          </div>
          <div className="col-3">
            {props.renderSwitch("available_all_positions", "Available for all Position Types?" )}
          </div>
        </div>
        
        <div className="form-footer">
          <div className="row justify-content-end align-items-center">
            <div className="col-auto">
              {props.renderButton("Save Organization Info")}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}

export default VolunteersFormInfo;
