import React from 'react';
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { updateStaffPositions } from '../../../../services/staffService';

function StaffPayRateRatesView({ item }) {
  return (
    <section className="PositionsPayRateSettingsEdit">
      {
        item.pay_rates.map( (item, idx) => (          
          <div className="StaffPayRateSettingsCard card" key={idx}>
            <h5 className="card-title">
              {item.name}
            </h5>
            <h6 className="card-subtitle">
              Min: ${item.minimum_rate} - Max: ${item.maximum_rate}
            </h6>
            <div className="ratesContainer">
              <div className="regularPayRateColumn">
                <RenderPayRateInput
                  id={item.staff_position_id}
                  label="Regular Pay Rate"
                  name="regular_pay_rate"
                  value={item.regular_pay_rate}
                  minimumRate={item.minimum_rate}
                  maximumRate={item.maximum_rate}
                />
              </div>

              {item.name === 'Staff' &&
                <div className="travelPayRateColumn">
                  <RenderPayRateInput
                    id={item.staff_position_id}
                    label="Travel Pay Rate"
                    name="travel_pay_rate"
                    value={item.travel_pay_rate}
                    minimumRate={item.minimum_rate}
                    maximumRate={item.maximum_rate}
                  />
                </div>
              }
              </div>
          </div>
        ))
      }
    </section>
  )
}

function RenderPayRateInput({ id, label, name, value, minimumRate, maximumRate }) {
  const handleBlur = (id, newValue, name) => {
    if (value === newValue) return;

    const errorMessage = validate(minimumRate, maximumRate, newValue);
    if (!errorMessage) {
      if (name === 'regular_pay_rate') {
        handleUpdateStaffPosition( id, newValue, null);
      } else if (name === 'travel_pay_rate') {
        handleUpdateStaffPosition(id, null, newValue);
      }
    } else {
      toast.error(errorMessage);
    }
  };

  const validate = (min, max, newValue) => {
    if (!(Number(newValue) >= Number(min) && Number(newValue) <= Number(max)))
      return messages.error.staffPositionValueOutOfRange;
  };

  const handleUpdateStaffPosition = async (positionItemId, regularPayRate, travelPayRate) => {
    const { data } = await updateStaffPositions(positionItemId, regularPayRate, travelPayRate);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.staffPositionUpdated, { autoClose: 1000 });
  }

  return (
    <div className='form-group'>
      <div className="d-flex flex-column-reverse">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input 
            type="number" 
            className="form-control" 
            name={name}
            aria-label={label}
            defaultValue={(value) ? value : ''}
            onBlur={e => handleBlur(id, e.currentTarget.value, e.currentTarget.name)}
          />
        </div>
        <label htmlFor={name}>{label}</label>
      </div>
    </div>
  )
}

export default StaffPayRateRatesView
