import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import { updateEventReconciliationBulk } from '../../../../../services/eventsService';
import { EventFetchContext, EventContext } from '../../Event';
import { EventReconciliationContext } from '../EventReconciliation';

function EventReconciliationToolBulk() {
  const event = useContext(EventReconciliationContext).event;
  const eventFetchContext = useContext(EventFetchContext);
  const eventContext = useContext(EventContext);
  const eventReconciliationContext = useContext(EventReconciliationContext);
  const eventdate = useContext(EventReconciliationContext).eventdate;
  const [bulkReconciliation, setBulkReconciliation] = useState(false);
  const [bulkActionSelected, setBulkActionSelected] = useState(null);
  const [actionButtonText, setActionButtonText] = useState("I'm not sure");
  const optionsBuild = [
    { value: 1 , label: "Reconcile"  },
    { value: 2 , label: "Unreconcile"  }
  ];

  // ** HANDLE
  // *********************************************************
  const handleActionSelection = async (selected) => {
    setBulkActionSelected(selected);
    if (selected.value === 1) {
      setActionButtonText("Reconcile All");
    } else {
      setActionButtonText("Unreconcile All");
    }
  }

  const handleSaveBulkReconciliation = async () => {
    setBulkReconciliation(false);
    
    const dataToSave = {
      event_date_id: eventdate.id,
      event_name: event.name,
      event_id: event.id,
      action: bulkActionSelected.value,
      event_date: moment(eventReconciliationContext.eventdate.start_date).format('MM/DD/YYYY')
    }

    try {
      const { data } = await updateEventReconciliationBulk(dataToSave);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage);
        return
      }

      data.status && eventContext.dispatchEvent({ TYPE: 'EVENT_STATUS', VALUE: data.status });
      if (bulkActionSelected.value === 1) {
        toast.success('All shifts reconciled!', { autoClose: 1000 });
      } else {
        toast.success('All shifts unreconciled!', { autoClose: 1000 });
      }
      setBulkActionSelected(null);
      setActionButtonText("I'm not sure");

      eventReconciliationContext.fetchReconciliationList(event.id, eventReconciliationContext.eventdate.id)
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED', VALUE: true });
      setTimeout(() => {
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      }, 500);
    } catch (error) {}
  };

  return (
    <>
      <button type="button" className="btn btn-primary"
        onClick={() => setBulkReconciliation(true)}
      >Bulk Reconciliation</button>

      <Modal show={bulkReconciliation} onHide={() => setBulkReconciliation(false)} className="EventReconciliationToolBulkEndTime-modal">
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-tasks"></i>&nbsp;Bulk Reconciliation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-3 justify-content-center">
            <div className="col-sm-9">
              <Select
                className="select-style-default"
                options={optionsBuild}
                placeholder="Select the bulk action:"
                onChange={selected => handleActionSelection(selected)}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <p className="disclaimer text-center">
                This will update ALL shifts on the selected day
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setBulkReconciliation(false)}>Cancel</Button>
          <Button variant="success" 
            disabled={!bulkActionSelected} 
            onClick={() => handleSaveBulkReconciliation()} 
          >{actionButtonText}</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventReconciliationToolBulk
