import React, { useEffect, useState } from 'react';
import { getPaycomSyncLatest } from "../../../services/widgetsService";

function PaycomSyncWidget() {
  const [latestPaycom, setLatestPaycom] = useState([]);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const getLatestPayconData = async () => {
    const { data } = await getPaycomSyncLatest();
    setLatestPaycom(data.log);
    setTitle(data.title);
    setText(data.text);
  }

  useEffect(() => {
    getLatestPayconData();
  }, []);

  return (
    <div>
      { !latestPaycom
        ? ''
        : <div className="col-auto">    
          <div className='DashboardList-item btn d-flex flex-column'>
            <h4><i className="fas fa-sync-alt"></i>&nbsp;{title}</h4>
            <p>{text}</p>
            <ul>
              <li>Started on {latestPaycom.start_date}</li>
              <li>Finished on {latestPaycom.end_date}</li>
              <li>{latestPaycom.summary}</li>
              <li>Run by {latestPaycom.user}</li>
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default PaycomSyncWidget;