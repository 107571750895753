import React, { useContext } from 'react';
import { EventStaffAssignedListContext } from '../EventStaffAssignedList';

function EventStaffAssignedListItemSalary(props) {
  const item = props.item;
  const eventStaffAssignedListContext = useContext(EventStaffAssignedListContext);

  return (
    <button className="btn checkbox" 
      disabled={item.staff_id === 0 || item.exported || item.reconciled || item.type !== 'Staff'} 
      onClick={() => eventStaffAssignedListContext.handleAssignedStaffPositionUpdate('salary', item)}
    >
      { item.salary 
        ? <i className="fas fa-check-square" ></i>  
        : <i className="far fa-square" ></i>
      }
    </button>
  );
}

export default EventStaffAssignedListItemSalary
