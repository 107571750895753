import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getErrorReportHistory, deleteErrorLogs } from '../../../services/logsService';
import Pagination from '../../Common/pagination/pagination';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, LogsContext } from '../Logs';
import './ErrorHistory.scss';

function ErrorHistory() {
  const logsContext = useContext(LogsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [errorReportHistory, setErrorReportHistory ] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "desc" });
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      path: 'created_date',
      label: 'Date Created'
    },
    {
      path: 'user',
      label: 'User',
      disableSort: true,
    },
    {
      path: 'request',
      label: 'Request',
    },
    {
      path: 'error',
      label: 'Error'
    }
  ];

  useEffect(() => {
    const fetchErrorHistory = async () => {
      const { data } = await getErrorReportHistory({
        LIMIT: pageSize,
        PAGE: currentPage,
        ORDER_BY: sortColumn.path,
        SORT_ORDER: sortColumn.order
      });
      if (!data.success) {
        toast.error('Error on get Error History data.');
        return
      }
      setErrorReportHistory(data.logs);
      setTotalCount(data.count);
      setLoading(false);
    };
    fetchErrorHistory();
  }, [currentPage, sortColumn]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });


  // ** HANDLE
  // *********************************************************
  const handleDeleteLogs = async () => {
    const {data: resDeleteLogs } = await deleteErrorLogs();
    if (!resDeleteLogs.success) return toast.error("Couldnt delete logs");
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={logsContext.props.location} />
      : <section className="ErrorHistory content-wrapper container-fluid">
          <button className="btn btn-delete-logs" type="button" onClick={() => handleDeleteLogs()}>
            <i className="fa fa-trash"></i>DELETE LOGS
          </button>
          <Table
            name="ErrorHistoryTable"
            columns={columns}
            data={errorReportHistory}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={loading}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={ page => setCurrentPage(page) }
          />
        </section>
  )
}

export default ErrorHistory
