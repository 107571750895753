import http from "./httpService";

const apiEndpoint = "/roles";

export function getRolesList() {
  return http.get(apiEndpoint + '/list_roles');
}

export function getResorcesList() {
  return http.get(apiEndpoint + '/list_resources');
}

export function getRoleResourcesList(roleId) {
  return http.get(apiEndpoint + '/list_role_resources', { 
    params: {
      role_id: roleId 
    }
  });
}

export function updateRoleResourceAccess(id, access) {
  return http.post(apiEndpoint + '/update_role_resource', {  id: id, access: access  });
}