import React, { Component } from 'react';
import PropTypes from "prop-types";
import Table from '../../../../Common/table/table';
import Pagination from '../../../../Common/pagination/pagination';
import auth from "../../../../../services/authService";

class VenueConfigurationPositionsList extends Component {

  columns = [
    { path: "assignment_name", label: "Assignment Name" },
    { path: "position_name", label: "Positions Type" },
    { path: "qty", label: "QTY" },
    { path: "blazer", 
      label: "Blazer",
      content: item => (
        (item.blazer)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
    { path: "coat", 
      label: "Coat",
      content: item => (
        (item.coat)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
    { path: "shirt", 
      label: "Shirt",
      content: item => (
        (item.shirt)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
    { path: "traffic", 
      label: "Traffic",
      content: item => (
        (item.traffic)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
    { path: "radio_qty", 
      label: "Radio",
      content: item => (
        (item.radio)
          ? (item.radio_qty === 0)
            ? "–"
            : item.radio_qty
          : "–"
      )
    },
  ];

  deleteColumn = {
    key: "delete",
    path: "delete",
    content: item => (
      <button
        onClick={() => this.props.openModalDeleteConfirmation(item, 'configurationPosition')}
        className="btn btn-danger btn-sm btn-delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    content: item => (
      <button className="btn btn-sm btn-edit" onClick={() => this.props.handleEditConfigurationPosition(item)} >
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </button>
    )
  };

  authPermissions = auth.getCurrentUserPermisions();

  constructor(props) {
    super(props);
    if (this.authPermissions["module_venues_add"]) this.columns.push(this.editColumn);
    if (this.authPermissions["module_venues_delete"]) this.columns.push(this.deleteColumn);
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  render() {
    const {
      handleAddConfigurationPosition,
      data,
      sortColumn,
      onSort,
      dataLoading,
      totalCount,
      pageSize,
      currentPage,
      onPageChange
    } = this.props;
    return (
      <section className="configuration-position-list">
        <section className="table-console d-flex align-items-end justify-content-end">
          <div className="table-console-left d-flex align-items-center">
            {this.authPermissions["module_venues_add"] &&
              <button className="btn btn-primary btn-block" 
                onClick={() => handleAddConfigurationPosition()} 
              > 
                Add Positions
              </button>
            }
          </div>
        </section>
        <Table
          name={'venues-configuration'}
          columns={this.columns}
          data={data}
          sortColumn={sortColumn}
          onSort={onSort}
          isLoading={dataLoading}
        />
        { !dataLoading && (
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={onPageChange}
          />
        )}
      </section>
    );
  }
}

VenueConfigurationPositionsList.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default VenueConfigurationPositionsList;
