import _ from "lodash";
import icnShirt from "../../../../assets/icn-shirt.svg";
import icnBlazer from "../../../../assets/icn-blazer.svg";
import icnCoat from "../../../../assets/icn-coat.svg";
import { getShirtSizes } from '../../../../services/shirtSizesService';
import { getBlazerSizes } from '../../../../services/blazerSizesService';
import { getCoatSizes } from '../../../../services/coatSizesService';

export const CLOTHES_TYPES = {
  SHIRT: {
    TYPE : 'shirt',
    LABEL: 'Shirt Sizes',
    ICON : icnShirt,
    DATA: 'shirtSizes',
    FORM_SIZE_FIELDS : [
      {
        NAME: 'shirtSize',
        ELEMENT: 'input'
      }
    ] 
  },
  BLAZER: {
    TYPE : 'blazer',
    LABEL: 'Blazer Sizes',
    ICON : icnBlazer,
    DATA: 'blazerSizes',
    FORM_SIZE_FIELDS : [
      {
        NAME: 'blazerGender',
        ELEMENT: 'select',
        OPTIONS : [
          { id: "", name: 'Gender'},
          { id: "M", name: 'Male'},
          { id: "F", name: 'Female'}
        ]
      },
      {
        NAME: 'blazerSize',
        ELEMENT: 'input'
      }
    ] 
  },
  COAT: {
    TYPE : 'coat',
    LABEL: 'Coat Sizes',
    ICON : icnCoat,
    DATA: 'coatSizes',
    FORM_SIZE_FIELDS : [
      {
        NAME: 'coatSize',
        ELEMENT: 'input'
      }
    ] 
  },
}

export async function getClothesSizesMiddleware() {
  let ERRORS = [];
  const { data: resShirtSizes } = await getShirtSizes();
  const { data: resBlazerSizes } = await getBlazerSizes();
  const { data: resCoatSizes } = await getCoatSizes();

  !resShirtSizes.success && ERRORS.push('Error getting Shirt Sizes data');
  !resBlazerSizes.success && ERRORS.push('Error getting Blazer Sizes data');
  !resCoatSizes.success && ERRORS.push('Error getting Shirt Coat data');

  const shirtSizes = _.orderBy(resShirtSizes.shirt_size, ['name'], ['asc']);
  const blazerSizes = _.orderBy(resBlazerSizes.blazer_size, ['gender', 'name'], ['asc', 'asc'] );
  const coatSizes = _.orderBy(resCoatSizes.coat_size, ['size'], ['asc']);

  return  {
    data: {
      shirtSizes,
      blazerSizes,
      coatSizes,
      errors: ERRORS.length > 0 ? ERRORS : null
    }
  }

}
