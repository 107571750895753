import React from 'react';
import Select from "react-select";
import auth from "../../../services/authService";

export default function FilterByDepartment(props) {
  const userData = auth.getCurrentUser();
  const { departments, handleDepartmentChange } = props;
  
  const departmentOptions = [];
  let disabledDepartments = false;

  let departmentIndex = 0;
  let departmentPreselected = null;
  const userDepartmentId = userData.department_id;
  if (userDepartmentId) {
    disabledDepartments = true;
  }

  departments.forEach((element) => {
    if (userDepartmentId === element.id) {
      departmentPreselected = departmentIndex;
    }
    departmentOptions.push({
      value: element.id,
      label: element.name
    })
    departmentIndex++;
  });

  function getDefaultValue() {
    return departmentOptions[departmentPreselected];
  }

  return (
    <div className="filter-by-status">
      <Select
        isMulti
        value={getDefaultValue()}
        name="selectDepartment"
        placeholder="Filter by Department"
        options={departmentOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleDepartmentChange}
        isDisabled={disabledDepartments}
      />
    </div>
  )
}
