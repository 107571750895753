import React, { useEffect, useState, useLayoutEffect }  from "react";
import EmptyDataIcon from "../assets/cms-icon-empty-data.svg";
import { PageWrapperHeight } from "../utils/LayoutSizes";

const NotFound = () => {
  const [pageWrapperHeight, setPageWrapperHeight] = useState(0);
  
  useEffect(() => {
    setPageWrapperHeight(PageWrapperHeight());
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageWrapperHeight(PageWrapperHeight());
    };
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });
  
  return (
    <main className="PageNotFound page-wrapper container-fluid d-flex align-items-center justify-content-center" style={{minHeight: pageWrapperHeight + "px"}}>
      <div className="PageNotFound-wrapper">
        <div className="d-flex align-items-center">
          <span className="number">4</span>
          <span className="img-wrapper">
            <img className="img-fluid" src={EmptyDataIcon} alt="Not Found"/>
          </span>
          <span className="number">4</span>
        </div>
        <h1>Page Not Found</h1>
      </div>
    </main>
  );
};

export default NotFound;
