import React from 'react';
import Select from "react-select";

export default function FilterByYear(props) {
  const { handleYearChange } = props;
  const yearOptions =  [];
  const currentYear = (new Date().getFullYear());
  var currentYearIndex = 0;

  for (let year = 2020; year <= currentYear+1; year++) {
    if (year === currentYear) {
      currentYearIndex = yearOptions.length;
    }

    yearOptions.push(
      { value: year, label: year }
    )
  }

  return (
    <div className="filter-by-year">
      <Select
        name="selectYear"
        placeholder="Filter by Year"
        options={ yearOptions }
        defaultValue={ yearOptions[currentYearIndex] }
        className="basic-select col-md-4"
        classNamePrefix="select"
        onChange={ handleYearChange }
      />
    </div>
  )
}
