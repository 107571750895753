import React from 'react';
import _ from "lodash";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { toast } from "react-toastify";
import SwipeableViews from 'react-swipeable-views';
import auth from "../../services/authService";
import { getBlazerSizes } from '../../services/blazerSizesService';
import { getDepartmentList } from '../../services/departmentService';
import { getCoatSizes } from '../../services/coatSizesService';
import { getShirtSizes } from '../../services/shirtSizesService';
import { saveStaff, validateEmail, getStaffItem } from '../../services/staffService';
import { getFileById } from "../../services/filesService";
import { messages, pagetitle } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import { staffSchema, staffDataModel, staffEditMapToViewModel } from "../../models/staffSchema";
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import FilesManager from '../Common/files-manager/filesManager';
import StaffFormPersonalInfo from './staff-forms/staffFormPersonalInfo';
import StaffFormCMSInfo from './staff-forms/staffFormCMSInfo';
import StaffFormLicenses from './staff-forms/staffFormLicenses';
import StaffFormPositionsPayRates from './staff-forms/staffFormPositionsPayRates';
import StaffFormAvailability from './staff-forms/staffFormAvailability';
import StaffFormRatings from './staff-forms/staffFormRatings';
import StaffFormNotifications from './staff-forms/staffFormNotifications';

class StaffForm extends Form {

  state = {
    dataLoading: true,
    tabIndex: 0,
    data: staffDataModel(),
    errors: {}
  };

  schema = {};
  
  staffForms = [];

  StaffFormPersonalInfo = {
    label: "Personal Info",
    submitButton: true,
    content: idx => (
      <StaffFormPersonalInfo 
        key={idx}
        state={this.state}
        renderInput={this.renderInput}
        renderMaskInput={this.renderMaskInput}
        renderSelect={this.renderSelect}
        renderSwitch={this.renderSwitch}
        renderCheckbox={this.renderCheckbox}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleFileChange={this.handleFileChange}
        isEditing={this.isEditing} />
    )
  }

  StaffFormCMSInfo = {
    label: "CMS Info",
    submitButton: true,
    content: idx => (
      <StaffFormCMSInfo 
        key={idx}
        state={this.state}
        renderInput={this.renderInput}
        renderMaskInput={this.renderMaskInput}
        renderCheckbox={this.renderCheckbox}
        renderSelect={this.renderSelect}
        renderDatePicker={this.renderDatePicker}
        renderSwitch={this.renderSwitch}
        optionsCMSdepartments={this.state.optionsCMSdepartments} 
        optionsBlazerSizes={this.state.optionsBlazerSizes}
        optionsShirtSizes={this.state.optionsShirtSizes}
        optionsCoatSizes={this.state.optionsCoatSizes}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        handleFocusDatePicker={this.handleFocusDatePicker} 
        handleChangeDatePicker={this.handleChangeDatePicker}
        isEditing={this.isEditing} />
    )
  }

  StaffFormLicenses = {
    label: "Licenses",
    submitButton: true,
    content: idx => (
      <StaffFormLicenses 
        key={idx}
        state={this.state}
        renderInput={this.renderInput}
        renderSelect={this.renderSelect}
        handleChange={this.handleChange}
        handleBlur={this.handleBlur}
        renderDatePicker={this.renderDatePicker}
        handleFocusDatePicker={this.handleFocusDatePicker} 
        handleChangeDatePicker={this.handleChangeDatePicker}
        isEditing={this.isEditing} />
    )
  }

  StaffFormAvailability = {
    label: "Availability",
    content: idx => (
      <StaffFormAvailability
        key={idx}
        loading={this.state.dataLoading}
        staffId={this.state.data.id}
      />
    )
  }

  StaffFormPositionsPayRates = {
    label: "Positions & Pay Rates",
    content: idx => (
      <StaffFormPositionsPayRates
        key={idx} state={this.state}
      />
    )
  }

  StaffFilesManager = {
    label: "File Uploads",
    content: idx => (
      <FilesManager
        key={idx}
        component="Staff"
        loading={this.state.dataLoading} 
        itemId={this.state.data.id}
      />
    )
  }

  StaffFormRatings = {
    label: "Events",
    content: idx => (
      <StaffFormRatings
        key={idx}
        loading={this.state.dataLoading}
        staffId={this.state.data.id}
      />
    )
  }

  StaffFormNotifications = {
    label: "Notifications",
    content: idx => (
      <StaffFormNotifications
        key={idx}
        loading={this.state.dataLoading}
        staffId={this.state.data.id}
      />
    )
  }

  constructor(props) {
    super(props);
    if (this.authPermissions("module_staff_tab_personal")) this.staffForms.push(this.StaffFormPersonalInfo);
    if (this.authPermissions("module_staff_tab_cms")) this.staffForms.push(this.StaffFormCMSInfo);
    if (this.isEditing() && this.authPermissions("module_staff_tab_licenses")) this.staffForms.push(this.StaffFormLicenses);
    if (this.isEditing() && this.authPermissions("module_staff_tab_availability")) this.staffForms.push(this.StaffFormAvailability);
    if (this.isEditing() && this.authPermissions("module_staff_tab_positions")) this.staffForms.push(this.StaffFormPositionsPayRates);
    if (this.isEditing() && this.authPermissions("module_staff_tab_files")) this.staffForms.push(this.StaffFilesManager);
    if (this.isEditing() && this.authPermissions("module_staff_tab_ratings")) this.staffForms.push(this.StaffFormRatings);
    if (this.isEditing() && this.authPermissions("module_staff_tab_notifications")) this.staffForms.push(this.StaffFormNotifications);
  }
  
  async componentDidMount() {
    this.setState({ dataLoading: true })
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = [{ id: '', name: '' }, ...sortDepartments];
    const { data: resBlazerSizes } = await getBlazerSizes();
    const blazerSizes = [ { id: '', gender: null, name: null }, ...resBlazerSizes.blazer_size ];
    const { data: resCoatSizes } = await getCoatSizes();
    const coatSizes = [ { id: '', size: null }, ...resCoatSizes.coat_size ];
    const { data: resShirtSizes } = await getShirtSizes();
    const shirtSizes = [ { id: '', name: null }, ...resShirtSizes.shirt_size ];
    this.setState({ optionsBlazerSizes: blazerSizes, optionsCMSdepartments: departments, optionsCoatSizes: coatSizes, optionsShirtSizes: shirtSizes });
    this.schema = staffSchema(this.isEditing());
    const staffName = await this.populateStaff();
    const titlePage =  ( this.isEditing() ) ? pagetitle.staffEdit + ' — ' + staffName : pagetitle.staffCreate ;
    this.setState({ dataLoading: false, pagetitle: titlePage, submitButton: this.staffForms[0].submitButton })
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateStaff() {
    this.setState({ dataLoading: true })
    try {
      const staffId = this.props.match.params.id;
      if ( staffId === 'create') return;
      
      const { data: resStaff } = await getStaffItem(staffId);
      const staff = resStaff.staff;
      const { data: resFile } = await getFileById(staff.photo);
      const avatar = resFile.data;
      this.setState({ data: staffEditMapToViewModel(staff, avatar) });
      this.validate();
      this.setState({ dataLoading: false });

      return staff.first_name + ' ' + staff.last_name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }
  
  isEditing = () => {
    const staffId = this.props.match.params.id;
    const isEditing = ( staffId === 'create') ? false : true ;
    return isEditing
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value,
      submitButton: this.staffForms[value].submitButton
    });
  };

  handleChangeTabsIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  validateEmail = async input => {
    const errors = { ...this.state.errors };
    const { data: resValidate} = await validateEmail(input.value);
    const isValid = resValidate.valid;
    if ( !isValid ) errors[input.name] = messages.error.emailExist;
    this.setState({ errors });
  }

  doHandleBlur = input => {
    if (input.name ==='email') 
      this.validateEmail(input);
  }

  doHandleChange = input => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
    }
    if (input.className.includes("datepicker") ) {
      value = this.state.dateSelected;
    }
    return value;
  }

  doHandleFileChange = (input) => {
    if ( input.name === 'staff_avatar')
      return ( input.files.length > 0 ) && this.staffAvatarFile(input);
  }

  staffAvatarFile = (input) => {
    const file = input.files[0];
    const reader = new FileReader();
    const sizeLimit = 3000000; //3MB
    if (file.size > sizeLimit) 
      return toast.error(messages.error.fileExceedSize);
      
    reader.onload = (e) => {
      const data = { ...this.state.data };
      data['photo'] = reader.result;
      this.setState({ data });
    }
    reader.readAsDataURL(input.files[0])
  }

  doSubmit = async () => {
    try {
      const { data } = await saveStaff(this.state.data);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      if (this.isEditing()) {
        toast.success(messages.success.staffUpdated, { autoClose: 1000 })
      } else {
        toast.success(messages.success.staffCreated, { autoClose: 1000 });
        this.props.history.push("/staff/" + data.id);
        setTimeout(() => {
          window.location.reload()
        }, 500);
      }
    } catch (ex) {
    }
    return;
  };

  authPermissions = (module) => {
    const authPermissions = auth.getCurrentUserPermisions();
    return authPermissions[module];
  }

  render() { 
    const { 
      dataLoading,
      tabIndex,
      submitButton,
      pagetitle,
      pageWrapperHeight
    } = this.state;

    return ( 
      !auth.getCurrentUser() 
        ? <RedirectToLogin location={this.props.location} />
        : <main className="staff-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
            <Loading active={dataLoading} />
            <section className="page-header">
              <div className="row">
                <div className="col">
                  <h1 className="page-title"><i aria-hidden="true" className="fa fa-users"></i>&nbsp;{pagetitle}</h1>
                </div>
              </div>
            </section>
            {!dataLoading && 
            <section className="page-body">
              <Tabs 
                value={tabIndex}  
                onChange={this.handleChangeTabs} 
                classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
              >
                {this.staffForms.map((form, idx) => (
                  <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
                ))}
              </Tabs>
              <form onSubmit={this.handleSubmit}>
                <SwipeableViews 
                  index={tabIndex} 
                  onChangeIndex={this.handleChangeTabsIndex} 
                  animateHeight={true}
                  animateTransitions={true} >
                  {this.staffForms.map((form, idx) => (
                    form.content(idx)
                  ))}
                </SwipeableViews>
                {submitButton &&
                  <div className="form-footer">
                    {this.renderButton("Save Staff Info")}
                  </div>
                }
              </form>
            </section>
            }
          </main>
    );
  }
}

export default StaffForm;
