import http from "./httpService";

const apiEndpoint = "/logs";

export function getPayrollReportHistory( paginationSettings ) {
  const { LIMIT = 10, PAGE = 1, ORDER_BY = 'created_date', SORT_ORDER = 'desc'} = paginationSettings || { LIMIT: 10, PAGE: 1, ORDER_BY: 'created_date', SORT_ORDER: 'desc'}
  return http.get(apiEndpoint + '/list_report_log', {
    params: {
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getPaycomReportHistory( paginationSettings ) {
  const { LIMIT = 10, PAGE = 1, ORDER_BY = 'start_date', SORT_ORDER = 'desc'} = paginationSettings || { LIMIT: 10, PAGE: 1, ORDER_BY: 'start_date', SORT_ORDER: 'desc'}
  return http.get(apiEndpoint + '/list_paycom_sync_log', {
    params: {
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getActivityReportHistory( paginationSettings ) {
  const { USER_ID = [], SECTION = [], LIMIT = 10, PAGE = 1, ORDER_BY = 'created_date', SORT_ORDER = 'desc'} = paginationSettings || { LIMIT: 10, PAGE: 1, ORDER_BY: 'created_date', SORT_ORDER: 'desc'}
  return http.get(apiEndpoint + '/list_activity_log', {
    params: {
      user_id : USER_ID,
      section : SECTION,
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getDepartmentRateLog( paginationSettings ) {
  const { DEPARTMENT_ID = [], LIMIT = 10, PAGE = 1, ORDER_BY = 'created_date', SORT_ORDER = 'desc'} = paginationSettings || { LIMIT: 10, PAGE: 1, ORDER_BY: 'created_date', SORT_ORDER: 'desc'}
  return http.get(apiEndpoint + '/list_department_rate_log', {
    params: {
      department_id : DEPARTMENT_ID,
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getErrorReportHistory( paginationSettings ) {
  const { LIMIT = 10, PAGE = 1, ORDER_BY = 'created_date', SORT_ORDER = 'desc'} = paginationSettings || { LIMIT: 10, PAGE: 1, ORDER_BY: 'created_date', SORT_ORDER: 'desc'}
  return http.get(apiEndpoint + '/list_error_log', {
    params: {
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function deleteErrorLogs() {
  return http.post(apiEndpoint + '/delete_error_log' );
}
