import React from 'react';
import { isEnvDev } from "../../utils/environment";

const company = process.env.REACT_APP_COMPANY;
const appName = process.env.REACT_APP_NAME;
const appVersion= 'v' + process.env.REACT_APP_VERSION;
const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid">
        <div className="row d-flex justify-content-between">
          <div className="col-auto">
            <p><small><b>{company}</b> © {year} - All rights reserved</small></p>
          </div>
          <div className="col-auto">
            <p><small><b>{appName}</b> {appVersion} {isEnvDev && "⚒"}</small></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;