import React from 'react';
import { Modal , Button } from 'react-bootstrap';
import Input from '../../../Common/form/input';

const renderInput = (name, label, handleChange, data) => {
  return (
    <Input
      type={'text'}
      label={label}
      name={name}
      value={data[name]}
      onChange={handleChange}
    />
  )
}

const VolunteersEdit = ({ isOpen, onRequestClose, closeModal, data, handleChange, handleSave }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-volunteers-edit">
      <Modal.Header>
        <Modal.Title>Edit Volunteer</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="row">
          <div className="col-6">
            {renderInput('first_name', 'First Name', handleChange, data )}
          </div>
          <div className="col-6">
            {renderInput('last_name', 'Last Name', handleChange, data )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" disabled={!data.first_name || !data.last_name} onClick={() => handleSave()} >
          Edit Volunteer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default VolunteersEdit;