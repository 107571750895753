import React, { useReducer, useContext }  from 'react';
import { Modal , Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getSettingsKey } from '../../../../../../services/settingsService';
import { currencyUSD } from '../../../../../../utils/value-formats';
import { EventFetchContext } from '../../../Event';
import { EventStaffAssignedListContext } from '../EventStaffAssignedList';
import { EventReconciliationListContext } from '../../../EventReconciliation/EventReconciliationList';

const initialEditPayrate = {
  open: false,
  selected: null,
}

const reducerEditPayRate = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE':
      return {...state, open: action.VALUE}
    case 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE_SELECTED':
      return {...state, selected: action.VALUE}
    default:
      return state
  }
}

function EventStaffAssignedListItemPayRate(props) {
  const item = props.item;
  const type = props.type;
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffAssignedListContext = useContext(EventStaffAssignedListContext);
  const eventReconciliationListContext = useContext(EventReconciliationListContext);
  const [editPayRate, dispatchEditPayRate] = useReducer(reducerEditPayRate, initialEditPayrate);

  // ** HANDLE
  // *********************************************************

  const handleEditPayRate = async (item) => {

    if (!item) {
      dispatchEditPayRate({TYPE: 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE', VALUE: false});
      dispatchEditPayRate({TYPE: 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE_SELECTED', VALUE: null});
      return
    }

    try {
      const eventId = item.staff_event_id;
      const itemPayrates = item.pay_rate[0];
      const { data } = await getSettingsKey('additional_rate_event');
      const success = data.success;
      if (!success) {
        const errorMessage = data.data;
        toast.error(errorMessage);
        return
      }
      const payrates = {
        ...itemPayrates,
        additional_rate_event: data.setting.value ? data.setting.value : 0,
        staff_event_id: eventId
      }
      dispatchEditPayRate({TYPE: 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE', VALUE: true});
      dispatchEditPayRate({TYPE: 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE_SELECTED', VALUE: payrates});

    } catch (error) {}
  }

  const handleChangePayRate = () => ({ currentTarget: input }) => {
    const payratesSelected = editPayRate.selected;
    payratesSelected['current_pay_rate'] = input.value;
    dispatchEditPayRate({TYPE: 'EVENT_STAFF_ASSIGNED_EDIT_PAY_RATE_SELECTED', VALUE: payratesSelected})
  }

  const handleUpdatePayRate = async () => {
    const payrate = editPayRate.selected;
    handleEditPayRate();
    if (type === '1') {
      await eventStaffAssignedListContext.handleAssignedStaffPositionUpdate('payrate', payrate);
    } else if (type === '2') {
      await eventReconciliationListContext.handleUpdateReconciliation('payrate', payrate);
    }
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
  }

  const validatePayRate = () => {
    const min = Number(editPayRate.selected.minimum_rate);
    const max = Number(editPayRate.selected.maximum_rate) + Number(editPayRate.selected.additional_rate_event);
    const value =  Number(editPayRate.selected.current_pay_rate);
    if (value >= min && value <= max) {
      return false;
    }
    return true;
  };


  // ** RENDER VIEWS
  // *********************************************************
  const renderCurrencyInput = name => {
    
    const hasErrorClassName = validatePayRate() ? ' has-error ' : '';

    return (
      <div className={"form-group currency-input" + hasErrorClassName}>
        <div className="d-flex flex-column-reverse">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input type="number" 
              className="form-control" 
              name={name}
              onChange={handleChangePayRate()}
              aria-label={name} 
              value={editPayRate.selected.current_pay_rate}
              onBlur={() => validatePayRate()}
            />
          </div>
          {validatePayRate() && <div className="alert alert-danger">Out of range</div>}
        </div>
      </div>
    );
  }


  return (
    <React.Fragment>
      <div className="payrate-wrapper">
        { currencyUSD(item.pay_rate[0].current_pay_rate) }
        { !item.exported && item.type === 'Staff'
            ? !item.reconciled
                ? <i className="fas fa-pencil-alt" onClick={() => handleEditPayRate(item)}></i>
                : ''
            : ''
        }
      </div>
      <Modal show={editPayRate.open} onHide={() => handleEditPayRate()} className="modal-payrate">
      { editPayRate.selected &&
        <React.Fragment>
          <Modal.Header>
            <Modal.Title>Pay rate</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <h6 className="label">
              Min: {currencyUSD(editPayRate.selected.minimum_rate)} | 
              Max: {currencyUSD(editPayRate.selected.maximum_rate)} 
              <span className="additional-rate-event"> + {currencyUSD(editPayRate.selected.additional_rate_event)}</span>
            </h6>
            {renderCurrencyInput('current_payrate')}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleEditPayRate()}>Cancel</Button>
            <Button variant="success" disabled={validatePayRate()} onClick={() => handleUpdatePayRate()} >Apply</Button>
          </Modal.Footer>
        </React.Fragment>
      }
      </Modal>
    </React.Fragment>

    
  )
}

export default EventStaffAssignedListItemPayRate
