import React, { useEffect, useReducer, useState, useContext } from 'react';
import Table from '../../../Common/table/table';
import _ from "lodash";
import { toast } from "react-toastify";
import { messages } from '../../../../utils/language-en.json';
import { currencyUSD } from '../../../../utils/value-formats';
import { getEventExpensesList, addEventExpense, deleteEventExpense, generateExpensesSheet} from '../../../../services/eventsService';
import { EventFetchContext } from '../Event';
import EventExpense from './partials/EventExpense.jsx';
import './EventInvoicing.scss';

const initialEventInvoicing =  {
  loading: true,
  data: null,
  error: null
}

const reducerEventInvoicing = (state, action) => {
  switch (action.TYPE) {
    case 'LOADING':
      return  { ...state, loading: action.VALUE }
    case 'ERROR':
      return  { ...state, error: action.VALUE }
    case 'DATA':
      return  { ...state, data: action.VALUE }
    default:
      break;
  }
}

function EventInvoicing({ event }) {

  const [eventInvoicing, dispatchEventInvoicing] = useReducer(reducerEventInvoicing, initialEventInvoicing);
  const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "asc" });
  const eventFetchContext = useContext(EventFetchContext);
  const [expenseDescription, setExpenseDescription] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');

  const fetchEventExpenses = async (eventId) => {
    dispatchEventInvoicing({ TYPE: 'LOADING', VALUE: true });
    const { data } = await getEventExpensesList(eventId)
    if (!data.success) {
      dispatchEventInvoicing({ TYPE: 'ERROR', VALUE: data.message });
    }

    const sortedDataRows = _.orderBy(data.expenses, [sortColumn.path], [sortColumn.order]);
    dispatchEventInvoicing({ TYPE: 'DATA', VALUE: { rows: sortedDataRows, total_amount: data.total_amount } });
    dispatchEventInvoicing({ TYPE: 'LOADING', VALUE: false });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: false });
  }

  const columns = [
    {
      path: 'created_date',
      label: 'Date'
    },
    {
      path: 'description',
      label: 'Description'
    },
    {
      path: 'amount',
      label: 'Amount',
      content: item => (currencyUSD(item.amount))
    },
    {
      key: "delete",
      path: "delete",
      disableSort: true,
      content: item => (
        item.id > 0 &&
        <button
          onClick={() => handleDeleteExpense(item)}
          className="btn btn-danger btn-sm btn-delete"
        >
          <span className="sr-only">Delete</span>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      )
    }
  ]

  useEffect(() => {
    fetchEventExpenses(event.id);
  },[event.id]);

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_EXPENSES && fetchEventExpenses(event.id);
  }, [eventFetchContext.fetchData.EVENT_EXPENSES]);

  useEffect(() => {
    if (eventInvoicing.data) {
      const sortedRows = _.orderBy(eventInvoicing.data.rows, [sortColumn.path], [sortColumn.order]);
      dispatchEventInvoicing({ TYPE: 'DATA', VALUE: { rows: sortedRows, total_amount: eventInvoicing.data.total_amount } });
    }
  }, [sortColumn]);

  const handleChangeNewDescription = ({ currentTarget: input}) => {
    setExpenseDescription(input.value);
  };

  const handleChangeNewAmount = ({ currentTarget: input}) => {
    setExpenseAmount(input.value);
  };

  // ** HANDLES
  // *********************************************************
  const handlePrintOption = async () => {
    const {data: resExpensesSheet } = await generateExpensesSheet(event.id);
    if (!resExpensesSheet.success) return toast.error("Print file couldn't be generated");
    window.open(process.env.REACT_APP_API_URL + '/' + resExpensesSheet.file_name);
  }

  const handleAddExpense = async () => {
    try {
      const { data } = await addEventExpense(expenseDescription, expenseAmount, event.id, event.name)
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.expenseCreated, { autoClose: 1000 });
      fetchEventExpenses(event.id);
      setExpenseDescription('');
      setExpenseAmount('');
    } catch (ex) {
    }
    return; 
  };

  const handleDeleteExpense = async (item) => {
    try {
      const { data } = await deleteEventExpense(item.id, item.description, event.name);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.expenseDeleted, { autoClose: 1000 });
      fetchEventExpenses(event.id);
    } catch (ex) {
    }
  };

  return (
    eventInvoicing.loading 
      ? "Loading"
      : <section className="EventInvoicing form-wrapper container-fluid">
          <div className="row-print">
            <button className="btn btn-print-pdf" type="button" onClick={() => handlePrintOption()}>
              <i className="far fa-file-pdf"></i>EXPORT TO PDF
            </button>
          </div>
          <div className="row">
            <div className="col-sm-4 col-lg-3">
              <EventExpense
                newExpenseDescription={expenseDescription}
                handleChangeNewDescription={handleChangeNewDescription}
                newExpenseAmount={expenseAmount}
                handleChangeNewAmount={handleChangeNewAmount}
                handleAddExpense={handleAddExpense}
              />
            </div>
            <div className="col-sm-8 col-lg-9">
              <div className="row">
                <Table
                  name="EventInvoicingList"
                  columns={columns}
                  data={eventInvoicing.data.rows}
                  sortColumn={sortColumn}
                  onSort={sortColumn => setSortColumn(sortColumn)}
                  isLoading={eventInvoicing.loading}
                />
              </div>
              <div className="row">
                <table className="table EventInvoicingTotal">
                  <tbody>
                    <tr>
                      <td className="created_date"></td>
                      <td className="total_label">Total</td>
                      <td className="total_amount">
                        { (eventInvoicing.data.total_amount) 
                        ? currencyUSD(eventInvoicing.data.total_amount)
                        : '$0' }
                      </td>
                      <td className="delete"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
  );
}

export default EventInvoicing
