import React from 'react';
import Select from "react-select";

export default function FilterBySection(props) {
  const { handleSectionChange } = props;
  const sectionOptions =  [
    { value: 'Staff', label: 'Staff' },
    { value: 'Volunteers', label: 'Volunteers' },
    { value: 'Applicants', label: 'Applicants' },
    { value: 'Customers', label: 'Customers' },
    { value: 'Venues', label: 'Venues' },
    { value: 'Events', label: 'Events' },
    { value: 'Reports', label: 'Reports' },
    { value: 'Logs', label: 'Logs' },
    { value: 'Users', label: 'Users' },
    { value: 'Settings', label: 'Settings' }
  ];

  return (
    <div className="filter-by-section">
      <Select
        isMulti
        name="selectSection"
        placeholder="Filter by Section"
        options={sectionOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleSectionChange}
      />
    </div>
  )
}
