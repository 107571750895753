import React, { useContext, useReducer, useEffect } from 'react'
import { getEventStaffSummary } from '../../../../../services/eventsService';
import { currencyUSD } from '../../../../../utils/value-formats';
import { EventFetchContext } from '../../Event';
import EventsStaffStatsResourcesShared from './EventsStaffStatsResources/EventsStaffStatsResourcesShared';
import EventsStaffStatsResourcesVolunteers from './EventsStaffStatsResources/EventsStaffStatsResourcesVolunteers';
import './EventsStaffStats.scss';

const initialEventStaffStats = {
  loading: false,
  data: null,
  error: null
}

const reducerEventStaffStats = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_STAFF_STATS_FETCH':
      return { ...state, data: action.EVENT_STAFF_STATS_DATA }
    default:
      return state
  }
}

function EventsStaffStats({ event, eventDateSelected }) {
  const eventFetchContext = useContext(EventFetchContext);
  const [eventStaffStats, dispatchEventStaffStats] = useReducer(reducerEventStaffStats, initialEventStaffStats);
  
  const eventStaffStatsItems = [
    { 
      label: "Total Members Staffed",
      content: eventStaffStats.data 
        ? eventStaffStats.data.staff_count + '/' + eventStaffStats.data.staff_total 
        : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      show: true
    },
    { 
      label: "Day Members Staffed",
      content: eventStaffStats.data 
        ? eventStaffStats.data.staff_day_assigned + '/' + eventStaffStats.data.staff_day_total 
        : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      show: true
    },
    { 
      label: "Total Hours",
      content: eventStaffStats.data ? eventStaffStats.data.total_hours : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      show: true
    },
    { 
      label: "Total Cost",
      content: eventStaffStats.data ? currencyUSD(eventStaffStats.data.staff_cost) : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      show: true
    },
    { 
      label: "Target Hourly Rate",
      content: eventStaffStats.data ? currencyUSD(eventStaffStats.data.target_rate) : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      show: true
    },
    { 
      label: "Current Hourly Rate",
      content: eventStaffStats.data ? currencyUSD(eventStaffStats.data.average_rate) : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      icon: eventStaffStats.data && eventStaffStats.data.on_target ? "fas fa-check" : '',
      className: eventStaffStats.data && eventStaffStats.data.on_target ? 'current-hourly-rate on-target' : 'current-hourly-rate ',
      show: true
    },
    { 
      label: "Volunteers Resources",
      show: (eventStaffStats.data) 
      ? (eventStaffStats.data.volunteers_total > 0) 
        ? true : false
      : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      content: eventStaffStats.data
      ? 
        <div className="volunteers">
          { eventStaffStats.data.volunteers_day_assigned }/{ eventStaffStats.data.volunteers_total }
          <EventsStaffStatsResourcesVolunteers data={eventStaffStats.data.volunteers} />
        </div>
      : 
        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    },
    { 
      label: "Shared Resources",
      show: (eventStaffStats.data) 
      ? (eventStaffStats.data.shared) 
        ? true : false
      : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>,
      content: eventStaffStats.data
      ? 
        <div className="shared-resources">
          { eventStaffStats.data.shared_total }
          <EventsStaffStatsResourcesShared data={eventStaffStats.data.departments} />
        </div>
      : 
        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    }
  ];

  const fetchEventStaffStats = async () => {
    try {
      const { data } = await getEventStaffSummary( event.id, eventDateSelected.id );
      dispatchEventStaffStats({ TYPE: 'EVENT_STAFF_STATS_FETCH', EVENT_STAFF_STATS_DATA: data.average });
      return
    } catch (ex) { }
  };

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_STAFF_STATS && fetchEventStaffStats();
    eventFetchContext.fetchData.EVENT_STAFF_STATS && eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: false});
  }, [eventFetchContext.fetchData.EVENT_STAFF_STATS]);

  return (
    <section className="EventsStaffStats">
      <div className="d-flex justify-content-between align-items-center">
      { eventStaffStatsItems.map((item, idx) => {
          const styleClass = item.className && item.className;
          return (
            (item.show) 
            ? <div className="col-1" key={idx}>
                <div className={"EventsStaffStats-item " + styleClass }>
                  <h6 className="EventsStaffStats-item-label">{item.label}</h6>
                  <div className="EventsStaffStats-item-info">
                    {item.content}
                    {item.icon && <React.Fragment>&nbsp;<i className={item.icon}></i></React.Fragment> }
                  </div>
                </div>
              </div>
            : ''
          )})
        }
      </div>
    </section>
  )
}

export default EventsStaffStats
