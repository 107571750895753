import React, { Component } from "react";
import Table from "../../Common/table/table";
import Pagination from '../../Common/pagination/pagination';
import { getStaffRatings } from '../../../services/staffService';
import { getStaffRatingsSummary } from '../../../services/staffService';
import { messages } from "../../../utils/language-en.json";
import EmptyData from '../../Common/emptydata/emptyData.jsx';
import EmptyDataIcon from "../../../assets/cms-icon-empty-files.svg";
import StaffRatingsPrint from '../staff-modals/staffRatingsPrint';
import StaffRatingsSummary from './staffFormRatingsSummary';

class StaffFormRatings extends Component {
  state = { 
    staffRatings: [],
    staffRatingsSummary: [],
    staffRatingsLoading: true,
    currentPage: 1,
    pageSize: 10,
    totalCount: 0,
    sortColumn: { path: "event_date", order: "desc" },
    errors: {}
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) 
      return this.populateStaffRatings();
  }

  async populateStaffRatings() {
    const { data: resStaffRatings } = await getStaffRatings({
      STAFF_ID: this.props.staffId, 
      LIMIT: this.state.pageSize, 
      PAGE: this.state.currentPage,
      ORDER_BY: this.state.sortColumn.path, 
      SORT_ORDER: this.state.sortColumn.order
    });
    this.setState({ 
      staffRatings: resStaffRatings.ratings,
      totalCount: resStaffRatings.count,
      staffRatingsLoading: false 
    });

    const { data: resStaffRatingsSummary } = await getStaffRatingsSummary({
      STAFF_ID: this.props.staffId
    });
    this.setState({ 
      staffRatingsSummary: resStaffRatingsSummary.ratings
    });
  }

  columns = [
    { 
      path: "name", 
      label: "Event Name",
      disableSort: true,
      content: item => (
        <>
          {item.name}&nbsp;&nbsp;
          <a href={'/events/' + item.id} target="_blank" rel="noopener noreferrer"> 
            <i className="fas fa-external-link-alt external-link-alt-custom-xsmall"></i>
          </a>
        </>
      )
    },
    { path: "status", label: "Event Status", disableSort: true },
    { path: "start_date", label: "Event Date", disableSort: true },
    { path: "hours", label: "Hours", disableSort: true },
    { path: "pay_rate", label: "Pay Rate", content: item => '$' + item.pay_rate, disableSort: true },
    { path: "amount", label: "Amount", content: item => '$' + item.amount, disableSort: true },
    { path: "tardy", 
      label: "Tardy",
      disableSort: true,
      content: item => (
      (item.tardy)
        ? <i className="fas fa-check blueIcon"></i>
        : <i className="fas fa-close redIcon"></i>
      )  
    },
    { path: "no_show", 
      label: "No Show",
      disableSort: true,
      content: item => (
      (item.no_show)
        ? <i className="fas fa-check blueIcon"></i>
        : <i className="fas fa-close redIcon"></i>
      )   
    },
    { path: "short_notice", 
      label: "Short Notice",
      disableSort: true,
      content: item => (
      (item.short_notice)
        ? <i className="fas fa-check blueIcon"></i>
        : <i className="fas fa-close redIcon"></i>
      )  
    },
    { path: "cancel", 
      label: "Cancel",
      disableSort: true,
      content: item => (
      (item.cancel)
        ? <i className="fas fa-check blueIcon"></i>
        : <i className="fas fa-close redIcon"></i>
      )  
    },
    { path: "rating", label: "Rating", disableSort: true },
    { path: "note", label: "Notes", disableSort: true }
  ];

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateStaffRatings();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateStaffRatings();
  };

  render() {
    const { 
      staffRatings,
      staffRatingsSummary,
      staffRatingsLoading,
      pageSize,
      currentPage,
      totalCount,
      sortColumn
    } = this.state;

    return (
    <section className="form-wrapper staff-rating container-fluid">
      <StaffRatingsSummary summary={staffRatingsSummary}/>

      { !staffRatingsLoading && staffRatings.length > 0 &&
      <>
        <div className="col-left">
          <StaffRatingsPrint id={this.props.staffId}/>
        </div>
        <Table
          name={ 'ratings' }
          columns={ this.columns }
          data={ staffRatings }
          sortColumn={ sortColumn }
          onSort={ this.handleSort }
        />
      </>
      }
      { !staffRatingsLoading && (
        <Pagination
          itemsCount={ totalCount }
          pageSize={ pageSize }
          currentPage={ currentPage }
          onPageChange={ this.handlePageChange }
        />
      )}

      { staffRatings.length === 0  &&
        <EmptyData 
          type={ 'ratings' } 
          icon={ EmptyDataIcon }
          title={ messages.emptydata.tableTitle }
        />
      }
    </section>
    );
  }
}

export default StaffFormRatings;
