import React, { useContext } from 'react';
import Table from '../../../Common/table/table';
import { EventCheckInContext } from './EventCheckIn';

export const EventCheckInListContext = React.createContext();

function EventCheckInList() {
  const eventCheckInContext = useContext(EventCheckInContext);
  const checkInList = useContext(EventCheckInContext).eventCheckInList;
  const openModalUpdate = useContext(EventCheckInContext).openModalUpdate;

  const columns = [
    {
      path: 'staff_name',
      label: 'Staff Name',
    },
    {
      path: 'position_name',
      label: 'Position'
    },
    {
      path: "volunteer", 
      label: "Volunteer",
      content: item => (
        (item.volunteer)
          ? <i className="fas fa-check blueIcon"></i>
          : <i></i>
      )
    },
    {
      path: 'start_date',
      label: 'Start',
    },
    {
      path: 'end_date',
      label: 'End'
    },
    {
      path: 'shirt_number',
      label: 'Shirt'
    },
    {
      path: 'jacket_number',
      label: 'Jacket'
    },
    {
      path: 'radio_number',
      label: 'Radio'
    },
    {
      path: 'flagger_number',
      label: 'Flagger'
    },
    {
      path: "check_in", 
      label: "In",
      content: item => (
        (item.check_in)
          ? <i className="fas fa-check blueIcon"></i>
          : <button disabled={item.reconciled} className="btn btn-primary mt-3" 
              onClick={() => openModalUpdate({...item, 
                checkAction : 'Check In', 
                buttonType: 'success',
                field: 'start_date'
              })}
            >Check In</button>
      )
    },
    {
      path: "check_out", 
      label: "Out",
      content: item => (
        (item.check_out)
          ? <i className="fas fa-check blueIcon"></i>
          : (item.check_in)
            ? <button disabled={item.reconciled} className="btn btn-terciary mt-3" 
              onClick={() => openModalUpdate({...item, 
                checkAction : 'Check Out', 
                buttonType: 'terciary',
                field: 'end_date'
              })}
            >Check Out</button>
            : <i className="fas fa-close redIcon"></i>
      )
    }
  ];

  return (
    <EventCheckInListContext.Provider >
      {!checkInList.loading &&
        <Table
          name="CheckInList"
          columns={columns}
          data={checkInList.data}
          sortColumn={eventCheckInContext.sortColumn}
          onSort={sortColumn => eventCheckInContext.dispatchSortColumn({ TYPE: 'SORT_COLUMN', VALUE: sortColumn })}
          isLoading={checkInList.loading}
        />
      }
    </EventCheckInListContext.Provider>
  )
}

export default EventCheckInList
