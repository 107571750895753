import React, { useContext } from 'react';
import { EventStaffAssignedContext } from '../EventStaffAssigned';


function EventStaffAssignedToolsSelect() {
  
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);

  return (
    <button type="button" className="btn btn-toolsbar-item btn-sm" 
      onClick={() => eventStaffAssignedContext.handleSelectAssignedStaff()}
    >
      { !eventStaffAssignedContext.eventStaffAssignedList.selected ? 'Select All' : 'Deselect All' }
    </button>
  )
}

export default EventStaffAssignedToolsSelect
