import React, { useContext } from 'react'
import { EventReconciliationContext } from './EventReconciliation';

function EventsReconciliationSummary() {
  const reconciliationList = useContext(EventReconciliationContext).eventReconciliationList;

  const eventReconciliationSummaryItems = [
    { 
      label: "Selected Day",
      content: reconciliationList.summaryData ? reconciliationList.summaryData[0].staff_day_reconciled + '/' + reconciliationList.summaryData[0].staff_day_total : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    },
    { 
      label: "Event",
      content: reconciliationList.summaryData ? reconciliationList.summaryData[0].staff_event_reconciled + '/' + reconciliationList.summaryData[0].staff_event_total : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    }
  ];

  return (
    <section className="EventsCheckInSummary">
      <div className="d-flex justify-content-between align-items-center">
      { eventReconciliationSummaryItems.map((item, idx) => {
        return (
          <div className="col-5" key={idx}>
            <div className="EventsCheckInSummary-item">
              <h6 className="EventsCheckInSummary-item-label">{item.label}</h6>
              <div className="EventsCheckInSummary-item-info">{item.content}</div>
            </div>
          </div>
        )})
      }
      </div>
    </section>
  )
}

export default EventsReconciliationSummary
