import React, { Component } from "react";
import '../../src/Public.scss';
import logo from "../assets/cms-logo-login.svg";
import { toast } from "react-toastify";
import { getEventNotificationData, updateEventNotification } from "../services/eventsService";

class Notification extends Component {
  state = {
    eventNotificationId: null,
    validNotification: true,
    staffName: null,
    eventName: null,
    eventDate: null,
    eventTimeRange: null,
    eventDateRange: null,
    adminName: null,
    adminEmail: null,
    defaultPhone: null,
    defaultPhoneFormated: null,
    sentResponse: false,
    responseMessage: ''
  };

  async componentDidMount() {
    await this.getNotificationData(this.props.match.params.token, this.props.match.params.source);
  }

  async getNotificationData(token, source) {
    const { data: resNotification } = await getEventNotificationData(token);
    const notification = resNotification.notification;
    if (!notification) {
      this.setState({ validNotification: false });
      return
    }
    
    this.setState({ eventNotificationId: notification.event_notification_id });
    this.setState({ staffName: notification.staff_first_name + ' ' + notification.staff_last_name });
    this.setState({ eventName: notification.name });
    this.setState({ eventDateRange: notification.date_range });
    this.setState({ adminName: notification.admin_name });
    this.setState({ adminEmail: notification.admin_email });
    this.setState({ defaultPhone: notification.default_phone });
    this.setState({ defaultPhoneFormated: notification.default_phone_formated });
    this.setState({ source: source });
  }

  doSubmit = async (status) => {
    try {
      const { data } = await updateEventNotification(this.state.eventNotificationId, status, this.state.source);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }

      let responseMessage = '<h1>Thank you!</h1>' +
        '<p>Your confirmation has been sent.</p>' +
        '<p>Your interest in this event has been sent to the manager.</p>' +
        '<h1>PLEASE NOTE: <br />' +
        'THIS IS NOT CONFIRMATION THAT YOU ARE SCHEDULED.<br/>' +
        'YOU WILL RECEIVE A SEPARATE CONFIRMATION WITH SHIFT<br/>' +
        'ASSIGNMENT BASED ON EVENT AVAILABILITY.</h1>';

      if (status === 'Rejected') {
        responseMessage = '<h1>Thank you for your response</h1>' + 
          '<p>We understand you are not interested in attending this event.</p>'+
          '<p>Should anything change, please contact your manager.</p>';
      }
        
      this.setState({ sentResponse: true });
      this.setState({ responseMessage: responseMessage });
    } catch (ex) {
    }
    return;
  };

  render() {
    const {
      validNotification,
      staffName,
      eventName,
      eventDateRange,
      adminName,
      adminEmail,
      defaultPhone,
      defaultPhoneFormated,
      sentResponse,
      responseMessage
    } = this.state;

    return (
      <div className="public">
        <div className="main">
          <div className="header">
            <img src={logo} width="146" height="71" alt={ process.env.REACT_APP_NAME } />
          </div>
          <div className="content">
            {!validNotification
            ? <div className="message">
                <div className="content-center">
                  <h1>Error</h1>
                  <p>This invitation is expired.</p>
                </div>
              </div>
            : (sentResponse) 
              ?
              <div className="message">
                <div className="content-center" dangerouslySetInnerHTML={{ __html: responseMessage }}>
                </div>
              </div>
              :
              <div className="message">
                <div className="content-center">
                  <h2>Hi {staffName}</h2>
                  <p>We have the following event:</p>
                  <h1>{eventName}</h1>
                  <p>
                      {eventDateRange}<br />
                      Position call times may vary.
                  </p>
                
                  <p>Would you like to join our staff for this event?</p>
                  <div className="buttons">
                    <button className="btn btn-secondary" type="button" onClick={() => this.doSubmit('Rejected')}>
                      No Thanks
                    </button>
                    <button className="btn btn-primary" type="button" onClick={() => this.doSubmit('Accepted')}>
                      Yes, I'm interested
                    </button>
                  </div>

                  <p>&nbsp;</p>
                  <p>By clicking "YES" your manager will receive a notification <br />that shows your interest to participate in this event.</p>

                  <p>For further information, please contact {adminName} <br />
                    by <a href={'mailto:' + adminEmail}>email</a>
                    
                    {defaultPhone
                    ? <span>
                        &nbsp;or by phone <a href={'tel:+' + defaultPhone}>{defaultPhoneFormated}</a>.
                      </span>
                    : '.'
                    }
                  </p>
                </div>
              </div>
            }

          <p className="disclaimer">
              Crowd Management Services © 2022 - All rights reserved.<br />
              <a href="https://www.cmsprotectsfun.com/terms-privacy-policy/" rel="noopener noreferrer" target="_blank">Terms & Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Notification
