import React, { useEffect, useState } from 'react';
import { getStaffInvalidPaycom } from "../../../services/widgetsService";
 
function InvalidStaffDataWidget() {
  const [staff, setStaff] = useState([]);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const getInvalidStaffData = async () => {
    const { data } = await getStaffInvalidPaycom();
    setStaff(data.staff);
    setTitle(data.title);
    setText(data.text);
  }

  useEffect(() => {
    getInvalidStaffData();
  }, []);

  return (
    <div>
      { staff.length === 0
        ? ''
        : <div className="col-auto">    
          <div className='DashboardList-item btn d-flex flex-column'>
            <h4><i className="fas fa-user-slash"></i>&nbsp;{title}</h4>
            <p>{text}</p>
            <ul>
            { staff.map( (item, idx) => (
                <li key={idx}><a href={"staff/" + item.id}>{item.first_name} {item.last_name} - {item.department}</a></li>
              ) )
            }
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default InvalidStaffDataWidget;