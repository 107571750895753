import React, { useEffect, useState } from 'react';
import { getActiveMessages } from "../../../services/messagesService";

function SystemMessagesWidget() {
  const [messages, setMessages] = useState([]);

  const getAllMessages = async () => {
    const { data } = await getActiveMessages();
    setMessages(data.messages);
  }

  function createContent(content) {
    return {__html: content};
  }

  useEffect(() => {
    getAllMessages();
  }, []);

  return (
    <div>
    { messages.length === 0
      ? ''
      : <div className="col-auto">    
        { messages.map( (item, idx) => (
          <div key={idx} className='DashboardList-item btn d-flex flex-column systemItem' style={{backgroundColor: item.background_color, color: item.text_color}}>
            <h4><i className={ item.title.includes('Features') ? 'far fa-envelope' : 'fas fa-exclamation-triangle' }></i>&nbsp;{item.title}</h4>
            <div className="message" key={idx}>
              <h6>{item.created_date}</h6>
              <div dangerouslySetInnerHTML={createContent(item.content)} />
            </div>
          </div>
          ) )
        }
      </div>
    }
    </div>
  )
}

export default SystemMessagesWidget;