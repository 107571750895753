

export const weekDaysSelectOptions = [
  {id:"" , name:" " },
  {id:"Sunday" , name:" Sunday" },
  {id:"Monday" , name:" Monday" },
  {id:"Tuesday" , name:"Tuesday" },
  {id:"Wednesday" , name:" Wednesday" },
  {id:"Thursday" , name:" Thursday" },
  {id:"Friday" , name:" Friday" },
  {id:"Saturday" , name:" Saturday" },
];

export const DepartmentDataModel = {
  name: "",
  office_phone: "",
  company_code: "",
  state: "",
  division: "",
  city_code: "",
  target_hourly_rate: 0
}

