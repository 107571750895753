import React from 'react';
import Select from "react-select";

export default function FilterByUser(props) {
  const { users, handleUserChange } = props;
  const userOptions =  [];

  users.forEach((element) => {
    userOptions.push({
      value: element.id,
      label: element.first_name + ' ' + element.last_name
    })
  });

  return (
    <div className="filter-by-status">
      <Select
        isMulti
        name="selectUser"
        placeholder="Filter by User"
        options={userOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleUserChange}
      />
    </div>
  )
}
