import React from 'react';
import './EventInfoSummary.scss'

function EventInfoSummary({ event }) {

  return (
    <section className="EventInfoSummary">
      <h1 className="name"><i aria-hidden="true" className="far fa-calendar-alt"></i>&nbsp;{event.name}</h1>
      <p className="info">
        <span>{event.department}</span>
        &nbsp;–&nbsp;
        <span>{event.customer}</span>
        &nbsp;–&nbsp;
        <span>{event.venue}</span>
      </p>
    </section>
  )
}

export default EventInfoSummary
