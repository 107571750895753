import React, { useContext, useState } from 'react';
import { toast } from "react-toastify";
import { deleteBulkEventPositions } from '../../../../../../services/eventsService';
import DeleteConfirmation from '../../../../../Common/delete-confirmation/deleteConfirmation';
import { EventFetchContext } from '../../../Event';
import { EventStaffContext } from '../../EventStaff';
import { EventStaffAssignedContext } from '../EventStaffAssigned';

function EventStaffAssignedToolDelete() {
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  // ** HANDLE
  // *********************************************************

  const handleDeleteAssignedStaff = async () => {
    setDeleteConfirmation(false);
    const buildDeleteList = eventStaffAssignedContext.eventStaffAssignedList.selected.map(item => {
      return {
        staff_event_id: item.staff_event_id, 
        staff_id: item.staff_id
      }
    });
    const buildDeleteBulk = {
      staff: [...buildDeleteList],
      events_position_conf_id: eventStaffContext.position.selected.position_conf_id
    };
    try {
      const { data } = await deleteBulkEventPositions(buildDeleteBulk);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: null });
      setTimeout(() => {
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_CHECKIN', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
        toast.success('Position Deleted!', { autoClose: 1000 });
      }, 300);
    } catch (error) {
      
    }
  }

  return (
    <React.Fragment>
      <button type="button" className="btn btn-toolsbar-item btn-sm" 
        disabled={!eventStaffAssignedContext.eventStaffAssignedList.selected}
        onClick={() => setDeleteConfirmation(true)}
      >Remove</button>
      <DeleteConfirmation
        isOpen={deleteConfirmation}
        closeModal={() => setDeleteConfirmation(false)}
        itemName={eventStaffAssignedContext.eventStaffAssignedList.selected 
                  ? eventStaffAssignedContext.eventStaffAssignedList.selected.length > 1 
                    ? `${eventStaffAssignedContext.eventStaffAssignedList.selected.length} items` 
                    : `${eventStaffAssignedContext.eventStaffAssignedList.selected.length} item` 
                  : '' }
        onDelete={handleDeleteAssignedStaff}
      />
    </React.Fragment>
  );
}

export default EventStaffAssignedToolDelete
