import React, { useContext, useState } from 'react';
import { Modal , Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { saveBulkGroup } from '../../../../../../services/eventsService';
import { EventStaffAssignedContext } from '../EventStaffAssigned';

function EventStaffAssignedToolBulkGroup() {

  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);
  const [bulkGroup, setBulkGroup] = useState(false);
  const [bulkGroupSelected, setBulkGroupSelected] = useState(null);
  const groupOptionsBuild = [
    { value: 1 , label: 1  },
    { value: 2 , label: 2  },
    { value: 3 , label: 3  },
    { value: 4 , label: 4  },
    { value: 5 , label: 5  },
    { value: 6 , label: 6  },
    { value: 7 , label: 7  },
    { value: 8 , label: 8  },
    { value: 9 , label: 9  },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 }
  ];

  // ** HANDLE
  // *********************************************************

  const handleSaveBulkGroup = async () => {

    const buildStaffList = eventStaffAssignedContext.eventStaffAssignedList.selected.map(item => {
      return {staff_event_id: item.staff_event_id}
    });
    const buildDataToSave = {
      staff: [...buildStaffList],
      group: bulkGroupSelected.value
    }

    setBulkGroup(false);

    try {
      const { data } = await saveBulkGroup(buildDataToSave);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success('Group adjusted!', { autoClose: 1000 });
      const updateAssignedList = eventStaffAssignedContext.eventStaffAssignedList.data;
      const list = buildDataToSave.staff;
      for (let i = 0; i < list.length; i++) {
        const member = list[i];
        updateAssignedList.forEach((element, index) => {
          if (element.staff_event_id === member.staff_event_id) {
            updateAssignedList[index] = {...element, group: buildDataToSave.group, rowSelected: false};
          };
        });
      }
      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: updateAssignedList });
      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: null });
      setBulkGroupSelected(null);

    } catch (error) { }

  };

  return (
    <React.Fragment>
      <button type="button" className="btn btn-toolsbar-item btn-sm" 
        disabled={!eventStaffAssignedContext.eventStaffAssignedList.selected}
        onClick={() => setBulkGroup(true)}
      >Group</button>

      <Modal show={bulkGroup} onHide={() => setBulkGroup(false)} className="modal-bulk-group">

        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-sync-alt"></i>&nbsp;Group Options
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-3 justify-content-center">
            <div className="col-sm-9">
              <Select
                className="select-style-default"
                options={groupOptionsBuild}
                placeholder="Change selected shifts to group:"
                onChange={selected => setBulkGroupSelected(selected)}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <p className="disclaimer text-center">
                This adjustment will change all the selected employees
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setBulkGroup(false)}>Cancel</Button>
          <Button variant="success" 
            disabled={!bulkGroupSelected} 
            onClick={() => handleSaveBulkGroup()} 
          >Assign Group</Button>
        </Modal.Footer>

      </Modal>
    </React.Fragment>
  )
}

export default EventStaffAssignedToolBulkGroup
