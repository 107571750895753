import React, { useContext } from 'react';
import { ReportsContext } from '../Reports';

function ReportsList() {
  const reportsContext = useContext(ReportsContext);
  const reports = reportsContext.reports;
  const props = reportsContext.props;

  return (
    <section className="ReportsList">
      <div className="row justify-content-center">
        <div className="col-sm-12">
          <div className="ReportsList-header">
            <h2>Create a New Report</h2>
            <p>Please select the report type you want to create</p>
          </div>
          <div className="ReportsList-wrapper">
            <div className="row justify-content-center">
              { reports && reports.map( (item, idx) => {
                return (
                  <div className="col-auto" key={idx}>
                    <button className='ReportsList-item btn d-flex flex-column align-items-center justify-content-center' 
                      type="button"
                      onClick={ () => props.history.push("/reports/" + item.name.replace(/\s+/g, '-').toLowerCase())}
                    >
                      <i className={item.icon}></i>
                      <span>{item.name}</span>
                    </button>
                  </div>
                )})
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReportsList
