import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import defaultAvatar from "../../assets/staffAvatar-placeholder.svg";
import { Link } from "react-router-dom";

class StaffList extends Component {
  columns = [
    {
      path: "thumbnail",
      content: staffItem => (
        <img className="img-fluid" src={(staffItem.thumbnail) ? staffItem.thumbnail : defaultAvatar } alt={ staffItem.first_name + ' ' + staffItem.last_name } />
      )
    },
    { path: "last_name", label: "Last Name" },
    { path: "first_name", label: "First Name" },
    { path: "middle_name", label: "Middle Name" },
    { path: "department", label: "Department", disableSort: true },
    { path: "home_phone", label: "Home Phone" },
    { path: "paycom_id", label: "Paycom ID" },
    { path: "last_updated", label: "Paycom Last Update" },
    { path: "rating", label: "Avg Rating" },
    { path: "working_status", 
      label: "Working Status",
      content: staffItem => (
        (staffItem.working_status.toLowerCase() === "active")
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    }
  ];

  deleteColumn = {
    key: "delete",
    path: "delete",
    disableSort: true,
    content: staffItem => (
      <button
        onClick={() => this.props.onDelete(staffItem)}
        className="btn btn-danger btn-sm btn-delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: staffItem => (
      <Link to={`/staff/${staffItem.id}`} className="btn btn-sm btn-edit" target="_blank">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    const authPermissions = auth.getCurrentUserPermisions();
    if (user) this.columns.push(this.editColumn);
    if (user && authPermissions["module_staff_delete"]) this.columns.push(this.deleteColumn);
  }

  render() {

    const { staff, onSort, sortColumn, isLoading, overLoading } = this.props;

    return (
      <Table
        name={'staff'}
        columns={this.columns}
        data={staff}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
        overLoading={overLoading}
      />
    );
  }
}

export default StaffList;
