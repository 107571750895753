import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

export function customerDataModel() {
  return {
    id: null,
    name: "",
    department_id: "",
    contact: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip_code: "",
    phone_1: "",
    phone_2: "",
    fax: "",
    email: "",
    note: "",
    customer_id: "",
    past_due_check: false,
    past_due_amount: '',
    bad_checks: "",
    red_flag: false,
  }
}

export function customersSchema(isEditing) {
  return {
    id: Joi.number()
      .integer().allow(null, ''),
    customer_id: Joi.string()
      .allow(null, '')
      .label("Customer Id"),
    name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Customer Name"),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Department"),
    contact: Joi.string()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Contact"),
    email: Joi.string()
      .email()
      .required()
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.email":
              errorMessage = {message: messages.error.mailInvalid }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Email"),
    address_1: Joi.string()
      .required()
      .label("Address"),
    address_2: Joi.string()
      .allow([ null, '' ])
      .label("Address 2"),
    city: Joi.string()
      .required()
      .label("City"),
    state: Joi.string()
      .required()
      .label("State"),
    zip_code: Joi.string()
      .allow(null, '')
      .regex(/^\d{5}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          if (element.type === "string.regex.base") {
            errorMessage = {message: messages.error.zipcodeInvalid }
          }
          return errorMessage;
        }
      })
      .label("Zipcode"),
    phone_1: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Phone 1"),
    phone_2: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Phone 2"),
    fax: Joi.string()
      .allow(null, '')
      .regex(/^([(])?\d{3}([)])?(-|\s)?\d{3}(-|\s)?\d{4}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.regex.base":
              errorMessage = {message: messages.error.phoneInvalidRegex }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Fax"),
    past_due_check: Joi.boolean()
      .allow(null, ''),
    past_due_amount: Joi.number()
      .integer()
      .allow(null, ''),
    red_flag: Joi.boolean()
      .allow(null, ''),
    note: Joi.string()
      .allow(null, ''),
    bad_checks: Joi.string()
      .allow(null, ''),
  }
}

export function customerEditMapToViewModel(item,) {
  return {
    // active: item.active,
    address_1: (item.address_1) ? item.address_1 : "" ,
    address_2: (item.address_2) ? item.address_2 : "",
    bad_checks: (item.bad_checks) ? item.bad_checks : "",
    city: (item.city) ? item.city : "",
    contact: (item.contact) ? item.contact : "",
    customer_id: (item.customer_id) ? item.customer_id : "",
    department_id: (item.department_id) ? item.department_id : "",    
    email: (item.email) ? item.email : "",
    fax: (item.fax) ? item.fax : "",
    id: item.id,
    name: (item.name) ? item.name : "",
    note: (item.note) ? item.note : "",
    past_due_amount: (item.past_due_amount) ? item.past_due_amount : "",
    past_due_check: (item.past_due_check) ? item.past_due_check : false,
    phone_1: (item.phone_1) ? item.phone_1 : "",
    phone_2: (item.phone_2) ? item.phone_2 : "",
    red_flag: (item.red_flag) ? item.red_flag : false,
    state: (item.state) ? item.state : "",
    zip_code: (item.zip_code) ? item.zip_code : "",
  }
}

export default {
  customersSchema,
  customerEditMapToViewModel
}