import React from "react";
import DatePicker from "react-datepicker";
import "./form.scss";

const Datepicker = ({ className, name, label, error, required, selected, ...rest }) => {

  className = className || 'form-control datepicker'
  return (
    <div className={ error ? 'form-group has-error' : 'form-group'}>
      <div className="d-flex flex-column-reverse">
        <DatePicker
          className={className}
          selected={selected}
          name={name}
          autoComplete="off"
          {...rest}
        />
        { label && (
          <label className={ (required) ? 'required' : '' } htmlFor={name}>{label}</label>
        )}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Datepicker;
