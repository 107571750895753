import React, { Component } from 'react';
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import  { messages } from "../../../utils/language-en.json";
import { getStaffAvailability, updateStaffAvailabilty } from '../../../services/availabilityService';

class StaffFormAvailability extends Component {
  state = { 
    staffAvailability: [],
    staffAvailabilityLoading: true,
    errors: {}
  }
  
  componentDidMount() {  
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) 
      return this.populateStaffAvailability();
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  async populateStaffAvailability() {
    const { data: resStaffAvailability } = await getStaffAvailability(this.props.staffId) ;
    const availability = resStaffAvailability.availability;
    this.setState({ staffAvailability: availability, staffAvailabilityLoading: false });
  }

  availabilityHoursHeader = () => [
    "0:00",
    "1:00",
    "2:00",
    "3:00",
    "4:00",
    "5:00",
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00"
  ];

  handleUpdateAvailabilityHour = async (hourId, checked) => {
    checked = (checked) ? false : true ;
    const { data } = await updateStaffAvailabilty(hourId, checked);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.staffAvailabilityUpdated, { autoClose: 1000 });
    this.populateStaffAvailability();
  }

  handleUpdateAvailabilityAllDay = async (day) => {
    let allChecked = true;
    const hourItem = this.state.staffAvailability.find( (item) => item.day === day);
    allChecked = hourItem.hours.filter(item => item.checked === false).length === 0;
    const staffAvailabilityList = [...this.state.staffAvailability]
    const index = staffAvailabilityList.findIndex( (item) => item.day === day);
    staffAvailabilityList[index] = { ...staffAvailabilityList[index] };
    for (let i = 0; i < staffAvailabilityList[index].hours.length; i++) {
      const element = staffAvailabilityList[index].hours[i];
      element.checked = !allChecked
      updateStaffAvailabilty(element.id, !allChecked)
    }
    this.setState({ staffAvailability: staffAvailabilityList });
    toast.success(messages.success.staffAvailabilityUpdated, { autoClose: 1000 });

  }

  handleUpdateAvailabilityAllHour = async (hour) => {
    let allChecked = true;
    const staffAvailabilityList = [...this.state.staffAvailability]
    for (let i = 0; i < staffAvailabilityList.length; i++) {
      const day = staffAvailabilityList[i];
      if (day.hours.filter(p => p.hour === hour && p.checked === false).length > 0) {
        allChecked = false;
      }
    }
    for (let i = 0; i < staffAvailabilityList.length; i++) {
      const day = staffAvailabilityList[i]
      const hoursMatch = day.hours.find(p => p.hour === hour);
      hoursMatch.checked = !allChecked;
      updateStaffAvailabilty(hoursMatch.id, !allChecked)
    }
    this.setState({ staffAvailability: staffAvailabilityList });
    toast.success(messages.success.staffAvailabilityUpdated, { autoClose: 1000 });

  }

  render() {

    const { 
      staffAvailability,
      staffAvailabilityLoading
    } = this.state;

    return (
      <section className="form-wrapper staff-availability-form container-fluid">
        <table className="table table-sm">
          <thead>
            <tr>
              <th className="hour-head-icon">
                <i className="far fa-clock"></i>
              </th>
              { this.availabilityHoursHeader().map( (item, idx) => {
                return (
                  <th key={idx} className="hour-head" onClick={() => this.handleUpdateAvailabilityAllHour(item)}>{item}</th>
                )
              })}
            </tr>
          </thead>
          <tbody>
              { !staffAvailabilityLoading && staffAvailability.map( (item, idx) => {
                return (
                  <tr key={idx}>
                    <td className="day" onClick={() => this.handleUpdateAvailabilityAllDay(item.day)}>{item.day}</td>
                    {item.hours.map( (element) => {
                      return (
                        <td className="hour-availability" 
                          key={element.id} 
                          onClick={() =>  this.handleUpdateAvailabilityHour(element.id, element.checked)} 
                        >
                        { element.checked 
                          ? <i className="fas fa-check"></i>
                          : <i className="fas fa-circle"></i>
                        }
                        </td>
                      )
                    }) }
                  </tr>
                )
              })}
            </tbody>
        </table>
      </section>
    );
  }
}

StaffFormAvailability.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default StaffFormAvailability;
