import React from 'react';
import Joi from "joi-browser";
import { toast } from 'react-toastify';
import { messages } from '../../utils/language-en.json';
import RedirectToLogin from '../../utils/RedirectToLogin.jsx';
import { getCurrentUser, refreshToken } from '../../services/authService.js';
import Form from '../Common/form/form';
import Loading from '../Common/loading/loading';
import { usersSchemaEditProfile, userEditProfileMapToViewModel } from '../../models/usersSchema';
import { updateUserProfileMiddleware } from '../../services/usersService';

class ProfileForm extends Form {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      userData: null,
      errors: {}
    }
    this.schema = usersSchemaEditProfile();
  }

  async populateUser() {
    try {
      this.setState({ dataLoading: true });
      const user = getCurrentUser();
      this.setState({ data: userEditProfileMapToViewModel(user) });
      this.setState({ userData: user });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    this.schema = usersSchemaEditProfile();
    await this.populateUser();
    this.setState({ loading: false });
  }

  validateUserInfo = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    const hasErrors = (Object.keys(this.state.errors).length > 0) ? true : false ;
    if (!error && !hasErrors)
      return false
    return true;
  }

  doHandleBlur = input => {
    
  }

  doHandleChange = input => {
    return input.value;
  }

  doSubmit = async () => {
    try {
      const response = await updateUserProfileMiddleware(this.state.data);
      if (!response.success) {
        toast.error(response.message);
        return;
      } else {
        refreshToken();
        toast.success(messages.success.profileUpdated, { autoClose: 1000 });
        this.props.history.push("/");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (ex) {
    }
  };

  render() {
    const { loading, userData } = this.state;

    return (
      !getCurrentUser()
        ? <RedirectToLogin location={this.props.location} />
        : loading 
          ? <Loading type="page" />
          : <main className="EventCreate page-wrapper container-fluid">
              <section className="page-header">
                <div className="row justify-content-between">
                  <div className="col">
                    <h1 className="page-title"><i aria-hidden="true" className="fas fa-id-card"></i>&nbsp;My Profile</h1>
                  </div>
                </div>
              </section>
              <section className="page-body">
                <section className="EventInfoForm form-wrapper container-fluid">
                  <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="form-body">
                      <div className="row justify-content-around align-items-start">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <h3 className="form-group-label">Personal Info</h3>
                            </div>
                            <div className="col-4">
                              <div className="avatar-upload d-flex flex-column align-items-center">
                                <div className="avatar-wrapper">
                                  <img className="avatar img-fluid" src={userData.avatar} alt="Staff Avatar"/>
                                </div>
                              </div>
                            </div>
                            <div className="col-8">
                              <div className="row">
                                <div className="col-6">  
                                  {this.renderInput("first_name", "First Name", "text", true )}
                                </div>
                                <div className="col-6">
                                  {this.renderInput("last_name", "Last Name", "text", true )}
                                </div>
                                <div className="col-6">
                                  <label>Email</label>
                                  <p>{userData.email}</p>
                                </div>
                                <div className="col-6">
                                  <label>Role</label>
                                  <p>{userData.role}</p>
                                </div>
                                <div className="col-6">
                                  <label>Department</label>
                                  <h6>{userData.department_name}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <h3 className="form-group-label">Password</h3>
                              <p>To change your password, enter your current password and your new password.<br/>
                                If you leave these fields blank, the password won't change.
                              </p>
                            </div>
                            <div className="col-6">
                              {this.renderInput("password", "Current Password", "password", false )}
                            </div>
                            <div className="col-6">
                            {this.renderInput("new_password", "New Password", "password", false )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-footer">
                      <div className="row justify-content-around align-items-center">
                        <div className="col-5"></div>
                        <div className="col-6 text-right">
                          <button disabled={this.validateUserInfo() } className="btn btn-primary" type="submit">
                            Save Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </section>
            </main>
    )
  }
}

export default ProfileForm
