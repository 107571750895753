import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

export function usersDataModel() {
  return {
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role_id: "",
    avatar: "",
    active: true
  }
}

export function usersSchema(isEditing) {
  return {
    id: Joi.number()
      .integer().allow(null, ''),
    first_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("First Name"),
    last_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Last Name"),
    email: Joi.string()
      .email()
      .required()
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          switch (element.type) {
            case "string.email":
              errorMessage = {message: messages.error.mailInvalid }
              break;
            default:
              errorMessage = {message: element.flags.label + ' ' +  messages.error.isRequired}
              break;
          }
          return errorMessage;
        }
      })
      .label("Email"),
    password: (!isEditing)
      ? Joi.string()
        .required()
        .label("Password")
      : Joi.string().allow(null, ''),
    role_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Role"),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .allow(null, '')
      .label("Department"),
    avatar: Joi.string().allow([ null, '' ]),
    active: Joi.boolean()
      .allow(null, '')
      .label("Active"),
  }
}

export function userEditMapToViewModel(item) {
  return {
    id: item.id,
    first_name: (item.first_name) ? item.first_name : "",
    last_name: (item.last_name) ? item.last_name : "",
    email: (item.email) ? item.email : "",
    password: "",
    role_id: item.role_id,
    avatar: (item.avatar) ? item.avatar : "",
    active: (item.active) ? item.active : false,
    department_id: item.department_id
  }
}


export function userEditProfileMapToViewModel(item) {
  return {
    first_name: (item.firstName) ? item.firstName : "",
    last_name: (item.lastName) ? item.lastName : "",
    avatar: (item.avatar) ? item.avatar : "",
    password: "",
    new_password: ""
  }
}

export function usersSchemaEditProfile() {
  return {
    first_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("First Name"),
    last_name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Last Name"),
    password: Joi.string()
      .allow(null, ''),
    new_password: Joi.string()
      .allow(null, ''),
    avatar: Joi.string().allow([ null, '' ]),
  }
}

export default {
  usersDataModel,
  usersSchema,
  userEditMapToViewModel,
  usersSchemaEditProfile,
  userEditProfileMapToViewModel
}