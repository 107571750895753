import http from "./httpService";

const apiEndpoint = "/staff";

export function getStaff({DEPARTMENT_ID = [], WORKING_STATUS = 'Active', LIMIT = 1000, PAGE = 1, ORDER_BY = ['last_name'], SORT_ORDER = "ASC", SEARCH}) {
  return http.get(apiEndpoint + '/list', {
    params: {
      department_id: DEPARTMENT_ID,
      working_status: WORKING_STATUS,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  } );
}

export function getStaffItem(staffId) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: staffId 
    }
  });
}

export function getStaffPreferences(token) {
  return http.get(apiEndpoint + '/get_preferences_id', { 
    params: {
      token: token 
    }
  });
}

export function getStaffRatings({STAFF_ID = null, LIMIT = 1000, PAGE = 1, ORDER_BY = ['event_date'], SORT_ORDER = "DESC"}) {
  return http.get(apiEndpoint + '/list_ratings', { 
    params: {
      id: STAFF_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getStaffRatingsReport({STAFF_ID = null, START_DATE = null, END_DATE = null}) {
  return http.get(apiEndpoint + '/list_ratings_report', { 
    params: {
      id: STAFF_ID,
      start_date: START_DATE, 
      end_date: END_DATE
    }
  });
}

export function getStaffRatingsSummary({STAFF_ID = null}) {
  return http.get(apiEndpoint + '/list_ratings_summary', { 
    params: {
      id: STAFF_ID
    }
  });
}

export function getStaffNotifications({STAFF_ID = null, LIMIT = 1000, PAGE = 1, ORDER_BY = ['name'], SORT_ORDER = "DESC"}) {
  return http.get(apiEndpoint + '/list_notifications', { 
    params: {
      id: STAFF_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER
    }
  });
}

export function getStaffNotificationsReport(staffId) {
  return http.get(apiEndpoint + '/list_notifications_report', { 
    params: {
      id: staffId
    }
  });
}

export function updateWorkingStatus(staffId, workinStatus) {
  return http.post(apiEndpoint + '/status_update', { id: staffId,  working_status: workinStatus });
}

export function updateStaffPreferences(staffId, email_opt_in, sms_opt_in) {
  return http.post(apiEndpoint + '/preferences_update', { 
    id: staffId, 
    email_opt_in: email_opt_in, 
    sms_opt_in: sms_opt_in 
  });
}

export function saveStaff(staffItem) {
  if (staffItem.id) {
    const body = { ...staffItem };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', staffItem);
}

export function deleteStaff(staffId) {
  return http.post( apiEndpoint + '/delete', { id: staffId } );
}

export function validateEmail(staffEmail) {
  return http.get( apiEndpoint + '/email_validation', { 
    params: {
      email: staffEmail 
    }
  });
}

export function getStaffPositions(departmentId, staffId) {
  return http.get(apiEndpoint + '/staff_positions_get', { 
    params: {
      department_id: departmentId, 
      staff_id: staffId 
    } 
  });
}

export function getStaffPayRates(departmentId) {
  return http.get(apiEndpoint + '/list_staff_pay_rate', { 
    params: {
      department_id: departmentId
    } 
  });
}

export function addStaffPositions(staffId, positionId, regularPayRate, travelPayRate) {
  return http.post(apiEndpoint + '/staff_positions_add', { 
    staff_id: staffId, 
    position_id: positionId, 
    regular_pay_rate: Number(regularPayRate),
    travel_pay_rate: Number(travelPayRate)
  });
}

export function updateStaffPositions( positionItemId, regularPayRate, travelPayRate) {
  return http.post(apiEndpoint + '/staff_positions_update', { 
    id: positionItemId, 
    regular_pay_rate: regularPayRate,
    travel_pay_rate: travelPayRate
  });
}

export function deleteStaffPositions( positionItemId) {
  return http.post(apiEndpoint + '/staff_positions_delete', { id: positionItemId });
}

export function syncStaffPaycom() {
  return http.post(apiEndpoint + '/sync_paycom' );
}

export async function syncStaffPaycomMiddleware() {
  try {
    const { data } = await syncStaffPaycom();
    if (!data.success) {
      return { success: false, message: data.message }
    }
    return { success: true, message: data.summary }
  } catch (ex) {
    if (ex.response && ex.response.status === 404)
      return { success: false, message: ex.message }
  }
}