import React from 'react';
import Input from '../../../../Common/form/input';
import auth from '../../../../../services/authService';

const VenueConfigurations = ({
    newConfigurationName, 
    handleChangeNewName, 
    handleAddConfiguration, 
    configrationList,
    openModalDeleteConfirmation,
    configurationSelected,
    handleSelectConfiguration
  }) => {
  return (
    <div className="card configurations-list-card">
      <div className="add-configuration">
        <div className="card-title">
          Create New Configuration
        </div>
        <div className="row">
          <div className="col-9">
            <Input 
              type="text"
              placeholder={"Configuration Name*"}
              name={'newConfiguration'}
              value={newConfigurationName}
              onChange={handleChangeNewName}
            />
          </div>
          <div className="col-3 pl-0">
            <button 
              disabled={!newConfigurationName}
              className="btn btn-secondary btn-block" 
              onClick={() => handleAddConfiguration()}
            > 
              Add
            </button>
          </div>
        </div>
      </div>
      {configrationList.length > 0 &&
      <div className="card-body">
        <div className="card-title">
          Configurations for this venue
        </div>
        <ul className="list-group">
          {configrationList.map( (item) => { 
            const styleConfigSelected = configurationSelected === item.id ? 'active' : '';
            return (
              <li key={item.id} className={"list-group-item list-group-item-action d-flex justify-content-between align-items-center " + styleConfigSelected} >
                <div className="item-info d-flex justify-content-between align-items-center" onClick={() => handleSelectConfiguration(item.id)}>
                  <span className="configuration-title">{item.name}</span>
                  <div className="configuration-badges">
                    <span className="badge badge-primary">{item.positions_count}</span>
                    <span className="badge badge-primary">{item.shifts_count}</span>
                  </div>
                </div>
                <span className="list-group-item-console d-flex justify-content-between align-items-center">
                  { auth.getCurrentUserPermisions()["module_venues_delete"] && 
                    <i className="fa fa-trash" onClick={() => openModalDeleteConfirmation(item, 'configuration') }></i> 
                  }
                </span>
              </li>
            )
          })}
        </ul>
      </div>
      }
    </div>
  );
}
export default VenueConfigurations;