import React from 'react';
import { usaStates } from '../../../utils/usa-states';
import staffAvatarPlaceholder from "../../../assets/staffAvatar-placeholder.svg";

const StaffFormPersonalInfo = props => {

  const photo = props.state.data.photo;
  const avatarImg = (photo) ? photo : staffAvatarPlaceholder;
  const isEditing = props.isEditing();
  const optionsGender = [
    { id: '' },
    { id: 'M', name: 'Male' }, 
    { id: 'F', name: 'Female' }];
  const optionsMaritalStatus = [
    { id: '' },
    { id: 'single', name: 'Single' }, 
    { id: 'married', name: 'Married' }
  ];

  return ( 
    <section className="form-wrapper personal-info-form container-fluid">
      <div className="row">
        <div className="col-4">
          {
            <div className="avatar-upload d-flex flex-column align-items-center">
              <div className="avatar-wrapper">
                <img className="avatar img-fluid" src={avatarImg} alt="Staff Avatar"/>
              </div>
              <label htmlFor="avatar" className="btn btn-outline-secondary btn-sm"> Choose File </label>
              <p className="requisite  text-center"><small>Accepted formats: PNG, GIF or JPG.<br/>Maximum file size: 3MB.<br/>Recomended Image size: 300x300 px.</small></p>
              <input id="avatar" name="staff_avatar" onChange={props.handleFileChange} type="file" accept="image/*" className="cms-hidden" />
            </div>
          }
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>First Name</strong></label>
                      <p>{props.state.data.first_name}</p>
                    </div>
                : props.renderInput("first_name", "First Name", "text", true )
              }
            </div>
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Middle Name</strong></label>
                      <p>{props.state.data.middle_name}</p>
                    </div>
                : props.renderInput("middle_name", "Middle Name", "text", true )
              }
            </div>
          </div>
          <div className="row">
          <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Last Name</strong></label>
                      <p>{props.state.data.last_name}</p>
                    </div>
                : props.renderInput("last_name", "Last Name", "text", true )
              }
            </div>
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Home Phone</strong></label>
                      <p>{props.state.data.home_phone}</p>
                    </div>
                : props.renderMaskInput("home_phone", "Home Phone", '(111) 111-1111', "text", true )
              }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Work Phone</strong></label>
                      <p>{props.state.data.work_phone}</p>
                    </div>
                : props.renderMaskInput("work_phone", "Work Phone", '(111) 111-1111', "text" )
              }
            </div>
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Address 1</strong></label>
                      <p>{props.state.data.address_1}</p>
                    </div>
                : props.renderInput("address_1", "Address 1", "text", true )
              }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>City</strong></label>
                      <p>{props.state.data.city}</p>
                    </div>
                : props.renderInput("city", "City", "text", true )
              }
            </div>
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>State</strong></label>
                      <p>{props.state.data.state}</p>
                    </div>
                : props.renderSelect("state", "State", usaStates(), true )
              }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              { isEditing
                ?  <div>
                      <label><strong>Zipcode</strong></label>
                      <p>{props.state.data.zip_code}</p>
                    </div>
                : props.renderInput("zip_code", "Zipcode", "text", true, 5 )
              }
            </div>
            <div className="col-6">
            { isEditing
              ?  <div>
                    <label><strong>Email</strong></label>
                    <p>{props.state.data.email}</p>
                  </div>
              : props.renderInput( "email", "Email", "email", true )
            }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            { isEditing
              ?  <div>
                    <label><strong>Gender</strong></label>
                    <p>{props.state.data.gender}</p>
                  </div>
              : props.renderSelect( "gender", "Gender", optionsGender )
            }
            </div>
            <div className="col-6">
            { isEditing
              ?  <div>
                    <label><strong>Marital Status</strong></label>
                    <p>{props.state.data.marital_status}</p>
                  </div>
              : props.renderSelect( "marital_status", "Marital Status", optionsMaritalStatus )
            }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
            { isEditing
              ?  <div>
                    <label><strong>Contact Name</strong></label>
                    <p>{props.state.data.contact_name}</p>
                  </div>
              : props.renderInput( "contact_name", "Contact Name", "text" )
            }
            </div>
            <div className="col-6">
            { isEditing
              ?  <div>
                    <label><strong>Contact Phone</strong></label>
                    <p>{props.state.data.contact_phone_1}</p>
                  </div>
              : props.renderMaskInput( "contact_phone_1", "Contact Phone 1", "(111) 111-1111", "text" )
            }
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {props.renderSwitch("us_veteran", "US Veteran?", false, false, true )}
            </div>
            <div className="col-6">
              {props.renderSwitch("national_guard", "Currently a member of the National Guard?", false, false, true )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              {props.renderSwitch("has_car", "Has Car", false, false, true )}
            </div>
            <div className="col-6">
              {props.renderSwitch("has_blocked_phone", "Has Blocked Phone?", false, false, true )}
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              {props.renderSwitch("email_opt_in", "Email Opt In/Out", false, false, true )}
            </div>
            <div className="col-6">
              {props.renderSwitch("sms_opt_in", "SMS Opt In/Out", false, false, true )}
            </div>
          </div>

          <div className="row justify-content-between align-items-center">
            <div className="col-12">
              <h3 className="form-group-label">
                Race/Ethnicity
              </h3>
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="col-6">
                {props.renderCheckbox("american_indian", "American Indian" )}
                {props.renderCheckbox("alaskan_native", "Alaskan Native" )}
                {props.renderCheckbox("asian_pacific_islander", "Asian of Pacific Islander" )}
              </div>
              <div className="col-6">
                {props.renderCheckbox("black_not_hispanic", "Black, not of Hispanic Origin" )}
                {props.renderCheckbox("hispanic", "Hispanic" )}
                {props.renderCheckbox("white_not_hispanic", "White, not of Hispanic Origin" )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default StaffFormPersonalInfo;