import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import { generateSignInSheetBlank, generateSignInSheetStaffDay, 
  generateSignInSheetStaffAll, generatePositionSheetDay, 
  generatePositionSheetAll, generateGroupPositionSheetDay, 
  generateGroupPositionSheetAll, generateNotificationStatusSheetAll,
  generateNotificationStatusSheetAccepted } 
  from '../../../../../../services/eventsService';

function EventStaffAssignedToolPrint(props) {
  const  { event, eventDate} = props;
  const [printOptions, setPrintOptions] = useState(false);
  const [station, setStation] = useState(1);
  
  const maxStations = 5;
  const stationOptions = [];
  for (var i = 1; i < maxStations+1; i++) {
    stationOptions.push({value: i, label: i});
  }

  const PRINT_OPTIONS_NOTIFICATIONS = [
    {
      TYPE: 'notification-status-all',
      LABEL: 'Notification status'
    },
    {
      TYPE: 'notification-status-accepted',
      LABEL: 'Notifications - Accepted'
    }
  ]
  
  const PRINT_OPTIONS_BLANK = [
    {
      TYPE: 'sign-in-blank',
      LABEL: 'Sign-in Sheets'
    }
  ]
  const PRINT_OPTIONS_SIGNIN_STAFF = [
    {
      TYPE: 'sign-in-staff-day',
      LABEL: '[DATE]'
    },
    {
      TYPE: 'sign-in-staff-all',
      LABEL: 'Entire Event'
    }
  ]
  const PRINT_OPTIONS_POSITION = [
    {
      TYPE: 'position-day',
      LABEL: '[DATE]'
    },
    {
      TYPE: 'position-all',
      LABEL: 'Entire Event'
    }
  ]
  const PRINT_OPTIONS_GROUP_POSITION = [
    {
      TYPE: 'group-position-day',
      LABEL: '[DATE]'
    },
    {
      TYPE: 'group-position-all',
      LABEL: 'Entire Event'
    }
  ]

  let eventDateLabel = '';
  if (eventDate && eventDate.start_date) {
    eventDateLabel = moment(eventDate.start_date).format('MM/DD/YYYY');
  }

  // ** HANDLE
  // *********************************************************
  
  const handlePrintOption = async type => {
    switch (type) {
      case 'notification-status-all':
        const { data: resNotificationStatus } = await generateNotificationStatusSheetAll(event.id);
        if (!resNotificationStatus.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resNotificationStatus.file_name);
        break;
      case 'notification-status-accepted':
        const { data: resNotificationAccepted } = await generateNotificationStatusSheetAccepted(event.id);
        if (!resNotificationAccepted.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resNotificationAccepted.file_name);
        break;
      case 'sign-in-blank':
        const { data: resSignInSheetBlank } = await generateSignInSheetBlank(event.id, eventDate.id);
        if (!resSignInSheetBlank.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSignInSheetBlank.file_name);
        break;
      case 'sign-in-staff-day':
        const { data: resSignInStaffSheet } = await generateSignInSheetStaffDay(event.id, eventDate.id, station);
        if (!resSignInStaffSheet.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSignInStaffSheet.file_name);
        break;
      case 'sign-in-staff-all':
        const { data: resSignInStaffSheetAll } = await generateSignInSheetStaffAll(event.id, station);
        if (!resSignInStaffSheetAll.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSignInStaffSheetAll.file_name);
        break;
      case 'position-day':
        const { data: resPositionSheet } = await generatePositionSheetDay(event.id, eventDate.id);
        if (!resPositionSheet.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resPositionSheet.file_name);
        break;
      case 'position-all':
        const { data: resPositionSheetAll } = await generatePositionSheetAll(event.id);
        if (!resPositionSheetAll.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resPositionSheetAll.file_name);
        break;
      case 'group-position-day':
        const { data: resGroupPositionSheet} = await generateGroupPositionSheetDay(event.id, eventDate.id);
        if (!resGroupPositionSheet.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resGroupPositionSheet.file_name);
        break;
      case 'group-position-all':
        const { data: resGroupPositionSheetAll } = await generateGroupPositionSheetAll(event.id);
        if (!resGroupPositionSheetAll.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resGroupPositionSheetAll.file_name);
        break;
      default:
        break;
    }
  }

  const handleChange = async (itemSelected) => {
    const value = (itemSelected.value) ? itemSelected.value : '1';
    setStation(value);
  };

  return (
    <React.Fragment>
      <button className="btn btn-print-options" type="button"
        onClick={() => setPrintOptions(true)}>
        <i className="fas fa-print"></i>Print Options
      </button>
      <Modal show={printOptions} onHide={() => setPrintOptions(false)} className="modal-print">
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fas fa-print"></i>&nbsp;Print Options
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="d-flex justify-content-around align-items-center"><strong>Notifications</strong></p>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS_NOTIFICATIONS.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{item.LABEL}</button>
            })}
          </p>
          <hr />
          <p className="d-flex justify-content-around align-items-center"><strong>Blank sheets</strong></p>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS_BLANK.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{item.LABEL}</button>
            })}
          </p>
          <hr />
          <p className="d-flex justify-content-around align-items-center"><strong>Sign-in Sheets</strong></p>
          <div className="d-flex align-items-center stations-center">
            <label>How many stations?</label>
            <Select
              name="stations"
              className="basic-select"
              classNamePrefix="select"
              options={stationOptions}
              defaultValue={ stationOptions[0] }
              onChange={handleChange}
              isSearchable={false}
            />
          </div>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS_SIGNIN_STAFF.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{(item.LABEL === '[DATE]') ? eventDateLabel : item.LABEL}</button>
            })}
          </p>
          <hr />
          <p className="d-flex justify-content-around align-items-center"><strong>Position Sheets</strong></p>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS_POSITION.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{(item.LABEL === '[DATE]') ? eventDateLabel : item.LABEL}</button>
            })}
          </p>
          <hr />
          <p className="d-flex justify-content-around align-items-center"><strong>Group Position Sheets</strong></p>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS_GROUP_POSITION.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{(item.LABEL === '[DATE]') ? eventDateLabel : item.LABEL}</button>
            })}
          </p>
        </Modal.Body>
        </Modal>
    </React.Fragment>
  )
}

export default EventStaffAssignedToolPrint
