import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import { saveBulkDateTime, getEventPositionList } from '../../../../../../services/eventsService';
import { messages } from "../../../../../../utils/language-en.json";
import Datepicker from '../../../../../Common/form/datepicker';
import { EventFetchContext } from '../../../Event';
import { EventStaffContext } from '../../EventStaff';
import { EventStaffAssignedContext } from '../EventStaffAssigned';

function EventStaffAssignedToolBulkDate() {
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);
  const [bulkDate, setBulkDate] = useState(false);
  const [bulkDateSelected, setBulkDateSelected] = useState(null);
  const [validEventDateRange, setValidEventDateRange] = useState(true);

  useEffect(() => {

    if (!eventStaffContext.eventdate) return;

    const initBulkSelected = {
      start_date: eventStaffContext.eventdate.start_date,
      start_date_edited: false,
      end_date: eventStaffContext.eventdate.end_date,
      end_date_edited: false,
    }

    !bulkDate && setBulkDateSelected(initBulkSelected);
    !bulkDate && setValidEventDateRange(true);
  }, [eventStaffContext.eventdate, bulkDate]);

  // ** HANDLE
  // *********************************************************
  const handleSaveBulkDate = async () => {
    const buildStaffIdList = eventStaffAssignedContext.eventStaffAssignedList.selected.map(item => {
      return item.staff_event_id
    });
    const type = () => {
      if (bulkDateSelected.start_date_edited && !bulkDateSelected.end_date_edited)  return 'Start';
      if (!bulkDateSelected.start_date_edited && bulkDateSelected.end_date_edited)  return 'End';
      if (bulkDateSelected.start_date_edited && bulkDateSelected.end_date_edited)  return 'Both';
    }
    const buildDataToSave = {
      staff: buildStaffIdList,
      start_datetime: moment(bulkDateSelected.start_date).format('MM/DD/YYYY HH:mm'),
      end_datetime: moment(bulkDateSelected.end_date).format('MM/DD/YYYY HH:mm'),
      type: type()
    }
    setBulkDate(false)
    try {
      const { data } = await saveBulkDateTime(buildDataToSave);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success('Datetime Updated!', { autoClose: 1000 });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: null });

      const updateAssignedList = async () => {
        eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH_LOADING', VALUE: true });
        const { data: dataPosition } = await getEventPositionList( eventStaffContext.position.selected.position_event_id );
        const positions = dataPosition.positions;
        eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: positions });
        eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH_LOADING', VALUE: false });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_CHECKIN', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
      }

      setTimeout( async () => {
        updateAssignedList();
      }, 300);

    } catch (error) { }
  }

  const handleChangeBulkDate = (name, date) => {
    date = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    date =  new Date( date ).toISOString();

    let buildBulkDate ;
    if (bulkDateSelected) {
      buildBulkDate = {
        ...bulkDateSelected,
        [name] : date,
        [name+'_edited']: true
      }
    } else {
      buildBulkDate = {
        [name] : date
      }
    }
    setBulkDateSelected(buildBulkDate);
  }

  const validateEventDateRange = () => {
    
    const { start_date, end_date } = bulkDateSelected;
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    if (endDate <= startDate){
      setValidEventDateRange(false);
      toast.error(messages.error.dateOutOfRange);
      return
    }

    setValidEventDateRange(true);

  }

  // ** RENDER
  // *********************************************************
  const renderEventDateTimePicker = (name) => {
    const dateFormat = bulkDateSelected && moment(bulkDateSelected[name]).format('YYYY-MM-DD HH:mm');
    const value = new Date(dateFormat);
    const alertStyleClass = validEventDateRange ? '' : ' alert-danger ' ;

    return (
      <Datepicker
        className={"form-control form-control-sm datepicker " + alertStyleClass }
        selected={value}
        name={name}
        onChange={date => handleChangeBulkDate(name, date)}
        onBlur={validateEventDateRange}
        fixedHeight
        calendarClassName="event-datepicker"
        timeFormat="HH:mm"
        timeIntervals={5}
        dateFormat="MM/dd/yyyy – HH:mm"
        timeInputLabel="Time:"
        showTimeInput
      />
    )
  }

  return (
    <React.Fragment>
      <button type="button" className="btn btn-toolsbar-item btn-sm" 
        disabled={!eventStaffAssignedContext.eventStaffAssignedList.selected}
        onClick={() => setBulkDate(true)}
      >Start/End Date</button>

      <Modal show={bulkDate} onHide={()=> setBulkDate(false)} className="modal-bulk-time">

        <Modal.Header>
          <Modal.Title>
            <i className="far fa-clock"></i>&nbsp;Adjust START/END Time
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-4 mt-3 justify-content-center align-items-center">
            <div className="col-sm-1 p-0">
              <label htmlFor="">
                Start
              </label>
            </div>
            <div className="col-sm-5">
              {renderEventDateTimePicker('start_date')}
            </div>
          </div>
          <div className="row mb-4 justify-content-center align-items-center">
            <div className="col-sm-1 p-0">
              <label htmlFor="">
                End
              </label>
            </div>
            <div className="col-sm-5">
              {renderEventDateTimePicker('end_date')}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <p className="disclaimer text-center">
                This adjustment will change all the hours by employee.
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setBulkDate(false)}>Cancel</Button>
          <Button variant="success" 
            disabled={!validEventDateRange} 
            onClick={() => handleSaveBulkDate()}
          >Adjust Date</Button>
        </Modal.Footer>

      </Modal>
    </React.Fragment>
  )
}

export default EventStaffAssignedToolBulkDate
