import React, { Component } from 'react';
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import { getUsers } from '../../services/usersService';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import SearchBox from "../Common/searchBox";
import Pagination from '../Common/pagination/pagination';
import UsersList from './usersList';
import "./users.scss";

class Users extends Component {
  mounted = false;

  state = {
    dataLoading: true,
    users: [],
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: "first_name", order: "asc" },
    searchQuery: "",
    totalCount: 0,
  }

  async componentDidMount() {
    this.mounted = true;
    this.mounted && this.populateUsers();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateUsers = async () => {
    try {
      const { data: resUser } = await getUsers({
        LIMIT: this.state.pageSize, 
        PAGE: this.state.currentPage, 
        ORDER_BY: this.state.sortColumn.path, 
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery
      });
      this.setState({ 
        users: resUser.users, 
        totalCount: resUser.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateUsers();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateUsers();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateUsers();
  };

  render() {
    const {
      dataLoading,
      users,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      totalCount,
      pageWrapperHeight
    } = this.state;
    const { user } = this.props;
    const authPermissions = auth.getCurrentUserPermisions();

    return (
      <main className="users-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-users-cog"></i>&nbsp;Users</h1>
            </div>
            <div className="col text-right">
              {authPermissions["module_users_add"] &&
                <Link
                  to="/users/create/"
                  className="btn btn-primary"
                >
                  Add User
                </Link>
              }
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-right">
              <div className="table-console-left d-flex align-items-end justify-content-center">
                
              </div>
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={ searchQuery } onChange={ this.handleSearch } />
              </div>
            </div>
          </section>
          <UsersList
            users={ users }
            user={ user }
            sortColumn={ sortColumn }
            onSort={ this.handleSort }
            isLoading={ dataLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={ totalCount }
              pageSize={ pageSize }
              currentPage={ currentPage }
              onPageChange={ this.handlePageChange }
            />
          )}
        </section>
      </main>
    );
  }
}

export default Users;
