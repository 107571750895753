import http from "./httpService";

const apiEndpoint = "/communication_templates";

export function getTemplatesList() {
  return http.get(apiEndpoint + '/list');
}

export function updateTemplate(id, title, subject, contentText, contentHtml, contentSms) {
  return http.post(apiEndpoint + '/update', { 
    id: id,
    title: title,
    subject: subject, 
    content_text: contentText,
    content_html: contentHtml,
    content_sms: contentSms
  });
}
