import React, { useContext } from 'react';
import { UserPermissions } from '../../../../utils/UserPermissions';
import { DepartmentDataModel } from './DepartmentsSettingsUtils';
import { DepartmentSettingsContext } from '../DepartmentsSettings';

function EditDepartment({ item }) {
  const departmentSettingsContext = useContext(DepartmentSettingsContext);
  const setEditDepartment = departmentSettingsContext.action.setEditDepartment;
  const setNewDepartment = departmentSettingsContext.action.setNewDepartment;

  return (
    UserPermissions('module_settings_tab_departments_edit') &&
      <button title="Edit Department" className="btn btn-sm btn-edit"
        onClick={() => {
          setEditDepartment(item)
          setNewDepartment(DepartmentDataModel)
        }}
      ><i className="fa fa-pencil" aria-hidden="true"></i>
      </button> 
  );
}

export default EditDepartment
