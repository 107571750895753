import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { UserPermissions } from '../../utils/UserPermissions';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import { getCurrentUser } from '../../services/authService';
import ClothesSettings from './ClothesSettings/ClothesSettings';
import DepartmentsSettings from "./DepartmentsSettings/DepartmentsSettings";
import PositionsPayRateSettings from './PositionsPayRateSettings/PositionsPayRateSettings';
import StaffPayRateSettings from './StaffPayRateSettings/StaffPayRateSettings';
import RolesResourcesSettings from './RolesResourcesSettings/RolesResourcesSettings';
import StatesSettings from './StatesSettings/StatesSettings';
import MessagesSettings from './MessagesSettings/MessagesSettings';
import TemplatesSettings from './TemplatesSettings/TemplatesSettings';
import './Settings.scss';

export const SwipeableViewsContext = React.createContext();
export const SettingsContext = React.createContext();

function Settings(props) {
  const [tabIndex, setTabIndex] = useState(0)
  const [tabsContent, setTabsContent] = useState([]);
  const [swipeableActions, setSwipeableActions] = useState(null);
  const [pageWrapperHeight, setPageWrapperHeight] = useState(0);

  const ClothesSettingsContent = {
    label: 'Clothes Sizes',
    content: idx => (
      <ClothesSettings key={idx} />
    )
  };

  const DepartmentSettingsContent = {
    label: 'Departments',
    content: idx => (
      <DepartmentsSettings key={idx} />
    )
  }

  const PositionsSettingsContent = {
    label: 'Positions Pay Rates',
    content: idx => (
      <PositionsPayRateSettings key={idx} />
    )
  }

  const StaffSettingsContent = {
    label: 'Staff Pay Rates',
    content: idx => (
      <  StaffPayRateSettings key={idx} />
    )
  }

  const RolesResourcesSettingsContent = {
    label: 'Roles and Permissions',
    content: idx => (
      <RolesResourcesSettings key={idx} />
    )
  }

  const StatesSettingsContent = {
    label: 'US States',
    content: idx => (
      <StatesSettings key={idx} />
    )
  }

  const MessagesSettingsContent = {
    label: 'Dashboard Messages',
    content: idx => (
      <MessagesSettings key={idx} />
    )
  }

  const TemplatesSettingsContent = {
    label: 'Communication Templates',
    content: idx => (
      <TemplatesSettings key={idx} />
    )
  }


  const tabContentBuilder = () => {
    let tabContentBuild = [];
    UserPermissions('module_settings_tab_clothes') && tabContentBuild.push(ClothesSettingsContent);
    UserPermissions('module_settings_tab_departments') && tabContentBuild.push(DepartmentSettingsContent);
    UserPermissions('module_settings_tab_pay_rates') && tabContentBuild.push(PositionsSettingsContent);
    UserPermissions('module_settings_tab_staff_pay_rates') && tabContentBuild.push(StaffSettingsContent);
    UserPermissions('module_settings_tab_roles_permissions') && tabContentBuild.push(RolesResourcesSettingsContent);
    UserPermissions('module_settings_tab_states') && tabContentBuild.push(StatesSettingsContent);
    UserPermissions('module_settings_tab_messages') && tabContentBuild.push(MessagesSettingsContent);
    UserPermissions('module_settings_tab_templates') && tabContentBuild.push(TemplatesSettingsContent);
    setTabsContent(tabContentBuild);
  };

  const updateSwipeableHeight = () => {
    swipeableActions && swipeableActions.updateHeight();
  }

  useEffect(() => {
    setPageWrapperHeight(PageWrapperHeight());
    tabContentBuilder();
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageWrapperHeight(PageWrapperHeight());
    };
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={props.location} />
      : <SwipeableViewsContext.Provider value={{ updateSwipeableHeight }}>
          <SettingsContext.Provider value={{ props }}>
            <main className="SettingsPage page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
              <section className="page-header">
                <div className="row">
                  <div className="col">
                    <h1 className="page-title"><i aria-hidden="true" className="fa fa-cogs"></i>&nbsp;Settings</h1>
                  </div>
                </div>
              </section>
              <section className="page-body">
                <Tabs 
                  value={tabIndex}  
                  onChange={(e, value) => setTabIndex(value)}
                  classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
                >
                  {tabsContent.map((form, idx) => (
                    <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
                  ))}
                </Tabs>
                <SwipeableViews 
                  index={tabIndex}
                  onChangeIndex={tabIndex => setTabIndex(tabIndex)}
                  animateHeight={true}
                  disableLazyLoading={true}
                  animateTransitions={true}
                  action={actions => {
                    setSwipeableActions(actions);
                  }} >
                  {tabsContent.map((form, idx) => (
                    form.content(idx)
                  ))}
                </SwipeableViews>
              </section>
            </main>
          </SettingsContext.Provider>
        </SwipeableViewsContext.Provider>
  )
}

export default Settings
