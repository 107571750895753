import React, { Component } from "react";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import auth from "../../services/authService";
import SystemMessagesWidget from './widgets/systemMessagesWidget';
import UpcommingEventsWidget from './widgets/upcommingEventsWidget';
import LicenseExpirationWidget from './widgets/licenseExpirationWidget';
import InvalidStaffDataWidget from './widgets/invalidStaffDataWidget';
import PaycomSyncWidget from './widgets/paycomSyncWidget';
import UnreconciledEventsWidget from './widgets/unreconciledEventsWidget';
import LatestActivitiesWidget from './widgets/latestActivitiesWidget';
import EventDepartmentWidget from './widgets/eventsDepartmentWidget';
import EventsStatusWidget from './widgets/eventsStatusWidget';
import EventsStaffDepartmentWidget from './widgets/eventsStaffDepartmentWidget';
import EventYearWidget from './widgets/eventYearWidget';
import AverageRateDepartmentWidget from './widgets/averageRateDepartmentWidget';
import ApplicantDepartmentWidget from './widgets/applicantDepartmentWidget';
import './Dashboard.scss';

class Dashboard extends Component {
  mounted = false;
  state = {
    pageWrapperHeight: null,
    userAdmin: false
  }

  async componentDidMount() {
    const userData = auth.getCurrentUser();
    const roleId = userData.roleId;
    let userAdmin = true;
    if (roleId === 2) {
      userAdmin = false;
    }
    this.setState({ userAdmin: userAdmin });

    this.mounted = true;
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  render() {
    const {
      pageWrapperHeight,
      userAdmin
    } = this.state;

    return (
      <main className="DashboardPage page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="DashboardPageHeader">
          <div className="row">
            <div className="col d-flex align-items-center">
              <h1 className="page-title"><i aria-hidden="true" className="fa fa-tachometer"></i>&nbsp;Dashboard</h1>
            </div>
          </div>
        </section>
        <section className="DashboardList">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <div className="DashboardList-wrapper">
                <div className="row justify-content-center">
                  <div className="col-widgets">
                    <SystemMessagesWidget/>
                    <AverageRateDepartmentWidget/>
                    { userAdmin && (
                      <>
                      <EventDepartmentWidget/>
                      <EventsStaffDepartmentWidget/>
                      <PaycomSyncWidget/>
                      <LatestActivitiesWidget/>
                      </>
                    )}
                    <ApplicantDepartmentWidget/>
                    <EventsStatusWidget/>
                    <EventYearWidget/>
                    <UpcommingEventsWidget/>
                    <UnreconciledEventsWidget/>
                    <LicenseExpirationWidget/>
                    <InvalidStaffDataWidget/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    )
  }
}

export default Dashboard;
