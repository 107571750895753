import http from "./httpService";

const apiEndpoint = "/states";

export function getActiveStates() {
    return http.get(apiEndpoint + '/list_active');
}

export function getStates() {
    return http.get(apiEndpoint + '/list');
}

export function updateState(id, active) {
    return http.post(apiEndpoint + '/update', {  id: id, active: active  });
}
