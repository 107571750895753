import React, { useContext, useState, useEffect } from 'react';
import { ReportsContext } from '../Reports';
import ReportToolFilters from './ReportTools/ReportToolFilters';
import { getReport } from '../../../services/reportsServices';
import ReportResults from './ReportResults/ReportResults';

export const ReportContext = React.createContext();

function Report() {
  const reportsContext = useContext(ReportsContext);
  const report = reportsContext.reportSelected;
  const [reportData, setReportData] = useState(null);
  const [reportResults, setReportResults] = useState(null);

  const fetchReport = async () => {
    const { data } = await getReport(report.id);
    setReportData(data.report);
  };

  useEffect(() => {
    report && fetchReport();
  }, [ report ]);

  return (
    <ReportContext.Provider value={{ reportData, reportResults, setReportResults }}>
      <ReportToolFilters />
      <ReportResults />
    </ReportContext.Provider>
  )
}

export default Report
