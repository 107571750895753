import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getActivityReportHistory } from '../../../services/logsService';
import Pagination from '../../Common/pagination/pagination';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, LogsContext } from '../Logs';
import { getUsers } from "../../../services/usersService";
import FilterBySection from "../../Common/filters/FilterBySection";
import FilterByUser from "../../Common/filters/FilterByUser";
import './ActivityHistory.scss';

function ActivityHistory() {
  const logsContext = useContext(LogsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [activityReportHistory, setActivityReportHistory ] = useState([]);
  const [users, setUsers ] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "desc" });
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterUser, setFilterUser] = useState([]);
  const [filterSection, setFilterSection] = useState([]);

  const columns = [
    {
      path: 'created_date',
      label: 'Date Created'
    },
    {
      path: 'user',
      label: 'User',
      disableSort: true,
    },
    {
      path: 'section',
      label: 'Section',
    },
    {
      path: 'subsection',
      label: 'Subsection',
    },
    {
      path: 'activity',
      label: 'Activity'
    }
  ];

  const handleUserChange = async (itemSelected) => {
    const userSelected = itemSelected ? itemSelected.map((user) => user.value) : [];
    setFilterUser(userSelected);
  };

  const handleSectionChange = async (itemSelected) => {
    const sectionSelected = itemSelected ? itemSelected.map((section) => section.value) : [];
    setFilterSection(sectionSelected);
  };

  const fetchActivityHistory = async () => {
    const { data } = await getActivityReportHistory({
      USER_ID: filterUser,
      SECTION: filterSection,
      LIMIT: pageSize,
      PAGE: currentPage,
      ORDER_BY: sortColumn.path,
      SORT_ORDER: sortColumn.order,
    });
    if (!data.success) {
      toast.error('Error getting Activity History data.');
      return;
    }
    setActivityReportHistory(data.logs);
    setTotalCount(data.count);
    setLoading(false);
  };

  const fetchUsers = async () => {
    const { data: resUser } = await getUsers({
      LIMIT: 1000,
      PAGE: 1,
    });
    setUsers(resUser.users);
  };

  useEffect(() => {
    fetchActivityHistory();
    fetchUsers();
  }, [currentPage, sortColumn, filterUser, filterSection]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={logsContext.props.location} />
      : <section className="ActivityHistory content-wrapper container-fluid">
          <div className="table-console-left d-flex align-items-end">
            <FilterByUser
              users={users}
              handleUserChange={handleUserChange}
            />
            <FilterBySection 
              handleSectionChange={handleSectionChange} 
            />
          </div>
          <Table
            name="ActivityHistoryTable"
            columns={columns}
            data={activityReportHistory}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={loading}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={ page => setCurrentPage(page) }
          />
        </section>
  )
}

export default ActivityHistory;
