import React from 'react';
import "./form.scss";

const Checkbox = ({ name, label, error, required, justifyContent, ...rest }) => {
  return ( 
    <div className={"checkbox d-flex align-items-center justify-content-"+justifyContent }>
      <input {...rest} name={name} id={name} type="checkbox" />
      <label htmlFor={name}>{label}</label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>

  );
}

export default Checkbox;