import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

function CloneEventDates({ eventDates, dispatchEventDates }) {
  const [startDate, setStartDate] = useState([]);
  
  // ** RENDERS 
  // *************************************************************
  const renderTableHeader = () => (
    <tr>
      <th className="day"></th>
      <th className="date">Original Start Date</th>
      <th className="date">New Start Date</th>
    </tr>
  );

  const setNewDate = (position, newDate) => {
    dispatchEventDates({...startDate, [position]: newDate });
    setStartDate({...startDate, [position]: newDate });
  }
  
  const renderEventDatePicker = (idx, name, label, required = false) => {
    const CustomInput = React.forwardRef((props,ref) => {
      return (
        <button type="button" className="btn btn-datepicker"
          onClick={props.onClick} 
          ref={ref}>
          {props.value || props.placeholder} &nbsp;<span className="fas fa-calendar-day"></span>
        </button>
      )
    });

    return (
      <div className="form-group">
        <ReactDatePicker
          idx={idx}
          name={name}
          label={label}
          required={required}
          selected={startDate[idx]}
          onChange={date => setNewDate(idx, date)}
          customInput={<CustomInput />}
          popperPlacement="auto"
        />
      </div>
    )
  }

  const renderDatesList = () => {
    let list = [];
    if (eventDates) {
      list = eventDates.map((date, idx) => (
        <tr className="event-date-item" key={idx}>
          <td className="day">
            <i className="far fa-clock"></i>&nbsp;Day&nbsp;{idx+1}
          </td>
          <td className="date">
            {moment(date.start_date).format('MM/DD/YYYY')}
          </td>
          <td className="date">
            {renderEventDatePicker(idx, "start_date", "Start Date", true)}
          </td>
        </tr>
      ));
    }

    return list;
  };

  return (
    <section className='EventInfoDates'>
      <table className="EventInfoDates-list table">
        <thead>
          { renderTableHeader() }
        </thead>
        <tbody>
          { renderDatesList() }
        </tbody>
      </table>
    </section>
  )
}

export default CloneEventDates
