import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { saveDepartment } from '../../../../services/departmentService';
import { DepartmentDataModel } from './DepartmentsSettingsUtils';
import { DepartmentSettingsContext } from '../DepartmentsSettings';

function AddDepartment() {
  const departmentSettingsContext = useContext(DepartmentSettingsContext);
  const setFetchData = departmentSettingsContext.action.setFetchData;
  const newDepartment = departmentSettingsContext.state.newDepartment;
  const setNewDepartment = departmentSettingsContext.action.setNewDepartment;

  const handleAddDepartment = async () => {
    const { data } = await saveDepartment(newDepartment);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.settingsDepartementCreated, { autoClose: 1000 });
    setFetchData(true);
    setNewDepartment(DepartmentDataModel)
  }

  return (
    <button title="Add Department" className="btn btn-secondary btn-sm btn-add" 
      disabled={ !newDepartment.name || !newDepartment.target_hourly_rate }
      onClick={() => handleAddDepartment()}
    ><i className="fa fa-plus" aria-hidden="true"></i>
    </button>
  )
}

export default AddDepartment
