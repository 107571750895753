import { getCurrentUserPermisions } from "../services/authService"

export const UserPermissions = (module) => {
  const userPermissions = getCurrentUserPermisions();
  return userPermissions[module];
}

export default {
  UserPermissions
}
