import React, { useContext, useEffect, useState } from 'react';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getPositionByDepartment } from '../../../services/positionsService';
import PositionsPayRateSettingsFilters from './PositionsPayRateSettingsUtils/PositionsPayRateSettingsFilters';
import PositionsPayRateSettingsList from './PositionsPayRateSettingsUtils/PositionsPayRateSettingsList';
import './PositionsPayRateSettings.scss';

function PositionsPayRateSettings() {
  const settingsContext = useContext(SettingsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [departmentIdSelected, setDepartmentIdSelected] = useState(null);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    const getDepartmentPositions = async () => {
      const { data } = await getPositionByDepartment(departmentIdSelected);
      setPositions(data.positions);
    }
    departmentIdSelected && getDepartmentPositions();
  }, [departmentIdSelected]);

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : <section className="PositionsPayRateSettings content-wrapper container-fluid">
          <PositionsPayRateSettingsFilters setDepartmentIdSelected={setDepartmentIdSelected} />
          <PositionsPayRateSettingsList positions={positions} />
        </section>
  )
}

export default PositionsPayRateSettings
