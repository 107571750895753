import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Chart from "react-google-charts";

function EventsStaffStatsResourcesVolunteers(props) {
  const data = props.data;
  const [volunteersInfo, setVolunteersInfo] = useState(false);
  const staffData =  [['Type', 'Staff']];
  const hoursData =  [['Type', 'Hours']];
  const costData =  [['Type', 'Cost']];

  data && data.forEach((element) => {
    staffData.push(
      [element.name, parseInt(element.staff)]
    );
    hoursData.push(
      [element.name, parseFloat(element.hours)]
    );
    costData.push(
      [element.name, parseFloat(element.cost)]
    );
  });

  return (
    <>
      <button className="btn btn-volunteers" type="button"
        onClick={() => setVolunteersInfo(true)}>
        <i className="fas fa-hands-helping" title="Volunteers Resources"></i>
      </button>
      <Modal show={volunteersInfo} onHide={() => setVolunteersInfo(false)} className="modal-shared" size="xl">
        <Modal.Header>
          <Modal.Title>
            <i className="fas fa-hands-helping"></i>&nbsp;
            Volunteers Resources Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <h6>Members Staffed</h6>
            <Chart
              chartType="PieChart"
              loader={<div>Loading</div>}
              data={staffData}
              options={{
                backgroundColor: 'transparent',
                width: '300',
                height: 200
              }}
            />
            <Chart
              chartType="Table"
              loader={<div>Loading</div>}
              data={staffData}
              options={{
                  width: '100%'
              }}
            />
          </div>
          <div className="col">
            <h6>Hours</h6>
            <Chart
              chartType="PieChart"
              loader={<div>Loading</div>}
              data={hoursData}
              options={{
                backgroundColor: 'transparent',
                width: '300',
                height: 200
              }}
            />
            <Chart
              chartType="Table"
              loader={<div>Loading</div>}
              data={hoursData}
              options={{
                  width: '100%'
              }}
            />
          </div>
          <div className="col">
            <h6>Cost</h6>
            <Chart
              chartType="PieChart"
              loader={<div>Loading</div>}
              data={costData}
              options={{
                backgroundColor: 'transparent',
                width: '300',
                height: 200
              }}
            />
            <Chart
              chartType="Table"
              loader={<div>Loading</div>}
              data={costData}
              options={{
                  width: '100%'
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setVolunteersInfo(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventsStaffStatsResourcesVolunteers
