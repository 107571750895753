import React from 'react';
import { Modal , Button } from 'react-bootstrap';
import Input from '../../../../Common/form/input';
import Select from '../../../../Common/form/select';
import Checkbox from '../../../../Common/form/checkbox';

const renderCheckbox = (name, label, handleChange, data) => {
  return (
    <Checkbox
      name={name}
      checked={(data[name])}
      value={data[name]}
      label={label}
      onChange={handleChange}
      justifyContent={'start'}
    />
  )
}

const renderInput = (name, label, handleChange, data, type, handleBlur) => {
  const inputsize =  (name === 'radio_qty') ? 'sm' : undefined;
  return (
    <Input
      type={type}
      label={label}
      name={name}
      value={data[name]}
      onChange={handleChange}
      onBlur={handleBlur}
      inputsize={inputsize}
    />
  )
}

const renderSelect = (name, label, handleChange, data, options) => {
  return (
    <Select
      name={name}
      label={label}
      value={data[name]}
      options={options}
      onChange={handleChange}
    />
  )
}

const VenueConfigurationPositionForm = ({ isOpen, onAfterOpen, onRequestClose, closeModal, data, handleChange, positionsTypeOptions, handleSave, handleBlur }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-configuration-position-form">
      <Modal.Header closeButton>
        <Modal.Title>
        <i className="fas fa-shield-alt"></i>&nbsp;Add Position
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            {renderInput('assignment_name', 'Assignment Name', handleChange, data, 'text' )}
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            {renderSelect('position_id', 'Position Type', handleChange, data, positionsTypeOptions)}
          </div>
          <div className="col-4">
            {renderInput('qty', 'Qty', handleChange, data, 'number', handleBlur )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h3 className="form-group-label">Equipment</h3>
          </div>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <div className="col-3">
              {renderCheckbox('blazer', 'Blazer', handleChange, data )}
            </div>
            <div className="col-3">
              {renderCheckbox('coat', 'Coat', handleChange, data )}
            </div>
            <div className="col-3">
              {renderCheckbox('shirt', 'Shirt', handleChange, data )}
            </div>
            <div className="col-3">
              {renderCheckbox('traffic', 'Traffic', handleChange, data )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-start align-items-baseline">
            <div className="col-3 pr-0">
              {renderCheckbox('radio', 'Radio', handleChange, data )}
            </div>
            <div className="col-3 pl-0">
              {data['radio'] && 
              <div className="input-radio-qty">
                {renderInput('radio_qty', 'Radio Qty', handleChange, data, 'number' )}
              </div>
              }
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" disabled={!data.assignment_name || !data.position_id || !data.qty} onClick={() => handleSave()} >
          { !data.id ? 'Add Position' : 'Edit Position' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default VenueConfigurationPositionForm;