import React from 'react';
import { Modal , Button } from 'react-bootstrap';
import Input from '../../../Common/form/input';
import Select from '../../../Common/form/select';

const renderInput = (name, label, handleChange, data, type, handleBlur) => {
  return (
    <Input
      type={type}
      label={label}
      name={name}
      value={data[name]}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}

const renderSelect = (name, label, handleChange, data, options) => {
  return (
    <Select
      name={name}
      label={label}
      value={data[name]}
      options={options}
      onChange={handleChange}
    />
  )
}

const VolunteersAddModal = ({ isOpen, onRequestClose, closeModal, data, handleChange, organizationOptions, handleSave }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-volunteers-add-form">
      <Modal.Header closeButton>
        <Modal.Title>
        <i className="fas fa-hands-helping"></i>&nbsp;Add Volunteer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            {renderSelect('organization_id', 'Organization', handleChange, data, organizationOptions)}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {renderInput('first_name', 'First Name', handleChange, data, 'text' )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {renderInput('last_name', 'Last Name', handleChange, data, 'text' )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" disabled={!data.organization_id || !data.first_name || !data.last_name} onClick={() => handleSave()} >
          Add Volunteer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default VolunteersAddModal;