import React, { useContext } from 'react';
import { toast } from "react-toastify";
import defaultVolunteerAvatar from "../../../../../assets/volunteerAvatar-placeholder.svg";
import { currencyUSD } from '../../../../../utils/value-formats';
import { EventStaffContext } from '../EventStaff';
import { EventContext } from '../../Event';
import { EventStaffAvailableContext } from './EventStaffAvailable';
import { UserPermissions } from '../../../../../utils/UserPermissions';

function EventStaffAvailableList({ event }) {
  const eventStaffAvailableContext = useContext(EventStaffAvailableContext);
  const eventStaffContext = useContext(EventStaffContext);
  const eventContext = useContext(EventContext);

  const validateAvailability = (member) => {
    const warningMessage = <span>The limit for <b>{eventStaffContext.position.selected.position_name}</b> on this event is <b>{eventStaffContext.availableAssignedPositions.positionsTotal}</b> </span>

    if (member.staff_selected) {
      return false
    }

    if (!eventStaffAvailableContext.staffAvailable.selected ) {
      if (eventStaffContext.availableAssignedPositions.total === 0) {
        toast.warning(() => warningMessage);
        return false
      } 
        return true
    } else {
      const isSelected = eventStaffAvailableContext.staffAvailable.selected.find(item => item.staff_id === member.staff_id);
      if (isSelected) {
        return true
      }
      if (eventStaffContext.availableAssignedPositions.total === eventStaffAvailableContext.staffAvailable.selected.length) {
        toast.warning(() => warningMessage);
        return false
      }
    }

    return true;
  }

  // ** HANDLE
  // *********************************************************
  
  const handleSelectAvailableStaffModel = (member) => {
    let selected;
    const eventDate = eventContext.event.data.events_dates.find(item => item.id === eventStaffContext.eventdate.id );
    const payrate = (member.department_id === event.department_id) ? member.regular_pay_rate : (member.travel_pay_rate) ? member.travel_pay_rate : member.regular_pay_rate;
    const staffAvailableSelected = eventStaffAvailableContext.staffAvailable.selected;

    const selectMemberModel =  {
      staff_id: member.staff_id,
      staff_name: member.staff_name,
      current_pay_rate: payrate,
      start_date: eventDate.start_date,
      end_date: eventDate.end_date,
      type: member.type
    }

    const isSelected = staffAvailableSelected && staffAvailableSelected.find(item => item.staff_id === selectMemberModel.staff_id );
    if (isSelected) {
      const deselect = staffAvailableSelected.filter(m => m.staff_id !== selectMemberModel.staff_id);
      selected= deselect
    } else {
      selected = !staffAvailableSelected 
                    ? [selectMemberModel] 
                    : [...staffAvailableSelected, selectMemberModel] 
    }

    eventStaffAvailableContext.dispatchStaffAvailable({ TYPE: 'STAFF_AVAILABLE_SELECTED', VALUE: selected });
    return
  }

  return (
    <ul className="list-unstyled">
      { eventStaffAvailableContext.staffAvailable.data && eventStaffAvailableContext.staffAvailable.data.map((member, idx) => {
          const isStaff = member.staff_selected ? ' isStaff ' : '';
          const isSelected =  eventStaffAvailableContext.staffAvailable.selected && eventStaffAvailableContext.staffAvailable.selected.find(item => item.staff_id === member.staff_id);
          const isSelectedStyle = isSelected ? ' isSelected ' : member.staff_selected ? '': ' notSelected ';
          const payrate = (member.department_id === event.department_id) ? member.regular_pay_rate : (member.travel_pay_rate) ? member.travel_pay_rate : member.regular_pay_rate;

          return (
            <li className={"EventStaffAvailable-item d-flex align-items-center " + member.type + " justify-content-between" + isStaff + isSelectedStyle } 
              key={idx}
              onClick={() => validateAvailability(member) && handleSelectAvailableStaffModel(member) }
            >
              <div className="EventStaffAvailable-item-info d-flex align-items-center">
                <div className="avatar">
                  { member.type === 'Volunteer' && 
                    <img className="img-fluid" src={defaultVolunteerAvatar} alt="{member.staff_name}"/>
                  }
                </div>
                <h5 className="name">{member.staff_name}</h5>
                <div className="staff-member-info-overlay">
                  <h6 className="staff-member-info-overlay-name">{member.staff_name}</h6>
                  <p className="positions">{(member.type === 'Staff') ? member.positions : 'Volunteer from ' + member.organization}</p>
                  
                  <div className="staff-members-badges">
                    <span className="badge badge-outline badge-payrate">
                      {currencyUSD(payrate)}
                    </span>
                    { member.type === 'Staff' &&
                      <span className="badge badge-outline badge-rating">
                        <i className="fas fa-star"></i>&nbsp;{ (member.rating > 0 ) ? member.rating : '–' }
                      </span>
                    }
                    { member.public_facing  &&
                      <span className="badge ">Public Facing</span> 
                    }
                  </div>
                </div>
              </div>
              { member.type === 'Volunteer' &&
                <div className="organization-wrapper">
                  {member.organization}
                </div>
              }
              { member.type === 'Staff' &&
                <div className="staff-badges">
                  <span className="badge badge-outline badge-payrate">
                    {currencyUSD(payrate)}
                  </span>

                  { UserPermissions('module_events_sub_tab_notification') &&
                    <>
                      <div className="circle-wrapper">
                        <div className={"circle " + member.notification_status.replace(/\s/g, '')}></div>
                      </div>
                      <div className="notification-status">
                        {member.notification_status}&nbsp;
                        { (member.number_notification > 0 ) ? "(" + member.number_notification + ")" : "" }
                      </div>
                    </>
                  }

                  { member.public_facing 
                    ? <span className="badge ">PF</span> 
                    : <span className="badge invisible"></span>
                  }
                  { member.staff_selected 
                    ? <i className="selected fas fa-check"></i>
                    : <i className="selected fas fa-uncheck invisible"></i>
                  }
                </div>
              }
            </li>
          )
        })
      }
    </ul>
  )
}

export default EventStaffAvailableList
