import http from "./httpService";

const apiEndpoint = "/department";

export function getDepartmentList() {
  return http.get(apiEndpoint + '/list');
}

export function saveDepartment(item) {
  if (item.id) {
    return http.post(apiEndpoint + '/update', item);
  }

  return http.post(apiEndpoint + '/add', item);
}
