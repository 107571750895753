import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { generateSummarySheet } from '../../../../../services/eventsService';

function EventSummaryToolPrint(props) {
  const  { event} = props;
  const [printOptions, setPrintOptions] = useState(false);
  
  const PRINT_OPTIONS = [
    {
      TYPE: 'summary-all',
      LABEL: 'Staff + Volunteers '
    },
    {
      TYPE: 'summary-staff',
      LABEL: 'Staff'
    },
    {
      TYPE: 'summary-volunteers',
      LABEL: 'Volunteers'
    }
  ]

  // ** HANDLE
  // *********************************************************
  
  const handlePrintOption = async type => {
    switch (type) {
      case 'summary-all':
        const { data: resSummarySheetAll } = await generateSummarySheet(event.id, 1);
        if (!resSummarySheetAll.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSummarySheetAll.file_name);
        break;
      case 'summary-staff':
        const { data: resSummarySheetStaff } = await generateSummarySheet(event.id, 2);
        if (!resSummarySheetStaff.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSummarySheetStaff.file_name);
        break;
      case 'summary-volunteers':
        const { data: resSummarySheetVolunteer } = await generateSummarySheet(event.id, 3);
        if (!resSummarySheetVolunteer.success) return toast.error("File couldn't be generated");
        window.open(process.env.REACT_APP_API_URL + '/' + resSummarySheetVolunteer.file_name);
        break;
      default:
        break;
    }
  }

  return (
    <React.Fragment>
      <button className="btn btn-print-pdf" type="button"
        onClick={() => setPrintOptions(true)}>
        <i className="far fa-file-pdf"></i>EXPORT TO PDF
      </button>
      <Modal show={printOptions} onHide={() => setPrintOptions(false)} className="modal-print">
        <Modal.Header closeButton>
          <Modal.Title>
          <i className="far fa-file-pdf"></i>&nbsp;Options
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="d-flex justify-content-around align-items-center">
            {PRINT_OPTIONS.map((item, idx) => {
              return <button key={idx} className="btn btn-color-primary-outline" type="button" onClick={() => handlePrintOption(item.TYPE)}>{item.LABEL}</button>
            })}
          </p>
        </Modal.Body>
        </Modal>
    </React.Fragment>
  )
}

export default EventSummaryToolPrint
