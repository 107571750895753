import React, { useState, useEffect } from 'react';
import { getDepartmentList } from '../../../../services/departmentService';
import Select from '../../../Common/form/select';
import auth from "../../../../services/authService";

function StaffPayRateSettingsFilters({ setDepartmentIdSelected, numberStaffMembers }) {
  const [departments, setDepartments] = useState([]);

  const userData = auth.getCurrentUser();  
  const userDepartmentId = userData.department_id;
  const userDepartmentName = userData.department_name;

  useEffect(() => {
    const fetchDepartmentsList = async () => {
      const { data } = await getDepartmentList();
      setDepartments(data.department);
      if (userDepartmentId) {
        setDepartmentIdSelected(userDepartmentId);
      } else if (data.department[0]) {
        setDepartmentIdSelected(data.department[0].id);
      }
    }
    fetchDepartmentsList();
  }, []);

  return (
    <div className="StaffPayRateSettingsFilters">
      <div className="row align-items-center">
        <div className="col-auto">
          <h3 className="setting-label">
            <i className="fas fa-id-badge"></i>
            &nbsp;Staff &amp; pay rates for&nbsp;
            {userDepartmentId &&
              userDepartmentName
            }
          </h3>
        </div>
        <div className="col-sm-2">
            {!userDepartmentId &&
              <Select
                name={'selectDepartment'}
                options={departments}
                onChange={({ currentTarget: input }) => setDepartmentIdSelected(Number(input.value))}
              />
            }
        </div>
        <div className="col-sm-4">
          Number of active staff members: <strong>{numberStaffMembers}</strong>
        </div>
      </div>
    </div>
  )
}

export default StaffPayRateSettingsFilters
