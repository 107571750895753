import React, { useEffect, useState } from "react";
import Table from "../../Common/table/table";
import { addVolunteer, updateVolunteer, getVolunteersOrganization } from '../../../services/volunteersService';
import { toast } from "react-toastify";
import { messages } from '../../../utils/language-en.json';
import VolunteersAdd from './partials/VolunteersAdd';
import VolunteersEdit from './partials/VolunteersEdit';

const VolunteersFormStaff = props => {
  const { onSort, sortColumn, organizationId, onDelete } = props;
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [volunteersData, setVolunteersData] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [volunteerItem, setVolunteerItem] = useState([]);

  const fetchVolunteersOrganization = async (organizationId) => {
    const { data: resVolunteers } = await getVolunteersOrganization(organizationId);
    setVolunteersData(resVolunteers.volunteers);
  }

  useEffect(() => {
    fetchVolunteersOrganization(organizationId);
  },[organizationId]);

  const columns = [
    { path: "last_name", label: "Last Name" },
    { path: "first_name", label: "First Name" },
    {
      key: "edit",
      path: "edit",
      disableSort: true,
      content: item => (
        <button className="btn btn-sm btn-edit" onClick={() => handleEditVolunteerOpen(item)} >
          <span className="sr-only">Edit</span>
          <i className="fa fa-pencil" aria-hidden="true"></i>
        </button>
      )
    },
    {
      key: "delete",
      path: "delete",
      disableSort: true,
      content: item => (
        <button
          onClick={() => onDelete(item)}
          className="btn btn-danger btn-sm btn-delete"
        >
          <span className="sr-only">Delete</span>
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      )
    }
  ];

  const handleChangeNewFirstName = ({ currentTarget: input}) => {
    setNewFirstName(input.value);
  };

  const handleChangeNewLastName = ({ currentTarget: input}) => {
    setNewLastName(input.value);
  };

  const handleEditVolunteerOpen = (item) => {
    setVolunteerItem(item);
    setShowEditModal(true);
  };

  const handleEditVolunteerClose = () => {
    setShowEditModal(false);
  };

  const handleChangeEditForm = ({ currentTarget: input }) => {
    let value = input.value;
    const data = { ...volunteerItem };
    data[input.name] = value;
    setVolunteerItem(data);
  }

  const handleSaveVolunteer = async () => {
    try {
      const { data } = await updateVolunteer(volunteerItem.id, volunteerItem.first_name, volunteerItem.last_name);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.volunteerUpdated, { autoClose: 1000 });
      fetchVolunteersOrganization(organizationId);
    } catch (ex) {
    }
    setShowEditModal(false);
    return; 
  }

  // ** HANDLES
  // *********************************************************
  const handleAddVolunteer = async () => {
    try {
      const { data } = await addVolunteer(newFirstName, newLastName, organizationId);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.volunteerCreated, { autoClose: 1000 });
      fetchVolunteersOrganization(organizationId);
      setNewFirstName('');
      setNewLastName('');
    } catch (ex) {
    }
    return; 
  };

  return (
    <div className="volunteers-form">
      <div className="row">
        <div className="col-sm-4 col-lg-3">
          <VolunteersAdd
            newFirstName={newFirstName}
            handleChangeNewFirstName={handleChangeNewFirstName}
            newLastName={newLastName}
            handleChangeNewLastName={handleChangeNewLastName}
            handleAddVolunteer={handleAddVolunteer}
          />
        </div>
        <div className="col-sm-8 col-lg-9"> 
          <Table
            name={'volunteers'}
            columns={columns}
            data={volunteersData}
            sortColumn={sortColumn}
            onSort={onSort}
          />
        </div>
      </div>
      <VolunteersEdit
        isOpen={showEditModal}
        onRequestClose={handleEditVolunteerClose}
        closeModal={handleEditVolunteerClose}
        data={volunteerItem}
        handleChange={handleChangeEditForm}
        handleSave={handleSaveVolunteer}
      />
    </div>
  );
}

export default VolunteersFormStaff;