import http from "./httpService";

const apiEndpoint = "/settings";

export function getSettingsKey(key) {
  return http.get(apiEndpoint + '/get_key', { 
    params: {
      key: key
    }
  });
}
