import React from 'react';
import { Redirect } from 'react-router-dom';

function RedirectToLogin({ location }) {
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location }
      }}
    />
  )
}

export default RedirectToLogin
