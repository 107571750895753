import React, { useState, useContext, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import moment from 'moment';
import { messages } from "../../../../../utils/language-en.json";
import { EventReconciliationListContext } from '../EventReconciliationList';

function EventReconciliationListItemDate(props) {
  const item = props.item;
  const eventReconciliationListContext = useContext(EventReconciliationListContext);
  const [datepickerNameSelected, setDatepickerNameSelected] = useState(null);
  const isEditable = eventReconciliationListContext.editReconciledDate.selected && eventReconciliationListContext.editReconciledDate.selected.staff_event_id === item.staff_event_id;
  
  useEffect(() => {
    if (!eventReconciliationListContext.editReconciledDate.original) return
    if (eventReconciliationListContext.editReconciledDate.original.save_start_date && eventReconciliationListContext.editReconciledDate.original.save_end_date) {
      eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_SELECTED', VALUE: null});
      eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_ORIGINAL', VALUE: null});
    }
  },[eventReconciliationListContext.editReconciledDate.original]);

  // ** HANDLE
  // *********************************************************

  const handleEditReconciledDate = (item) => {
    if (eventReconciliationListContext.editReconciledDate.selected && !item) {
      eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_SELECTED', VALUE: null});
      eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_ORIGINAL', VALUE: null});
      return
    }

    const ORIGINAL = {
      start_date: item.start_date,
      save_start_date: false,
      end_date: item.end_date,
      save_end_date: false
    }
    eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_SELECTED', VALUE: item});
    eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_ORIGINAL', VALUE: ORIGINAL});
  }

  const handleChangeDatePicker = (date) => {
    const dateFormat = 'MM/DD/YYYY – HH:mm';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    
    if (moment(toDateFormat, dateFormat, true).isValid()) {
      date = moment(date).format('YYYY-MM-DDTHH:mm:ss');
      date = new Date( date ).toISOString();

      const updatedDate = {
        ...eventReconciliationListContext.editReconciledDate.selected,
        [datepickerNameSelected]: moment(date).format('MM/DD/YYYY HH:mm')
      }

      eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_SELECTED', VALUE: updatedDate})
    } else {
      toast.error(messages.error.dateTimeInvalid);
    }
  }

  const validateEventDateRange = () => {
    const { start_date, end_date } = eventReconciliationListContext.editReconciledDate.selected;
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    if (endDate <= startDate){
      toast.error(messages.error.dateOutOfRange);
      return false;
    }

    var startDateShift = moment(start_date);
    var endDateShift = moment(end_date);
    var durationShift = endDateShift.diff(startDateShift, 'minutes');
    if (durationShift > 1440) {
      toast.error(messages.error.dateShiftLonger);
      return false;
    }

    handleSaveDate();
  }

  const handleSaveDate = async () => {
    await eventReconciliationListContext.handleUpdateReconciliation(datepickerNameSelected, eventReconciliationListContext.editReconciledDate.selected);
    const dateNameSaved = {
      ...eventReconciliationListContext.editReconciledDate.original,
      start_date: eventReconciliationListContext.editReconciledDate.selected.start_date,
      end_date: eventReconciliationListContext.editReconciledDate.selected.end_date,
      save_start_date: true, 
      save_end_date: true
    }
    eventReconciliationListContext.dispatchEditReconciledDate({ TYPE: 'EDIT_RECONCILED_DATE_ORIGINAL', VALUE: dateNameSaved});
  }

  // ** RENDERS
  // *********************************************************

  const renderDateTimePicker = (name) => {
    const dateFormat = moment(eventReconciliationListContext.editReconciledDate.selected[name]).format('YYYY-MM-DD HH:mm:ss');
    const value = new Date(dateFormat);

    const CustomInput = React.forwardRef((props,ref) => {
      return (
        <button type="button" className="btn btn-datepicker"
          onClick={props.onClick} 
          ref={ref}>
          {props.value || props.placeholder} &nbsp;<span className="fas fa-calendar-day"></span>
        </button>
      )
    });

    const CustomTimeInput = ({ date, value, onChange }) => (
      <input
        value={value}
        onChange={(e) => onChange(e.target ? e.target.value : null)}
      />
    );

    return (
      <div className="form-group">
        <ReactDatePicker
          calendarClassName="event-datepicker"
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MM/dd/yyyy – HH:mm"
          fixedHeight
          selected={value}
          name={name}
          onChange={handleChangeDatePicker}
          onCalendarOpen={() => setDatepickerNameSelected(name)}
          onCalendarClose={() => validateEventDateRange()}
          customInput={<CustomInput />}
          timeInputLabel="Time:"
          showTimeSelect
          popperPlacement="auto"
          customTimeInput={<CustomTimeInput/>}
          timeCaption="Time"
        />
      </div>
    )
  }

  return (
    <div className="date-wrapper">
      { !isEditable
        ? <div className="read-only">
            <p>{item.start_date}</p>
            <p>{item.end_date}</p>
            { !item.reconciled &&
              <i className="fas fa-pencil-alt" 
                onClick={() => handleEditReconciledDate(item)}
              ></i>
            }
          </div>
        : <div className="editable">
            {renderDateTimePicker('start_date')}
            {renderDateTimePicker('end_date')}
            <i className="fas fa-times" 
              onClick={() => handleEditReconciledDate()}
            ></i>
          </div>
      }
    </div>
  )
}

export default EventReconciliationListItemDate
