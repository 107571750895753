import React, { Component } from 'react';
import { getEventsMonth } from "../../../services/widgetsService";
import FilterByYear from "../../Common/filters/FilterByYear";
import Chart from "react-google-charts";
import { messages } from '../../../utils/language-en.json';
 
class EventYearWidget extends Component {
  mounted = false;
  state = {
    events: [],
    hasData: false
  }

  async componentDidMount() {
    this.mounted = true;
    this.mounted && this.getEventsMonthData(new Date().getFullYear());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getEventsMonthData = async (year) => {
    const { data } = await getEventsMonth(year);
    const eventsData =  [['Month', 'Qty']];
    var hasData = false;

    data.events.forEach((element) => {
      if (parseInt(element.total) > 0) {
        hasData = true;

        eventsData.push(
          [element.month, parseInt(element.total)]
        )
      }
    });

    this.setState({events: eventsData});
    this.setState({hasData: hasData});
    this.setState({title: data.title});
  }

  handleYearChange = async (itemSelected) => {
    const yearSelected = (itemSelected) ? itemSelected.value: new Date().getFullYear();
    this.getEventsMonthData(yearSelected);
  };

  render() {
    const {
      events,
      hasData,
      title
    } = this.state;

    return (
      <div className="col-auto"> 
        <div className='DashboardList-item btn d-flex flex-column'>
          <h4><i className="fas fa-chart-area"></i>&nbsp;{title}</h4>
          <FilterByYear handleYearChange={this.handleYearChange}/> 
          { !hasData 
            ? <div className="no-data">{messages.emptydata.widgetsMessage}</div>
            : 
            <Chart
              chartType="AreaChart"
              loader={<div>Loading</div>}
              data={events}
              options={{
                backgroundColor: 'transparent',
                width: '100%',
                height: 180,
                colors: ['red'],
                legend: {
                  position: 'none'
                }
              }}
            />
          }
        </div>
      </div>
    )
  }
}

export default EventYearWidget;