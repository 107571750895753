import React from 'react';
import "./deleteConfirmation.scss"
import { Modal , Button } from 'react-bootstrap';

const DeleteConfirmation = ({ isOpen, onAfterOpen, onRequestClose, closeModal, itemName, onDelete }) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} className="modal-delete-confirmation">
      <Modal.Header>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>Are you sure you want to delete <b>{ itemName }</b>?</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" onClick={onDelete} >Delete</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default DeleteConfirmation;
