import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { UserPermissions } from '../../utils/UserPermissions';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import { getCurrentUser } from '../../services/authService';
import PayrollHistory from './PayrollHistory/PayrollHistory';
import ActivityHistory from './ActivityHistory/ActivityHistory';
import ErrorHistory from './ErrorHistory/ErrorHistory';
import PaycomHistory from './PaycomHistory/PaycomHistory';
import DepartmentRate from './DepartmentRate/DepartmentRate';
import './Logs.scss';

export const SwipeableViewsContext = React.createContext();
export const LogsContext = React.createContext();

function Logs(props) {
  const [tabIndex, setTabIndex] = useState(0)
  const [tabsContent, setTabsContent] = useState([]);
  const [swipeableActions, setSwipeableActions] = useState(null);
  const [pageWrapperHeight, setPageWrapperHeight] = useState(0);

  const ActivityHistoryContent = {
    label: 'Activity History',
    content: idx => (
      <ActivityHistory key={idx} />
    )
  }
  
  const PayrollHistoryContent = {
    label: 'Payroll History',
    content: idx => (
      <PayrollHistory key={idx} />
    )
  }

  const PaycomHistoryContent = {
    label: 'Paycom Sync History',
    content: idx => (
      <PaycomHistory key={idx} />
    )
  }

  const ErrorHistoryContent = {
    label: 'Error History',
    content: idx => (
      <ErrorHistory key={idx} />
    )
  }

  const DepartmentRateContent = {
    label: 'Target Hourly Rate',
    content: idx => (
      <DepartmentRate key={idx} />
    )
  }

  const tabContentBuilder = () => {
    let tabContentBuild = [];
    UserPermissions('module_logs_tab_activity') && tabContentBuild.push(ActivityHistoryContent);
    UserPermissions('module_logs_tab_payroll') && tabContentBuild.push(PayrollHistoryContent);
    UserPermissions('module_logs_tab_paycom') && tabContentBuild.push(PaycomHistoryContent);
    UserPermissions('module_logs_tab_error') && tabContentBuild.push(ErrorHistoryContent);
    UserPermissions('module_logs_tab_department_rate') && tabContentBuild.push(DepartmentRateContent);
    setTabsContent(tabContentBuild);
  };

  const updateSwipeableHeight = () => {
    swipeableActions && swipeableActions.updateHeight();
  }

  useEffect(() => {
    setPageWrapperHeight(PageWrapperHeight());
    tabContentBuilder();
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageWrapperHeight(PageWrapperHeight());
    };
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={props.location} />
      : <SwipeableViewsContext.Provider value={{ updateSwipeableHeight }}>
          <LogsContext.Provider value={{ props }}>
            <main className="LogsPage page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
              <section className="page-header">
                <div className="row">
                  <div className="col">
                    <h1 className="page-title"><i aria-hidden="true" className="fas fa-file-medical-alt"></i>&nbsp;Logs</h1>
                  </div>
                </div>
              </section>
              <section className="page-body">
                <Tabs 
                  value={tabIndex}  
                  onChange={(e, value) => setTabIndex(value)}
                  classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
                >
                  {tabsContent.map((form, idx) => (
                    <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
                  ))}
                </Tabs>
                <SwipeableViews 
                  index={tabIndex}
                  onChangeIndex={tabIndex => setTabIndex(tabIndex)}
                  animateHeight={true}
                  disableLazyLoading={true}
                  animateTransitions={true}
                  action={actions => {
                    setSwipeableActions(actions);
                  }} >
                  {tabsContent.map((form, idx) => (
                    form.content(idx)
                  ))}
                </SwipeableViews>
              </section>
            </main>
          </LogsContext.Provider>
        </SwipeableViewsContext.Provider>
  )
}

export default Logs
