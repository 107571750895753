import React, { useContext, useState } from 'react';
import { ReportContext } from '../Report';
import ReportResultsHeader from './ReportResultsItems/ReportResultsHeader';
import ReportResultsBody from './ReportResultsItems/ReportResultsBody';

export const ReportResultContext = React.createContext();

function ReportResults() {
  const reportContext = useContext(ReportContext);
  const [printComponentRef, setPrintComponentRef] = useState(null);

  return (
    <>
    { reportContext.reportResults && reportContext.reportResults.rows &&
      <ReportResultContext.Provider value={{ printComponentRef, setPrintComponentRef }}>
        <section className="ReportResults">
          <ReportResultsHeader />
          <ReportResultsBody />
        </section>
      </ReportResultContext.Provider>
    }
    </>
  )
}

export default ReportResults
