import http from "./httpService";

const apiEndpoint = "/reports";

export function getReportsList() {
  return http.get(apiEndpoint + '/list');
}

export function getReport(reportId) {
  return http.get(apiEndpoint + '/get_id/',  {
    params: {
      id: reportId
    }
  });
}

export function generateReport(endpoint, data) {
  return http.post(endpoint, data);
}
