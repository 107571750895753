import React, { useEffect, useReducer, useState, useContext } from 'react';
import _ from "lodash";
import { currencyUSD } from '../../../../utils/value-formats';
import { getEventSummaryList } from '../../../../services/eventsService';
import Table from '../../../Common/table/table';
import './EventSummary.scss';
import { EventFetchContext } from '../Event';
import { Link } from "react-router-dom";
import EventSummaryToolPrint from './EventSummaryTools/EventSummaryToolPrint';

const initialEventSummary =  {
  loading: true,
  data: null,
  error: null
}

const reducerEventSummary = (state, action) => {
  switch (action.TYPE) {
    case 'LOADING':
      return  { ...state, loading: action.VALUE }
    case 'ERROR':
      return  { ...state, error: action.VALUE }
    case 'DATA':
      return  { ...state, data: action.VALUE }
    default:
      break;
  }
}

function EventSummary({ event }) {

  const [eventSummary, dispatchEventSummary] = useReducer(reducerEventSummary, initialEventSummary);
  const [sortColumn, setSortColumn] = useState({ path: "start_date_mod", order: "asc" })
  const eventFetchContext = useContext(EventFetchContext);

  const fetchEventSummary = async (eventId) => {
    dispatchEventSummary({ TYPE: 'LOADING', VALUE: true });
    const { data } = await getEventSummaryList(eventId)
    if (!data.success) {
      dispatchEventSummary({ TYPE: 'ERROR', VALUE: data.message });
    }
    const sortedDataRows = _.orderBy(data.rows, [sortColumn.path], [sortColumn.order]);
    dispatchEventSummary({ TYPE: 'DATA', VALUE: { rows: sortedDataRows, total: data.total } });
    dispatchEventSummary({ TYPE: 'LOADING', VALUE: false });
    eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: false });
  }

  const columns = [
    {
      path: 'staff_name',
      label: 'Staff Name'
    },
    {
      path: 'position_name',
      label: 'Position'
    },
    {
      path: "volunteer", 
      label: "Volunteer",
      content: item => (
        (item.volunteer)
          ? <i className="fas fa-check blueIcon"></i>
          : <i></i>
      )
    },
    {
      path: 'department',
      label: 'Department'
    },
    {
      path: 'start_date',
      label: 'Start Date'
    },
    {
      path: 'pay_rate',
      label: 'Pay Rate',
      content: item => (currencyUSD(item.pay_rate))
    },
    {
      path: 'hours_worked',
      label: 'Hours Worked'
    },
    {
      path: 'cost',
      label: 'Cost',
      content: item => (currencyUSD(item.cost))
    },
    {
      path: 'exported',
      label: 'Exported',
      content: item => (item.exported)
    },
    {
      path: 'info',
      label: 'Notes',
    },
    {
      path: "view",
      disableSort: true,
      content: item => (
        (item.type === 'Staff' &&
          <Link to={`/staff/${item.staff_id}`} target="_blank">
            <span className="sr-only">View</span>
            <i className="fas fa-external-link-alt external-link-alt-custom-small"></i>
          </Link>
        )
      )
    }
  ]

  useEffect(() => {
    fetchEventSummary(event.id);
  },[event.id]);

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_SUMMARY && fetchEventSummary(event.id);
  }, [eventFetchContext.fetchData.EVENT_SUMMARY]);

  useEffect(() => {
    if (eventSummary.data) {
      const sortedRows = _.orderBy(eventSummary.data.rows, [sortColumn.path], [sortColumn.order]);
      dispatchEventSummary({ TYPE: 'DATA', VALUE: { rows: sortedRows, total: eventSummary.data.total } });
    }
  }, [sortColumn]);

  return (
    eventSummary.loading 
      ? "Loading"
      : <section className="EventSummary form-wrapper container-fluid">
       <EventSummaryToolPrint event={event}/>
          <section className="StatsConsole">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Members Staffed</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.staff_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Volunteers Staff</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.volunteer_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Salary Staff</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.salary_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total No show Staff</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.no_show_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Short Notice Staff</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.short_notice_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Cancel Staff</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.cancel_count}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Hours Worked</h6>
                  <p className="StatsConsole-item-info">{eventSummary.data.total.hours_worked}</p>
                </div>
              </div>
              <div className="col-1">
                <div className="StatsConsole-item">
                  <h6 className="StatsConsole-item-label">Total Cost</h6>
                  <p className="StatsConsole-item-info">{currencyUSD(eventSummary.data.total.total_cost)}</p>
                </div>
              </div>
            </div>
          </section>
          <Table
            name="EventSummaryList"
            columns={columns}
            data={eventSummary.data.rows}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={eventSummary.loading}
          />
        </section>
  );
}

export default EventSummary
