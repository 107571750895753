import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Datepicker from '../../../Common/form/datepicker';
import { ReportContext } from '../Report';
import { generateReport } from '../../../../services/reportsServices';
import { getCurrentUser } from '../../../../services/authService';
import RedirectToLogin from '../../../../utils/RedirectToLogin';
import { ReportsContext } from '../../Reports';

function ReportToolFilters() {
  const reportsContext = useContext(ReportsContext);
  const reportContext = useContext(ReportContext);
  const reportData = reportContext.reportData;
  const [reportFilters, setReportFilters] = useState([]);
  const [reportFiltersRequired, setReportFiltersRequired] = useState(null);
  const [filterBy, setFilterBy] = useState(null);
  const [datePickerNameSelected, setDatePickerNameSelected] = useState(null);
  const [generateReportConfirmation, setGenerateReportConfirmation] = useState();
  const [loading, setLoading] = useState(false);

  const reportFiltersBuilder = dependent => {
    const withDependent = dependent ? true : false;
    const allFilters = reportData.filters;
    const filtersBuilder = [];
    for (let i = 0; i < allFilters.length; i++) {
      const filter = allFilters[i];
      if (withDependent && filter.dependent_value === dependent ) {
        filtersBuilder.push( filter );
      }
      if (!filter.dependent_id) {
        filtersBuilder.push( filter );
      }
    };
    const requiredFiltersBuilder = [];
    for (let i = 0; i < filtersBuilder.length; i++) {
      const filterRequired = filtersBuilder[i];
      if (filterRequired.required) {
        requiredFiltersBuilder.push(filterRequired.field_name);
      }
    }

    const reportFilters =  _.orderBy(filtersBuilder, ['sort'], ['asc']);

    setReportFilters(reportFilters);
    setReportFiltersRequired(requiredFiltersBuilder);

  };

  useEffect(() => {
    reportData && reportData.filters && setReportFilters(reportData.filters);
    reportData && reportData.filters && reportFiltersBuilder();
  }, [reportData]);

  useEffect(() => {
    reportFilters.length !== 0 && reportFilters.map( (item) => {
      if (item.type === 'default') {
        setFilterBy({...filterBy, [item.field_name]: item.data[0].value });
      }
      return true
    });
  },[reportFilters]);
  
  // ** ACTIONS HANDLE
  // *********************************************************

  const validate = () => {
    let isValid = true;

    for (let idx = 0; idx < reportFiltersRequired.length; idx++) {
      const element = reportFiltersRequired[idx];
      if ( filterBy && filterBy[element] ) {
        isValid = false
      } 
      else {
        return true
      }
    }

    return isValid;
  };

  const handleOnChangeSelectFilter = selectedFilter => {
    // CUSTOM TIME RANGE FILTERS
    if (selectedFilter.id === 5 && selectedFilter.field_name === 'time_range_id') {
      reportFiltersBuilder(selectedFilter.id);
    } else if (selectedFilter.field_name === 'time_range_id') {
      reportFiltersBuilder();
    }

    const buildFilterBy = {
      ...filterBy,
      [selectedFilter.field_name]: selectedFilter.id.toString()
    }
    setFilterBy(buildFilterBy);
  };

  const handleChangeDatepicker = date => {
    const input = {
      name: datePickerNameSelected,
      value: moment(date).format('YYYY-MM-DD')
    }
    const buildFilterBy = { ...filterBy, [input.name]: input.value};

    setFilterBy(buildFilterBy);
  };

  const handleGenerateReport = async (exportReport) => {
    reportContext.setReportResults(null);
    let requestProperties = filterBy;

    if (exportReport) {
      requestProperties = {...filterBy, export_report: true}
    }

    setLoading(true);
    const { data } = await generateReport(reportData.endpoint, requestProperties);
    const success = data.success;
    if (!success) {
      const errorMessage ='Error on generating the report';
      toast.error(errorMessage);
      return
    }

    setGenerateReportConfirmation({
      status: data.status,
      summary: data.summary
    });

    reportContext.setReportResults({
      filters: data.filters,
      rows: data.rows,
      files: data.files,
      totalized: data.totalized
    });

    setLoading(false);

  };

  const handleCloseConfirmation = () => {
    setGenerateReportConfirmation() 
    setFilterBy({
      department_id: null,
      time_range_id: null,
      start_date: null,
      end_date: null
    })
    setReportFiltersRequired(null);
    reportFiltersBuilder(null);
  };

  // ** RENDERS
  // *********************************************************

  const renderSelect = item => {
    const filterIdx = filterBy && item.data.map(e => e.id).indexOf(Number(filterBy[item.field_name]));
    return (
      <div className="filter-item"  >
        <label htmlFor="">{item.label}</label>
        <Select
          className="select-style-default underline"
          isSearchable={true}
          value={item.data[filterIdx] || ''}
          options={item.data}
          getOptionLabel ={(option)=>option.name}
          getOptionValue ={(option)=>option.id}
          onChange={handleOnChangeSelectFilter}
        />
      </div>
    );
  };

  const renderDatepicker = (item) => {
    const date = filterBy ? moment(filterBy[item.field_name]).format() : null ;
    const dateSelected = (filterBy && filterBy[item.field_name]) ? new Date(date) : null;
    
    return (
      <div className="filter-item">
        <Datepicker
          selected={dateSelected}
          name={item.field_name}
          label={item.label}
          className="datepicker form-control form-control-sm"
          onChange={handleChangeDatepicker}
          onFocus={ ({ currentTarget: input }) => setDatePickerNameSelected(input.name)}
        />
      </div>
    )
  };

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={reportsContext.props.location} />
      : <>
        { reportFilters.length !== 0 &&
          <section className="ReportToolFilters">
            <div className="row justify-content-between align-items-center">
              <div className="filters col-auto d-flex">
                { reportFilters.map( (item, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        { item.type === 'select' && renderSelect(item) }
                        { item.type === 'date' && renderDatepicker(item) }
                      </React.Fragment>
                    )
                  })
                }
                <span className="d-flex align-items-end justify-content-center">
                  <button type="button" className="btn btn-primary btn-generate-report"
                    disabled={reportFiltersRequired && validate()}
                    onClick={ () => handleGenerateReport() }
                  >
                    Generate&nbsp;
                    {loading && <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>}
                  </button>
                </span>
              </div>
            </div>
          </section>
        }
        { generateReportConfirmation && generateReportConfirmation.summary &&
          <section className={"ReportToolFiltersConfirmation alert"} role="alert">
            <button className="btn btn-close"
              onClick={() => handleCloseConfirmation()}
            ><i className="fas fa-times"></i></button>
            <div className="row">
              <div className="col-12">
                <h5 className="ReportToolFiltersConfirmation-message">{generateReportConfirmation.summary.message}</h5>
                <>
                  { generateReportConfirmation.summary.events && generateReportConfirmation.summary.events.length !== 0 &&
                    <section className="ReportToolFiltersConfirmation-badge-wrapper">
                      <div className="row header">
                        <div className="col-5 name">
                          Event Name
                        </div>
                        <div className="col">
                          Shifts
                        </div>
                        <div className="col">
                          Total Hours
                        </div>
                        <div className="col">
                        </div>
                      </div>
                      {generateReportConfirmation.summary.events.map((item, idx) => (
                        <div className="row">
                          <div className="col-5 name">
                            {item.name}
                          </div>
                          <div className="col">
                            {item.staff}
                          </div>
                          <div className="col">
                            {item.hours}
                          </div>
                          <div className="col button">
                            <a key={idx} href={'/events/' + item.id} target="_blank" rel="noopener noreferrer" className={"badge badge-" + generateReportConfirmation.status}>Open Event</a>
                          </div>
                        </div>
                    ))}
                    </section>
                  }

                  { generateReportConfirmation.summary.preview && generateReportConfirmation.summary.preview.length !== 0 &&
                    <section className="ReportToolPreviewConfirmation-badge-wrapper">
                      <div className="row header">
                        <div className="col">
                          Paycom ID
                        </div>
                        <div className="col-3">
                          Name
                        </div>
                        <div className="col-3">
                          Event
                        </div>
                        <div className="col">
                          Pay Rate
                        </div>
                        <div className="col">
                          Hours
                        </div>
                        <div className="col-2">
                          Date
                        </div>
                        <div className="col">
                          Reconciled
                        </div>
                      </div>
                      {generateReportConfirmation.summary.preview.map((item, idx) => (
                        <div className="row" key={idx}>
                          <div className="col">
                            {item.paycom_id}
                          </div>
                          <div className="col-3">
                            {item.first_name} {item.last_name}
                          </div>
                          <div className="col-3">
                            {item.event_name}
                          </div>
                          <div className="col">
                            {item.pay_rate}
                          </div>
                          <div className="col">
                            {item.hours_worked}
                          </div>
                          <div className="col-2">
                            {item.start_date}
                          </div>
                          <div className="col">
                            {
                              item.reconciled
                              ? <i className="fas fa-check blueIcon"></i>
                              : <i className="fas fa-close redIcon"></i>
                            }
                          </div>
                        </div>
                    ))}
                    </section>
                  }

                  { generateReportConfirmation.summary.staff && generateReportConfirmation.summary.staff.length !== 0 &&
                    <section className="ReportToolFiltersConfirmation-badge-wrapper">
                      <div className="row header">
                        <div className="col name">
                          First Name
                        </div>
                        <div className="col">
                          Last Name
                        </div>
                        <div className="col">
                        </div>
                      </div>
                      {generateReportConfirmation.summary.staff.map((item, idx) => (
                        <div className="row">
                          <div className="col name">
                            {item.first_name}
                          </div>
                          <div className="col">
                            {item.last_name}
                          </div>
                          <div className="col button">
                            <a key={idx} href={'/staff/' + item.staff_id} target="_blank" rel="noopener noreferrer" className={"badge badge-" + generateReportConfirmation.status}>Open Staff</a>
                          </div>
                        </div>
                    ))}
                    </section>
                  }
                </>
                {generateReportConfirmation.summary.message_confirmation &&
                  <>
                  <p className="ReportToolFiltersConfirmation-message-confirmation"  dangerouslySetInnerHTML={{ __html: generateReportConfirmation.summary.message_confirmation }} />
                  <button className="btn btn-success"
                    onClick={() => handleGenerateReport(true)}
                  >Export</button>
                  </>
                }
              </div>
            </div>
          </section>
        }
        </>
  )
}

export default ReportToolFilters
