import http from "./httpService";

const apiEndpoint = "/customer";

export function getCustomers({DEPARTMENT_ID = [], LIMIT = 100, PAGE = 1, ORDER_BY = 'name', SORT_ORDER = 'ASC', SEARCH} ) {
  return http.get(apiEndpoint + '/list', { 
    params: {
      department_id: DEPARTMENT_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  });
}

export function deleteCustomer(id) {
  return http.post( apiEndpoint + '/delete', { id: id } );
}

export function saveCustomer(data) {
  if (data.id) {
    const body = { ...data };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', data);
}

export function getCustomersItem(id) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: id 
    }
  });
}

export function getCustomerListNotFlagged(id) {
  return http.get(apiEndpoint + '/list', { 
    params: {
      department_id: id,
      show_red_flag: false
    }
  });
}
