import React, { useContext, useEffect, useState } from 'react';
import _ from "lodash";
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getMessagesList } from '../../../services/messagesService';
import Loading from '../../Common/loading/loading';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import { MessageDataModel } from './MessagesSettingsUtils/MessagesSettingsUtils';
import MessagesTableCellView from './MessagesSettingsUtils/MessageTableCellView';
import UpdateMessage from './MessagesSettingsUtils/UpdateMessage';
import EditMessage from './MessagesSettingsUtils/EditMessage';
import AddMessage from './MessagesSettingsUtils/AddMessage';
import './MessagesSettings.scss';

export const MessagesSettingsContext = React.createContext();

function MessagesSettings() {
    const settingsContext = useContext(SettingsContext);
    const swipeableViewsContext = useContext(SwipeableViewsContext);
    const [loading, setLoading] = useState(true);
    const [fetchData, setFetchData] = useState(true);
    const [messages, setMessages] = useState(null);
    const [messagesList, setMessagesList] = useState(null);
    const [sortColumn, setSortColumn] = useState({ path: "created_date", order: "asc" });
    const [editMessage, setEditMessage] = useState(null);
    const [newMessage, setNewMessage] = useState(MessageDataModel);
    
    const TABLE_COLUMNS = [
      { 
        path: "title", 
        label: "Title",
        content: item => <MessagesTableCellView item={item} fieldName="title" fieldType="input" />
      },
      { 
        path: "content", 
        label: "Content",
        content: item => <MessagesTableCellView item={item} fieldName="content" fieldType="textarea" />
      },
      { 
        path: "background_color", 
        label: "Background color",
        content: item => <MessagesTableCellView item={item} fieldName="background_color" fieldType="input" />
      },
      { 
        path: "text_color", 
        label: "Text color",
        content: item => <MessagesTableCellView item={item} fieldName="text_color" fieldType="input" />
      },
      { 
        path: "start_date", 
        label: "Start Date",
        content: item => <MessagesTableCellView item={item} fieldName="start_date" fieldType="input" />
      },
      { 
        path: "end_date", 
        label: "End Date",
        content: item => <MessagesTableCellView item={item} fieldName="end_date" fieldType="input" />
      },
      {
        key: "edit",
        path: "edit",
        content: item => (
          item.id 
            ? editMessage && item.id === editMessage.id 
              ? <UpdateMessage />
              : <EditMessage item={item} />
            : <AddMessage />
        )
      }
    ]

    useEffect(() => {
      swipeableViewsContext.updateSwipeableHeight();
    });
  
    useEffect(() => {
      const fetchMessages = async () => {
        const { data } = await getMessagesList();
        const messagesList = [newMessage, ...data.messages];
        setMessages(messagesList);
        setMessagesList(data.messages);
        setLoading(false);
        setFetchData(false);
      }
      fetchData && fetchMessages();
    }, [fetchData]);
  
    useEffect(() => {
      const sortedData = _.orderBy(messagesList, [sortColumn.path], [sortColumn.order]);
      const dataList = [newMessage, ...sortedData];
      setMessages(dataList);
    }, [sortColumn]);

    return (
      !getCurrentUser()
        ? <RedirectToLogin location={settingsContext.props.location} />
        : loading
          ? <Loading type="tabsContentLoading" />
          : <MessagesSettingsContext.Provider value={{ 
            action: { setEditMessage, setNewMessage, setFetchData },
            state: { editMessage, newMessage }
          }} >
              <section className="MessagesSettings content-wrapper container-fluid">
                <Table
                  name={'messages'}
                  columns={TABLE_COLUMNS}
                  data={messages}
                  sortColumn={sortColumn}
                  onSort={sortColumn => setSortColumn(sortColumn)}
                  isLoading={loading}
                  itemIdSelected={editMessage && editMessage.id}
                />
              </section>
            </MessagesSettingsContext.Provider>
    )
  }

export default MessagesSettings