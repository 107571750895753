import React, { Component } from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import downloadjs from 'downloadjs';
import { toast } from "react-toastify";
import { messages } from "../../../utils/language-en.json";
import FilesUploader from './filesUploader.jsx';
import { getFilesList, getFileById, saveFile, deleteFile } from '../../../services/filesService.js';
import { fileTypeIcon } from "../../../utils/file-type-icons";
import DeleteConfirmation from '../delete-confirmation/deleteConfirmation.jsx';
import EmptyData from '../emptydata/emptyData.jsx';
import EmptyDataIcon from "../../../assets/cms-icon-empty-files.svg";

class FilesManager extends Component {
  state = {
    files: [],
    dataLoading: true,
    deleteConfirmationModalIsOpen: false,
    deleteFileIdSelected: null,
    deleteFileNameSelected: '',
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading) 
      return this.populateFiles()
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  async populateFiles() {
    const component = this.props.component;
    const { data } = await getFilesList( component, this.props.itemId) ;
    const sortData = _.orderBy(data.files, ['date'], ['desc']);
    const files = sortData;
    this.setState({ files: files, dataLoading: false });
  }
  
  base64MimeType(encoded) {
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
  }

  downloadFile = async (file) => {
    const { data: resFile } = await getFileById( file.id );
    const fileData = resFile.data;
    downloadjs(fileData, file.file_name, file.type);
  }

  handleUploadFile = async (filesToUpload) => {
    const { data } = await saveFile(filesToUpload[0]);
    const success = data.success;
    if (!success) {
      const errorMessage = messages.error.staffFileUpload;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.staffFileUploaded, { autoClose: 1000 });
    this.populateFiles();
  }

  openModalDeleteConfirmation = (fileId, fielName) => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteFileIdSelected: fileId, deleteFileNameSelected: fielName });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteFileIdSelected: null, deleteFileNameSelected: '' });
  }

  handleDeleteFile = async () => {
    const fileId = this.state.deleteFileIdSelected;
    const originalFiles = this.state.files;
    const files = originalFiles.filter(m => m.id !== fileId);
    this.setState({ files, deleteConfirmationModalIsOpen: false, deleteFileIdSelected: null });

    try {
      const { data } = await deleteFile(this.state.deleteFileIdSelected);
      const success = data.success;
      if (!success) {
        const errorMessage = messages.error.staffFileDelete;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.staffFileDeleted, { autoClose: 1000 });
      this.populateFiles();
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.staffFileDelete);

      this.setState({ files: originalFiles });
    }
  }

  render() { 
    const { 
      dataLoading,
      files,
      deleteConfirmationModalIsOpen,
      deleteFileNameSelected
    } = this.state;

    return ( 
      <section className="files-manager form-wrapper container-fluid">
        <div className="row">
          <div className="col-sm-4 col-lg-3">
            <FilesUploader 
              component={this.props.component}
              componentId={this.props.itemId}
              handleUploadFile={this.handleUploadFile}
              realoadFilesList={this.populateFiles}
            />
          </div>
          <div className="col-sm-8 col-lg-9">
            <div className="row">
              { dataLoading && (
                <div className="loader">
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                </div>
              ) }
              { !dataLoading && files.length === 0  &&
                <EmptyData 
                  type={"files"} 
                  icon={EmptyDataIcon}
                  title={messages.emptydata.filesTitle}
                />
              }
              { files.length > 0 &&
                files.map( (file) => {
                  return (
                    <div key={file.id} className="card file-card justify-content-center">
                      <i className="fa fa-trash" aria-hidden="true" onClick={() => this.openModalDeleteConfirmation(file.id, file.file_name)}></i>
                      <div className="file-info d-flex justify-content-center align-items-center" onClick={() => this.downloadFile(file)}>
                        <div className="file-info-wrapper">
                          <div className="file-icon">
                            <i className={fileTypeIcon(file.type)}></i>
                          </div>
                          <p className="file-name">{file.file_name}</p>
                          <p className="file-description">{file.description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <DeleteConfirmation
              isOpen={deleteConfirmationModalIsOpen}
              onRequestClose={this.closeModalDeleteConfirmation}
              closeModal={this.closeModalDeleteConfirmation}
              itemName={deleteFileNameSelected}
              onDelete={this.handleDeleteFile}
            />
          </div>
        </div>
      </section>
    );
  }
}

FilesManager.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default FilesManager;
