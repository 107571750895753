import React, { useState, useEffect } from 'react';
import { getDepartmentList } from '../../../../services/departmentService';
import Select from '../../../Common/form/select';

function PositionsPayRateSettingsFilters({ setDepartmentIdSelected }) {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchDepartmentsList = async () => {
      const { data } = await getDepartmentList();
      setDepartments(data.department);
      if (data.department[0]) {
        setDepartmentIdSelected(data.department[0].id);
      }
    }
    fetchDepartmentsList();
  }, []);

  return (
    <div className="PositionsPayRateSettingsFilters">
      <div className="row align-items-center">
        <div className="col-auto">
          <h3 className="setting-label">
            <i className="fas fa-shield-alt"></i>
            &nbsp;Position &amp; pay rates for
          </h3>
        </div>
        <div className="col-sm-2">
          <Select
            name={'selectDepartment'}
            options={departments}
            onChange={({ currentTarget: input }) => setDepartmentIdSelected(Number(input.value))}
          />
        </div>
      </div>
    </div>
  )
}

export default PositionsPayRateSettingsFilters
