import React, { Component } from 'react';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { getStaffPositions, addStaffPositions, updateStaffPositions, deleteStaffPositions } from '../../../services/staffService';
import { staffPositionsMapToViewModel } from '../../../models/staffSchema';
import StaffPayRateHistoryLogsModal from '../staff-modals/staffPayRateHistoryLogs';
import  { messages } from "../../../utils/language-en.json";

class StaffFormPositionsPayRates extends Component {
  state = { 
    positionsLoaded: false,
    staffPositions: [],
    historyLogsModalisOpen: false,
    historyLogsSelected: {},
    errors: {}
  }
  
  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.state.loading) 
      return this.populateStaffPositions(nextProps.state.data)
  }

  async populateStaffPositions(staff) {
    const { data: resStaffPositions } = await getStaffPositions( staff.department_id, staff.id) ;
    const positions = resStaffPositions.positions;
    this.setState({ staffPositions: staffPositionsMapToViewModel(positions), positionsLoaded: true });
  }

  handleAddStaffPosition = async ( staffId, positionItemId, regularPayRate, travelPayRate ) => {
    try {
      const { data } = await addStaffPositions( staffId, positionItemId, regularPayRate, travelPayRate ) ;
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.staffPositionAdded, { autoClose: 1000 });
      this.populateStaffPositions(this.props.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.staffPositionAlreadyAdded);
    }
    return;
    
  }

  handleDeleteStaffPosition = async (positionItemId) => {
    try {
      const { data } = await deleteStaffPositions( positionItemId ) ;
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.staffPositionDeleted, { autoClose: 1000 });
      this.populateStaffPositions(this.props.state.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.staffPositionAlreadyDeleted);
    }
    return;
  }

  handleUpdateStaffPosition = async (positionItemId, regularPayRate, travelPayRate) => {
    try {
      const { data } = await updateStaffPositions( positionItemId, regularPayRate, travelPayRate ) ;
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.staffPositionUpdated, { autoClose: 1000 });
      this.populateStaffPositions(this.props.state.data);
    } catch (ex) {
    }
    return;
  }

  handleChange = idx => ({ currentTarget: input }) => {
    const staffPositions = [ ...this.state.staffPositions ];
    const staffPositionsItem = staffPositions[idx];

    if(input.type === "checkbox") {
      staffPositionsItem[input.name] = input.checked;
      if (input.checked && !staffPositionsItem.id) {
        staffPositionsItem['regular_pay_rate'] = staffPositionsItem['minimum_rate'];
        if (staffPositionsItem['travel_rate']) 
          staffPositionsItem['travel_pay_rate'] = staffPositionsItem['minimum_rate']
        this.handleAddStaffPosition( 
          this.props.state.data.id, 
          staffPositionsItem.position_id, 
          staffPositionsItem.regular_pay_rate, 
          staffPositionsItem.travel_pay_rate
        )
      }
      else if (input.checked) {
        this.handleUpdateStaffPosition( staffPositionsItem.id,  staffPositionsItem.regular_pay_rate, staffPositionsItem.travel_pay_rate);
      } else {
        this.handleDeleteStaffPosition( staffPositionsItem.id )
      }
    }
    else {
      staffPositionsItem[input.name] = input.value;
    }

    this.setState({ staffPositions });
  }

  handleBlur = idx => ({ currentTarget: input }) => {
    if (this.state.oldValue === input.value) return;
    const errorKey = input.name + '_' + idx;
    const position = this.state.staffPositions[idx];
    const errors = { ...this.state.errors };
    const min = this.state.staffPositions[idx].minimum_rate;
    const max = this.state.staffPositions[idx].maximum_rate;
    const value = input.value;
    const errorMessage = this.validate(min, max, value);
    if (errorMessage) {errors[errorKey] = errorMessage}
    else delete errors[errorKey];
    const errorsCount = Object.keys(errors).length;
    this.setState({ errors });
    (errorsCount === 0) && this.handleUpdateStaffPosition( position.id, position.regular_pay_rate, position.travel_pay_rate);
  }

  validate = (min, max, value) => {
    if ( !(Number(value) >= Number(min) && Number(value) <= Number(max)))
      return messages.error.staffPositionValueOutOfRange;
  };

  handleFocus = ({ currentTarget: input }) => {
    this.setState({ oldValue: input.value  })
  }

  handleHistoryLogs = historyLogs => e => {
    e.preventDefault();

    this.setState({
      historyLogsSelected: historyLogs,
      historyLogsModalisOpen: true
    });
    
  };

  handleCloseModalHistoryLogs = () => {
    this.setState({
      historyLogsSelected: [],
      historyLogsModalisOpen: false
    });
  } 
    
  renderCurrencyInput( idx, name, label, value, item ) {
    if (!this.state.positionsLoaded) return;
    const hasErrorClassName = (this.state.errors[name + '_' + idx]) ? ' has-error ' : '';
    return (
      <div className={"form-group" + hasErrorClassName}>
        <div className="d-flex flex-column-reverse">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <input type="text" 
              className="form-control" 
              name={name}
              onChange={this.handleChange(idx)}
              aria-label={label} 
              value={item[name]} 
              disabled={!item.active}
              onBlur={this.handleBlur(idx)}
              onFocus={this.handleFocus}
            />
          </div>
          <label htmlFor={name}>{label}</label>
          {this.state.errors && <div className="alert alert-danger">{this.state.errors[name + '_' + idx]}</div>}
        </div>
      </div>
    );
  }


  render() {

    const { staffPositions, positionsLoaded, historyLogsModalisOpen, historyLogsSelected } = this.state;

    return (
      <section className="form-wrapper staff-psotions-payrates-form container-fluid">
    
        <h3 className="form-group-label">
          <i className="fas fa-shield-alt"></i>&nbsp;Position Type
        </h3>

        <div className="row align-items-center">
        { positionsLoaded && staffPositions && staffPositions.map( (item, idx) => {
          
          const disabledClass = (item.active) ? '' : ' disabled ';

          return (
            <div className="col-3" key={idx}>
              <div className={"card position-card" + disabledClass}>
                <div className="card-body">
                  <div className="checkbox">
                    <input name="active" id={idx} type="checkbox" 
                      checked={(item.active)}
                      value={item.active} 
                      onChange={this.handleChange(idx)} />
                    <label htmlFor={idx}></label>
                  </div>
                  <h5 className="card-title">
                    <i className={item.class}></i>&nbsp;{item.position_name}
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Pay Range - Min: ${item.minimum_rate} - Max: ${item.maximum_rate}
                  </h6>
                  <div className="row payrate-form-group">
                    <div className="col-6">
                      {this.renderCurrencyInput(idx, "regular_pay_rate", "Regular Pay Rate", item.regular_pay_rate, item )}
                    </div>
                    { item.travel_rate &&
                      <div className="col-6">
                        {this.renderCurrencyInput(idx, "travel_pay_rate", "Travel Pay Rate", item.travel_pay_rate, item )}
                      </div>
                    }
                  </div>
                  <button className="btn card-link" disabled={!item.active} onClick={this.handleHistoryLogs(item)}>
                    <i className="fas fa-list"></i>&nbsp;History Log
                  </button>
                </div>
              </div>
            </div>
          )
        })}
        </div>

        <StaffPayRateHistoryLogsModal
            isOpen={historyLogsModalisOpen}
            closeModal={this.handleCloseModalHistoryLogs}
            item={historyLogsSelected}
          />
      </section>
    );
  }
}

StaffFormPositionsPayRates.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default StaffFormPositionsPayRates;
