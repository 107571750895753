import React, { useContext } from 'react';
import logo from "../../../../../assets/cms-logo.svg";
import Table from '../../../../Common/table/table';
import { ReportContext } from '../../Report';
import { ReportResultContext } from '../ReportResults';

function ReportResultsBody() {
  const reportContext = useContext(ReportContext);
  const reportResultContext = useContext(ReportResultContext);
  const sortColumn = () => {};

  return (
    <div className="ReportResults-body" ref={el => reportResultContext.setPrintComponentRef(el)}>
      <div className="row justify-content-center">
        <div className="col-sm-11">
          <div className="ReportResults-info d-flex align-items-start">
            <div className="col-8">
              <h3>{reportContext.reportData.name}</h3>
              <p>{reportContext.reportResults.filters.deparmentFiltered}</p>
              <p>{reportContext.reportResults.filters.datesFiltered}</p>
            </div>
            <div className="col-4 text-right p-0">
              <img className="cms-logo img-fluid" src={logo} width="100px" alt={ process.env.REACT_APP_NAME } />
            </div>
          </div>
          <div className="col-12">
            <Table
              name={reportContext.reportResults.totalized ? 'report with-total ' : 'report'}
              columns={reportContext.reportData.columns}
              data={reportContext.reportResults.rows}
              sortColumn={sortColumn}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportResultsBody
