import React, { useContext } from 'react';
import { ReportsContext } from '../Reports';

function ReportsPageHeader() {
  const reportsContext = useContext(ReportsContext);
  return (
    <section className="ReportsPageHeader">
      <div className="row">
        <div className="col d-flex align-items-center">
          { reportsContext.props.match.params.id &&
          <button type="button" className="btn btn-back" title="Go Back to Reports"
            onClick={ () => reportsContext.props.history.replace('/reports') }
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          }
          <h1 className="page-title"><i aria-hidden="true" className="fa fa-file-text"></i>&nbsp;{reportsContext.pagetitle}</h1>
        </div>
      </div>
    </section>
  )
}

export default ReportsPageHeader
