export function fileTypeIcon(MIMEtype) {
  switch (MIMEtype) {
    case 'image/jpeg':
      return "far fa-file-image";
      
    case 'image/png':
      return "far fa-file-image";
      
    case 'image/gif':
      return "far fa-file-image";
      
    case 'text/plain':
      return "far fa-file-alt";
      
    case 'application/pdf':
      return "far fa-file-pdf";
      
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return "far fa-file-word";
      
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return "far fa-file-excel";
      
    case 'application/zip':
      return "far fa-file-archive";
      
    default:
      return "far fa-file";
      
  }
}

export default {
  fileTypeIcon
}
