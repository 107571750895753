import React from 'react';
import Chart from "react-google-charts";

function getChartData(summaryData, type) {
    const ratingsTableData = [[
        { type: 'string', label: 'Year' }, 
        { type: 'number', label: 'Shifts' },
        { type: 'number', label: 'Hours' },
        { type: 'number', label: 'Amount' },
        { type: 'number', label: 'Rating' }
    ]];
    const ratingsShiftsData = [[{ type: 'string', label: 'Year' }, 'Shifts']];
    const ratingsHoursData = [[{ type: 'string', label: 'Year' }, 'Hours']];
    const ratingsAmountData = [[{ type: 'string', label: 'Year' }, 'Amount']];
    const ratingsRatingData = [[{ type: 'string', label: 'Year' }, 'Rating']];

    switch(type) {
        case 'Table':
            summaryData.summary.forEach((element) => {
                ratingsTableData.push(
                    [
                        element.year,
                        parseInt(element.shifts_counter),
                        parseFloat(element.hours),
                        parseFloat(element.total_amount),
                        parseFloat(element.average_rating)
                    ]
              )
            });
            return ratingsTableData;
        case 'Shifts':
            summaryData.summary.forEach((element) => {
                ratingsShiftsData.push(
                    [
                        element.year,
                        parseInt(element.shifts_counter)
                    ]
              )
            });
            return ratingsShiftsData;
        case 'Hours':
            summaryData.summary.forEach((element) => {
                ratingsHoursData.push(
                    [
                        element.year,
                        parseFloat(element.hours)
                    ]
              )
            });
            return ratingsHoursData;
        case 'Amount':
            summaryData.summary.forEach((element) => {
                ratingsAmountData.push(
                    [
                        element.year,
                        parseFloat(element.total_amount)
                    ]
              )
            });
            return ratingsAmountData;
        case 'Rating':
            summaryData.summary.forEach((element) => {
                ratingsRatingData.push(
                    [
                        element.year,
                        parseFloat(element.average_rating)
                    ]
              )
            });
            return ratingsRatingData;
        default:
          return;
    }
}

function staffRatingsSummary(summaryData) {
    const ratingsTableData = getChartData(summaryData, 'Table');
    const ratingsShiftsData = getChartData(summaryData, 'Shifts');
    const ratingsHoursData = getChartData(summaryData, 'Hours');
    const ratingsAmountData = getChartData(summaryData, 'Amount');
    const ratingsRatingData = getChartData(summaryData, 'Rating');

  return (
    <React.Fragment>
        { summaryData.summary && summaryData.summary.length > 0
        ?
        <div className='RatingSummary btn d-flex flex-column'>
            <Chart
                chartType="Table"
                loader={<div>Loading</div>}
                data={ratingsTableData}
                options={{
                    width: '100%'
                }}
            />
            <div className="rating-chart-container">
                <div className="rating-chart">
                    <Chart
                        chartType="ColumnChart"
                        loader={<div>Loading</div>}
                        data={ratingsShiftsData}
                        options={{
                            backgroundColor: 'transparent',
                            width: 300,
                            height: 150,
                            colors: ['#2399C6'],
                            legend: {
                                position: 'bottom'
                            }
                        }}
                    />
                </div> 
                <div className="rating-chart">
                    <Chart
                        chartType="ColumnChart"
                        loader={<div>Loading</div>}
                        data={ratingsHoursData}
                        options={{
                            backgroundColor: 'transparent',
                            width: 300,
                            height: 150,
                            colors: ['#F75B11'],
                            legend: {
                                position: 'bottom'
                            }
                        }}
                    />
                </div>           
                <div className="rating-chart">
                    <Chart
                        chartType="ColumnChart"
                        loader={<div>Loading</div>}
                        data={ratingsAmountData}
                        options={{
                            backgroundColor: 'transparent',
                            width: 300,
                            height: 150,
                            colors: ['#1A7C01'],
                            legend: {
                                position: 'bottom'
                            }
                        }}
                    />
                </div>
                <div className="rating-chart">
                    <Chart
                        chartType="ColumnChart"
                        loader={<div>Loading</div>}
                        data={ratingsRatingData}
                        options={{
                            backgroundColor: 'transparent',
                            width: 300,
                            height: 150,
                            colors: ['#F92803'],
                            legend: {
                                position: 'bottom'
                            }
                        }}
                    />
                </div>
            </div>
        </div>
        : ''
    }
    </React.Fragment>
  )
}

export default staffRatingsSummary
