import React from 'react';

const VenuesFormInfo = props => {
  const optionsCMSdepartments = props.optionsCMSdepartments;
  const optionsStates = props.optionsStates;
  const optionsTimeZone = props.optionsTimeZone;
  
  return (
    <section className="form-wrapper venue-info-form container-fluid">
      <form onSubmit={props.handleSubmit}>
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            {props.renderInput("name", "Venue Name", "text", true )}
          </div>
          <div className="col-3">
            {props.renderSelect("state", "State", optionsStates, true )}
          </div>
          <div className="col-3">
            {props.renderSelect("department_id", "Department", optionsCMSdepartments, true )}
          </div>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            {props.renderInput("zip_code", "Zipcode", "text", false, 5 )}
          </div>
          <div className="col-3">
            {props.renderInput("address_1", "Address 1", "text", true )}
          </div>
          <div className="col-3">
            {props.renderInput("address_2", "Address 2", "text" )}
          </div>
        </div>
        <div className="row justify-content-between align-items-center">
          <div className="col-3">
            {props.renderInput("city", "City", "text", true )}
          </div>
          <div className="col-3">
            {props.renderSelect("time_zone_id", "Time Zone", optionsTimeZone, true )}
          </div>
          <div className="col-3">
          </div>
        </div>
        <div className="form-footer">
          <div className="row justify-content-end align-items-center">
            <div className="col-auto">
              {props.renderButton("Save Venue Info")}
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}

export default VenuesFormInfo;
