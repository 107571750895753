import React, { Component } from 'react';
import { getApplicantsDepartment } from "../../../services/widgetsService";
import FilterByYear from "../../Common/filters/FilterByYear";
import Chart from "react-google-charts";
import { messages } from '../../../utils/language-en.json';
 
class ApplicantDepartmentWidget extends Component {
  mounted = false;
  state = {
    applicants: [],
    hasData: false
  }

  async componentDidMount() {
    this.mounted = true;
    this.mounted && this.getApplicantsDepartmentData(new Date().getFullYear());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getApplicantsDepartmentData = async (year) => {
    const { data } = await getApplicantsDepartment(year);
    const applicantsData =  [['Department', 'Qty']];
    var hasData = false;

    data.applicants.forEach((element) => {
      if (parseInt(element.total) > 0) {
        hasData = true;

        applicantsData.push(
          [element.name, parseInt(element.total)]
        )
      }
    });

    this.setState({applicants: applicantsData});
    this.setState({hasData: hasData});
    this.setState({title: data.title});
  }

  handleYearChange = async (itemSelected) => {
    const yearSelected = (itemSelected) ? itemSelected.value: new Date().getFullYear();
    this.getApplicantsDepartmentData(yearSelected);
  };

  render() {
    const {
      applicants,
      hasData,
      title
    } = this.state;

    return (
      <div className="col-auto"> 
        <div className='DashboardList-item btn d-flex flex-column'>
          <h4><i className="fas fa-solid fa-clipboard"></i>&nbsp;{title}</h4>
          <FilterByYear handleYearChange={this.handleYearChange}/>
          { !hasData 
            ? <div className="no-data">{messages.emptydata.widgetsMessage}</div>
            : 
            <Chart
              chartType="BarChart"
              loader={<div>Loading</div>}
              data={applicants}
              options={{
                backgroundColor: 'transparent',
                width: '100%',
                height: 180,
                legend: {
                  position: 'none'
                }
              }}
            />
          }
        </div>
      </div>
    )
  }
}

export default ApplicantDepartmentWidget;