import React from 'react';
import Input from '../../../../Common/form/input';

const EventExpense = ({
  newExpenseDescription,
  handleChangeNewDescription,
  newExpenseAmount,
  handleChangeNewAmount,
  handleAddExpense
  }) => {
  return (
    <div className="add-expense">
      <div className="card-title">
        Create New Expense
      </div>
      <div className="row">
        <div className="col-9">
          <Input 
            type="text"
            placeholder={"Description*"}
            name={'newDescription'}
            value={newExpenseDescription}
            onChange={handleChangeNewDescription}
          />
        </div>
        <div className="col-9">
          <Input 
            type="number"
            placeholder={"Amount*"}
            name={'newAmount'}
            value={newExpenseAmount}
            onChange={handleChangeNewAmount}
          />
        </div>
        <div className="col-3 pl-0">
          <button 
            disabled={!newExpenseDescription || !newExpenseAmount}
            className="btn btn-secondary btn-block" 
            onClick={() => handleAddExpense()}
          > 
            Add
          </button>
        </div>
      </div>
    </div>
  );
}
export default EventExpense;