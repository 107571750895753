import React, { useContext, useReducer, useEffect, useState } from 'react';
import _ from "lodash";
import moment from 'moment';
import { toast } from "react-toastify";
import { messages } from "../../../../utils/language-en.json";
import { getEventCheckInList, updateEventCheckIn } from '../../../../services/eventsService';
import usePreviousValue from '../../../../utils/usePreviousValue';
import { paginate } from "../../../../utils/paginate";
import EventDatesNav from '../EventTools/EventDatesNav/EventDatesNav';
import { EventFetchContext, SwipeableViewsContext } from '../Event';
import EventCheckInList from './EventCheckInList';
import EventsCheckInSummary from './EventCheckInSummary';
import FilterByShiftStatus from "../../../Common/filters/FilterByShiftStatus";
import UpdateCheckIn from './updateCheckIn/updateCheckIn';
import './EventCheckIn.scss';

export const EventCheckInContext = React.createContext();

const initialEventDate = null;
const initialSearchQuery= '';
const initialEventCheckInList = {
  loading: true,
  data: null,
  allData: null,
  error: null,
  selected: null
}
const initialEventCheckInSummary = {
  loading: true,
  data: null,
  error: null
}
const initialPagination = {
  currentPage: 1,
  pageSize: 1000,
  totalCount: 0
}

const initialSortColumn = {
  path: 'name', 
  order: 'asc'
}

const reducerEventCheckIn = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_DATE':
      return action.VALUE
    case 'CHECKIN_LIST':
      return {...state, data: action.VALUE}
    case 'CHECKIN_LIST_ALL_DATA':
      return {...state, allData: action.VALUE}
    case 'CHECKIN_LIST_LOADING':
      return {...state, loading: action.VALUE}
    case 'SEARCH_CHECKIN_LIST':
      return action.VALUE
    case 'PAGINATION_TOTAL_COUNT':
      return {...state, totalCount: action.VALUE}
    case 'PAGINATION_CURRENT_PAGE':
      return {...state, currentPage: action.VALUE}
    case 'SORT_COLUMN':
      return action.VALUE
    case 'CHECKIN_SUMMARY':
      return {...state, summaryData: action.VALUE}
    default:
      return state;
  }
}

function EventCheckIn({ event }) {
  const eventFetchContext = useContext(EventFetchContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [eventdate, dispatchEventDate] = useReducer(reducerEventCheckIn, initialEventDate);
  const [eventCheckInList, dispatchEventCheckInList] = useReducer(reducerEventCheckIn, initialEventCheckInList);
  const [eventCheckInSummary] = useReducer(reducerEventCheckIn, initialEventCheckInSummary);
  const [searchQuery, dispatchSearchQuery] = useReducer(reducerEventCheckIn, initialSearchQuery);
  const [pagination, dispatchPagination] = useReducer(reducerEventCheckIn, initialPagination);
  const [sortColumn, dispatchSortColumn] = useReducer(reducerEventCheckIn, initialSortColumn);
  const prevEventdateValue = usePreviousValue(eventdate);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState([]);
  const [optionsItemSelected, setOptionsItemSelected] = useState([]);

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: event.events_dates[0] });
  },[]);

  useEffect(() => {
    eventFetchContext.fetchData.EVENT_CHECKIN && fetchCheckInList( event.id, eventdate.id, 1 );
    eventFetchContext.fetchData.EVENT_CHECKIN && eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_CHECKIN', VALUE: false });
  }, [eventFetchContext.fetchData.EVENT_CHECKIN]);

  useEffect(() => {
    event && eventdate && fetchCheckInList( event.id, eventdate.id, 1 );
    prevEventdateValue && prevEventdateValue.id !== eventdate.id && dispatchPagination({ TYPE: 'PAGINATION_CURRENT_PAGE', VALUE: 1 });
  }, [
    event, 
    eventdate,
    eventCheckInSummary
  ]);

  useEffect(() => {
    eventCheckInList.allData && buildListData();
  }, [
    pagination.currentPage,
    sortColumn
  ]);

  useEffect(() => {
    searchQuery && dispatchPagination({ TYPE: 'PAGINATION_CURRENT_PAGE', VALUE: 1 });
    eventCheckInList.allData && buildListData();
  }, [
    eventCheckInList.allData,
    searchQuery
  ]);

  const openModalUpdate = item => {
    setUpdateModalIsOpen(true);
    setItemSelected(item);
  }
  
  const closeModalUpdate = () => {
    setUpdateModalIsOpen(false);
  }

  const handleChange = ({ currentTarget: input }) => {
    let value = input.value;
    const data = { ...optionsItemSelected };
    data[input.name] = value;
    setOptionsItemSelected(data);
  }
  
  const handleUpdate = async () => {
    try {
      let currentDate = moment().format();
      let rounded = Math.round(moment(currentDate).minute() / 15) * 15;  
      if (rounded < 10) {
        rounded = '0' + rounded;
      }
      currentDate = moment().minutes(rounded);

      let options = optionsItemSelected;
      const dataToUpdate = {
        staff_event_id: itemSelected.staff_event_id,
        event_id: event.id,
        field: itemSelected.field,
        date: currentDate.format('YYYY-MM-DD HH:mm'),
        shirt_number: (options) ? options.shirt_number : '',
        jacket_number: (options) ? options.jacket_number : '',
        radio_number: (options) ? options.radio_number : '',
        flagger_number: (options) ? options.flagger_number : '',
      };

      const { data } = await updateEventCheckIn(dataToUpdate);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      if (itemSelected.field === 'start_date') {
        toast.success(messages.success.staffCheckedIn, { autoClose: 1000 });
      } else {
        toast.success(messages.success.staffCheckedOut, { autoClose: 1000 });
      }

      setOptionsItemSelected(null);
      setUpdateModalIsOpen(false);
      setItemSelected([]);
      fetchCheckInList( event.id, eventdate.id, 1 );
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
    } catch (ex) {
    }
  };

  const buildListData = () => {
    let filtered = eventCheckInList.allData;
    
    if (searchQuery) {
      filtered = eventCheckInList.allData.filter(m =>
        m.staff_name.toLowerCase().includes(searchQuery.toLowerCase()) |
        m.position_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    const checkInListData = paginate(sorted, pagination.currentPage, pagination.pageSize);

    dispatchEventCheckInList({ TYPE: 'CHECKIN_LIST', VALUE: checkInListData });
    dispatchPagination({ TYPE: 'PAGINATION_TOTAL_COUNT', VALUE: filtered.length });
  }

  const fetchCheckInList = async (EVENT_ID, EVENT_DATE_ID, STATUS) => {
    const fetchProperties = {
      params: {
        event_id: EVENT_ID,
        event_date_id: EVENT_DATE_ID,
        staff_status: STATUS
      }
    }
    try {
      const { data: resCheckInList } = await getEventCheckInList(fetchProperties);
      dispatchEventCheckInList({ TYPE: 'CHECKIN_LIST_ALL_DATA', VALUE: resCheckInList.checkin });
      dispatchEventCheckInList({ TYPE: 'CHECKIN_SUMMARY', VALUE: resCheckInList.summary });
      dispatchEventCheckInList({ TYPE: 'CHECKIN_LIST_LOADING', VALUE: false });
    } catch (ex) { }
  }

  const handleShiftStatusChange = async (status) => {
    const statusSelected = status ? status.value : null;
    fetchCheckInList( event.id, eventdate.id, statusSelected )
  };

  // ** HANDLE
  // *********************************************************
  const handleChangeEventDate = (dateIndex) => {
    const eventDateSelected = event.events_dates[dateIndex.value];
    dispatchEventDate({TYPE: 'EVENT_DATE', VALUE: eventDateSelected });
  }

  return (
    <EventCheckInContext.Provider
      value={{
        event,
        eventCheckInList,
        dispatchEventCheckInList,
        eventdate,
        fetchCheckInList,
        sortColumn,
        dispatchSortColumn,
        eventCheckInSummary,
        updateModalIsOpen,
        itemSelected,
        openModalUpdate
      }}
    >
      <section className="EventCheckIn form-wrapper container-fluid">
        <section className="EventCheckIn-header">
          <div className="row justify-content-between align-items-center">
            <div>
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <FilterByShiftStatus 
                  handleStatusChange={handleShiftStatusChange} 
                />
                <div className="SearchBox">
                  <input
                    type="text"
                    name="query"
                    className="form-control"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={e => dispatchSearchQuery({ TYPE: 'SEARCH_CHECKIN_LIST', VALUE: e.currentTarget.value})}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-2">
              <EventDatesNav
                event={event}
                eventDateSelected={eventdate}
                handleChangeEventDate={handleChangeEventDate}
              />
            </div>
            <div className="col-sm-4 d-flex justify-content-end">
              <EventsCheckInSummary/>
            </div>
          </div>
        </section>
        <EventCheckInList />
        <UpdateCheckIn
            isOpen={updateModalIsOpen}
            onRequestClose={closeModalUpdate}
            closeModal={closeModalUpdate}
            item={itemSelected}
            onCheckIn={handleUpdate}
            handleChange={handleChange}
          />
      </section>
    </EventCheckInContext.Provider>
  )
}

export default EventCheckIn
