import React from 'react';
import _ from 'lodash';
import Joi from "joi-browser";
import { toast } from 'react-toastify';
import { messages } from '../../../utils/language-en.json';
import RedirectToLogin from '../../../utils/RedirectToLogin.jsx';
import { getCurrentUser } from '../../../services/authService.js';
import { getDepartmentList } from '../../../services/departmentService';
import { getCustomerListNotFlagged } from '../../../services/customersService';
import { getVenues, getVenuesListConfig } from '../../../services/venuesService';
import { validateEventNumber, addEventMiddleware } from '../../../services/eventsService';
import { EventCreateSchema } from '../../../models/eventsSchema';
import Loading from '../../Common/loading/loading';
import Form from '../../Common/form/form';
import EventInfoDates from './EventInfo/EventInfoItems/EventInfoDates';
import { filterDepartmentsUser } from '../../../utils/value-formats';

class EventCreate extends Form {
  constructor(props) {
    super(props)

    this.state = {
      departmentData: {},
      departmentHourlyRate: 0,
      loading: true,
      data: {
        id: null,
        name: '',
        department_id: '',
        customers_id: '',
        venues_id: '',
        venuesconf_id: '',
        event_number: '',
        target_hourly_rate: ''
      },
      errors: {},
      department: {
        options: [],
        required: true,
        selected: null
      },
      eventCustomer: {
        options: [],
        disabled: true,
        required: true,
        selected: null,
        placeholder: {
          init: 'Select a Department first',
          noData: 'No Customers available for the selected Department',
          data: 'Select a Customer'
        }
      },
      eventVenue: {
        options: [],
        disabled: true,
        required: true,
        selected: null,
        placeholder: {
          init: 'Select a Customer first',
          noData: 'No Venues available for the selected Customer',
          data: 'Select a Venue'
        }
      },
      eventVenueConfig: {
        options: [],
        disabled: true,
        required: true,
        selected: null,
        placeholder: {
          init: 'Select a Venue first',
          noData: 'No Configurations available for the selected Venue',
          data: 'Select a Venue Configuration'
        }
      },
      eventDates: []
    }
    this.schema = EventCreateSchema();
  }

  SELECT_NAME = {
    DEPARTMENT: 'department_id',
    EVENT_CUSTOMER: 'customers_id',
    EVENT_VENUE: 'venues_id',
    EVENT_VENUE_CONFIG: 'venuesconf_id'
  }

  SELECT_STATE = {
    customers_id: 'eventCustomer',
    venues_id: 'eventVenue',
    venuesconf_id: 'eventVenueConfig',
  }

  async componentDidMount() {
    const { data } = await getDepartmentList();
    this.setState({ departmentData: data.department });
    const departments = filterDepartmentsUser(data.department, 'Select Department');
    this.setState({ department: { ...this.state.department, options: departments} });
    Object.keys(this.SELECT_STATE).map(item => 
      this.setState({
        [this.SELECT_STATE[item]]:{ ...this.state[this.SELECT_STATE[item]], options: this.buildSelectOptions(this.state[this.SELECT_STATE[item]].placeholder.init) }
      })
    );
    this.setState({ loading: false });
  }

  buildSelectOptions = (placeholder, options) => {
    if (options) return [ { id: '', name: placeholder }, ...options ];
    return [ { id: '', name: placeholder } ];
  }

  buildSelectDependencies = async (name, id) => {

    const resetData = (fields) => {
      fields.map(item => {
        this.state.data[item] = '';
        this.state.errors[item] = '';
        return this.setState({
          [this.SELECT_STATE[item]]:{ ...this.state[this.SELECT_STATE[item]], options: this.buildSelectOptions(this.state[this.SELECT_STATE[item]].placeholder.init), disabled: true, selected: null },
        })
      });
    }

    const buildSelectData = (name, data) => {
      const stateComponent = this.state[this.SELECT_STATE[name]];
      const disabled = data.length === 0 ? true : false;
      const selectOptions = data.length === 0 
        ? this.buildSelectOptions(stateComponent.placeholder.noData) 
        : this.buildSelectOptions(stateComponent.placeholder.data, data) ;
      this.setState({ [this.SELECT_STATE[name]]: { ...stateComponent, options: selectOptions, disabled: disabled }});
    }

    switch (name) {
      case this.SELECT_NAME.DEPARTMENT:
        resetData([ this.SELECT_NAME.EVENT_CUSTOMER, this.SELECT_NAME.EVENT_VENUE, this.SELECT_NAME.EVENT_VENUE_CONFIG ]);
        if (id) {
          const { data } = await getCustomerListNotFlagged(Number(id));
          buildSelectData(this.SELECT_NAME.EVENT_CUSTOMER, data.customer);
        }
        this.state.departmentData.forEach((element) => {
          if (parseInt(id) === parseInt(element.id)) {
            this.setState({ departmentHourlyRate: element.target_hourly_rate });
            this.state.data['target_hourly_rate'] = element.target_hourly_rate;
          }
        });
        break;
      case this.SELECT_NAME.EVENT_CUSTOMER:
        resetData([ this.SELECT_NAME.EVENT_VENUE, this.SELECT_NAME.EVENT_VENUE_CONFIG ]);
        if (id) {
          const { data } = await getVenues({ DEPARTMENT_ID: [this.state.data.department_id] });
          const eventVenues = data.venue;
          eventVenues.forEach((element, index) => {
            eventVenues[index] = {...element};
          });
          buildSelectData(this.SELECT_NAME.EVENT_VENUE, eventVenues);
        }
        break;
      case this.SELECT_NAME.EVENT_VENUE:
        resetData([ this.SELECT_NAME.EVENT_VENUE_CONFIG ]);
        if (id) {
          const { data } = await getVenuesListConfig(id, false);
          const sortData = _.orderBy(data.venues_conf, ['name'], ['asc']);
          buildSelectData(this.SELECT_NAME.EVENT_VENUE_CONFIG, sortData);
        }
        break;
    
      default:
        break;
    }
  }

  doHandleBlur = input => {
    if (input.name ==='event_number') 
      this.validateEventNumber(input);
  }

  doHandleChange = input => {
    this.buildSelectDependencies(input.name, input.value);
    return input.value;
  }

  doSubmit = async () => {
    const response = await addEventMiddleware(this.state.data, this.state.eventDates);

    if (!response.success) {
      toast.error(response.message);
      return
    }

    toast.success(response.message, { autoClose: 1000 });
    this.props.history.push("/events/" + response.id);
  };

  dispatchEventDates = (eventDates) => {
    this.setState({ eventDates, eventDatesChange: true });
  };

  // ** VALIDATIONS 
  // ************************************************************* 
  validateEventNumber = async input => {
    const errors = { ...this.state.errors };
    const { data: resValidate} = await validateEventNumber(input.value);
    const isValid = resValidate.valid;
    if ( !isValid ) errors[input.name] = messages.error.eventNumberExist;
    this.setState({ errors });
  }

  validateEventInfo = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    const hasErrors = (Object.keys(this.state.errors).length > 0) ? true : false ;
    if (!error && !hasErrors && this.state.eventDates.length > 0)
      return false
    return true;
  }

  render() {
    const { loading, department, eventCustomer, eventVenue, eventVenueConfig } = this.state;
    return (
      !getCurrentUser()
        ? <RedirectToLogin location={this.props.location} />
        : loading 
          ? <Loading type="page" />
          : <main className="EventCreate page-wrapper container-fluid">
              <section className="page-header">
                <div className="row justify-content-between">
                  <div className="col-4">
                    <h1 className="page-title"><i aria-hidden="true" className="far fa-calendar-alt"></i>&nbsp;Create New Event</h1>
                  </div>
                </div>
              </section>
              <section className="page-body">
                <section className="EventInfoForm form-wrapper container-fluid">
                  <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="form-body">
                      <div className="row justify-content-around align-items-start">
                        <div className="col-5">
                          { this.renderInput("name", "Event Name", "text", true ) }
                          { this.renderSelect("department_id", "Department", department.options, department.required) }
                          { this.renderSelect("customers_id", "Customer", eventCustomer.options, eventCustomer.required, eventCustomer.disabled) }
                          { this.renderSelect("venues_id", "Venue", eventVenue.options, eventVenue.required, eventVenue.disabled) }
                          { this.renderSelect("venuesconf_id", "Configuration", eventVenueConfig.options, eventVenueConfig.required, eventVenueConfig.disabled) }
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              { this.renderInput("event_number", "Event Number", "text", true) }
                            </div>
                            <div className="col-6">
                              { this.renderReadOnlyField("Target Hourly Rate", "$" + Number(this.state.departmentHourlyRate).toFixed(2)) }
                            </div>
                          </div>
                          <EventInfoDates 
                            eventDates={this.state.eventDates}
                            dispatchEventDates={this.dispatchEventDates}
                            eventStatus='Pre-Event' 
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-footer">
                      <div className="row justify-content-around align-items-center">
                        <div className="col-5"></div>
                        <div className="col-6 text-right">
                          <button disabled={this.validateEventInfo() } className="btn btn-primary" type="submit">
                            Save Event
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </section>
            </main>
    )
  }
}

export default EventCreate
