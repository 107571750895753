import React, { Component } from 'react';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import _ from "lodash";
import auth from "../../services/authService";
import { getDepartmentList } from "../../services/departmentService";
import { getStaff, deleteStaff, updateWorkingStatus } from "../../services/staffService";
import { getPaycomSyncLatest } from "../../services/widgetsService";
import { messages } from "../../utils/language-en.json";
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import Pagination from "../Common/pagination/pagination";
import DeleteConfirmation from '../Common/delete-confirmation/deleteConfirmation';
import SearchBox from "../Common/searchBox";
import StaffList from './staffList';
import StaffPaycomSyncModal from './staff-modals/staffPaycomSync';
import FilterByDepartment from "../Common/filters/FilterByDepartment";
import FilterByWorkingStatus from "../Common/filters/FilterByWorkingStatus";
import "./staff.scss";

class Staff extends Component {
  mounted = false;
  state = {
    dataLoading: true,
    departments: [],
    staff: [],
    filterDepartment: [],
    filterWorkingStatus: 'Active',
    currentPage: 1,
    pageSize: 10,
    sortColumn: { path: ['last_name'], order: "asc"},
    searchQuery: "",
    totalCount: 0,
    deleteConfirmationModalIsOpen: false,
    deleteItemSelected: [],
    paycomSyncModalisOpen: false,
    paycomLastest: []
  }

  async componentDidMount() {
    this.mounted = true;
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = sortDepartments;

    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) {
      this.setState({ filterDepartment: [userDepartmentId], currentPage: 1 });
    } else {
      this.setState({ filterDepartment: [], currentPage: 1 });
    }
    
    const { data: resPaycomLastest } = await getPaycomSyncLatest();
    const paycomLastest = resPaycomLastest.log;
    this.setState({ paycomLastest: paycomLastest });
    
    this.mounted && this.setState({ departments });
    this.mounted && this.populateStaff();
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  populateStaff = async () => {
    try {
      const { data: resStaff } = await getStaff({
        DEPARTMENT_ID: this.state.filterDepartment, 
        WORKING_STATUS: this.state.filterWorkingStatus,
        LIMIT: this.state.pageSize, 
        PAGE: this.state.currentPage, 
        ORDER_BY: this.state.sortColumn.path, 
        SORT_ORDER: this.state.sortColumn.order,
        SEARCH: this.state.searchQuery
      });
      this.setState({ 
        staff: resStaff.staff, 
        totalCount: resStaff.count,
        dataLoading: false
      });
    } catch (error) { }
  }

  handleSearch = async query => {
    await this.setState({ searchQuery: query, currentPage: 1 });
    this.populateStaff();
  };

  handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    await this.setState({ filterDepartment: departmentSelected, currentPage: 1 });
    this.populateStaff();
  };

  handleWorkingStatusChange = async (itemSelected) => {
    const workingStatusSelected = itemSelected.value;
    await this.setState({ filterWorkingStatus: workingStatusSelected, currentPage: 1 });
    this.populateStaff();
  };

  handleSort = async sortColumn => {
    await this.setState({ sortColumn });
    this.populateStaff();
  };

  handlePageChange = async page => {
    await this.setState({ currentPage: page });
    this.populateStaff();
  };

  handleDelete = async () => {
    const staffItem = this.state.deleteItemSelected;
    const originalStaffList = this.state.staff;
    const staff = originalStaffList.filter(m => m.id !== staffItem.id);
    this.setState({ staff, deleteConfirmationModalIsOpen: false, deleteItemSelected:[] });

    try {
      const { data } = await deleteStaff(staffItem.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage);
        this.setState({ staff: originalStaffList });
        return
      }
      toast.success(messages.success.staffDeleted, { autoClose: 1000 });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.staffReadyDeleted);

      this.setState({ staff: originalStaffList });
    }
  };

  handleOnChangeWorkingStatus = async ({ currentTarget: input}) => {
    const workingStatus = (input.checked) ? "Active" : "Inactive";
    const { data } = await updateWorkingStatus(input.id, workingStatus);
    const success = data.success;

    if (!success) {
      const errorMessage = messages.error.staffOnChangeWorkingStatus;
      toast.error(errorMessage);
      return
    }

    toast.success(messages.success.staffWorkingStatusUpdate, { autoClose: 1000 });
    const staffList =  [...this.state.staff];
    const index = staffList.findIndex( (item) => item.id === Number(input.id));
    staffList[index] = { ...staffList[index] };
    staffList[index].working_status = workingStatus;
    this.setState({ staff : staffList });

  };

  openModalDeleteConfirmation = staffItem => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: staffItem });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  openModalPaycomSync = () => {
    this.setState({ paycomSyncModalisOpen: true });
  }
  
  closeModalPaycomSync = () => {
    this.setState({ paycomSyncModalisOpen: false });
  }

  render() {
    const { 
      dataLoading,
      overLoading,
      departments,
      staff,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      totalCount,
      deleteConfirmationModalIsOpen,
      deleteItemSelected,
      totalItemsCount,
      pageWrapperHeight,
      paycomSyncModalisOpen,
      paycomLastest
    } = this.state;
    const { user } = this.props;
    const authPermissions = auth.getCurrentUserPermisions();

    return (
      <main className="staff-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fa fa-users"></i>&nbsp;Staff</h1>
            </div>
            <div className="col text-right">
              {authPermissions["module_staff_add"] &&
                <Link
                  to="/staff/create/"
                  className="btn btn-primary"
                >
                  Add Staff
                </Link>
              }&nbsp;
              {authPermissions["module_staff_paycom_sync"] ?
              <button className="btn btn-primary" type="button" onClick={this.openModalPaycomSync}>
                Paycom Sync
              </button>
              : 
                <em><strong>Last Sync:</strong> {paycomLastest.end_date}</em>
              }
            </div>
          </div>
        </section>
        <section className="page-body">
          <section className="table-console d-flex justify-content-between">
            <div className="table-console-right">
              <div className="table-console-left d-flex align-items-end justify-content-center">
                <FilterByDepartment
                  departments={departments}
                  handleDepartmentChange={this.handleDepartmentChange}
                />
                <FilterByWorkingStatus
                  handleWorkingStatusChange={this.handleWorkingStatusChange}
                />
              </div>
            </div>
            <div className="table-console-left d-flex">
              <div className="search">
                <SearchBox value={searchQuery} onChange={this.handleSearch} />
              </div>
            </div>
          </section>
          <StaffList
            staff={ staff }
            user={ user }
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onDelete={this.openModalDeleteConfirmation}
            onWorkingStatus={this.handleOnChangeWorkingStatus}
            isLoading={ dataLoading }
            overLoading={ overLoading }
          />
          { !dataLoading && (
            <Pagination
              itemsCount={totalCount}
              totalItemsCount={totalItemsCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
              overLoading={overLoading}
            />
          )}
          <DeleteConfirmation
            isOpen={deleteConfirmationModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModalDeleteConfirmation}
            closeModal={this.closeModalDeleteConfirmation}
            itemName={deleteItemSelected.first_name + ' ' + deleteItemSelected.last_name}
            onDelete={this.handleDelete}
          />
          <StaffPaycomSyncModal
            isOpen={paycomSyncModalisOpen}
            closeModal={this.closeModalPaycomSync}
            item={paycomLastest}
          />
        </section>
      </main>
    );
  }
}

export default Staff;
