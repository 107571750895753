import React, { Component } from 'react';
import _ from "lodash";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Moment from 'react-moment';
import './notesManager.scss'
import { messages } from "../../../utils/language-en.json";
import { getNotes, saveNote, deleteNote, getNoteById } from '../../../services/notesService';
import DeleteConfirmation from '../delete-confirmation/deleteConfirmation.jsx';
import EmptyData from '../emptydata/emptyData.jsx';
import EmptyDataIcon from "../../../assets/cms-icon-empty-files.svg";
import Input from '../form/input.jsx';
import Textarea from '../form/textarea.jsx';

class NotesManager extends Component {
  state = {
    dataLoading: true,
    notes: [],
    note: {},
    deleteConfirmationModalIsOpen: false,
    deleteItemSelected: ""
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loading)
      return this.populateNotes();
  }

  componentWillMount() {
    this.initNoteModel();
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  initNoteModel = () => {
    const note = {
      title: "",
      data: "",
      component: this.props.component,
      component_id: this.props.itemId
    };
    this.setState({ note })
  }

  async populateNotes() {
    const component = this.props.component;
    const { data } = await getNotes( component, this.props.itemId) ;
    const sortData = _.orderBy(data.notes, ['date'], ['desc']);
    const notes = sortData;
    this.setState({ notes: notes, dataLoading: false });
  }

  handleChange = ({ currentTarget: input }) => {
    const note = { ...this.state.note }
    note[input.name] = input.value;
    this.setState({ note });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.handleSave();
  }

  handleSave = async () => {
    const {note} = this.state
    try {
      const { data } = await saveNote(note)
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      (note.id) ? toast.success(messages.success.noteUpdated) : toast.success(messages.success.noteCreated, { autoClose: 1000 });
      this.populateNotes();
      const { data: resNote } = await getNoteById(data.id)
      this.setState({ editNote: false, noteSelected: data.id, note: resNote.note });
      setTimeout(() => {
        this.props.refreshSwipeableViewsHeight();
      }, 100);
    } catch (ex) {
    }
    return; 
  }

  handleSelectNote = note => {
    note = {...note}
    note.title = (note.title) ? note.title : '';
    this.setState({ note , noteSelected: note.id, editNote: false });
  }

  handleEditNote = (boolean) => {
    this.setState({ editNote: boolean });
  }

  handleCreateNote = () => {
    this.initNoteModel();
    this.setState({ noteSelected: '' });
  }

  renderNoteForm = () => {
    const { note, editNote } = this.state;
    return (
      <form className="note-form" onSubmit={this.handleSubmit}>
        <Input
          type="text"
          name="title"
          value={note['title']}
          label="Title"
          onChange={this.handleChange}
        />
        <Textarea
          name="data"
          value={note['data']}
          label="Note"
          onChange={this.handleChange}
          rows="7"
        />
        <p className="text-right d-flex flex-row-reverse">
          <button disabled={!(note.title && note.data)} className="btn btn-primary btn-sm" type="submit">
            Save Note
          </button>
          { editNote &&
            <button  className="btn btn-outline-secondary btn-sm btn-cancel" 
              type="cancel" 
              onClick={() => this.handleEditNote(false)}>
              Cancel
            </button>
          }
        </p>
      </form>
    )
  }

  renderNoteView = () => {
    const { note } = this.state;
    return (
      <React.Fragment>
        <div className="note-view">
          <p className="date"> <Moment date={note.date} format="MM/DD/YYYY" /></p>
          <h4 className="title">{note.title}</h4>
          <p className="data">
            {note.data}
          </p>
          <div className="note-console d-flex justify-content-between align-items-center">
            <span className="author-info">
              <b>Author:</b>&nbsp;{note.user}
            </span>
            <i className="fa fa-pencil d-flex justify-content-center align-items-center" 
              aria-hidden="true"
              onClick={() => this.handleEditNote(true)}
              title="Edit Note"
            ></i>
          </div>
        </div>
        <div className="note-view-console d-flex justify-content-center">
          <button className="btn btn-create-note btn-sm d-flex align-items-center" onClick={() => this.handleCreateNote()} title="Create New Note">
            <i className="fa fa-plus"></i>
            Create New Note
          </button>
        </div>
      </React.Fragment>
    )
  }

  openModalDeleteConfirmation = (item) => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: "" });
  }

  handleDeleteFile = async () => {
    const itemID = this.state.deleteItemSelected.id;
    const originalNotes = this.state.notes;
    const notes = originalNotes.filter(m => m.id !== itemID);
    this.setState({ notes, deleteConfirmationModalIsOpen: false, deleteItemSelected: "" });

    try {
      const { data } = await deleteNote(this.state.deleteItemSelected.id);
      const success = data.success;
      if (!success) {
        const errorMessage = messages.error.noteAlreadyDeleted;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.noteDeleted, { autoClose: 1000 });
      (itemID === this.state.noteSelected) && this.initNoteModel();
      this.populateNotes();
      this.props.refreshSwipeableViewsHeight();
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error(messages.error.noteAlreadyDeleted);

      this.setState({ notes: originalNotes });
    }
  }

  render() { 
    const { 
      dataLoading,
      notes,
      note,
      deleteConfirmationModalIsOpen,
      deleteItemSelected,
      noteSelected,
      editNote
    } = this.state;

    return ( 
      <section className="notes-manager form-wrapper container-fluid">
        <div className="row">
          <div className="col-sm-4 col-lg-3">
            { note.id && !editNote &&
              this.renderNoteView()
            }
            { note.id && editNote &&
              this.renderNoteForm()
            }
            { !note.id  &&
              this.renderNoteForm()
            }
          </div>
          <div className="col-sm-8 col-lg-9">
            <div className="row">
              { dataLoading && (
                <div className="loader">
                  <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                </div>
              ) }
              { !dataLoading && notes.length === 0  &&
                <EmptyData 
                  type={"notes"} 
                  icon={EmptyDataIcon}
                  title={messages.emptydata.notesTitle}
                  messsage={messages.emptydata.notesMessage}
                />
              }
              { notes.length > 0 &&
                notes.map( (note) => {
                  const cardClassName = (noteSelected === note.id) ? 'card notes-card justify-content-center active' : 'card notes-card justify-content-center';
                  return (
                    <div key={note.id} className={cardClassName}>
                      <i className="fa fa-trash" aria-hidden="true" onClick={() => this.openModalDeleteConfirmation(note)}></i>
                      <div className="file-info d-flex justify-content-center align-items-center" onClick={() => this.handleSelectNote(note)}>
                        <div className="file-info-wrapper">
                          <div className="file-icon">
                            <i className="far fa-sticky-note"></i>
                          </div>
                          <p className="file-name">{note.title}</p>
                          <p className="file-description">Author: {note.user}</p>
                          <p className="file-date"> <Moment date={note.date} format="MM/DD/YYYY" /></p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <DeleteConfirmation
              isOpen={deleteConfirmationModalIsOpen}
              onRequestClose={this.closeModalDeleteConfirmation}
              closeModal={this.closeModalDeleteConfirmation}
              itemName={deleteItemSelected.title}
              onDelete={this.handleDeleteFile}
            />
          </div>
        </div>
      </section>
    );
  }
}

NotesManager.contextTypes = {
  swipeableViews: PropTypes.object.isRequired,
};

export default NotesManager;
