import React, { useContext } from 'react';
import { EventStaffAssignedContext } from '../EventStaffAssigned';

function EventStaffAssignedListItemSelect(props) {
  const item = props.item;
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);

  return (
    <button className="btn checkbox" disabled={item.exported || item.reconciled} onClick={ () => !item.exported && eventStaffAssignedContext.handleSelectAssignedStaff(item)}>
      {item.rowSelected 
        ? <i className="fas fa-check-square"></i>  
        : <i className="far fa-square" ></i>
      }
    </button>
  );
}

export default EventStaffAssignedListItemSelect
