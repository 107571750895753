import React, { useContext, useReducer, useEffect } from 'react'
import _ from "lodash";
import { toast } from 'react-toastify';
import moment from 'moment';
import { eventPostionUpdate } from '../../../../../services/eventsService';
import Table from '../../../../Common/table/table';
import Loading from '../../../../Common/loading/loading';
import { EventFetchContext, EventContext } from '../../Event';
import { EventStaffAssignedContext } from './EventStaffAssigned';
import EventStaffAssignedListItemPayRate from './EventStaffAssignedListItems/EventStaffAssignedListItemPayRate';
import EventStaffAssignedListItemDate from './EventStaffAssignedListItems/EventStaffAssignedListItemDate';
import EventStaffAssignedListItemGroup from './EventStaffAssignedListItems/EventStaffAssignedListItemGroup';
import EventStaffAssignedListItemSalary from './EventStaffAssignedListItems/EventStaffAssignedListItemSalary';
import EventStaffAssignedListItemSelect from './EventStaffAssignedListItems/EventStaffAssignedListItemSelect';
import EventStaffAssignedListItemEmployee from './EventStaffAssignedListItems/EventStaffAssignedListItemEmployee';
import EventStaffAssignedListItemBadges from './EventStaffAssignedListItems/EventStaffAssignedListItemBadges';

export const EventStaffAssignedListContext = React.createContext();

const initialEventStaffAssignedDate = {
  selected: null,
  original: null
}

const initialSortColumn = {
  path: "pay_rate[0].current_pay_rate", 
  order: "asc"
}

const reducerEventStaffAssignedList = (state, action) => {
  switch (action.TYPE) {
    case 'EVENT_STAFF_ASSIGNED_DATE_SELECTED':
      return {...state, selected: action.VALUE}
    case 'EVENT_STAFF_ASSIGNED_DATE_ORIGINAL':
      return {...state, original: action.VALUE}
    case 'SORT_COLUMN':
      return action.VALUE
    default:
      return state
  }
}

function EventStaffAssignedList() {
  const eventFetchContext = useContext(EventFetchContext);
  const eventContext = useContext(EventContext);
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);
  const [eventStaffAssignedDate, dispatchEventStaffAssignedDate] = useReducer(reducerEventStaffAssignedList, initialEventStaffAssignedDate);
  const [sortColumn, dispatchSortColumn] = useReducer(reducerEventStaffAssignedList, initialSortColumn);
  const eventIsClosed = eventContext.event.data.status === 'Closed';
  const eventIsReconciled = eventContext.event.data.status === 'Reconciled';

  useEffect(() => {
    const filtered = eventStaffAssignedContext.eventStaffAssignedList.data;
    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: sorted });
  }, [sortColumn]);

  // ** HANDLE
  // *********************************************************

  const handleAssignedStaffPositionUpdate = async (key, item) => {

    try {
      const memberData = eventStaffAssignedContext.eventStaffAssignedList.data.find(member => member.staff_event_id === item.staff_event_id );
      const memberDataMap = {
        event_id: eventContext.event.data.id,
        event_name: eventContext.event.data.name,
        staff_name: memberData.staff_name,
        staff_event_id: memberData.staff_event_id,
        staff_id: memberData.staff_id,
        start_date: memberData.start_date,
        end_date: memberData.end_date,
        group: memberData.group,
        salary: memberData.salary,
        volunteer: memberData.volunteer,
        current_pay_rate: memberData.pay_rate[0].current_pay_rate,
        type: memberData.type
      }

      let memberDataToUpdate;
      let updateAvailableStaff = true;

      switch (key) {
        case 'group':
          memberDataToUpdate = {
            ...memberDataMap,
            [key]:  item.value
          }
          updateAvailableStaff = false;
          break;
        case 'volunteer':
        case 'salary':
          memberDataToUpdate = {
            ...memberDataMap,
            [key]:  item[key] ? false : true
          }
          updateAvailableStaff = false;
          break;
        case 'payrate':
          memberDataToUpdate = {
            ...memberDataMap,
            current_pay_rate: Number(item.current_pay_rate)
          }
          updateAvailableStaff = false;
          break;
        case 'date':
          memberDataToUpdate = {
            ...memberDataMap,
            start_date: moment(item.start_date).format('MM/DD/YYYY HH:mm'),
            end_date: moment(item.end_date).format('MM/DD/YYYY HH:mm')
          }
          break;
        default:
          memberDataToUpdate = {
            ...memberDataMap
          }
          break;
      }

      const { data } = await eventPostionUpdate(memberDataToUpdate);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data;
        toast.error(errorMessage);
        return
      }
      toast.success('Position Updated', { autoClose: 1000 });
      const updateEventStaffAssignedListData = eventStaffAssignedContext.eventStaffAssignedList.data.map(member => {
        if (member.staff_event_id === item.staff_event_id) {
          switch (key) {
            case 'payrate':
              return {
                ...member, 
                pay_rate: [ { ...member.pay_rate[0], current_pay_rate: item.current_pay_rate } ]
              }
            case 'group':
              return {...member, [key]: item.value}
            case 'volunteer':
            case 'salary':
              return {...member, [key]: item[key] ? false : true};
            case 'date':
              return {...item};
            default:
              return {...member};
          }
        }
        return {...member};
      });

      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_FETCH', VALUE: updateEventStaffAssignedListData });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_CHECKIN', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_RECONCILIATION', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_SUMMARY', VALUE: true });
      if (updateAvailableStaff)
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
    } catch (ex) { }
  }

  // ** RENDERS
  // *********************************************************

  const columns = [
    { 
      path:'employee_num', 
      label: '#',
      disableSort: true,
      content: item => (eventStaffAssignedContext.eventStaffAssignedList.data.findIndex( (position) => position.staff_event_id === item.staff_event_id) + 1)
    },
    { 
      path:'staff_name', 
      label: 'Staff Name',
      content: item => <EventStaffAssignedListItemEmployee item={item} />
    },
    {
      path: 'rating',
      key: 'staff-members-badges',
      disableSort: true,
      content: item => <EventStaffAssignedListItemBadges item={item} type="1" />
    },
    { 
      path:'start_date', 
      label: 'Start | End Date', 
      content: item => <EventStaffAssignedListItemDate item={item} /> 
    },
    { 
      path:'pay_rate', 
      label: 'Pay Rate',
      content: item => item.pay_rate && item.pay_rate[0] && <EventStaffAssignedListItemPayRate item={item} type="1" />
    },
    { 
      path:'group', 
      label: 'Group',
      content: item => <EventStaffAssignedListItemGroup item={item} />
    },
    {
      path: "volunteer", 
      label: "Volunteer",
      content: item => (
        (item.volunteer)
          ? <i className="fas fa-check blueIcon"></i>
          : <i></i>
      )
    },
    { 
      path:'salary', 
      label: 'Salary',
      content: item => <EventStaffAssignedListItemSalary item={item} />
    },
  ]

  const buildColumns = () => {
    if (!eventIsClosed && !eventIsReconciled) {
      return [
        {
          path: 'rowSelected',
          key: 'select',
          disableSort: true,
          content: item => <EventStaffAssignedListItemSelect item={item} />
        },
        ...columns
      ]
    } else {
      return columns
    }
  }

  return (
    <EventStaffAssignedListContext.Provider
      value={{
        eventStaffAssignedDate, 
        dispatchEventStaffAssignedDate,
        handleAssignedStaffPositionUpdate
      }}
    >
      { !eventStaffAssignedContext.eventStaffAssignedList.loading &&
        <Table
          name={'eventStaffAssignedList'}
          columns={buildColumns()}
          data={eventStaffAssignedContext.eventStaffAssignedList.data}
          sortColumn={sortColumn}
          onSort={sortColumn => dispatchSortColumn({ TYPE: 'SORT_COLUMN', VALUE: sortColumn })}
          isLoading={eventStaffAssignedContext.eventStaffAssignedList.loading}
        />
      }
      <Loading active={eventStaffAssignedContext.eventStaffAssignedList.loading} type="assignedStaffMembers" />
    </EventStaffAssignedListContext.Provider>
  )
}

export default EventStaffAssignedList
