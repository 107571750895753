import React, { Component } from 'react';
import { getAverageRateDepartment } from "../../../services/widgetsService";
import FilterByYear from "../../Common/filters/FilterByYear";
import Chart from "react-google-charts";
import { messages } from '../../../utils/language-en.json';
 
class AverageRateDepartmentWidget extends Component {
  mounted = false;
  state = {
    average: [],
    hasData: false
  }

  async componentDidMount() {
    this.mounted = true;
    this.mounted && this.getAverageRateDepartmentData(new Date().getFullYear());
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getAverageRateDepartmentData = async (year) => {
    const { data } = await getAverageRateDepartment(year);
    const averageData =  [['Department', 'Average Hourly Rate', 'Target Hourly Rate', 'Delta']];
    var hasData = false;

    data.average.forEach((element) => {
      if (parseFloat(element.average_hourly_rate) > 0) {
        hasData = true;

        averageData.push(
          [element.name, 
            parseFloat(element.average_hourly_rate), 
            parseFloat(element.target_hourly_rate),
            parseFloat(element.delta)
          ]
        )
      }
    });

    this.setState({average: averageData});
    this.setState({hasData: hasData});
    this.setState({title: data.title});
  }

  handleYearChange = async (itemSelected) => {
    const yearSelected = (itemSelected) ? itemSelected.value: new Date().getFullYear();
    this.getAverageRateDepartmentData(yearSelected);
  };

  render() {
    const {
      average,
      hasData,
      title
    } = this.state;

    return (
      <div className="col-auto"> 
        <div className='DashboardList-item btn d-flex flex-column'>
          <h4><i className="fas fa-chart-bar"></i>&nbsp;{title}</h4>
          <FilterByYear handleYearChange={this.handleYearChange}/>
          { !hasData 
            ? <div className="no-data">{messages.emptydata.widgetsMessage}</div>
            : 
            <Chart
              chartType="BarChart"
              loader={<div>Loading</div>}
              data={average}
              options={{
                bars: 'horizontal',
                backgroundColor: 'transparent',
                width: '100%',
                height: 485,
                legend: {
                  position: 'none'
                }
              }}
            />
          }
        </div>
      </div>
    )
  }
}

export default AverageRateDepartmentWidget;