import React, { useContext, useEffect, useState } from 'react';
import { SwipeableViewsContext, SettingsContext } from '../Settings';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { toast } from "react-toastify";
import { getCurrentUser } from '../../../services/authService';
import { getRolesList } from '../../../services/rolesService';
import { getResorcesList, updateRoleResourceAccess } from '../../../services/rolesService';
import  { messages } from "../../../utils/language-en.json";
import _ from "lodash";
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import './RolesResourcesSettings.scss';

function RolesResourcesSettings() {
    const settingsContext = useContext(SettingsContext);
    const swipeableViewsContext = useContext(SwipeableViewsContext);
    const [roles, setRoles] = useState([]);
    const [resources, setResources] = useState([]);
  
    const getResourcesList = async () => {
      const { data } = await getResorcesList();
      const sortResources = _.orderBy(data.resources, ['id'], ['asc']);
      setResources(sortResources);
    }

    const HtmlTooltipUsers = withStyles(() => ({
      tooltip: {
        backgroundColor: '#ffc19f',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        border: '1px solid #dadde9',
        fontFamily: 'Source Sans Pro, sans-serif',
        fontSize: 12
      },
    }))(Tooltip);

    useEffect(() => {
      swipeableViewsContext.updateSwipeableHeight();
    });

    useEffect(() => {   
      getResourcesList();
    }, []);

    useEffect(() => {
      const fetchRolesList = async () => {
        const { data } = await getRolesList();
        const sortRoles = _.orderBy(data.roles, ['id'], ['asc']);
        setRoles(sortRoles);
      }
      fetchRolesList();
    }, []);

    async function handleUpdateResourceAccess(id, access) {
      access = (access) ? false : true ;
      const { data } = await updateRoleResourceAccess(id, access);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      toast.success(messages.success.roleResourceUpdated, { autoClose: 1000 });
      getResourcesList();
    }
  
    return (
      !getCurrentUser()
        ? <RedirectToLogin location={settingsContext.props.location} />
        : <section className="RolesResourcesSettings resources-form container-fluid">
        <table className="table">
          <thead>
            <tr>
              <th className="left-heading">
                Section
              </th>
              <th className="left-heading">
                Permission
              </th>
              { roles.map( (item, idx) => {
                return (
                  <th key={idx}>{item.name}&nbsp;
                    <HtmlTooltipUsers
                      title={
                        <React.Fragment>
                          <strong>Users with this role</strong>
                          <p>
                          { item.users.map( (element) => {
                            return (
                              <span key={element.id}>{element.first_name} {element.last_name}<br /></span>
                            )
                          }) }
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span className="badge badge-overtime">
                        <i className="fas fa-id-badge"></i>
                      </span>
                    </HtmlTooltipUsers>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
              { resources.map( (item, idx) => {
                return (
                  <tr className={item.section} key={idx}>
                    <td className="section">{item.section}</td>
                    <td className="resource">{item.name}</td>
                    {item.resources.map( (element) => {
                      return (  
                        <td className="access" 
                          key={element.id} 
                          onClick={() => handleUpdateResourceAccess(element.id, element.access)}
                          >
                        { element.access 
                          ? <i className="fas fa-check blueIcon" title="Click to disable"></i>
                          : <i className="fas fa-close redIcon" title="Click to enable"></i>
                        }
                        </td>
                      )                      
                    }) }
                  </tr>
                )
              })}
            </tbody>
        </table>
      </section>
    )
}

export default RolesResourcesSettings