import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

const unRequiredFields = {
  time_zone_code: Joi.string().allow(null, ''),
  time_zone_id: Joi.number().allow(null, ''),
  time_zone_gtm: Joi.string().allow(null, ''),
  time_zone_name: Joi.string().allow(null, ''),
  note: Joi.string().allow(null, ''),
  active: Joi.boolean().allow(null, ''),
  status: Joi.string().allow(null, ''),
  department: Joi.string().allow(null, ''),
  venue: Joi.string().allow(null, ''),
  venuesconf: Joi.string().allow(null, ''),
  customer: Joi.string().allow(null, ''),
  remaining_start_time: Joi.string().allow(null, ''),
  remaining_end_time: Joi.string().allow(null, ''),
  events_dates: Joi.array().allow(null, ''),
  start_date: Joi.string().allow(null, ''),
}

export function EventInfoSchemaEditing() {
  return {
    id: Joi.number().integer().allow(null, ''),
    name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Event Name"),
    event_number: Joi.string()
      .required()
      .label("Event Number"),
    target_hourly_rate: Joi.number()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Target Hourly Rate"),
    department_id: Joi.number().allow(null, ''),
    customers_id: Joi.number().allow(null, ''),
    venues_id: Joi.number().allow(null, ''),
    venuesconf_id: Joi.number().allow(null, ''),
    pending_confirmations: Joi.number().allow(null, ''),
    test_mode_notifications: Joi.boolean().allow(null, ''),
    ...unRequiredFields
  }
};

export function EventCreateSchema() {
  return {
    id: Joi.number().integer().allow(null, ''),
    name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Event Name"),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Department"),
    customers_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Event"),
    venues_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Venue"),
    venuesconf_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Configuration"),
    event_number: Joi.string()
      .required()
      .label("Event Number"),
    target_hourly_rate: Joi.number()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Target Hourly Rate"),

    ...unRequiredFields
  }
}

export default {
  EventInfoSchemaEditing,
  EventCreateSchema
}