import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import RedirectToLogin from '../../../../utils/RedirectToLogin';
import { messages } from '../../../../utils/language-en.json';
import { getCurrentUser } from '../../../../services/authService';
import { savePositionPayRate } from '../../../../services/positionsService';
import { SettingsContext } from '../../Settings';
import moment from "moment";

function PositionsPayRateSettingsEdit({ item }) {
  const settingsContext = useContext(SettingsContext);
  const [payRateRange, setPayRateRange] = useState({ 
    id: item.id, 
    position_id: item.position_id, 
    department_id: item.department_id,
    minimum_rate: item['minimum_rate'], 
    maximum_rate: item['maximum_rate'],
    date: moment().format('YYYY-MM-DD')
  });
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setPayRateRange({ 
      id: item.id, 
      position_id: item.position_id, 
      department_id: item.department_id,
      minimum_rate: item['minimum_rate'], 
      maximum_rate: item['maximum_rate'],
      date: moment().format('YYYY-MM-DD')
    })
  }, [item]);

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={settingsContext.props.location} />
      : <section className="PositionsPayRateSettingsEdit">
          <div className="row">
            <div className="col-6">
              <RenderPayRateInput
                label="Min Rate"
                name="minimum_rate"
                payRateRange={payRateRange}
                actions={{setPayRateRange, setErrors}}
                errors={errors}
              />
            </div>
            <div className="col-6">
              <RenderPayRateInput
                label="Max Rate"
                name="maximum_rate"
                payRateRange={payRateRange}
                actions={{setPayRateRange, setErrors}}
                errors={errors}
              />
            </div>
          </div>
        </section>
  )
}

function RenderPayRateInput({ label, name, payRateRange, actions, errors }) {
  const [startState, setstartState] = useState(payRateRange[name]);
  const [showModal, setShowModal] = useState(false);

  const validate = () => {
    const { minimum_rate, maximum_rate } = payRateRange;
    if (Number(minimum_rate) >= Number(maximum_rate)) {
      actions.setErrors({...errors, [name]: messages.error.settingsPositionRatesOutOfRange});
      return false;
    }
    if (minimum_rate.length === 0 || maximum_rate.length === 0) {
      return false;
    }
    actions.setErrors(null);
    return true;
  };

  const handleBlur = () => {
    if (startState === payRateRange[name]) return;
    if (!validate()) return;
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  }

  useEffect(() => {
    setShowModal(false);
    setstartState(payRateRange[name]);
  }, [payRateRange.id]);

  const handleSavePayRates = async () => {
    const { data } = await savePositionPayRate(payRateRange);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.settingsPositionUpdated, { autoClose: 1000 });
    setShowModal(false);
  }

  return (
    <>
    <div className={errors && errors[name] ? 'form-group has-error' : 'form-group'}>
      <div className="d-flex flex-column-reverse">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input 
            type="number" 
            className="form-control" 
            name={name}
            onChange={({ currentTarget: input }) => actions.setPayRateRange({ ...payRateRange, [name]: input.value})}
            aria-label={label}
            value={payRateRange[name]}
            onBlur={handleBlur}
          />
        </div>
        <label htmlFor={name}>{label}</label>
        {errors && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    </div>

    <Modal show={showModal} onHide={() => setShowModal(false)} className="modal-increase-payrate">
      <Modal.Header closeButton>
        <Modal.Title>
          Pay Rate Update
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scroll-wrapper">
          <table className="table">
            <tbody>
              <tr>
                <td>
                <div className="form-group">
                  <p><strong>Are you sure you want to update the Min and Max values? Doing this will update:</strong></p>
                  <div className="d-flex flex-column-reverse">
                    <ul>
                      <li>All Staff members in this position that are below the min new value</li>
                      <li>Rates in current assigned events after: <strong>{moment().format('YYYY-MM-DD')} 00:00</strong></li>
                    </ul>
                  </div>
                  </div>  
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>Cancel</Button>
        <Button variant="success" onClick={() => handleSavePayRates()}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  </>
  )
}

export default PositionsPayRateSettingsEdit
