import React from 'react';
import Select from "react-select";

export default function FilterByEventStatus(props) {
  const { handleEventStatusChange } = props;
  const statusOptions = [
    {
      label: 'ONGOING',
      options: [
        { value: 'Pre-Event', label: 'Pre-Event' },
        { value: 'Initiated', label: 'Initiated' },
        { value: 'Finalized', label: 'Finalized' },
        { value: 'Reconciled', label: 'Reconciled' }
      ]
    },
    {
      label: 'ARCHIVED',
      options: [
        { value: 'Closed', label: 'Closed' }
      ]
    }
  ];

  return (
    <div className="filter-by-section">
      <Select
        isMulti
        name="status"
        placeholder="Filter by Event Status"
        options={statusOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleEventStatusChange}
      />
    </div>
  )
}
