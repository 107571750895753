import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import moment from 'moment';
import { cleanUnusedPositions } from '../../../../../../services/eventsService';
import { messages } from '../../../../../../utils/language-en.json';
import { EventFetchContext } from '../../../Event';

function EventInfoToolCleanUnused(eventDate) {
  const eventFetchContext = useContext(EventFetchContext);
  const [showUnusedShifts, setShowUnusedShifts] = useState(false);

  let eventDateLabel = '';
  if (eventDate && eventDate.eventDate.start_date) {
    eventDateLabel = moment(eventDate.eventDate.start_date).format('MM/DD/YYYY');
  }

  // ** HANDLE
  // *********************************************************

  const handleCleanUnusedShifts = async (type) => {
    setShowUnusedShifts(false);
    try {
      const { data } = await cleanUnusedPositions(type, eventDate.eventDate.event_id, eventDate.eventDate.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      } else {
        toast.success(messages.success.cleanUnusedPositions, { autoClose: 3000 });
      }
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
    } catch (error) {}
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <i className="fas fa-eraser" title='Clean Unused Shifts' 
          onClick={() => setShowUnusedShifts(true)}
        ></i>
      
        <Modal show={showUnusedShifts} onHide={() => setShowUnusedShifts(false)} className="modal-notification">
          <Modal.Header>
            <Modal.Title>
              <i className="fas fa-eraser"></i>&nbsp;
              Clean Unused Shifts
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Clean Unused Shifts will remove the positions with no staff members or volunteers assigned.
            </p>
            <p className="disclaimer text-center">
              Are you sure you want to clean <br/>the shifts for this day <b>{eventDateLabel}</b>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUnusedShifts(false)}>Cancel</Button>
            <Button variant="success" 
              onClick={() => handleCleanUnusedShifts(1)} 
            >Clean</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  )
}

export default EventInfoToolCleanUnused;
