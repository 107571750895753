import React from 'react';
import EventStatusCountdown from './EventStatusCountdown';
import './EventStatus.scss';

const STATUS = {
  PRE_EVENT: 'Pre-Event',
  INITIATED: 'Initiated',
  FINALIZED: 'Finalized',
  RECONCILED: 'Reconciled',
  CLOSED: 'Closed'
}

const statusIcon = (event) => {
  if (event.status === STATUS.PRE_EVENT) return 'fa-thermometer-empty';
  if (event.status === STATUS.INITIATED) return 'fa-thermometer-half';
  if (event.status === STATUS.FINALIZED) return 'fa-thermometer-full';
  if (event.status === STATUS.RECONCILED) return 'fa-exchange-alt';
  if (event.status === STATUS.CLOSED) return 'fa-lock';
};

function EventStatus({ event , dispatchEvent}) {

  return (
    <section className="EventStatus">
      <div className="d-flex align-items-top justify-content-end">
        <div className="EventStatus-col-left">
          <h1 className="EventStatus-label">Event Status</h1>
          <div className="EventStatus-info d-flex align-items-center justify-content-end">
            {event.status === STATUS.PRE_EVENT && <EventStatusCountdown event={event} dispatchEvent={dispatchEvent} />}
            <p className={"EventStatus-value " + event.status.toLowerCase() }>{event.status}</p>
          </div>
        </div>
        <div className="EventStatus-col-right">
          <div className={"EventStatus-icon " + event.status.toLowerCase()}>
            <i className={"fas " + statusIcon(event)}></i>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EventStatus
