import http from "./httpService";

const apiEndpoint = "/venues";

export function getVenues({DEPARTMENT_ID = [], LIMIT = 100, PAGE = 1, ORDER_BY = 'name', SORT_ORDER = 'ASC', SEARCH} ) {
  return http.get(apiEndpoint + '/list', {
    params: {
      department_id: DEPARTMENT_ID,
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  });
}

export function deleteVenue(id) {
  return http.post( apiEndpoint + '/delete', { id: id } );
}

export function saveVenue(data) {
  if (data.id) {
    const body = { ...data };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', data);
}

export function getVenuesItem(id) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: id 
    }
  });
}

export function getVenuesListConfig(id, showAll) {
  return http.get(apiEndpoint + '/list_conf', { 
    params: {
      id: id, 
      show_all: showAll 
    }
  });
}

export function getPositionConflist(id) {
  return http.get(apiEndpoint + '/list_position_conf', { 
    params: {
      id: id
    }
  });
}

export function addConfiguration(name, venueId) {
  return http.post(apiEndpoint + '/add_conf', { name: name, venues_id: venueId });
}

export function deleteConfiguration(id) {
  return http.post(apiEndpoint + '/delete_conf', { id: id  });
}

export function saveConfigurationPosition(data, configurationId) {
  if (data.id) {
    return http.post(apiEndpoint + '/update_position_conf', data);
  }
  
  data = {id: configurationId, ...data}
  return http.post(apiEndpoint + '/add_position_conf', data);
}

export function deleteConfigurationPosition(id) {
  return http.post(apiEndpoint + '/delete_position_conf', { id: id  });
}
