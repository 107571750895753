import React from "react";
import styled from 'styled-components';
import { Link, NavLink } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import logo from "../../assets/cms-logo.svg";
import "./navBar.scss";
import auth from "../../services/authService";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: -1px;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #E0E0E0;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 40px;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 1.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 1;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open }) => {
  const authPermissions = auth.getCurrentUserPermisions();
  const userData = auth.getCurrentUser();

  return (
    <nav className="navbar ">
      <div className="navbar-content-right d-flex align-items-center">
        <Link className="navbar-brand" to="/">
          <span className="sr-only">{ process.env.REACT_APP_NAME } </span>
          <img className="img-fluid" src={logo} alt={ process.env.REACT_APP_NAME } />
        </Link>
        <div className="navbar-nav">
          <Ul open={open}>
            <li>
              <NavLink className="nav-item nav-link" to="/dashboard">
                <i aria-hidden="true" className="fa fa-tachometer"></i>&nbsp;Dashboard
              </NavLink>
            </li>
            {authPermissions["module_staff"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/staff">
                <i aria-hidden="true" className="fa fa-users"></i>&nbsp;Staff
              </NavLink>
            </li>
            }
            {authPermissions["module_volunteers"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/volunteers">
                <i aria-hidden="true" className="fas fa-hands-helping"></i>&nbsp;Volunteers
              </NavLink>
            </li>
            }
            {authPermissions["module_applicants"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/applicants">
                <i aria-hidden="true" className="fas fa-solid fa-clipboard"></i>&nbsp;Applicants
              </NavLink>
            </li>
            }
            {authPermissions["module_customers"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/customers">
                <i aria-hidden="true" className="fa fa-bullhorn"></i>&nbsp;Customers
              </NavLink>
            </li>
            }
            {authPermissions["module_venues"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/venues">
                <i aria-hidden="true" className="fas fa-location-arrow"></i>&nbsp;Venues
              </NavLink>
            </li>
            }
            {authPermissions["module_events"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/events">
                <i aria-hidden="true" className="far fa-calendar-alt"></i>&nbsp;Events
              </NavLink>
            </li>
            }
            {authPermissions["module_reports"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/reports">
                <i aria-hidden="true" className="fa fa-file-text"></i>&nbsp;Reports
              </NavLink>
            </li>
            }
            {authPermissions["module_logs"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/logs">
                <i aria-hidden="true" className="fas fa-file-medical-alt"></i>&nbsp;Logs
              </NavLink>
            </li>
            }
            {authPermissions["module_users"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/users">
                <i aria-hidden="true" className="fas fa-users-cog"></i>&nbsp;Users
              </NavLink>
            </li>
            }
            {authPermissions["module_settings"] && 
            <li>
              <NavLink className="nav-item nav-link" to="/settings">
                <i aria-hidden="true" className="fa fa-cogs"></i>&nbsp;Settings
              </NavLink>
            </li>
            }
          </Ul>
        </div>
      </div>

      <div className="navbar-content-left">
        {userData && (
          <div className="profile-nav d-flex align-items-center">
            <span className="profile-nav-item profile d-flex align-items-center">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span className="profile-avatar">
                    <img className="img-fluid" src={userData["avatar"]} alt="avatar"/>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/profile">My Profile</Dropdown.Item>
                  <Dropdown.Item href="/logout">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        )}
      </div>
    </nav>
  )
}

export default RightNav;