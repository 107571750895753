import http from "./httpService";

const apiEndpoint = "/blazer_size";

export function getBlazerSizes() {
  return http.get(apiEndpoint + '/list');
}

export function addBlazerSize(newSize) {
  return http.post(apiEndpoint + '/add', {name: newSize.blazerSize, gender: newSize.blazerGender} );
}

export function deleteBlazerSize(id) {
  return http.post(apiEndpoint + '/delete', { id: id } );
}
