import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";

class CustomersList extends Component {
  columns = [
    { path: "name", label: "Name" },
    { path: "department", label: "Department", disableSort: true }, 
    { path: "contact", label: "Contact" },
    { path: "phone_1", label: "Phone" },
    { path: "email", label: "email" },
    { path: "past_due_check", 
      label: "Past Due Payments?",
      content: customersItem => (
        (customersItem.past_due_check)
          ? <i className="fas fa-check blueIcon"></i>
          : <i className="fas fa-close redIcon"></i>
      )
    },
    { 
      path: "red_flag", 
      label: "Red Flag",
      content: customersItem => (
        (customersItem.red_flag)
          ? <i className="fas fa-flag"></i>
          : <i className="far fa-flag"></i>
      )
    }
  ];

  deleteColumn = {
    key: "delete",
    path: "delete",
    disableSort: true,
    content: customersItem => (
      <button
        onClick={() => this.props.onDelete(customersItem)}
        className="btn btn-danger btn-sm btn-delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: customersItem => (
      <Link to={`/customers/${customersItem.id}`} className="btn btn-sm btn-edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    const authPermissions = auth.getCurrentUserPermisions();
    if (user) this.columns.push(this.editColumn);
    if (user && authPermissions["module_customers_delete"]) this.columns.push(this.deleteColumn);
  }

  render() {

    const { customers, onSort, sortColumn, isLoading } = this.props;

    return (
      <Table
        name={'customers'}
        columns={this.columns}
        data={customers}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
      />
    );
  }
}

export default CustomersList;
