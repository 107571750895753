import Joi from "joi-browser";
import  { messages } from "../utils/language-en.json";

export function venuesDataModel() {
  return {
    id: null,
    name: "",
    state: "",
    department_id: "",
    zip_code: "",
    address_1: "",
    address_2: "",
    city: "",
    time_zone_id: ""
  }
}

export function venuesSchema(isEditing) {
  return {
    id: Joi.number()
      .integer().allow(null, ''),
    name: Joi.string()
      .required()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .label("Customer Name"),
    state: Joi.string()
      .required()
      .label("State"),
    department_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Department"),
    zip_code: Joi.string()
      .allow(null, '')
      .regex(/^\d{5}$/)
      .error(errors => {
        let errorMessage;
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          if (element.type === "string.regex.base") {
            errorMessage = {message: messages.error.zipcodeInvalid }
          }
          return errorMessage;
        }
      })
      .label("Zipcode"),
    address_1: Joi.string()
      .required()
      .label("Address"),
    address_2: Joi.string()
      .allow([ null, '' ])
      .label("Address 2"),
    city: Joi.string()
      .required()
      .label("City"),
    time_zone_id: Joi.number()
      .integer()
      .error( errors => { 
        for (let i = 0; i < errors.length; i++) {
          const element = errors[i];
          return {message: element.flags.label + ' ' +  messages.error.isRequired}
        }
      })
      .required()
      .label("Time Zone")
  }
}

export function venuesEditMapToViewModel(item,) {
  return {
    id: item.id,
    name: (item.name) ? item.name : "",
    state: (item.state) ? item.state : "",
    department_id: item.department_id,
    zip_code: (item.zip_code) ? item.zip_code : "",
    address_1: (item.address_1) ? item.address_1 : "" ,
    address_2: (item.address_2) ? item.address_2 : "",
    city: (item.city) ? item.city : "",
    time_zone_id: (item.time_zone_id) ? item.time_zone_id : ""
  }
}

export function venueConfigurationPositionModel() {
  return {
    assignment_name: "",
    blazer: false,
    coat: false,
    position_id: "",
    qty: "",
    radio: false,
    radio_qty: "",
    shirt: false,
    traffic: false
  }
}

export default {
  venuesDataModel,
  venuesSchema,
  venuesEditMapToViewModel,
  venueConfigurationPositionModel
}