import http from "./httpService";

const apiEndpoint = "/applicant";

export function getApplicants({DEPARTMENT_ID = [], STATUS, LIMIT = 1000, PAGE = 1, ORDER_BY = ['application_date'], SORT_ORDER = "DESC", SEARCH}) {
  return http.get(apiEndpoint + '/list', {
    params: {
      department_id: DEPARTMENT_ID,
      status: STATUS,
      limit: LIMIT,
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  } );
}

export function getApplicantItem(applicantId, departmentId) {
  return http.get(apiEndpoint + '/get_id', {
    params: {
      applicant_id: applicantId,
      department_id: departmentId
    }
  });
}

export function updateStatus(applicantId, status) {
  return http.post(apiEndpoint + '/update_status', {
    id: applicantId,
    status: status
  });
}

export function addNotes(applicantId, fullName, notes) {
  return http.post(apiEndpoint + '/add_notes', {
    id: applicantId,
    full_name: fullName,
    notes: notes
  });
}

export function generateApplicantPdf(applicantId) {
  return http.post(apiEndpoint + '/generate_applicant_pdf', { id: applicantId } );
}
