import React, { useEffect, useState} from 'react';
import "./calendar.scss";
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from "lodash";
import { getCurrentUser } from '../../../services/authService';
import { getEventsCalendar, generateEventsCalendar } from '../../../services/eventsService';
import { getDepartmentList } from "../../../services/departmentService";
import RedirectToLogin from '../../../utils/RedirectToLogin';
import Loading from '../../Common/loading/loading';
import auth from "../../../services/authService";
import { withStyles } from '@material-ui/core/styles';
import { Modal } from 'react-bootstrap';
import Tooltip from '@material-ui/core/Tooltip';
import FilterByDepartment from "../../Common/filters/FilterByDepartment";
import FilterByEventStatus from "../../Common/filters/FilterByEventStatus";

function EventCalendar(props) {
  const month = props.match.params.month;
  const year = props.match.params.year;
  const currentDate = moment({ year: year, month: month-1, day: 1});
  const previousDate = moment(currentDate).subtract(1, 'M');
  const nextDate = moment(currentDate).add(1, 'M');
  const [loading, setLoading] = useState(true);
  const [eventsCalendarData, setEventsCalendarData ] = useState([]);
  const [showDateModal, setShowDateModal] = useState(false);
  const [eventsDateData, setEventsDateData] = useState([]);
  const [eventsDate, setEventsDate] = useState('');
  const [departmentData, setDepartmentData] = useState([]);
  const currentDay = moment();
  const [departmentSelected, setDepartmentSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);

  const fetchDataEventsCalendar = async (department, status) => {
    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) { 
      department = userDepartmentId;
    }

    const { data } = await getEventsCalendar(month, year, department, status);
    if (!data.success) {
      toast.error('Error getting the calendar data.');
      return
    }
    setEventsCalendarData(data.events);
    setLoading(false);
  }

  const getDepartmentData = async () => {
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    setDepartmentData(sortDepartments);
  }

  const getEventsDate = async (date) => {
    const eventsDate = eventsCalendarData.filter(d => d.date === date);
    setEventsDate(moment(date).format("MMMM Do YYYY"));
    setEventsDateData(eventsDate[0].events);
    setShowDateModal(true);
  }

  const HtmlTooltipEvent = withStyles(() => ({
    tooltip: {
      backgroundColor: '#ffc19f',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 420,
      border: '1px solid #f2f2f2',
      fontFamily: 'Source Sans Pro, sans-serif',
      fontSize: 12
    },
  }))(Tooltip);

  const handleDepartmentChange = async (itemSelected) => {
    const departmentSelected = itemSelected ? itemSelected.map((department) => department.value) : [];
    setDepartmentSelected(departmentSelected);
    fetchDataEventsCalendar(departmentSelected, statusSelected);
  };

  const handleEventStatusChange = async (itemSelected) => {
    const statusSelected = itemSelected ? itemSelected.map((status) => status.value) : [];
    setStatusSelected(statusSelected);
    fetchDataEventsCalendar(departmentSelected, statusSelected);
  };

  const handlePrintOption = async () => {
    let department = departmentSelected;
    const userData = auth.getCurrentUser();
    const userDepartmentId = userData.department_id;
    if (userDepartmentId) { 
      department = userDepartmentId;
    }

    const { data: resEventsCalendar } = await generateEventsCalendar(month, year, department, statusSelected);
    if (!resEventsCalendar.success) return toast.error("File couldn't be generated");
    window.open(process.env.REACT_APP_API_URL + '/' + resEventsCalendar.file_name);  
  }

  useEffect(() => {
    fetchDataEventsCalendar([], []);
    getDepartmentData();
  },[]);

  return (!getCurrentUser()
  ? <RedirectToLogin />
  : loading ? <Loading type="page" /> :
    <main className="events-calendar-page page-wrapper container-fluid">
      <section className="page-header">
        <div className="row">
          <div className="col">
            <h1 className="page-title"><i aria-hidden="true" className="far fa-calendar-alt"></i>&nbsp;Calendar of Events</h1>
          </div>
        </div>
      </section>
      <section className="calendar-page-body">
        <section className="table-console d-flex justify-content-between">
          <div className="table-console-left d-flex align-items-end justify-content-center">
            <FilterByDepartment
              departments={departmentData}
              handleDepartmentChange={handleDepartmentChange}
            />
            <FilterByEventStatus 
                handleEventStatusChange={handleEventStatusChange} 
              />
          </div>
          <div className="table-console-left d-flex align-items-end justify-content-center">
            <button className="btn btn-print-pdf" type="button" onClick={handlePrintOption}>
              <i className="far fa-file-pdf"></i>Print
            </button>
          </div>
        </section>
        <hr />
        <div className="row">
          <div className="col-4">
            <div className="float-right">
              <a href={`/events/calendar/${previousDate.format('M')}/${previousDate.format('YYYY')}`}>
                <button type="button" className="page-link prev-page link">
                  <i className="fa fa-angle-left"></i>
                </button>
              </a>
            </div>
          </div>
          <div className="col-4">
            <h2>{currentDate.format('MMMM')} {currentDate.format('YYYY')}</h2>
          </div>
          <div className="col-4">
            <div className="float-left">
              <a href={`/events/calendar/${nextDate.format('M')}/${nextDate.format('YYYY')}`}>
                <button type="button" className="page-link next-page link">
                  <i className="fa fa-angle-right"></i>
                </button>
              </a>
            </div>
          </div>
        </div>          
      
        <div className="calendar-container">
          <div className="calendar-header">SUN</div>
          <div className="calendar-header">MON</div>
          <div className="calendar-header">TUE</div>
          <div className="calendar-header">WED</div>
          <div className="calendar-header">THU</div>
          <div className="calendar-header">FRI</div>
          <div className="calendar-header">SAT</div>  
          
          {eventsCalendarData.map((date, idx) => { 
            return (
              idx === 0 && date.dayOfWeek !== 0 ? (
              <div key={idx} className={`calendar-day-wide wide-${date.dayOfWeek}`}>
                <div className="date">&nbsp;</div>
                <div className="events"></div>
              </div>
              ) : null
            )
          })}

          {eventsCalendarData.map((date, idx) => {
            return (
              <div key={idx} className={currentDay.format('YYYY-MM-DD') === date.date ? 'calendar-day current-day' : 'calendar-day'}>
                <div className="date">{date.day}</div>
                <div className="events">
                  {date.events.map((event, idx) => {
                    return (
                      idx < 8 ? (
                      <div key={idx} className={`department ${event.department_name.toLowerCase()}`}>
                        <HtmlTooltipEvent
                          title={
                            <React.Fragment>
                              <table cellPadding={2}>
                                <tbody>
                                  <tr>
                                    <td colSpan={2}><h6><strong>{event.event_name}</strong></h6></td>
                                  </tr>
                                  <tr>
                                    <td><strong>Status:</strong></td>
                                    <td>{event.status}</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Customer:</strong></td>
                                    <td>{event.customer_name}</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Venue:</strong></td>
                                    <td>{event.venue_name}</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Event Number:</strong></td>
                                    <td>{event.event_number}</td>
                                  </tr>
                                  <tr>
                                    <td><strong>Days:</strong></td>
                                    <td>{event.event_days}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </React.Fragment>
                          }
                        >
                          <a href={`/events/${event.event_id}`} target="_blank" rel="noopener noreferrer">{event.event_name.length > 10 ? `${event.event_name.substring(0, 10)}..` : event.event_name}</a>
                        </HtmlTooltipEvent>
                        <div className="labels">
                          <span className="dept">{event.department_name}</span>
                          <span className="status">{event.status}</span>
                        </div>
                      </div>
                    ) : ''
                    )
                  })}

                  {date.events.length > 8 ?
                    (
                    <div key={idx} className="view-all">
                      <button className="btn btn-overlap" type="button"
                        onClick={() => getEventsDate(date.date)}>
                        {date.events.length - 8} more
                      </button>

                      <Modal key={idx} show={showDateModal} onHide={() => setShowDateModal(false)} className="modal-events-dates" size="xl">
                        <Modal.Header closeButton>
                          <Modal.Title>
                            <i className="fas fa-calendar-alt"></i>&nbsp;Events for {eventsDate}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="events">
                            {eventsDateData.map( (event, idx) => (
                              <div key={idx} className={`department ${event.department_name.toLowerCase()}`}>
                                <a href={`/events/${event.event_id}`} target="_blank" rel="noopener noreferrer">{event.event_name.length > 20 ? `${event.event_name.substring(0, 20)}..` : event.event_name}</a>
                                <div className="labels">
                                  <span className="dept">{event.department_name}</span> 
                                  <span className="status">{event.status}</span>
                                </div><br/>
                                <span><strong>Customer:</strong> {event.customer_name.length > 13 ? `${event.customer_name.substring(0, 13)}...` : event.customer_name}</span><br/>
                                <span><strong>Venue:</strong> {event.venue_name.length > 13 ? `${event.venue_name.substring(0, 13)}...` : event.venue_name}</span><br/>
                                <span><strong>Event Number:</strong> {event.event_number.length > 12 ? `${event.event_number.substring(0, 12)}...` : event.event_number}</span><br/>
                                <span><strong>Days:</strong> {event.event_days}</span>
                              </div>
                            ) )}
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                    )
                  : ''
                  }
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </main>
  );
}

export default EventCalendar;
