import React from "react";
import "./navBar.scss";
import Burger from './Burger';

const NavBar = () => {
  return (
    <Burger />
  );
};

export default NavBar;
