import React, { useContext } from 'react';
import { UserPermissions } from '../../../../utils/UserPermissions';
import { MessageDataModel } from './MessagesSettingsUtils';
import { MessagesSettingsContext } from '../MessagesSettings';

function EditMessage({ item }) {
  const messagesSettingsContext = useContext(MessagesSettingsContext);
  const setEditMessage = messagesSettingsContext.action.setEditMessage;
  const setNewMessage = messagesSettingsContext.action.setNewMessage;

  return (
    UserPermissions('module_settings_tab_messages') &&
      <button title="Edit Message" className="btn btn-sm btn-edit"
        onClick={() => {
          setEditMessage(item)
          setNewMessage(MessageDataModel)
        }}
      ><i className="fa fa-pencil" aria-hidden="true"></i>
      </button> 
  );
}

export default EditMessage
