import React, { Component } from "react";
import auth from "../../services/authService";
import Table from "../Common/table/table";
import { Link } from "react-router-dom";

class VenuesList extends Component {
  columns = [
    { path: "name", label: "Name" },
    { path: "department", label: "Department", disableSort: true },
    { path: "address_1", label: "Address 1" },
    { path: "address_2", label: "Address 2" },
    { path: "city", label: "City" },
    { path: "state", label: "State" },
    { path: "zip_code", label: "Zip" }
  ];

  deleteColumn = {
    key: "delete",
    path: "delete",
    disableSort: true,
    content: customersItem => (
      <button
        onClick={() => this.props.onDelete(customersItem)}
        className="btn btn-danger btn-sm btn-delete"
      >
        <span className="sr-only">Delete</span>
        <i className="fa fa-trash" aria-hidden="true"></i>
      </button>
    )
  };

  editColumn = {
    key: "edit",
    path: "edit",
    disableSort: true,
    content: customersItem => (
      <Link to={`/venues/${customersItem.id}`} className="btn btn-sm btn-edit">
        <span className="sr-only">Edit</span>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </Link>
    )
  };

  constructor() {
    super();
    let user = auth.getCurrentUser();
    const authPermissions = auth.getCurrentUserPermisions();
    if (user) this.columns.push(this.editColumn);
    if (user && authPermissions["module_venues_delete"]) this.columns.push(this.deleteColumn);
  }

  render() {

    const { data, onSort, sortColumn, isLoading } = this.props;

    return (
      <Table
        name={'venues'}
        columns={this.columns}
        data={data}
        sortColumn={sortColumn}
        onSort={onSort}
        isLoading={isLoading}
      />
    );
  }
}

export default VenuesList;
