import React, { useEffect, useState } from 'react';
import { getUnreconciledEvents } from "../../../services/widgetsService";
 
function UnreconciledEventsWidget() {
  const [events, setEvents] = useState([]);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');

  const getUnreconciledEventsData = async () => {
    const { data } = await getUnreconciledEvents();
    setEvents(data.events);
    setTitle(data.title);
    setText(data.text);
  }

  useEffect(() => {
    getUnreconciledEventsData();
  }, []);

  return (
    <div>
      { events.length === 0
        ? ''
        : <div className="col-auto">    
          <div className='DashboardList-item btn d-flex flex-column'>
            <h4><i className="far fa-calendar-times"></i>&nbsp;{title}</h4>
            <p>{text}</p>
            <ul>
            { events.map( (item, idx) => (
                <li key={idx}><a href={"events/" + item.id}>{item.name} - {item.department}</a></li>
              ) )
            }
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default UnreconciledEventsWidget;