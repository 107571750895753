import http from "./httpService";

const apiEndpoint = "/files";

export function saveFile(fileBodyData) {
  return http.post(apiEndpoint + '/add', fileBodyData);
}

export function getFileById(fileId) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: fileId
    }
  });
}

export function getFilesList(component, componentId) {
  return http.get(apiEndpoint + '/list', {
    params: {
      component: component, 
      component_id: componentId
    }
  });
}

export function deleteFile(fileId) {
  return http.post(apiEndpoint + '/delete', {id: fileId});
}
