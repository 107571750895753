import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { messages } from '../../../../utils/language-en.json';
import { saveDepartment } from '../../../../services/departmentService';
import { DepartmentSettingsContext } from '../DepartmentsSettings';

function UpdateDepartment() {
  const departmentSettingsContext = useContext(DepartmentSettingsContext);
  const setFetchData = departmentSettingsContext.action.setFetchData;
  const editDepartment = departmentSettingsContext.state.editDepartment;
  const setEditDepartment = departmentSettingsContext.action.setEditDepartment;

  const handleUpdateDepartment = async () => {
    const { data } = await saveDepartment(editDepartment);
    const success = data.success;
    if (!success) {
      const errorMessage = data.message;
      toast.error(errorMessage);
      return
    }
    toast.success(messages.success.settingsDepartementUpdated, { autoClose: 1000 });
    setFetchData(true);
    setEditDepartment(null);
  }

  return (
    <div className="d-flex align-items-center">
      <button title="Save Department" className="btn btn-sm  btn-save"
        disabled={ !editDepartment.name }
        onClick={() => handleUpdateDepartment()}
      ><i className="fa fa-save" aria-hidden="true"></i>
      </button> 
      <button title="Cancel" className="btn btn-sm btn-cancel"
        onClick={() => setEditDepartment(null)}
      ><i className="fa fa-close" aria-hidden="true"></i>
      </button> 
    </div>
  )
}

export default UpdateDepartment
