import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { CKEditor } from 'ckeditor4-react';
import { toast } from "react-toastify";
import { messages } from '../../../../utils/language-en.json';
import { updateEventReport } from '../../../../services/eventsService';
import './EventReport.scss';

class EventReport extends Component {
  state = {  }

  event = this.props.event;

  componentWillMount() {
    this.populateEventReport();
  }

  componentDidUpdate() {
    this.context.swipeableViews.slideUpdateHeight();
  }

  populateEventReport = async () => {
    try {
      const eventReportNote = this.event.note;
      this.setState({ eventReportNote });
    } catch (ex) { }
  }

  handleSaveEventReport = async () => {
    const dataToUpdate = {
      id: this.event.id,
      note: this.state.eventReportNote
    }
    try {
      const { data } = await updateEventReport(dataToUpdate);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage, { autoClose: 1000 });
        return
      }
      toast.success(messages.success.eventReportUpdated, { autoClose: 1000 });
    } catch (ex) { }
  }

  onEditorChange = ( evt ) => {
    this.setState( {
      eventReportNote: evt.editor.getData()
    } );
  }

  render() {
    return (
      <section className="EventReport form-wrapper container-fluid">
        <CKEditor
          onBeforeLoad={ ( CKEDITOR ) => ( CKEDITOR.disableAutoInline = true ) }
          initData={this.state.eventReportNote}
          type="classic"
          config={ {
            height: 'calc(100vh - 435px)',
            extraPlugins: ['print', 'preview', 'font', 'justify', 'colorbutton'],
            toolbar: [
              { name: 'document', items: ['Source', '-', 'Preview', 'Print', ] },
              { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo' ] },
              { name: 'styles', items: [ 'Format', 'FontSize' ] },
              { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
              { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
              { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
              { name: 'links', items: [ 'Link', 'Unlink' ] },
              { name: 'insert', items: [ 'Table', 'HorizontalRule' ] },
              { name: 'tools', items: [ 'Maximize' ] },
              { name: 'about', items: [ 'About' ] }
            ]
          } }
          onChange={this.onEditorChange}
        />

        <div className="row justify-content-end">
          <div className="col-sm-6 text-right">
            <button className="btn btn-primary mt-3"
              type="button"
              disabled={!this.state.eventReportNote}
              onClick={() => this.handleSaveEventReport()}
            >
              Save Event Report
            </button>
          </div>
        </div>
      </section>
    );
  }
}

EventReport.contextTypes = {
  swipeableViews: PropTypes.object.isRequired
};

export default EventReport;
