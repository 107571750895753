import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Datepicker from '../../Common/form/datepicker';
import moment from 'moment';
import { messages } from "../../../utils/language-en.json";
import { getStaffRatingsReport } from '../../../services/staffService';

function staffRatingsPrint(props) {
  const [newDate, setNewDate] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [validDateRange, setValidDateRange] = useState(true);

  // ** HANDLE
  // *********************************************************
  const handlePrintOption = async () => {
    if (dateSelected && dateSelected.start_date && dateSelected.end_date) {
      setNewDate(false);
      const {data: resRatingsSheet } = await getStaffRatingsReport({
        STAFF_ID: props.id,
        START_DATE: moment(dateSelected.start_date).format('YYYY-MM-DD'), 
        END_DATE: moment(dateSelected.end_date).format('YYYY-MM-DD'), 
      });
      if (!resRatingsSheet.success) return toast.error("Ratings file couldn't be generated");
      window.open(process.env.REACT_APP_API_URL + '/' + resRatingsSheet.file_name);
    }
  }

  const handleChangeDate = (name, date) => {
    let newDate = moment(date).format('YYYY-MM-DD');
    newDate = new Date(date).toISOString();

    let buildDate;
    if (dateSelected) {
      buildDate = {
        ...dateSelected,
        [name] : newDate
      }
    } else {
      buildDate = {
        [name] : newDate
      }
    }

    setDateSelected(buildDate);
  }

  const validateDateRange = () => {
    if (dateSelected) {
      const { start_date, end_date } = dateSelected;
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);
  
      if (endDate <= startDate){
        setValidDateRange(false);
        toast.error(messages.error.dateOutOfRange);
        return
      }
  
      setValidDateRange(true);
    }
  }

  // ** RENDER
  // *********************************************************
  const renderDateTimePicker = (name) => {
    const dateFormat = dateSelected && moment(dateSelected[name]).format('YYYY-MM-DD h:mm');
    var value;
    if (dateFormat && dateSelected[name] !== undefined) {
      value = new Date(dateFormat);
    }

    return (
      <Datepicker
        className={"datepicker form-control form-control-sm datepicker "}
        selected={value}
        name={name}
        onChange={newDate => handleChangeDate(name, newDate)}
        fixedHeight
        calendarClassName="staff-datepicker staff-ratings-datepicker"
        dateFormat="MM/dd/yyyy"
        onCalendarClose={() => validateDateRange()}    
      />
    )
  }

  return (
    <React.Fragment>
      <div className="export-container">
        <button className="btn btn-print-options" type="button" onClick={() => setNewDate(true)}>
          <i className="far fa-file-pdf"></i> EXPORT TO PDF
        </button>
      </div>

      <Modal show={newDate} onHide={()=> setNewDate(false)} className="modal-bulk-time">
        <Modal.Header>
          <Modal.Title>
            <i className="far fa-clock"></i>&nbsp;Select time range
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-4 mt-3 justify-content-center align-items-center">
            <div className="col-sm-1 p-0">
              <label htmlFor="">
                Start
              </label>
            </div>
            <div className="col-sm-5">
              {renderDateTimePicker('start_date')}
            </div>
          </div>
          <div className="row mb-4 justify-content-center align-items-center">
            <div className="col-sm-1 p-0">
              <label htmlFor="">
                End
              </label>
            </div>
            <div className="col-sm-5">
              {renderDateTimePicker('end_date')}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={()=> setNewDate(false)}>Cancel</Button>
          <Button variant="success" 
            disabled={!validDateRange} 
            onClick={() => handlePrintOption()}
          >Export</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default staffRatingsPrint
