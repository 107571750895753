import React, { useContext } from 'react';
import { currencyUSD } from '../../../../../../utils/value-formats';
import { EventStaffContext } from '../../EventStaff';

function EventStaffAssignedListItemEmployee(props) {
  const item = props.item;
  const event = useContext(EventStaffContext).event;

  return (
    <div className="staff-member-info d-flex aling-items-center">
      <p className="staff-name">
        {item.staff_name}&nbsp;&nbsp;
        {item.staff_id !== 0 && item.type === 'Staff' &&
          <a href={'/staff/' + item.staff_id} target="_blank" rel="noopener noreferrer">
            <i className="fas fa-external-link-alt external-link-alt-custom-xsmall"></i>
          </a>
        }
      </p>

      {item.staff_id !== 0 &&
        <div className="staff-member-info-overlay">
          <h6 className="staff-member-info-overlay-name">{item.staff_name}</h6>
          <p className="positions">{item.type === 'Staff' ? item.positions : 'Volunteer from ' + item.organization}</p>
          { item.department_id && item.department_id !== event.department_id && 
            <p className="travel"><i className="fas fa-plane"></i>&nbsp;{item.department_name}</p>
          }
          <div className="staff-members-badges">
            {item.pay_rate && item.pay_rate[0] &&
            <span className="badge badge-outline badge-payrate">
              {currencyUSD(item.pay_rate[0].current_pay_rate)}
            </span>
            }
            {item.type === 'Staff' &&
              <span className="badge badge-outline badge-rating">
                <i className="fas fa-star"></i>&nbsp;{ (item.rating > 0 ) ? item.rating : '–' }
              </span>
            }
            { item.public_facing  &&
              <span className="badge ">Public Facing</span> 
            }
          </div>
        </div>
      }
    </div>

  );
}

export default EventStaffAssignedListItemEmployee
