import React from 'react';
import "./loading.scss"

const Loading = ({ active, type, ...rest}) => {
  return (
    <>
    {type === 'page' &&
      <main className="Loading page-wrapper container-fluid">
        <div className="loader">
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </main>
    }

    {type === 'tabsContentLoading' &&
      <div className="Loading tabsContentLoading">
        <div className="loader">
          <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </div>
      </div>
    }
      
    {active
      ? <div className={type ? "Loading " + type : 'Loading'}>
          <div className="loader">
              <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        </div>
      : ''
    }
    </>
  );
}

export default Loading;