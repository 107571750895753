import http from "./httpService";

const apiEndpoint = "/coat_size";

export function getCoatSizes() {
  return http.get(apiEndpoint + '/list');
}

export function addCoatSize(newSize) {
  return http.post(apiEndpoint + '/add', {size: newSize.coatSize} );
}

export function deleteCoatSize(id) {
  return http.post(apiEndpoint + '/delete', { id: id } );
}
