import React, { useContext } from 'react';
import { toast } from "react-toastify";
import { addEventPosition } from '../../../../../../services/eventsService';
import { EventFetchContext } from '../../../Event';
import { EventStaffContext } from '../../EventStaff';
import { EventStaffAssignedContext } from '../EventStaffAssigned';

function EventStaffAssignedToolAddPosition() {
  const eventFetchContext = useContext(EventFetchContext);
  const eventStaffContext = useContext(EventStaffContext);
  const eventStaffAssignedContext = useContext(EventStaffAssignedContext);

  // ** HANDLE
  // *********************************************************
  
  const handleAddPosition = async () => {
    try {
      const { data } = await addEventPosition(eventStaffContext.position.selected.position_event_id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_POSITIONS', VALUE: true });
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      eventStaffAssignedContext.dispatchEventStaffAssignedList({ TYPE: 'EVENT_STAFF_ASSIGNED_SELECT', VALUE: null });
      toast.success('Position added!', { autoClose: 1000 });
    } catch (error) {
      
    }
  }

  return (
    <div className="addPositions d-flex align-items-center">
      <h6 className="title">Available positions {eventStaffContext.availableAssignedPositions.total}</h6>
      <button type="button" className="btn btn-color-primary-outline btn-sm"
        onClick={() => handleAddPosition()}
      ><b>+</b>&nbsp;Add Position</button>
    </div>
  )
}

export default EventStaffAssignedToolAddPosition
