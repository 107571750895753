import React, { useContext } from 'react'
import { EventCheckInContext } from './EventCheckIn';

function EventsCheckInSummary() {
  const checkInList = useContext(EventCheckInContext).eventCheckInList;

  const eventCheckInSummaryItems = [
    { 
      label: "Checked In",
      content: checkInList.summaryData ? checkInList.summaryData[0].staff_check_in + '/' + checkInList.summaryData[0].staff_total : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    },
    { 
      label: "Checked Out",
      content: checkInList.summaryData ? checkInList.summaryData[0].staff_check_out + '/' + checkInList.summaryData[0].staff_total : <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
    }
  ];

  return (
    <section className="EventsCheckInSummary">
      <div className="d-flex justify-content-between align-items-center">
      { eventCheckInSummaryItems.map((item, idx) => {
        return (
          <div className="col-4" key={idx}>
            <div className="EventsCheckInSummary-item">
              <h6 className="EventsCheckInSummary-item-label">{item.label}</h6>
              <div className="EventsCheckInSummary-item-info">{item.content}</div>
            </div>
          </div>
        )})
      }
      </div>
    </section>
  )
}

export default EventsCheckInSummary
