import React from 'react';
import moment from "moment";
import Select from 'react-select';
import './EventDatesNav.scss';

function EventDatesNav(props) {
  const  { event, eventDateSelected, handleChangeEventDate} = props;
  const dateOptions =  [];
  let dateSelected = null;
  let dateIndex = 0;

  if (!eventDateSelected) {
    dateSelected = 0;
  } else {
    dateSelected = eventDateSelected;
  }

  event.events_dates.forEach((element) => {
    dateOptions.push({
      value: dateIndex,
      label: 'Day ' + (dateIndex+1) + ' - ' + moment(element.start_date).format("MM/DD/YYYY")
    })
    dateIndex++;
  });

  return (
    <div className="EventDatesNav">
      <Select
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            paddingLeft: '48px',
          }),
        }}
        isSearchable={false}
        name="dateSelector"
        options={dateOptions}
        className="EventDates-select"
        classNamePrefix="select"
        value={dateOptions[dateSelected]}
        onChange={handleChangeEventDate}
      />
    </div>
  )
}

export default EventDatesNav
