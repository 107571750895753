import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Loading from '../../Common/loading/loading';
import { SwipeableViewsContext } from '../Settings';
import { CLOTHES_TYPES, getClothesSizesMiddleware } from './ClothesSettingsUtils/ClothesSettingsUtils';
import ClothesSizeCard from './ClothesSettingsUtils/ClothesSizeCard';
import './ClothesSettings.scss';

export const ClothesSettingsContext = React.createContext();

function ClothesSettings() {
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [fecthData, setFetchData] = useState(true);
  const [clothesItems, setClothesItems] = useState(null);

  useEffect(() => {
    swipeableViewsContext.updateSwipeableHeight();
  });

  useEffect(() => {
    const fetchColthesSizes = async () => {
      const { data } = await getClothesSizesMiddleware();
      if (data.errors) {
        data.errors.map(item => toast.error(item));
        setFetchData(false);
        return
      }
      setClothesItems({
        shirtSizes: data.shirtSizes,
        blazerSizes:data.blazerSizes,
        coatSizes: data.coatSizes
      });
      setLoading(false);
      setFetchData(false);

    };  
    fecthData && fetchColthesSizes();
    
  }, [fecthData]);

  return (
    loading
      ? <Loading type="tabsContentLoading" />
      : <ClothesSettingsContext.Provider value={{fecthData, setFetchData}} >
          <section className="ClothesSettings content-wrapper container-fluid">
            <div className="row">
              {Object.keys(CLOTHES_TYPES).map((item, idx) => {
                return (
                  <div key={idx} className="col-sm-6 col-md-6 col-lg-4">
                    <ClothesSizeCard  CLOTHES_TYPE={CLOTHES_TYPES[item]} CLOTHES_TYPE_DATA={clothesItems[CLOTHES_TYPES[item].DATA]} />
                  </div>
                )
              })}
            </div>
          </section>
        </ClothesSettingsContext.Provider>
  )
};

export default ClothesSettings
