import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import auth from "./services/authService";
import { isEnvDev } from "./utils/environment";
import NotFound from "./components/notFound";
import NavBar from "./components/Navbar/navBar";
import LoginForm from "./components/Login/loginForm";
import ProfileForm from "./components/Profile/profileForm";
import Logout from "./components/logout";
import ProtectedRoute from "./components/Common/protectedRoute";
import Dashboard from "./components/Dashboard/dashboard";
import Staff from "./components/Staff/staff";
import StaffForm from "./components/Staff/staffForm";
import Applicants from "./components/Applicants/applicants";
import ApplicantsForm from "./components/Applicants/applicantsForm";
import Volunteers from "./components/Volunteers/volunteers";
import VolunteersForm from "./components/Volunteers/volunteersForm";
import Customers from "./components/Customers/customers";
import CustomersForm from "./components/Customers/customersForm";
import Settings from "./components/Settings/Settings";
import Logs from "./components/Logs/Logs";
import Venues from "./components/Venues/venues";
import VenuesForm from "./components/Venues/venuesForm";
import Footer from "./components/Common/footer";
import Events from "./components/Events/events";
import Reports from "./components/Reports/Reports";
import Users from "./components/Users/users";
import UserForm from "./components/Users/usersForm";
import Event from "./components/Events/Event/Event";
import EventCreate from "./components/Events/Event/EventCreate";
import EventCalendar from "./components/Events/Calendar/calendar";
import Preferences from "./components/preferences"
import Notification from "./components/notification"
import "./App.scss";

const appName = process.env.REACT_APP_NAME;

class App extends Component {
  state = {};
  
  componentDidMount() {
    document.title = isEnvDev ? "⚒" + appName : appName;
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        {auth.getCurrentUser() && 
          <NavBar />
        }
        <Switch>
          <Route path="/login" component={LoginForm} />
          <Route path="/logout" component={Logout} />
          <Route path="/profile" component={ProfileForm} />
          <Route path="/preferences/:token" component={Preferences} />
          <Route path="/n/:token/:source" component={Notification} />

          <ProtectedRoute 
            path="/dashboard/" 
            component={Dashboard} authModule="module_dashboard" 
          />
          <ProtectedRoute
            path="/staff/:id"
            render={ props => <StaffForm {...props} user={ user } /> }
            authModule="module_staff"
          />
          <ProtectedRoute
            path="/staff/"
            render={props => <Staff {...props} user={user} />}
            authModule="module_staff"
          />

          <ProtectedRoute
            path="/applicants/:id"
            render={ props => <ApplicantsForm {...props} user={ user } /> }
            authModule="module_applicants"
          />
          <ProtectedRoute
            path="/applicants/"
            render={props => <Applicants {...props} user={user} />}
            authModule="module_applicants"
          />

          <ProtectedRoute
            path="/volunteers/:id"
            render={ props => <VolunteersForm {...props} user={ user } /> }
            authModule="module_volunteers"
          />
          <ProtectedRoute
            path="/volunteers/"
            render={props => <Volunteers {...props} user={user} />}
            authModule="module_volunteers"
          />
          <ProtectedRoute
            path="/customers/:id"
            render={props => <CustomersForm {...props} user={user} />}
            authModule="module_customers"
          />
          <ProtectedRoute
            path="/customers/"
            render={props => <Customers {...props} user={user} />}
            authModule="module_customers"
          />
          <ProtectedRoute
            path="/venues/:id"
            render={props => <VenuesForm {...props} user={user} />}
            authModule="module_venues"
          />
          <ProtectedRoute
            path="/venues/"
            render={props => <Venues {...props} user={user} />}
            authModule="module_venues"
          />
          <ProtectedRoute
            path="/events/create"
            render={props => <EventCreate {...props} user={user} />}
            authModule="module_events"
          />
          <ProtectedRoute
            path="/events/calendar/:month/:year"
            render={props => <EventCalendar {...props} user={user} />}
            authModule="module_events"
          />
          <ProtectedRoute
            path="/events/:id"
            render={props => <Event {...props} user={user} />}
            authModule="module_events"
          />
          <ProtectedRoute
            path="/events/"
            render={props => <Events {...props} user={user} />}
            authModule="module_events"
          />
          <ProtectedRoute
            path="/reports/:id"
            render={props => <Reports {...props} user={user} />}
            authModule="module_reports"
          />
          <ProtectedRoute
            path="/reports/"
            render={props => <Reports {...props} user={user} />}
            authModule="module_reports"
          />
          <ProtectedRoute
            path="/users/:id"
            render={ props => <UserForm {...props} user={ user } /> }
            authModule="module_users"
          />
          <ProtectedRoute
            path="/users/"
            render={props => <Users {...props} user={user} />}
            authModule="module_users"
          />
          <ProtectedRoute
            path="/logs/"
            render={props => <Logs {...props} user={user} />}
            authModule="module_logs"
          />
          <ProtectedRoute
            path="/settings/"
            render={props => <Settings {...props} user={user} />}
            authModule="module_settings"
          />

          { auth.getCurrentUser() && <Route path="/not-found" component={NotFound} /> }
          <Redirect from="/" exact to="/dashboard" />
          { auth.getCurrentUser()
            ? <Redirect to="/not-found" />
            : <Redirect to="/login" />
          }
        </Switch>
        {auth.getCurrentUser() && <Footer />}
      </React.Fragment>
    );
  }
}

export default App;
