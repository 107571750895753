import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import { fileTypeIcon } from "../../../utils/file-type-icons";
import "./filesUploader.scss"
import Input from '../form/input';

class FilesUploader extends Component {
  state = {
    files: [],
    isFileReady: false,
    settings: {
      maxSize: 5000000, // 5MB
      minSize: 0,
      multiple: false
    }
  }

  handleOnDrop = (files) => {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.fileSaveModelGenerator(file);
      }
    }
  }

  fileSaveModelGenerator = (file) => {
    let filesModel = [];
    const reader = new FileReader();

    reader.onload = (e) => {
      filesModel.push({
        file_name: file.path,
        description: "",
        data: reader.result,
        component: this.props.component,
        component_id: this.props.componentId,
        type: file.type,
        size: file.size,
        extension: file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name
      })
    }
    reader.readAsDataURL(file)
    this.setState({ files: filesModel, isFileReady: true });
  }

  actionClassName = (isDragActive, isDragReject, rejectedFiles) => {
    if (isDragActive && !isDragReject )
      return 'dropzone drag-hover';

    if (isDragReject)
      return 'dropzone drag-hover-error';
    
    if (rejectedFiles.length > 0 && rejectedFiles[0].size > this.state.settings.maxSize)
      return 'dropzone drag-hover-error';

    return 'dropzone'
  }

  handleChange = ({ currentTarget: input }) => {
    const files = { ...this.state.files };
    files[0][input.name] = input.value;

    this.setState({ files })
  }
  
  uploadFiles = async e => {
    e.preventDefault();
    const filesToUpload = this.state.files;
    this.props.handleUploadFile(filesToUpload);
    this.setState({isFileReady: false});
  }

  cancelUpload = () => {
    this.setState({isFileReady: false});
  }

  render() { 
    const { settings, isFileReady } = this.state
    const renderDragMessage = {
      init: (message) => { return (<p className="">{message}</p>) },
      success: (message) => { return (<p>{message}</p>) },
      error: (message) => { return (<p className="text-danger">{message}</p>) }
    }

    return ( 
      <div className="file-uploader">
        <Dropzone 
          onDrop={acceptedFiles => this.handleOnDrop(acceptedFiles) }
          multiple={settings.multiple} 
          minSize={settings.minSize} 
          maxSize={settings.maxSize} 
        >
          {({getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles, rejectedFiles, maxSize }) => {
            const isFileMaxSize = rejectedFiles.length > 0 && rejectedFiles[0].size > settings.maxSize;
            return (
              <React.Fragment>
                <div className="dropzone-uploader">
                  <div className={ this.actionClassName(isDragActive, isDragReject, rejectedFiles) } {...getRootProps()}>
                    <input {...getInputProps()} />
                    <i className="fas fa-cloud-upload-alt"></i>
                    {!isDragActive && renderDragMessage.init('Click here or drop a file to upload!')}
                    {isDragActive && !isDragReject && renderDragMessage.success("Drop it like it's hot!")}
                    {isDragReject && renderDragMessage.error("File type not accepted, sorry!",)}
                    {!isDragActive && isFileMaxSize && renderDragMessage.error("File is too large.")}
                  </div>
                  <ul className="list-group">
                    {isFileReady && acceptedFiles.map(acceptedFile => (
                        <li key={acceptedFile.name} className={"list-group-item list-group-item-success d-flex align-items-baseline"}>
                          <i className={fileTypeIcon(acceptedFile.type)}></i>
                          <span>{acceptedFile.name}</span>
                        </li>
                      ) 
                    )}
                  </ul>
                </div>
                <br/>
                {isFileReady && 
                  <React.Fragment>
                    <Input
                      type={'text'}
                      name={'file_name'}
                      placeholder={acceptedFiles[0].name}
                      label={'File Name'}
                      onChange={this.handleChange}
                    />
                    <Input
                      type={'text'}
                      name={'description'}
                      label={'File Description'}
                      onChange={this.handleChange}
                    />
                    <p className="text-right">
                      <button className="btn btn-outline-secondary btn-sm btn-cancel mr-2" onClick={this.cancelUpload}>
                        Cancel Upload
                      </button>
                      &nbsp;
                      <button className="btn btn-primary btn-sm" onClick={this.uploadFiles}>
                        Upload File
                      </button>
                    </p>
                  </React.Fragment>
                }
              </React.Fragment>
            )
          }}
        </Dropzone>
      </div>
    );
  }
}

export default FilesUploader;
