import React, { useEffect, useState, useLayoutEffect } from 'react';
import _ from 'lodash';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import { getCurrentUser } from '../../services/authService';
import { getReportsList } from '../../services/reportsServices';
import ReportsPageHeader from './ReportsItems/ReportsPageHeader';
import ReportsList from './ReportsItems/ReportsList';
import Report from './Report/Report';
import './Reports.scss';

export const ReportsContext = React.createContext();

function Reports(props) {
  const [pagetitle, setPagetitle] = useState();
  const [reports, setReports] = useState(null);
  const [reportSelected, setReportSelected] = useState(null);
  const [pageWrapperHeight, setPageWrapperHeight] = useState(0);

  const fetchReports = async () => {
    const { data } = await getReportsList();
    const reports = _.orderBy(data.reports, ['sort'], ['asc']);
    setPagetitle('Reports');
    setReports(reports);
    setReportSelected(null);
  };

  const generateReport = async (urlParams) => {
    const { data } = await getReportsList();
    const report = data.reports.find(item => {
      const name = item.name.replace(/\s+/g, '-').toLowerCase();
      if (name === urlParams) return item;
      else return null;
    });

    if (!report) {
      setPagetitle('Report Not Found');
      return
    }

    setPagetitle(report.name + ' Report');
    setReportSelected(report);
  };

  useEffect(() => {
    const urlParams = props.match.params.id;
    !urlParams ? fetchReports() : generateReport(urlParams);
  }, [props.match.params.id]);

  useEffect(() => {
    setPageWrapperHeight(PageWrapperHeight());
  }, []);

  useLayoutEffect(() => {
    const handleResize = () => {
      setPageWrapperHeight(PageWrapperHeight());
    };
    window.addEventListener('resize', handleResize)

    return _ => {
      window.removeEventListener('resize', handleResize)
    }
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={props.location} />
      : <ReportsContext.Provider
          value={{
            props,
            pagetitle,
            reports,
            reportSelected
          }}
        >
          <main className="ReportsPage page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
            <ReportsPageHeader />
            <section className="page-body">
            { !props.match.params.id 
              ? <ReportsList />
              : <Report />
            }
            </section>
          </main>
        </ReportsContext.Provider>
  )
}

export default Reports
