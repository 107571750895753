import React from 'react';
import { Modal } from 'react-bootstrap';
import  moment from "moment";
import { currencyUSD } from '../../../utils/value-formats';
import _ from "lodash";

const StaffPayRateHistoryLogsModal = ({ isOpen, closeModal, item }) => {
  const historyLogs = _.orderBy(item.logs, ['created_date'], ['desc']);
  
  return (
    <Modal show={isOpen} onHide={closeModal} className="modal-staff-payrates-history-logs">
      <Modal.Header closeButton>
        <Modal.Title>
          <i className={item.class}></i>&nbsp;{item.position_name} – History Log
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="scroll-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>Date/Time</th>
                <th>User</th>
                <th>Pay rate</th>
                <th>Travel Pay rate</th>
              </tr>
            </thead>
            <tbody>
              {isOpen && historyLogs.map( log => (
                <tr key={log.id}>
                  <td>{moment(log.created_date).format("MM/DD/YYYY HH:mm")}</td>
                  <td>{log.user}</td>
                  <td className="pay-rate-col">{log.regular_pay_rate && currencyUSD(log.regular_pay_rate)}</td>
                  <td className="pay-rate-col">{log.travel_pay_rate && currencyUSD(log.travel_pay_rate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default StaffPayRateHistoryLogsModal;
