import React, { Component } from "react";

// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {
  raiseSort = path => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = column => {
    const { sortColumn, isLoading, overLoading } = this.props;
    const visibility = (isLoading || overLoading) ? 'invisible ' : '' ;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className={visibility + "fa fa-sort-asc"} />;
    return <i className={visibility + "fa fa-sort-desc" }/>;
  };

  render() {
    const { overLoading, isLoading } = this.props;
    const disableSort = (this.props.disableSort || this.props.onSort === undefined) ;

    return (
      <thead>
        <tr>
          {this.props.columns.map(column => (
            <th
              className={ (isLoading || overLoading || column.disableSort || disableSort) ? column.path : 'clickable ' + column.path }
              key={column.path || column.key}
              onClick={() => !isLoading && !overLoading && !column.disableSort && !disableSort && this.raiseSort(column.path)}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
