import React, { Component } from "react";
import '../../src/Public.scss';
import logo from "../assets/cms-logo-login.svg";
import { toast } from "react-toastify";
import Switch from "./Common/form/switch";
import { messages } from '../utils/language-en.json';
import { getStaffPreferences, updateStaffPreferences } from "../services/staffService";

const renderSwitch = ( name, label, value, handleChange, required = false, centerElement = true, strongTitle = false, justifyContent = "start", flexDirection = "column") => { 
  return (
    <Switch
      name={name}
      checked={value}
      value={(value) ? value : false}
      label={label}
      onChange={handleChange}
      required={required} 
      strongTitle={strongTitle}
      centerElement={centerElement}
      justifyContent={justifyContent}
      flexDirection={flexDirection} />
  )
}

class Preferences extends Component {
  state = {
    validNotification: true,
    staffId: null,
    staffName: null,
    emailOptIn: null,
    smsOptIn: null
  };

  async componentDidMount() {
    await this.getStaffData(this.props.match.params.token);
  }

  async getStaffData(token) {
    const { data: resStaff } = await getStaffPreferences(token);
    const staff = resStaff.staff;
    if (!staff) {
      this.setState({ validNotification: false });
      return
    }
    
    this.setState({ staffId: staff.id });
    this.setState({ staffName: staff.first_name + ' ' + staff.last_name });
    this.setState({ emailOptIn: staff.email_opt_in });
    this.setState({ smsOptIn: staff.sms_opt_in });
  }

  handleChangeEmail = async ({ currentTarget: input }) => {
    try {
      const { data } = await updateStaffPreferences(this.state.staffId, input.checked, this.state.smsOptIn);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      } else {
        toast.success(messages.success.preferencesUpdated, { autoClose: 3000 });
      }

      await this.getStaffData(this.props.match.params.token);
    } catch (ex) {
    }
    return;
  }

  handleChangeSMS = async ({ currentTarget: input }) => {
    try {
      const { data } = await updateStaffPreferences(this.state.staffId, this.state.emailOptIn, input.checked);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      } else {
        toast.success(messages.success.preferencesUpdated, { autoClose: 3000 });
      }

      await this.getStaffData(this.props.match.params.token);
    } catch (ex) {
    }
    return;
  }

  render() {
    const {
      validNotification,
      staffName,
      emailOptIn,
      smsOptIn
    } = this.state;

    return (
      <div className="public">
        <div className="main">
          <div className="header">
            <img src={logo} width="146" height="71" alt={ process.env.REACT_APP_NAME } />
          </div>
          <div className="content">
            {!validNotification
            ? 
              <div className="message">
                <div className="content-center">
                  <h1>Error</h1>
                  <p>This link is not valid.</p>
                  </div>
              </div>
            : 
              <div className="message">
                <div className="content-center">
                  <h1>Hi {staffName}.</h1>
                  <p>You can update these preferences:</p>
                </div>
                
                <div className="preferences">
                  <span className="content">
                    {renderSwitch("emailOptIn", "Receive email communications?", emailOptIn, this.handleChangeEmail )}
                  </span>
                  <span className="content">
                    {renderSwitch("smsOptIn", "Receive SMS communications?", smsOptIn, this.handleChangeSMS )}
                  </span>
                </div>
              </div>
            }

            <p className="disclaimer">
              Crowd Management Services © 2022 - All rights reserved.<br />
              <a href="https://www.cmsprotectsfun.com/terms-privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>&nbsp;|&nbsp;
              <a href="https://www.cmsprotectsfun.com/terms-privacy-policy/" rel="noopener noreferrer" target="_blank">Terms &amp; Conditions</a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default Preferences
