import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core';
import _ from "lodash";
import auth from "../../services/authService";
import { saveVenue, getVenuesItem } from '../../services/venuesService';
import { getDepartmentList } from '../../services/departmentService';
import { getActiveStates } from "../../services/statesService";
import { getTimeZone } from '../../services/timeZoneService';
import { venuesDataModel, venuesSchema, venuesEditMapToViewModel } from '../../models/venuesSchema';
import { messages, pagetitle } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import FilesManager from '../Common/files-manager/filesManager';
import NotesManager from '../Common/notes-manager/notesManager';
import VenuesFormInfo from './venues-forms/venuesForm-Info';
import VenuesConfigurations from './venues-forms/venue-form-configuration/venuesForm-configurations';
import { filterDepartmentsUser } from '../../utils/value-formats';

class VenuesForm extends Form {
  mounted = false;

  state = { 
    dataLoading: false,
    tabIndex: 0,
    data: venuesDataModel(),
    errors: {}
  }

  schema = {};

  venuesForms = [];

  VenuesFormInfo = {
    label: "Venue Info",
    submitButton: true,
    content: idx => (
      <VenuesFormInfo
        key={idx}
        state={this.state}
        data={this.state.data}
        renderInput={this.renderInput}
        renderSelect={this.renderSelect}
        renderButton={this.renderButton}
        optionsCMSdepartments={this.state.optionsCMSdepartments}
        optionsStates={this.state.optionsStates}
        optionsTimeZone={this.state.timezoneOptions}
        isEditing={this.isEditing}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        validate={this.validate}
      />
    )
  }

  VenuesFormConfiguration = {
    label: "Configurations",
    submitButton: false,
    content: idx => (
      <VenuesConfigurations 
        key={idx}
        venueId={this.state.data.id}
      />
    )
  }

  FilesManager = {
    label: "Venue Images",
    content: idx => (
      <FilesManager 
        key={idx} 
        component="Venues"
        loading={this.state.dataLoading} 
        itemId={this.state.data.id}
      />
    )
  }

  NotesManager = {
    label: "Notes",
    content: idx => (
      <NotesManager
        key={idx} 
        component="Venues"
        loading={this.state.dataLoading} 
        itemId={this.state.data.id}
      />
    )
  }

  constructor(props) {
    super(props);
    if (this.authPermissions("module_venues_tab_info")) this.venuesForms.push(this.VenuesFormInfo);
    if (this.isEditing() && this.authPermissions("module_venues_tab_configurations")) this.venuesForms.push(this.VenuesFormConfiguration);
    if (this.isEditing() && this.authPermissions("module_venues_tab_images")) this.venuesForms.push(this.FilesManager);
    if (this.isEditing() && this.authPermissions("module_venues_tab_notes")) this.venuesForms.push(this.NotesManager);
  }
  
  async componentDidMount() {
    this.mounted = true;
    this.setState({ dataLoading: true })
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = filterDepartmentsUser(sortDepartments, '');
    const { data: resTimeZone } = await getTimeZone();
    const timezoneOptions = [{ id: '', name: '' }, ...resTimeZone.time_zone];
    const { data: resStates } = await getActiveStates();
    const states = [{ id: '', name: '' }, ...resStates.states];
    if (this.mounted) this.setState({ optionsStates: states, dataLoading: false })
    this.schema = venuesSchema(this.isEditing());
    if (this.mounted) this.setState({ optionsCMSdepartments: departments, timezoneOptions, dataLoading: false })
    const venueName = await this.populateVenues();
    const titlePage =  ( this.isEditing() ) ? pagetitle.venueEdit + ' — ' + venueName : pagetitle.venueCreate ;
    this.setState({ pagetitle: titlePage, submitButton: this.venuesForms[0].submitButton })
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateVenues() {
    try {
      const id = this.props.match.params.id;
      if ( id === 'create') return;
      this.setState({ dataLoading: true })
      
      const { data: resVenues } = await getVenuesItem(id);
      const venues = resVenues.venue;
      
      this.setState({ data: venuesEditMapToViewModel(venues) });
      this.setState({ dataLoading: false })

      return venues.name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  authPermissions = (module) => {
    const authPermissions = auth.getCurrentUserPermisions();
    return authPermissions[module];
  }

  isEditing = () => {
    const urlParams = this.props.match.params.id;
    const isEditing = ( urlParams === 'create') ? false : true ;
    return isEditing
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value,
      submitButton: this.venuesForms[value].submitButton
    });
  };

  handleChangeTabsIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  doHandleChange = input => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
    }
    return value;
  }

  doSubmit = async () => {
    try {
      const { data } = await saveVenue(this.state.data);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      if (this.isEditing()) {
        toast.success(messages.success.venueUpdated, { autoClose: 1000 })
      } else {
        toast.success(messages.success.venueCreated, { autoClose: 1000 });
        this.props.history.push("/venues/" + data.id);
        setTimeout(() => {
          window.location.reload()
        }, 500);
      }
    } catch (ex) {
    }
    return;
  };

  render() { 
    const { 
      dataLoading,
      pagetitle,
      tabIndex,
      pageWrapperHeight
    } = this.state;

    if (!auth.getCurrentUser())
      return <RedirectToLogin location={this.props.location} />

    if (!this.isEditing() && !this.authPermissions("module_venues_add"))
      return (
        <Redirect
          to={{
            pathname: "/venues",
            state: { from: this.props.location }
          }}
        />
      )

    return (
      <main className="venues-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <Loading active={dataLoading} />
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-location-arrow"></i>&nbsp;{pagetitle}</h1>
            </div>
          </div>
        </section>
        {!dataLoading && 
          <section className="page-body">
            <Tabs 
              value={tabIndex}  
              onChange={this.handleChangeTabs} 
              classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
            >
              {this.venuesForms.map((form, idx) => (
                <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
              ))}
            </Tabs>
            <SwipeableViews 
              index={tabIndex}
              onChangeIndex={this.handleChangeTabsIndex} 
              animateHeight={true}
              animateTransitions={true} >
              {this.venuesForms.map((form, idx) => (
                form.content(idx)
              ))}
            </SwipeableViews>
          </section>
        }
      </main>
    );
  }
}

export default VenuesForm;
