import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import RedirectToLogin from '../../../utils/RedirectToLogin';
import { getCurrentUser } from '../../../services/authService';
import { getPaycomReportHistory } from '../../../services/logsService';
import Pagination from '../../Common/pagination/pagination';
import Table from '../../Common/table/table';
import { SwipeableViewsContext, LogsContext } from '../Logs';
import './PaycomHistory.scss';

function PaycomHistory() {
  const logsContext = useContext(LogsContext);
  const swipeableViewsContext = useContext(SwipeableViewsContext);
  const [loading, setLoading] = useState(true);
  const [paycomReportHistory, setPaycomReportHistory ] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "start_date", order: "desc" });
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = [
    {
      path: 'start_date',
      label: 'Start Date'
    },
    {
      path: 'end_date',
      label: 'End Date'
    },
    {
      path: 'user',
      label: 'User',
      disableSort: true
    },
    {
      path: 'summary',
      label: 'Summary',
      disableSort: true
    },
    {
      path: 'status',
      label: 'Status'
    }
  ];

  useEffect(() => {
    const fetchPaycomHistory = async () => {
      const { data } = await getPaycomReportHistory({
        LIMIT: pageSize,
        PAGE: currentPage,
        ORDER_BY: sortColumn.path,
        SORT_ORDER: sortColumn.order
      });
      if (!data.success) {
        toast.error('Error on get Paycom History data.');
        return
      }
      setPaycomReportHistory(data.logs);
      setTotalCount(data.count);
      setLoading(false);
    };
    fetchPaycomHistory();
  }, [currentPage, sortColumn]);

  useEffect(() => {
    swipeableViewsContext && swipeableViewsContext.updateSwipeableHeight();
  });

  return (
    !getCurrentUser()
      ? <RedirectToLogin location={logsContext.props.location} />
      : <section className="PaycomHistory content-wrapper container-fluid">
          <Table
            name="PaycomHistoryTable"
            columns={columns}
            data={paycomReportHistory}
            sortColumn={sortColumn}
            onSort={sortColumn => setSortColumn(sortColumn)}
            isLoading={loading}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={ page => setCurrentPage(page) }
          />
        </section>
  )
}

export default PaycomHistory
