import http from "./httpService";

const apiEndpoint = "/user";

export function getUsers({ LIMIT = 1000, PAGE = 1, ORDER_BY = ['first_name'], SORT_ORDER = "ASC", SEARCH }) {
  return http.get(apiEndpoint + '/list', {
    params: {
      limit: LIMIT, 
      page: PAGE,
      order_by: ORDER_BY,
      sort_order: SORT_ORDER,
      search: SEARCH
    }
  } );
}

export function getUsersByRole(roleId) {
  return http.get(apiEndpoint + '/list_users_role', { 
    params: {
      role_id: roleId 
    }
  });
}

export function getUserItem(userId) {
  return http.get(apiEndpoint + '/get_id', { 
    params: {
      id: userId 
    }
  });
}

export function saveUser(userItem) {
  if (userItem.id) {
    const body = { ...userItem };
    return http.post(apiEndpoint + '/update', body);
  }

  return http.post(apiEndpoint + '/add', userItem);
}

export function validateEmail(userEmail) {
  return http.get( apiEndpoint + '/email_validation', { 
    params: {
      email: userEmail 
    }
  });
}

export function updateUserProfile(firstName, lastName, currentPassword, newPassword) {
  return http.post(apiEndpoint + '/update_personal', {  
    first_name: firstName, 
    last_name: lastName,
    current_password: currentPassword,
    new_password: newPassword
  });
}

export async function updateUserProfileMiddleware(userInfo) {
  try {
    var firstName = userInfo.first_name;
    var lastName = userInfo.last_name;
    var currentPassword = userInfo.password;
    var newPassword = userInfo.new_password;

    const { data } = await updateUserProfile(firstName, lastName, currentPassword, newPassword);
    if (!data.success) {
      return { success: false, message: data.message }
    }
    return { success: true, message: data.summary }
  } catch (ex) {
    if (ex.response && ex.response.status === 404)
      return { success: false, message: ex.message }
  }
}

export async function changePasswordUser(id, password) {
  return http.post(apiEndpoint + '/change_password', {  
    id: id, 
    password: password
  });
}
