import http from "./httpService";

const apiEndpoint = "/shirt_size";

export function getShirtSizes() {
  return http.get(apiEndpoint + '/list');
}

export function addShirtSize(newSize) {
  return http.post(apiEndpoint + '/add', {name: newSize.shirtSize} );
}

export function deleteShirtSize(id) {
  return http.post(apiEndpoint + '/delete', { id: id } );
}
