import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Modal , Button } from 'react-bootstrap';
import { updateEventReconciliationBulkEndTimes } from '../../../../../services/eventsService';
import Datepicker from '../../../../Common/form/datepicker';
import { messages } from "../../../../../utils/language-en.json";
import { EventFetchContext } from '../../Event';
import { EventReconciliationContext } from '../EventReconciliation';

function EventReconciliationToolBulkEndTime() {
  const event = useContext(EventReconciliationContext).event;
  const eventFetchContext = useContext(EventFetchContext);
  const eventReconciliationContext = useContext(EventReconciliationContext);
  const eventdate = useContext(EventReconciliationContext).eventdate;
  const [bulkEndTime, setBulkEndTime] = useState(false);
  const [endTimeSelected, setEndTimeSelected] = useState();

  const CustomTimeInput = ({ date, value, onChange }) => (
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );

  useEffect(() => {
    let dateValue = eventdate && eventdate.end_date;
    dateValue = moment(dateValue).format('MM/DD/YYYY HH:mm');
    
    bulkEndTime && setEndTimeSelected(new Date(dateValue));
  },[eventdate, bulkEndTime]);

  // ** HANDLE
  // *********************************************************
  const handleSaveBulkEndTime = async () => {
    setBulkEndTime(false);
    let date = moment(endTimeSelected).format('MM/DD/YYYY HH:mm');
    const dataToSave = {
      event_date_id: eventdate.id,
      date: date,
      type: 'End'
    }

    try {
      const { data } = await updateEventReconciliationBulkEndTimes(dataToSave);
      const success = data.success;
      if (!success) {
        const errorMessage = data.data.message;
        toast.error(errorMessage);
        return
      }
      toast.success('Reconciliation Bulk End Time Updated!', { autoClose: 1000 });
      eventReconciliationContext.fetchReconciliationList(event.id, eventReconciliationContext.eventdate.id)
      eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_ASSIGNED', VALUE: true });
      setTimeout(() => {
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_STAFF_STATS', VALUE: true });
        eventFetchContext.dispatchFetchData({ TYPE: 'EVENT_EXPENSES', VALUE: true });
      }, 500);
    } catch (error) {}
  };

  const handleChangeDate = (date) => {
    const dateFormat = 'MM/DD/YYYY – HH:mm';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    
    if (moment(toDateFormat, dateFormat, true).isValid()) {
      setEndTimeSelected(date);
    } else {
      toast.error(messages.error.dateTimeInvalid);
    }
  }

  const CustomInput = React.forwardRef((props,ref) => {
    return (
      <button type="button" className="btn btn-datepicker"
        onClick={props.onClick} 
        ref={ref}>
        {props.value || props.placeholder} &nbsp;<span className="fas fa-calendar-day"></span>
      </button>
    )
  });

  return (
    <>
      <button type="button" className="btn btn-primary"
        onClick={() => setBulkEndTime(true)}
      >Set all End Times</button>

      <Modal show={bulkEndTime} onHide={() => setBulkEndTime(false)} className="EventReconciliationToolBulkEndTime-modal">

        <Modal.Header>
          <Modal.Title>
            <i className="far fa-clock"></i>&nbsp;Set All End Time
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row justify-content-center">
            <div className="form-group">
              <Datepicker
                className={"datepicker-input form-control form-control-sm"}
                selected={endTimeSelected}
                name={'bullk-end-time'}
                onChange={(date) => handleChangeDate(date)}
                fixedHeight
                calendarClassName="event-datepicker"
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy HH:mm"
                customTimeInput={<CustomTimeInput/>}
                showTimeSelect
                timeCaption="Time"
                customInput={<CustomInput />}
              />
              </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-11">
              <p className="disclaimer text-center">
                This adjustment will change all the selected employees
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setBulkEndTime(false)}>Cancel</Button>
          <Button variant="success"  
            onClick={() => handleSaveBulkEndTime()} 
          >Adjust All End Times</Button>
        </Modal.Footer>

      </Modal>
    </>
  )
}

export default EventReconciliationToolBulkEndTime
