import React from "react";
import { messages } from '../../../utils/language-en.json';
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import "./table.scss";
import EmptyData from "../emptydata/emptyData";
import EmptyDataIcon from "../../../assets/cms-icon-empty-files.svg";

const renderLoading = () => {
  return (
    <div className="table-loading d-flex justify-content-center align-items-center">
      <div className="loader">
        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
    </div>
  );
}

const Table = ({ columns, sortColumn, onSort, data, isLoading, overLoading, name, itemIdSelected, onClickRow, disableSort }) => {

  return (
    <React.Fragment>
      <table className={'table ' + name }>
        <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} disableSort={disableSort} isLoading={isLoading} overLoading={overLoading} />
        {data.length > 0 &&
          <TableBody columns={columns} data={data} name={name} itemIdSelected={itemIdSelected} onClickRow={onClickRow} />
        }
      </table>
      {!isLoading && data.length === 0 && !overLoading &&
        <EmptyData 
          type={"datatable"} 
          icon={EmptyDataIcon}
          title={messages.emptydata.tableTitle}
          messsage={messages.emptydata.tableCopy}
        />
      }
      { isLoading && renderLoading() }
      { overLoading && data.length === 0 && renderLoading() }
    </React.Fragment>
  );
};

export default Table;
