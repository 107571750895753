import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import Tabs from '@material-ui/core/Tabs';
import { Tab } from '@material-ui/core';
import _ from "lodash";
import auth from "../../services/authService";
import { saveOrganization, getOrganizationItem, deleteVolunteer } from '../../services/volunteersService';
import { getDepartmentList } from '../../services/departmentService';
import { organizationsDataModel, organizationsSchema, organizationsEditMapToViewModel } from '../../models/volunteersSchema';
import { messages, pagetitle } from '../../utils/language-en.json';
import { PageWrapperHeight } from '../../utils/LayoutSizes';
import RedirectToLogin from '../../utils/RedirectToLogin';
import Form from "../Common/form/form";
import Loading from '../Common/loading/loading';
import VolunteersFormInfo from './volunteers-forms/volunteersForm-Info';
import VolunteersFormStaff from './volunteers-forms/volunteersForm-Staff';
import DeleteConfirmation from '../Common/delete-confirmation/deleteConfirmation';
import { filterDepartmentsUser } from '../../utils/value-formats';

class VolunteersForm extends Form {
  mounted = false;

  state = { 
    dataLoading: false,
    tabIndex: 0,
    data: organizationsDataModel(),
    deleteItemSelected : [],
    sortColumn: { path: "last_name", order: "asc"},
    errors: {}
  }

  schema = {};

  organizationsForms = [];

  VolunteersFormInfo = {
    label: "Organization Info",
    submitButton: true,
    content: idx => (
      <VolunteersFormInfo
        key={idx}
        state={this.state}
        data={this.state.data}
        renderInput={this.renderInput}
        renderSelect={this.renderSelect}
        renderButton={this.renderButton}
        renderSwitch={this.renderSwitch}
        optionsCMSdepartments={this.state.optionsCMSdepartments}
        isEditing={this.isEditing}
        handleChange={this.handleChange}
        handleSubmit={this.handleSubmit}
        validate={this.validate}
      />
    )
  }

  VolunteersFormStaff = {
    label: "Volunteers",
    submitButton: false,
    content: idx => (
      <VolunteersFormStaff 
        key={idx}
        organizationId={this.state.data.id}
        sortColumn={this.state.sortColumn.path}
        onSort={this.handleSort}
        onDelete={this.openModalDeleteConfirmation}
      />
    )
  }

  constructor(props) {
    super(props);
    if (this.authPermissions("module_volunteers_tab_info")) this.organizationsForms.push(this.VolunteersFormInfo);
    if (this.isEditing() && this.authPermissions("module_volunteers_tab_staff")) this.organizationsForms.push(this.VolunteersFormStaff);
  }

  openModalDeleteConfirmation = item => {
    this.setState({ deleteConfirmationModalIsOpen: true, deleteItemSelected: item });
  }

  closeModalDeleteConfirmation = () => {
    this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
  }

  handleDelete = async () => {
    const item = this.state.deleteItemSelected;

    try {
      const { data } = await deleteVolunteer(item.id);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      this.setState({ deleteConfirmationModalIsOpen: false, deleteItemSelected: [] });
      toast.success(messages.success.volunteerDeleted, { autoClose: 1000 });
    } catch (ex) {
    }
  };
  
  async componentDidMount() {
    this.mounted = true;
    this.setState({ dataLoading: true })
    const { data: resDepartments } = await getDepartmentList();
    const sortDepartments = _.orderBy(resDepartments.department, ['name'], ['asc']);
    const departments = filterDepartmentsUser(sortDepartments, '');
    
    this.schema = organizationsSchema(this.isEditing());
    if (this.mounted) this.setState({ optionsCMSdepartments: departments, dataLoading: false })
    
    const organizationName = await this.populateOrganization();
    const titlePage =  ( this.isEditing() ) ? pagetitle.organizationEdit + ' — ' + organizationName : pagetitle.organizationCreate ;
    this.setState({ pagetitle: titlePage, submitButton: this.organizationsForms[0].submitButton })
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
    window.addEventListener('resize', this.updatePageWrapperHeight);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updatePageWrapperHeight);
  }

  updatePageWrapperHeight = () => {
    this.setState({ pageWrapperHeight: PageWrapperHeight() });
  };

  async populateOrganization() {
    try {
      const id = this.props.match.params.id;
      if ( id === 'create') return;
      this.setState({ dataLoading: true });
      
      const { data: resOrganization } = await getOrganizationItem(id);
      const organization = resOrganization.organization;
      this.setState({ data: organizationsEditMapToViewModel(organization) });
      this.setState({ dataLoading: false });

      return organization.name;
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  authPermissions = (module) => {
    const authPermissions = auth.getCurrentUserPermisions();
    return authPermissions[module];
  }

  isEditing = () => {
    const urlParams = this.props.match.params.id;
    const isEditing = ( urlParams === 'create') ? false : true ;
    return isEditing
  }

  handleChangeTabs = (event, value) => {
    this.setState({
      tabIndex: value,
      submitButton: this.organizationsForms[value].submitButton
    });
  };

  handleChangeTabsIndex = tabIndex => {
    this.setState({
      tabIndex,
    });
  };

  doHandleChange = input => {
    let value = input.value
    if ( input.type === 'checkbox') {
      value = input.checked;
    }
    return value;
  }

  doSubmit = async () => {
    try {
      const { data } = await saveOrganization(this.state.data);
      const success = data.success;
      if (!success) {
        const errorMessage = data.message;
        toast.error(errorMessage);
        return
      }
      if (this.isEditing()) {
        toast.success(messages.success.organizationUpdated, { autoClose: 1000 })
      } else {
        toast.success(messages.success.organizationCreate, { autoClose: 1000 });
        this.props.history.push("/volunteers/" + data.id);
        setTimeout(() => {
          window.location.reload()
        }, 500);
      }
    } catch (ex) {
    }
    return;
  };

  render() { 
    const { 
      dataLoading,
      pagetitle,
      tabIndex,
      pageWrapperHeight,
      deleteConfirmationModalIsOpen,
      deleteItemSelected
    } = this.state;

    if (!auth.getCurrentUser())
      return <RedirectToLogin location={this.props.location} />

    if (!this.isEditing() && !this.authPermissions("module_volunteers_add"))
      return (
        <Redirect
          to={{
            pathname: "/volunteers",
            state: { from: this.props.location }
          }}
        />
      )

    return (
      <main className="volunteers-page page-wrapper container-fluid" style={{minHeight: pageWrapperHeight + "px"}}>
        <Loading active={dataLoading} />
        <section className="page-header">
          <div className="row">
            <div className="col">
              <h1 className="page-title"><i aria-hidden="true" className="fas fa-hands-helping"></i>&nbsp;{pagetitle}</h1>
            </div>
          </div>
        </section>
        {!dataLoading && 
          <section className="page-body">
            <Tabs 
              value={tabIndex}  
              onChange={this.handleChangeTabs} 
              classes={{ root: 'cms-tabs', indicator: 'cms-tabs-indicator' }}
            >
              {this.organizationsForms.map((form, idx) => (
                <Tab key={idx} label={form.label} classes={{ root: 'cms-tab', selected: 'cms-tab-selected' }} />
              ))}
            </Tabs>
            <SwipeableViews 
              index={tabIndex}
              onChangeIndex={this.handleChangeTabsIndex} 
              animateHeight={true}
              animateTransitions={true} >
              {this.organizationsForms.map((form, idx) => (
                form.content(idx)
              ))}
            </SwipeableViews>
            <DeleteConfirmation
              isOpen={deleteConfirmationModalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModalDeleteConfirmation}
              closeModal={this.closeModalDeleteConfirmation}
              itemName={deleteItemSelected.name}
              onDelete={this.handleDelete}
            />
          </section>
        }
      </main>
    );
  }
}

export default VolunteersForm;
